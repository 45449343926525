import React, { Fragment, useState, useEffect } from "react";
import { Radio } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import "./Eligibility.css";
import Footer from "./../../Footer/NewFooter";
import Contact from "../../../Components/ResponsiveNavBar/Contacts/Contacts";
import Navigation from "../../../Components/myNavbar/navbar";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
}));

const initialValues = {
  age: "",
  maritalStatus: "",
  highSchoolCertificate: "",
  germanCertificate: "",
  speakListenGermanLang: "",
  childless: "",
  childhoodExperiences: "",
  criminalRecords: "",
  goodHealth: "",
  vaccinations: "",
  travalDocuments: "",
  visaExpenses: "",
  travalExpenses: "",
  returnInCountry: "",
  driverLicense: "",
};

const Eligibility = () => {
  const classes = useStyles();
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
    console.log(formValues);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log(formValues);
    //  clear();
  };

  // const clear = () => {
  //   setFormValues(initialValues);
  // };

  useEffect(() => {
    console.log("errors", formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  }, [formErrors, isSubmit, formValues]);

  const validate = (values) => {
    const errors = {};

    if (!values.age) {
      errors.age = "This field is required!";
    }
    if (!values.maritalStatus) {
      errors.maritalStatus = "This field is required!";
    }
    if (!values.highSchoolCertificate) {
      errors.highSchoolCertificate = "This field is required!";
    }
    if (!values.germanCertificate) {
      errors.germanCertificate = "This field is required!";
    }
    if (!values.speakListenGermanLang) {
      errors.speakListenGermanLang = "This field is required!";
    }

    if (!values.childless) {
      errors.childless = "This field is required!";
    }

    if (!values.childhoodExperiences) {
      errors.childhoodExperiences = "This field is required!";
    }

    if (!values.criminalRecords) {
      errors.criminalRecords = "This field is required!";
    }
    if (!values.goodHealth) {
      errors.goodHealth = "This field is required!";
    }
    if (!values.vaccinations) {
      errors.vaccinations = "This field is required!";
    }
    if (!values.travalDocuments) {
      errors.travalDocuments = "This field is required!";
    }
    if (!values.visaExpenses) {
      errors.visaExpenses = "This field is required!";
    }
    if (!values.travalExpenses) {
      errors.travalExpenses = "This field is required!";
    }
    if (!values.returnInCountry) {
      errors.returnInCountry = "This field is required!";
    }
    if (!values.driverLicense) {
      errors.driverLicense = "This field is required!";
    }

    return errors;
  };

  return (
    <Fragment>
      <Contact />
      <Navigation />
      <div className="eligibility">
        <div className="eligibility__page">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xlg={12}>
              <Paper className={classes.paper} elevation={0}>
                <h4 className="eligibility_primary_heading">
                  Aupair Eligibility Checklist
                </h4>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xlg={12}>
              <Paper className={classes.paper} elevation={0}>
                <p className="eligibility-info-paragraph">
                  Before completing the application for the placement, please
                  check whether you meet the the minimum requirements for the
                  program:
                </p>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xlg={4}>
              <Paper className={classes.paper} elevation={0}>
                <div>
                  <label>
                    Age between 18 to 26 years old (both included) ?
                  </label>
                </div>
                <Radio.Group
                  name="age"
                  value={formValues.age}
                  onChange={onChange}
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
                <small className="eligibilityformError">{formErrors.age}</small>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xlg={4}>
              <Paper className={classes.paper} elevation={0}>
                <div>
                  <label>Marital status</label>
                </div>
                <Radio.Group
                  name="maritalStatus"
                  value={formValues.maritalStatus}
                  onChange={onChange}
                >
                  <Radio value="Single">Single </Radio>
                  <Radio value="Maried">Maried</Radio>
                </Radio.Group>
                <small className="eligibilityformError">
                  {formErrors.maritalStatus}
                </small>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xlg={4}>
              <Paper className={classes.paper} elevation={0}>
                <div>
                  <label>
                    Completed high school education with A2 certificate
                    (diplome)
                  </label>
                </div>
                <Radio.Group
                  name="highSchoolCertificate"
                  onChange={onChange}
                  value={formValues.highSchoolCertificate}
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
                <small className="eligibilityformError">
                  {formErrors.highSchoolCertificate}
                </small>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xlg={4}>
              <Paper className={classes.paper} elevation={0}>
                <div>
                  <label>
                    I have German certificate language at level of A1
                  </label>
                </div>
                <Radio.Group
                  name="germanCertificate"
                  onChange={onChange}
                  value={formValues.germanCertificate}
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
                <small className="eligibilityformError">
                  {formErrors.germanCertificate}
                </small>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xlg={4}>
              <Paper className={classes.paper} elevation={0}>
                <div>
                  <label>
                    I speak and listen the German language at level of B1
                  </label>
                </div>
                <Radio.Group
                  name="speakListenGermanLang"
                  onChange={onChange}
                  value={formValues.speakListenGermanLang}
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
                <small className="eligibilityformError">
                  {formErrors.speakListenGermanLang}
                </small>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xlg={4}>
              <Paper className={classes.paper} elevation={0}>
                <div>
                  <label>I have children</label>
                </div>
                <Radio.Group
                  name="childless"
                  onChange={onChange}
                  value={formValues.childless}
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
                <small className="eligibilityformError">
                  {formErrors.childless}
                </small>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xlg={4}>
              <Paper className={classes.paper} elevation={0}>
                <div>
                  <label>I have experince in chirldhood education</label>
                </div>
                <Radio.Group
                  name="childhoodExperiences"
                  onChange={onChange}
                  value={formValues.childhoodExperiences}
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
                <small className="eligibilityformError">
                  {formErrors.childhoodExperiences}
                </small>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xlg={4}>
              <Paper className={classes.paper} elevation={0}>
                <div>
                  <label>I have clear criminal records </label>
                </div>
                <Radio.Group
                  name="criminalRecords"
                  onChange={onChange}
                  value={formValues.criminalRecords}
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
                <small className="eligibilityformError">
                  {formErrors.criminalRecords}
                </small>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xlg={4}>
              <Paper className={classes.paper} elevation={0}>
                <div>
                  <label>I have Good health conditions</label>
                </div>
                <Radio.Group
                  name="goodHealth"
                  onChange={onChange}
                  value={formValues.goodHealth}
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
                <small className="eligibilityformError">
                  {formErrors.goodHealth}
                </small>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xlg={4}>
              <Paper className={classes.paper} elevation={0}>
                <div>
                  <label>I have all Reqiured Vaccinations</label>
                </div>
                <Radio.Group
                  name="vaccinations"
                  onChange={onChange}
                  value={formValues.vaccinations}
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
                <small className="eligibilityformError">
                  {formErrors.vaccinations}
                </small>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xlg={4}>
              <Paper className={classes.paper} elevation={0}>
                <div>
                  <label>
                    I must hold my valid travel documents such passport
                  </label>
                </div>
                <Radio.Group
                  name="travalDocuments"
                  onChange={onChange}
                  value={formValues.travalDocuments}
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
                <small className="eligibilityformError">
                  {formErrors.travalDocuments}
                </small>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xlg={4}>
              <Paper className={classes.paper} elevation={0}>
                <div>
                  <label>I am able to cover visa expenses</label>
                </div>
                <Radio.Group
                  name="visaExpenses"
                  onChange={onChange}
                  value={formValues.visaExpenses}
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
                <small className="eligibilityformError">
                  {formErrors.visaExpenses}
                </small>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xlg={4}>
              <Paper className={classes.paper} elevation={0}>
                <div>
                  <label>I am able to cover your travel expenses</label>
                </div>
                <Radio.Group
                  name="travalExpenses"
                  onChange={onChange}
                  value={formValues.travalExpenses}
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
                <small className="eligibilityformError">
                  {formErrors.travalExpenses}
                </small>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xlg={4}>
              <Paper className={classes.paper} elevation={0}>
                <div>
                  <label>I am willing to return in my country</label>
                </div>
                <Radio.Group
                  name="returnInCountry"
                  onChange={onChange}
                  value={formValues.returnInCountry}
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
                <small className="eligibilityformError">
                  {formErrors.returnInCountry}
                </small>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xlg={4}>
              <Paper className={classes.paper} elevation={0}>
                <div>
                  <label>I have driver´s license</label>
                </div>
                <Radio.Group
                  name="driverLicense"
                  onChange={onChange}
                  value={formValues.driverLicense}
                >
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
                <small className="eligibilityformError">
                  {formErrors.driverLicense}
                </small>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xlg={12}>
              <Paper className={classes.paper} elevation={0}>
                <button
                  className="checkbtn"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Check Now
                </button>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xlg={12}>
              <Paper className={classes.paper} elevation={0}>
                <p className="NB__pragaraph">
                  <strong> NB: </strong>
                  If you find out that you have fulfilled almost of these
                  criterias please contact us on E-mail:
                  contact@das-aupair-matching.de or call us : +25079139823 you
                  can also vist us on our office: KG 1 St., 12 during our
                  working hours for consultancy session
                </p>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Eligibility;
