import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import BrainTreeAPIsModal from "./../../../../utils/payment";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  form: {
    background: "#4f4f4f",
  },
  paper: {
    display: "block",
    width: "400px",
    padding: "5px",
    borderRadius: "0px",
    borderTopLeftRadius: "3px",
    borderBottomLeftRadius: "3px",
    background: "#9e9e9e",
    color: "#000",
  },
  button: {
    width: "50%",
    padding: "5px",
    borderRadius: "0px",
    borderTopRightRadius: "3px",
    borderBottomRightRadius: "3px",
    background: "#464852",
    color: "#fff",
  },
}));

const PaymentModal = ({ plan, setHasSubscribed }) => {
  const classes = useStyles();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [onDone, setOnDone] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (onDone) {
      setIsModalVisible(false);
    } else {
      return;
    }
  }, [onDone]);

  return (
    <>
      <Paper className={classes.button} component="button" onClick={showModal}>
        Find a Match
      </Paper>
      <Modal
        title={`Subscribe for a ${plan.name} Plan`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        bodyStyle={{
          backgroundColor: "#464852",
        }}
      >
        <BrainTreeAPIsModal
          plan={plan}
          setHasSubscribed={setHasSubscribed}
          onDone={setOnDone}
        />
      </Modal>
    </>
  );
};

export default PaymentModal;
