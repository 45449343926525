import React, { useEffect, Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import "./message.css";
import DeleteModal from "./DeleteModal";
import { connect, useSelector } from "react-redux";
import { fetchMessages } from "./../../../Redux/action/messages";
import { Empty } from "antd";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    backgroundColor: "#696969",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 10px 50px",
    paddingRight: "20px",
    borderRadius: "3px",
    color: "#fff",
  },
  inline: {
    display: "inline",
  },
  title: {
    fontSize: "20px",
    textAlign: "center",
    fontWeight: 700,
    color: "#9e9e9e",
    marginBottom: "50px",
  },
}));

const Messages = ({ messages, fetchMessages, setSearchableData, deleted }) => {
  const classes = useStyles();
  const { message: searchResult } = useSelector(
    (state) => state.search.searchResults
  );
  const [open, setOpen] = useState(false);
  const [messageData, setMessageData] = useState([]);

  useEffect(() => {
    const fetchAllMessages = () => {
      fetchMessages();
    };
    if (deleted?.success) {
      fetchAllMessages();
    } else {
      fetchAllMessages();
    }
  }, [fetchMessages, deleted?.success]);

  useEffect(() => {
    if (!messages.success) {
      setOpen(true);
    } else if (messages.success) {
      setOpen(false);
      setMessageData(messages?.message?.data?.contacts);
      setSearchableData(messages?.message?.data?.contacts);
    }
  }, [messages.success, messages?.message?.data?.contacts, setSearchableData]);

  useEffect(() => {
    if (searchResult) {
      setMessageData(searchResult);
    } else if (messages.success) {
      setMessageData(messages?.message?.data?.contacts);
    }
  }, [searchResult, messages.success, messages?.message?.data?.contacts]);

  useEffect(() => {
    const toastify = () => {
      if (deleted?.success) {
        toast.success(deleted?.message?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    toastify();
  }, [deleted?.message?.message, deleted?.success]);

  return (
    <Fragment>
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      {messageData?.length === 0 ? (
        <Empty />
      ) : (
        <List className={classes.root}>
          {messageData &&
            messageData.map((message) => (
              <>
                <ListItem alignItems="flex-start" key={message.uuid}>
                  <ListItemText
                    className="message__body"
                    primary={`${message.name}   ${message.phone}`}
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          color="textPrimary"
                        >
                          <small className="user_email">{message.email}</small>
                        </Typography>
                        &nbsp; &nbsp;
                        <h4 style={{ margin: "20px 0", fontWeight: "bold" }}>
                          {message.subject}
                        </h4>
                        <p className="message__paragraph">{message.message}</p>
                        <div
                          style={{
                            position: "absolute",
                            top: 50,
                            right: 0,
                          }}
                        >
                          <DeleteModal msgId={message.uuid} />
                        </div>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </>
            ))}
        </List>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    messages: state.messages.messages,
    deleted: state.deleteMessage.messages,
  };
};

export default connect(mapStateToProps, { fetchMessages })(Messages);
