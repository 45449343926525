import React from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useStyles } from "./style";
import { connect } from "react-redux";
import { aupairExpectation } from "../../../Redux/action/aupairForms";
import Grid from "@material-ui/core/Grid";
import "./AupairHome.css";
import Navigation from "./../../Page/Landing/Navigation/Navigation";
import Contact from "./../../Page/Landing/Contacts/Contacts";
import AupairHeader from "./AupairHeader";
import Footer from "../../Footer/NewFooter";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const experience = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes ",
    label: "Yes ",
  },
  {
    value: "No",
    label: "No",
  },
  {
    value: "Not Necessary",
    label: "Not Necessary",
  },
];
const famwith = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Couple ",
    label: "Couple ",
  },
  {
    value: "Single mother",
    label: "Single mother",
  },
  {
    value: "Single Father",
    label: "Single Father",
  },
  {
    value: "All family constellation",
    label: "All family constellation",
  },
];
const famwStatus = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Classic Family",
    label: "Classic Family",
  },
  {
    value: "Single Female",
    label: "Single Female",
  },
  {
    value: "Single Male",
    label: "Single Male",
  },
  {
    value: "Divers",
    label: "Divers",
  },
];
const FamilyCountry = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Germany",
    label: "Germany",
  },
  {
    value: "Switzerland",
    label: "Switzerland",
  },
  {
    value: "Austria",
    label: "Austria",
  },
  {
    value: "France",
    label: "France",
  },
  {
    value: "Luxamburg",
    label: "Luxamburg",
  },
  {
    value: "Netherland",
    label: "Netherland",
  },
  {
    value: "Dan-mark",
    label: "Dan-mark",
  },
  {
    value: "No preference",
    label: "No preference",
  },
];
const FamilyLanguage = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Good knowledge of German",
    label: "Good knowledge of German",
  },
  {
    value: "Basic knowledge of German",
    label: "Basic knowledge of German",
  },
  {
    value: "Good knowledge of English",
    label: "Basic knowledge of German",
  },
  {
    value: " Basic knowledge of English",
    label: " Basic knowledge of English",
  },
  {
    value: "Good knowledge of French",
    label: "Good knowledge of French",
  },
  {
    value: "Basic knowledge of French",
    label: "Basic knowledge of French",
  },
  {
    value: "Other language",
    label: "Other language",
  },
];

const smok4g = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes ",
    label: "Yes ",
  },
  {
    value: "No",
    label: "No",
  },
  {
    value: "Yes Not At Home",
    label: "Yes Not At Home",
  },
];
const workPlaned = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes ",
    label: "Yes ",
  },
  {
    value: "No",
    label: "No",
  },
];

const timeTable = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes ",
    label: "Yes ",
  },
  {
    value: "No",
    label: "No",
  },
];

const flexibleFamily = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes ",
    label: "Yes ",
  },
  {
    value: "No",
    label: "No",
  },
];
const IntegrateFamLife = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes ",
    label: "Yes ",
  },
  {
    value: "No",
    label: "No",
  },
];
const eatOnWishes = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes ",
    label: "Yes ",
  },
  {
    value: "No",
    label: "No",
  },
];

const AupairExpectaions = ({ formData, setFormData, navigation }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const {
    famExpAupair,
    famLiveWith,
    childrenNo,
    childrenAge,
    exFamilyStatus,
    famAge,
    famCountry,
    famLanguage,
    smokingHostFam,
    WorkingHrs,
    houseWorkPlanned,
    timetableDef,
    flexibleFam,
    integrateFamLife,
    eatOnWish,
  } = formData;

  const Next = (event) => {
    event.preventDefault();
    setOpen(!open);
    setTimeout(() => {
      setOpen(false);
      navigation.next();
    }, 1000);
  };

  const Previous = (event) => {
    event.preventDefault();
    setOpen(!open);
    setTimeout(() => {
      setOpen(false);
      navigation.previous();
    }, 1000);
  };
  return (
    <React.Fragment>
      <Contact />
      <Navigation />
      <AupairHeader />
      <CssBaseline />
      <div>
        <Backdrop
          style={{ zIndex: 1000, color: "#fff" }}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Container maxWidth="md" className="form_container">
        <form className={classes.root} noValidate autoComplete="off">
          <div className="personalInformationGroup">
            <h3 className="InnerTitles">
              Your Family preference & Expectations
            </h3>

            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    The family with experience{" "}
                    <span className="required">*</span>
                  </label>
                  <select
                    name="famExpAupair"
                    value={famExpAupair}
                    onChange={setFormData}
                  >
                    {experience.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.value}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Live family with <span className="required">*</span>
                  </label>
                  <select
                    name="famLiveWith"
                    value={famLiveWith}
                    onChange={setFormData}
                  >
                    {famwith.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Children Number <span className="required">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="childrenNo"
                    placeholder="Ex: 5-20 years"
                    value={childrenNo}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Children aged <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    name="childrenAge"
                    placeholder="Ex: 5-20 years"
                    value={childrenAge}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Family Status <span className="required">*</span>
                  </label>
                  <select
                    name="exFamilyStatus"
                    value={exFamilyStatus}
                    onChange={setFormData}
                  >
                    {famwStatus.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.value}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Family Age <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    name="famAge"
                    value={famAge}
                    onChange={setFormData}
                    placeholder="Ex:20-75"
                  />
                </div>
              </Grid>
            </div>

            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Family Country <span className="required">*</span>
                  </label>
                  <select
                    name="famCountry"
                    value={famCountry}
                    onChange={setFormData}
                  >
                    {FamilyCountry.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Family Language <span className="required">*</span>
                  </label>
                  <select
                    name="famLanguage"
                    onChange={setFormData}
                    value={famLanguage}
                  >
                    {FamilyLanguage.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    smoking Host Family? <span className="required">*</span>
                  </label>
                  <select
                    name="smokingHostFam"
                    value={smokingHostFam}
                    onChange={setFormData}
                  >
                    {smok4g.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Working Hours <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    name="WorkingHrs"
                    placeholder="Working Hours"
                    value={WorkingHrs}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    House work planned? <span className="required">*</span>
                  </label>
                  <select
                    name="houseWorkPlanned"
                    value={houseWorkPlanned}
                    onChange={setFormData}
                  >
                    {workPlaned.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Defined Timetable ? <span className="required">*</span>
                  </label>
                  <select
                    name="timetableDef"
                    value={timetableDef}
                    onChange={setFormData}
                  >
                    {timeTable.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.value}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Flexible Family ?<span className="required">*</span>
                  </label>
                  <select
                    name="flexibleFam"
                    value={flexibleFam}
                    onChange={setFormData}
                  >
                    {flexibleFamily.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Integrate Family Life ?<span className="required">*</span>
                  </label>
                  <select
                    name="integrateFamLife"
                    value={integrateFamLife}
                    onChange={setFormData}
                  >
                    {IntegrateFamLife.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Freedom to eat on your with?{" "}
                    <span className="required">*</span>
                  </label>
                  <select
                    name="eatOnWish"
                    value={eatOnWish}
                    onChange={setFormData}
                  >
                    {eatOnWishes.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            </div>
          </div>
          <button className="Back" onClick={Previous}>
            <ArrowBackIcon /> &nbsp; Back
          </button>
          <button className="Continue" type="submit" onClick={Next}>
            Next &nbsp; <ArrowForwardIcon />
          </button>
        </form>
      </Container>
      <Footer />
    </React.Fragment>
  );
};
const mapStateToProps = ({ aupairExpectationReducer }) => {
  const { Aupexpect } = aupairExpectationReducer;
  return { Aupexpect };
};
export default connect(mapStateToProps, { aupairExpectation })(
  AupairExpectaions
);
