import React from "react";
import "./FaqFront.css";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const Front = () => (
  <div className="faq-front">
    <div className="faq-with">
      <h1>
        <span className="heading__primary--main">FAQ</span>
        <span className="heading__primary--sub">
          Home
          <ArrowForwardIosIcon />
          FAQ
        </span>
      </h1>
      <div className="general__asked--questions">
        <h3>General Asked Questions</h3>
      </div>
    </div>
  </div>
);
export default Front;
