import {
  GET_REDIRECTION_LINK_START,
  GET_REDIRECTION_LINK_SUCCESS,
  GET_REDIRECTION_LINK_FAILURE,
  GET_TRANSACTION_START,
  GET_TRANSACTION_SUCCESS,
  GET_TRANSACTION_FAILURE,
  GET_PAYMENTS_START,
  GET_PAYMENTS_SUCCESS,
  GET_PAYMENTS_FAILURE,
  PURCHASE_PLAN_START,
  PURCHASE_PLAN_SUCCESS,
  PURCHASE_PLAN_FAILURE,
} from "../action/actionTypes/types";
import * as api from "./../../api";

export const getRedirectionLink = (link) => async (dispatch) => {
  dispatch({
    type: GET_REDIRECTION_LINK_START,
  });
  try {
    return dispatch({
      type: GET_REDIRECTION_LINK_SUCCESS,
      payload: link,
    });
  } catch (error) {
    return dispatch({
      type: GET_REDIRECTION_LINK_FAILURE,
      payload: "Oops! Something went wrong",
    });
  }
};

export const getTransaction = (transaction) => async (dispatch) => {
  console.log("transaction", transaction);
  dispatch({
    type: GET_TRANSACTION_START,
  });

  try {
    return dispatch({
      type: GET_TRANSACTION_SUCCESS,
      payload: transaction,
    });
  } catch (error) {
    return dispatch({
      type: GET_TRANSACTION_FAILURE,
      payload: "Oops! Something went wrong",
    });
  }
};

export const getPayments = () => async (dispatch) => {
  dispatch({
    type: GET_PAYMENTS_START,
  });
  const { data } = await api.getAllPayments();
  try {
    return dispatch({
      type: GET_PAYMENTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    return dispatch({
      type: GET_PAYMENTS_FAILURE,
      payload: error.message,
    });
  }
};

export const purchasePlan = (subscription) => async (dispatch) => {
  dispatch({
    type: PURCHASE_PLAN_START,
  });
  try {
    return dispatch({
      type: PURCHASE_PLAN_SUCCESS,
      payload: subscription,
    });
  } catch (error) {
    return dispatch({
      type: PURCHASE_PLAN_FAILURE,
      payload: error.message,
    });
  }
};
