import React from "react";
import "./InvolvedFront.css";

const Front = () => (
  <div className="involved-front">
    <div className="involved-with">
      <h1>Get involved</h1>
      <p>
        Home <span className="material-icons">arrow_forward_ios</span>
        Get INVOLVED
      </p>
    </div>
  </div>
);
export default Front;
