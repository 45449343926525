import {
  SUBSCRIBE_START,
  SUBSCRIBE_SUCCESS,
  SUBSCRIBE_FAILURE,
  FETCH_SUBSCRIBERS_START,
  FETCH_SUBSCRIBERS_SUCCESS,
  FETCH_SUBSCRIBERS_FAILURE,
  DELETE_SUBSCRIBER_START,
  DELETE_SUBSCRIBER_SUCCESS,
  DELETE_SUBSCRIBER_FAILURE,
} from "./actionTypes/types";

import * as api from "../../api/index";

export const subscribe = (formData) => async (dispatch) => {
  dispatch({
    type: SUBSCRIBE_START,
  });

  try {
    const { data } = await api.subscribe(formData);
    return dispatch({
      type: SUBSCRIBE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: SUBSCRIBE_FAILURE,
      payload: errorMessage,
    });
  }
};

export const fetchSubscribers = () => async (dispatch) => {
  dispatch({
    type: FETCH_SUBSCRIBERS_START,
  });

  try {
    const { data } = await api.fetchSubscribers();
    return dispatch({
      type: FETCH_SUBSCRIBERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: FETCH_SUBSCRIBERS_FAILURE,
      payload: errorMessage,
    });
  }
};

export const deleteSubscriber = () => async (dispatch) => {
  dispatch({
    type: DELETE_SUBSCRIBER_START,
  });

  try {
    const { data } = await api.deleteSubscriber();
    return dispatch({
      type: DELETE_SUBSCRIBER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: DELETE_SUBSCRIBER_FAILURE,
      payload: errorMessage,
    });
  }
};
