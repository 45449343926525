import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableHead from "@material-ui/core/TableHead";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditRole from "./EditModal";
import DeleteModal from "./DeleteModal";
import { connect } from "react-redux";
import {
  fetchAllRoles,
  getRole as getRoleAction,
} from "./../../../Redux/action/roles";
import { toast } from "react-toastify";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#3f3f3f",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#9e9e9e",
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    background: "#464852a9",
    boxShadow: "rgba(0, 0, 0, 0.5) 0px 4px 12px",
  },
});

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const AllRoles = ({
  fetchAllRoles,
  allRoles,
  updateRole,
  deleteRole,
  addRole,
}) => {
  const tableStyles = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      allRoles?.message?.data?.roles.length - page * rowsPerPage
    );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const getRoles = () => {
      fetchAllRoles();
    };

    if ((updateRole?.success || deleteRole?.success, addRole?.success)) {
      getRoles();
    } else {
      getRoles();
    }
  }, [
    fetchAllRoles,
    updateRole?.success,
    deleteRole?.success,
    addRole?.success,
  ]);

  useEffect(() => {
    if (!allRoles.success) {
      setOpen(true);
    } else if (allRoles.success) {
      setOpen(false);
    }
  }, [allRoles.success]);

  useEffect(() => {
    const toastify = () => {
      if (updateRole?.success) {
        toast.success(updateRole?.message?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    toastify();
  }, [updateRole?.message?.message, updateRole?.success]);

  useEffect(() => {
    const toastify = () => {
      if (addRole?.success) {
        toast.success("Added Successfully!!👍", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    toastify();
  }, [addRole?.success]);

  useEffect(() => {
    const toastify = () => {
      if (deleteRole?.success) {
        toast.success(deleteRole?.message?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    toastify();
  }, [deleteRole?.message?.message, deleteRole?.success]);

  return (
    <>
      <div>
        <Backdrop style={{ zIndex: 1000, color: "#fff" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <TableContainer component={Paper}>
        <Table
          className={tableStyles.table}
          aria-label="custom pagination table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell align="right">Role Name</StyledTableCell>
              <StyledTableCell align="right">Edit</StyledTableCell>
              <StyledTableCell align="right">Delete</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsPerPage > 0
              ? allRoles?.message?.data?.roles
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((role) => (
                    <StyledTableRow key={role.uuid}>
                      <StyledTableCell component="th" scope="row">
                        {role.createdAt.split("T")[0]}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {role.role}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <EditRole role={role} />
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <DeleteModal roleId={role.uuid} />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
              : allRoles?.message?.data?.roles.map((role) => (
                  <StyledTableRow key={role.uuid}>
                    <StyledTableCell component="th" scope="row">
                      {role.createdAt.split("T")[0]}
                    </StyledTableCell>
                    <StyledTableCell align="right">{role.role}</StyledTableCell>
                    <StyledTableCell align="right">
                      <EditRole role={role} />
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <DeleteModal roleId={role.uuid} />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}

            {emptyRows > 0 && (
              <StyledTableRow style={{ height: 53 * emptyRows }}>
                <StyledTableCell colSpan={6} />
              </StyledTableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={allRoles?.message?.data?.roles.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allRoles: state.Allroles.roles,
    getRole: state.getRole.getRole,
    updateRole: state.updateRole.updateRole,
    deleteRole: state.deleteRole.deleteRole,
    addRole: state.createRole.addRole,
  };
};

export default connect(mapStateToProps, {
  fetchAllRoles,
  getRoleAction,
})(AllRoles);
