import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useStyles } from "./style";
import Grid from "@material-ui/core/Grid";
import "./Host.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import {
  hostExpectation,
  UpdatehostExpectations,
} from "./../../../../../../../Redux/action/hostFroms";
import { toast } from "react-toastify";

const initialValues = {
  houseworK: "",
  timetable: "",
  flexibleFamily: "",
  integrateFamily: "",
  initiative: "",
};

const HostExpectaions = ({ hostExpectationData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [createMode, setCreateMode] = useState(true);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [message, setMessage] = useState("");

  const { expectations } = useSelector((state) => state.hostExpectations);

  const { hostExpectations: updatedHostExpectations } = useSelector(
    (state) => state.updateHostExpectations
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
    console.log(formValues);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));

    if (Object.keys(formErrors).length === 0) {
      if (createMode) {
        setOpen(true);
        dispatch(hostExpectation(formValues));
        clear();
      } else {
        setOpen(true);
        dispatch(UpdatehostExpectations(formValues, hostExpectationData.uuid));
      }
    }
  };

  const clear = () => {
    setFormValues(initialValues);
  };

  useEffect(() => {
    if (expectations.success) {
      setOpen(false);
      setMessage(expectations?.message?.message);
    } else if (!expectations.success && expectations.error !== null) {
      setOpen(false);
      setMessage("There was an error,try again");
    }
  }, [
    expectations.success,
    expectations?.message?.message,
    expectations.error,
  ]);

  useEffect(() => {
    if (updatedHostExpectations.success) {
      setOpen(false);
      setMessage(updatedHostExpectations?.message?.message);
    } else if (
      !updatedHostExpectations.success &&
      updatedHostExpectations.error !== null
    ) {
      setOpen(false);
      setMessage("There was an error,try again");
    }
  }, [
    updatedHostExpectations.success,
    updatedHostExpectations.error,
    updatedHostExpectations?.message?.message,
  ]);

  useEffect(() => {
    const notify = () => {
      if (expectations?.error && message) {
        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (expectations?.success && message) {
        toast.success(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    if (expectations?.success && message) {
      notify();
    } else if (
      !expectations?.success &&
      expectations?.error !== null &&
      message
    ) {
      notify();
    } else {
      return;
    }
  }, [expectations?.success, expectations?.error, message]);

  useEffect(() => {
    const notify = () => {
      if (updatedHostExpectations?.error && message) {
        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (updatedHostExpectations?.success && message) {
        toast.success(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    if (updatedHostExpectations?.success && message) {
      notify();
    } else if (
      !updatedHostExpectations?.success &&
      updatedHostExpectations?.error !== null &&
      message
    ) {
      notify();
    } else {
      return;
    }
  }, [
    updatedHostExpectations?.success,
    updatedHostExpectations?.error,
    message,
  ]);

  const validate = (values) => {
    const errors = {};

    if (!values.houseworK) {
      errors.houseworK = "This field is required!";
    }
    if (!values.timetable) {
      errors.timetable = "This field is required!";
    }
    if (!values.flexibleFamily) {
      errors.flexibleFamily = "This field is required!";
    }
    if (!values.integrateFamily) {
      errors.integrateFamily = "This field is required!";
    }
    if (!values.initiative) {
      errors.initiative = "This field is required!";
    }
    return errors;
  };

  useEffect(() => {
    if (hostExpectationData) {
      setFormValues({
        houseworK: hostExpectationData?.houseworK,
        timetable: hostExpectationData?.timetable,
        flexibleFamily: hostExpectationData?.flexibleFamily,
        integrateFamily: hostExpectationData?.integrateFamily,
        initiative: hostExpectationData?.initiative,
      });
      setCreateMode(false);
    } else if (!hostExpectationData) {
      setCreateMode(true);
      setFormValues(initialValues);
    }
  }, [hostExpectationData]);

  useEffect(() => {
    if (
      formValues.houseworK === "" ||
      formValues.timetable === "" ||
      formValues.flexibleFamily === "" ||
      formValues.integrateFamily === "" ||
      formValues.initiative === ""
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formValues]);

  return (
    <React.Fragment>
      <CssBaseline />
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <Container maxWidth="md" className="form_container">
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="personalInformationGroup">
            <h3 className="InnerTitles">OUR EXPECTATION TO THE AUPAIR</h3>

            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Work is done on the pre-established plan
                    <span className="required">*</span>
                  </label>
                  <select
                    name="houseworK"
                    value={formValues.houseworK}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Not Necessary">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">{formErrors.houseworK}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    There are clearly defined timetable
                    <span className="required">*</span>
                  </label>
                  <select
                    name="timetable"
                    value={formValues.timetable}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Not Necessary">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">{formErrors.timetable}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Life is flexible and spontaneous
                    <span className="required">*</span>
                  </label>
                  <select
                    name="flexibleFamily"
                    value={formValues.flexibleFamily}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Not Necessary">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">{formErrors.flexibleFamily}</small>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    I wish to integrate the family life.
                    <span className="required">*</span>
                  </label>
                  <select
                    name="integrateFamily"
                    value={formValues.integrateFamily}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Not Necessary">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">
                  {formErrors.integrateFamily}
                </small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Wish Aupair to work on her/his initiative.
                    <span className="required">*</span>
                  </label>
                  <select
                    name="initiative"
                    value={formValues.initiative}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Not Necessary">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">{formErrors.initiative}</small>
              </Grid>
            </div>
            <button
              className={disabled ? "disabledButton" : "submit__btn"}
              type="submit"
              disabled={disabled}
            >
              {createMode ? "Create" : "Update"}
            </button>
          </div>
        </form>
      </Container>
    </React.Fragment>
  );
};

export default HostExpectaions;
