import React, { Fragment } from "react";
import FirstPage from "./Page_one";
import SecondPage from "./Page_two";
import ThirdPage from "./Page_three";
import FourthPage from "./Page_four";
import FifthPage from "./Page_five";
import "./Contract.css";
import Contact from "../../../Components/ResponsiveNavBar/Contacts/Contacts";
import Navigation from "../../../Components/ResponsiveNavBar/Nvabar";

const Contract = () => {
  return (
    <Fragment>
      <Contact />
      <Navigation />
      <div className="contract_container">
        <FirstPage />
        <SecondPage />
        <ThirdPage />
        <FourthPage />
        <FifthPage />
      </div>
    </Fragment>
  );
};

export default Contract;
