export const getClientToken = () => {
  return fetch(
    // "https://aupair-services-backend-production.up.railway.app/api/v1/payments/generate/token",
    "http://localhost:8000/api/v1/payments/generate/token",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("profile"))?.token
        }`,
      },
    }
  )
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

export const makePayment = (data) => {
  return fetch(
    "https://das-aupair-new-app-4481db6b895a.herokuapp.com/api/v1/payments/checkout",
    // "http://localhost:8000/api/v1/payments/checkout",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("profile"))?.token
        }`,
      },
      body: JSON.stringify(data),
    }
  )
    .then((response) => response.json())
    .catch((err) => console.error(err));
};

export const subscribe = (data) => {
  return fetch(
    "https://das-aupair-new-app-4481db6b895a.herokuapp.com/api/v1/payments/subscribe",
    // "http://localhost:8000/api/v1/payments/subscribe",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("profile"))?.token
        }`,
      },
      body: JSON.stringify(data),
    }
  )
    .then((response) => response.json())
    .catch((err) => console.error(err));
};

export const cancelSubscription = (data) => {
  return fetch(
    `https://das-aupair-new-app-4481db6b895a.herokuapp.com/api/v1/payments/subscriptions/cancel/${data.planId}`,
    // `http://localhost:8000/api/v1/payments/subscriptions/cancel/${data.planId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("profile"))?.token
        }`,
      },
    }
  )
    .then((response) => response.json())
    .catch((err) => console.error(err));
};
