import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ListItemText from "@material-ui/core/ListItemText";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Navigation from "./../../Page/Landing/Navigation/Navigation";
import Contact from "./../../Page/Landing/Contacts/Contacts";
import HostHeader from "./HostFamilyHome";
import Footer from "../../Footer/NewFooter";
import "./HostFamilyHome.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import {
  hostProfile as hostProfileAction,
  hostExpectation as hostExpectationAction,
  hostOtherInfo as hostOtherInfoAction,
  hostWishes as hostWishesAction,
} from "../../../Redux/action/hostFroms";

const Review = ({
  formData,
  hostUploads,
  navigation,
  hostProfileAction,
  hostExpectationAction,
  hostOtherInfoAction,
  hostWishesAction,
  hostProfile,
  hostExpect,
  hostOtherInfo,
  hostWish,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const { go } = navigation;

  const {
    fullName,
    partnerFullName,
    phone,
    email,
    birthDate,
    familyStatus,
    country,
    religion,
    religionImpt,
    havingChildren,
    stChildFullName,
    stChildAge,
    ndChildFullName,
    ndChildAge,
    rdChildFullName,
    rdChildAge,
    fothChildFullName,
    fothChildAge,
    fithChildFullName,
    fithChildAge,
    hobbies,
    routine,
    uniqueness,
    quality,
    weakness,
    specialNeed,
    street,
    postCode,
    houseNo,
    city,
    workPreference,

    //Expectations

    aupairExpAupair,
    aupairLiveWith,
    childrenNo,
    childrenAge,
    searchingFor,
    aupirAge,
    aupairCountry,
    aupairLanguage,
    smokingAupair,
    WorkingHrs,
    houseWorkPlanned,
    timetableDef,
    flexibleFam,
    integrateFamLife,
    eatOnWish,
    //Other info
    drivingLicense,
    canRideBike,
    canSwim,
    firstAidTraining,
    houseWork,
    childrenCareness,
    careAboutPets,
    educatLevel,
    specialRegime,
    foodPreference,
    //wishes
    travelExpenceFees,
    medicalInsuranceFees,
    pocketMoney,
    languageCourseFees,
    localTranspTicket,
    provideCar,
    provideBike,
    privateRoom,
    privateBathroom,
    letter,
  } = formData;

  const Previous = (event) => {
    event.preventDefault();
    setOpen(!open);
    setTimeout(() => {
      setOpen(false);
      navigation.previous();
    }, 1000);
  };

  const handleSubmit = async (event) => {
    setOpen(!open);
    //********HOST PROFILE ********/
    await hostProfileAction({
      fullName,
      partnerFullName,
      phone,
      email,
      birthDate,
      familyStatus,
      country,
      religion,
      religionImpt,
      havingChildren,
      stChildFullName,
      stChildAge,
      ndChildFullName,
      ndChildAge,
      rdChildFullName,
      rdChildAge,
      fothChildFullName,
      fothChildAge,
      fithChildFullName,
      fithChildAge,
      hobbies,
      routine,
      uniqueness,
      quality,
      weakness,
      specialNeed,
      street,
      postCode,
      houseNo,
      city,
      workPreference,
    });

    //********HOST EXPECTATION ********/
    await hostExpectationAction({
      aupairExpAupair,
      aupairLiveWith,
      childrenNo,
      childrenAge,
      searchingFor,
      aupirAge,
      aupairCountry,
      aupairLanguage,
      smokingAupair,
      WorkingHrs,
      houseWorkPlanned,
      timetableDef,
      flexibleFam,
      integrateFamLife,
      eatOnWish,
    });

    //********HOST OTHER INFO ********/
    await hostOtherInfoAction({
      drivingLicense,
      canRideBike,
      canSwim,
      firstAidTraining,
      houseWork,
      childrenCareness,
      careAboutPets,
      educatLevel,
      specialRegime,
      foodPreference,
    });

    //********HOST WISHES ********/

    await hostWishesAction({
      travelExpenceFees,
      medicalInsuranceFees,
      pocketMoney,
      languageCourseFees,
      localTranspTicket,
      provideCar,
      provideBike,
      privateRoom,
      privateBathroom,
      letter,
      imageOne: hostUploads[0]?.secure_url,
      imageTwo: hostUploads[1]?.secure_url,
      imageThree: hostUploads[2]?.secure_url,
      imageFour: hostUploads[3]?.secure_url,
      imageFive: hostUploads[4]?.secure_url,
    });
  };

  useEffect(() => {
    if (
      hostProfile.success &&
      hostExpect.success &&
      hostOtherInfo.success &&
      hostWish.succcess
    ) {
      setOpen(false);
    }
  }, [
    hostProfile.success,
    hostExpect.success,
    hostOtherInfo.success,
    hostWish.succcess,
  ]);

  return (
    <React.Fragment>
      <Contact />
      <Navigation />
      <HostHeader />
      <div>
        <Backdrop
          style={{ zIndex: 1000, color: "#fff" }}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Container maxWidth="md" className="reviews">
        <h3 className="reviewsTitle">Review Your Application</h3>

        <RenderAccordion
          go={go}
          summary="PROFILES"
          details={[
            { FullName: fullName },
            { PartnerFullName: partnerFullName },
            { Phone: phone },
            { Email: email },
            { BirthDate: birthDate },
            { FamilyStatus: familyStatus },
            { Country: country },
            { Religion: religion },
            { IsReligionImportant: religionImpt },
            { HaveChildren: havingChildren },
            { FirstChildName: stChildFullName },
            { FirstChildAge: stChildAge },
            { SecondChildFullName: ndChildFullName },
            { SecondChildAge: ndChildAge },
            { ThirdChildFullName: rdChildFullName },
            { ThirdChildAge: rdChildAge },
            { FourthChildFullName: fothChildFullName },
            { FourthChildAge: fothChildAge },
            { FifthChildFullName: fithChildFullName },
            { FifthChildAge: fithChildAge },
            { Hobbies: hobbies },
            { Routine: routine },
            { Uniqueness: uniqueness },
            { Quality: quality },
            { Weakness: weakness },
            { SpecialNeeds: specialNeed },
            { Street: street },
            { PostCode: postCode },
            { HouseNumber: houseNo },
            { City: city },
            { WorkPreference: workPreference },
          ]}
        />
        <RenderAccordion
          go={go}
          summary="EXPECTATIONS"
          details={[
            { AupairWithExperience: aupairExpAupair },
            { AupairLiveWith: aupairLiveWith },
            { ChildrenNumber: childrenNo },
            { ChildrenAge: childrenAge },
            { SearchingFor: searchingFor },
            { AupairAge: aupirAge },
            { AupairCountry: aupairCountry },
            { AupairLanguage: aupairLanguage },
            { SmokingAupair: smokingAupair },
            { WorkingHours: WorkingHrs },
            { HouseWorkPlanned: houseWorkPlanned },
            { TimeTablePlanned: timetableDef },
            { FlexibleFamily: flexibleFam },
            { IntegrateFamilyLife: integrateFamLife },
            { EatOnYourOwnWish: eatOnWish },
          ]}
        />
        <RenderAccordion
          go={go}
          summary="OTHERINFOS"
          details={[
            { IhaveDrivingLicense: drivingLicense },
            { ICanRideBike: canRideBike },
            { IcanSwim: canSwim },
            { FirstAidTraining: firstAidTraining },
            { IcanDoHouseWork: houseWork },
            { ChildrenCareNess: childrenCareness },
            { CareAboutPets: careAboutPets },
            { EducationLevel: educatLevel },
            { SpecialRegime: specialRegime },
            { FoodPrefrence: foodPreference },
          ]}
        />
        <RenderAccordion
          go={go}
          summary="WISHES"
          details={[
            { ProvideTravelExpenseFees: travelExpenceFees },
            { ProvideMedicalInsuranceFees: medicalInsuranceFees },
            { ProvidePocketMoney: pocketMoney },
            { ProvideLanguageCourseFees: languageCourseFees },
            { ProvideLocalTransportTicket: localTranspTicket },
            { ProvideCar: provideCar },
            { ProvideBike: provideBike },
            { ProvidePrivateRoom: privateRoom },
            { ProvidePrivateBirthRoom: privateBathroom },
            { Letter: letter },
          ]}
        />
        <div className="hostImages">
          <div className="hostimg">
            <img src={hostUploads[0]?.secure_url} alt="first" />
          </div>
          <div className="hostimg">
            <img src={hostUploads[1]?.secure_url} alt="second" />
          </div>
          <div className="hostimg">
            <img src={hostUploads[2]?.secure_url} alt="third" />
          </div>
          <div className="hostimg">
            <img src={hostUploads[3]?.secure_url} alt="fourth" />
          </div>
          <div className="hostimg">
            <img src={hostUploads[4]?.secure_url} alt="fourth" />
          </div>
        </div>
        <button
          className="Back"
          onClick={Previous}
          style={{ marginTop: "1rem" }}
        >
          Back
        </button>
        <button
          className="Continue"
          onClick={handleSubmit}
          style={{ marginTop: "1rem" }}
        >
          Submit
        </button>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

const mapStateToProps = ({
  hostProfileReducer,
  hostExpectation,
  hostOtherInfoReducer,
  hostWishesReducer,
}) => {
  const { hostProfile } = hostProfileReducer;
  const { hostExpect } = hostExpectation;
  const { hostOtherInfo } = hostOtherInfoReducer;
  const { hostWish } = hostWishesReducer;

  return {
    hostProfile,
    hostExpect,
    hostOtherInfo,
    hostWish,
  };
};
export default connect(mapStateToProps, {
  hostProfileAction,
  hostExpectationAction,
  hostOtherInfoAction,
  hostWishesAction,
})(Review);

export const RenderAccordion = ({ summary, details, go }) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      {summary}
    </AccordionSummary>
    <AccordionDetails>
      <div>
        {details.map((data, index) => {
          const objKey = Object.keys(data)[0];
          const objValue = data[Object.keys(data)[0]];

          return (
            <ListItemText key={index}>
              <strong style={{ marginRight: "50px" }}>{`${objKey}`}:</strong>
              {`${objValue}`}
            </ListItemText>
          );
        })}
        <IconButton
          style={{ color: "#077" }}
          component="span"
          onClick={() => go(summary.toLowerCase())}
        >
          <EditIcon />
        </IconButton>
      </div>
    </AccordionDetails>
  </Accordion>
);
