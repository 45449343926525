import * as type from "../action/actionTypes/types";

const iniitialState = {
  transaction: {
    message: null,
    loading: false,
    success: false,
    error: null,
  },
};

const transactionReducer = (state = iniitialState, action) => {
  switch (action.type) {
    case type.GET_TRANSACTION_START:
      return {
        ...state,
        transaction: {
          message: null,
          loading: true,
          success: false,
          error: null,
        },
      };
    case type.GET_TRANSACTION_SUCCESS:
      return {
        ...state,
        redirectionLink: {
          message: action.payload,
          loading: false,
          success: true,
          error: null,
        },
      };
    case type.GET_TRANSACTION_FAILURE:
      return {
        ...state,
        transaction: {
          message: null,
          loading: false,
          success: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default transactionReducer;
