import React from "react";
import "./Contract.css";

const Page_four = () => {
  return (
    <div className="page">
      <main className="page__four__main__body">
        <small className="page__four__main_body_pagraph">
          II. 7. In the event of the au pair's falling ill, the host shall
          continue to provide board and lodging and shall give all appropriate
          care until such time as necessary arrangements have been made.
        </small>
        <h4 className="page__four__primary__heading">
          III. DUTIES OF THE AU PAIR
        </h4>
        <small className="page__four__main_body_pagraph">
          III. 1. The au pair undertakes to participate, for:
          ..............................................hours per day, 4 in day
          to day family duties by furnishing the following services
          .................................................................................
          ..............................................................................
          ...........................................................................................................................................
          (specify the services the au pair will be required to perform)
        </small>
        <small className="page__four__main_body_pagraph">
          III. 2. The au pair agrees to comply with any formality necessary on
          her/his part to enable the host to fulfil her/his obligations under
          paragraph II 6 of this agreement.
        </small>
        <small className="page__four__main_body_pagraph">
          III. 3. The au pair agrees to furnish without delay the medical
          certificate provided for in Article 5 of the European Agreement on “au
          pair” Placement 5.
        </small>

        <article className="page__four__main__body_article">
          <small className="page__four__main__body_article_content">
            <strong>1.</strong> According to Article 8, paragraph 3, of the
            European Agreement on “au pair” Placement, the au pair shall have at
            least one full free day pet week, not less than one such free day in
            every month being a Sunday.
          </small>
          <small className="page__four__main__body_article_content">
            <strong>2.</strong> The Protocol to the European Agreement provides
            that in the event of sickness, maternity, and accident benefits
            shall cover, as far as possible, the cost of medical treatment,
            pharmacy and hospital care.
          </small>
          <small className="page__four__main__body_article_content">
            <strong>3.</strong> The possibility of making the au pair
            responsible for half the insurance premiums can only exist where a
            Contracting Party has made a reservation on this point at the time
            of ratification of the European Agreement on “au pair” Placement.
          </small>
          <small className="page__four__main__body_article_content">
            <strong>4.</strong> According to Article 9 of the European Agreement
            on “au pair” Placement, the time effectively occupied in such
            services shall generally net be more that five hours per day.
          </small>
          <small className="page__four__main__body_article_content">
            <strong>5.</strong> According to Article 5 of the European Agreement
            on “au pair” Placement, the au pair's medical certificate shall have
            been established less than three months before placement and shall
            declare her/his general state of health.
          </small>
        </article>
      </main>
    </div>
  );
};

export default Page_four;
