import React, { useState } from "react";
import "./HostFront.css";
import { Modal } from "antd";
import Auth from "./../../../Account/Authentication";

const Actions = () => {
  const [visible, setVisible] = useState(false);
  const [isSignup, setIsSignUp] = useState(true);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };

  return (
    <React.Fragment>
      <Modal
        title="LOGIN/SIGNUP"
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        width={700}
      >
        <Auth isSignup={isSignup} setIsSignUp={setIsSignUp} />
      </Modal>

      <div className="get-involved">
        <h1 className="Involved-title">GET INVOLVED</h1>

        <div className="Action__btn-container">
          <div
            className="btn-orange ActionBtn Action__btn__register-aupair"
            onClick={showModal}
          >
            <span>Register As Aupair</span>
          </div>
          <div
            className="btn-orange ActionBtn Action__btn__find-hostFamily"
            onClick={showModal}
          >
            <span>Find Host Family</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Actions;
