import {
  GET_ALL_TESTMONIALS_START,
  GET_ALL_TESTMONIALS_SUCCESS,
  GET_ALL_TESTMONIALS_FAILURE,
  GET_TESTMONY_START,
  GET_TESTMONY_SUCCESS,
  GET_TESTMONY_FAILURE,
  CREATE_TESTMONY_START,
  CREATE_TESTMONY_SUCCESS,
  CREATE_TESTMONY_FAILURE,
  UPDTAE_TESTMONY_START,
  UPDTAE_TESTMONY_SUCCESS,
  UPDTAE_TESTMONY_FAILURE,
  DELETE_TESTMONY_START,
  DELETE_TESTMONY_SUCCESS,
  DELETE_TESTMONY_FAILURE,
} from "../action/actionTypes/types";
import * as api from "../../api/index";

export const fetchAllTestmonials = () => async (dispatch) => {
  dispatch({
    type: GET_ALL_TESTMONIALS_START,
  });
  try {
    const { data } = await api.getTestmonials();
    return dispatch({
      type: GET_ALL_TESTMONIALS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: GET_ALL_TESTMONIALS_FAILURE,
      payload: errorMessage,
    });
  }
};

export const deleteTestmony = (testId) => async (dispatch) => {
  dispatch({
    type: DELETE_TESTMONY_START,
  });
  try {
    const { data } = await api.deleteTestmony(testId);
    return dispatch({
      type: DELETE_TESTMONY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: DELETE_TESTMONY_FAILURE,
      payload: errorMessage,
    });
  }
};

export const createTestmony = (formData) => async (dispatch) => {
  dispatch({
    type: CREATE_TESTMONY_START,
  });

  try {
    const { data } = await api.createTestmony(formData);

    return dispatch({
      type: CREATE_TESTMONY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: CREATE_TESTMONY_FAILURE,
      payload: errorMessage,
    });
  }
};

export const getTestmony = (uuid) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TESTMONY_START,
    });

    const { data } = await api.getTestmony(uuid);

    return dispatch({ type: GET_TESTMONY_SUCCESS, payload: data });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: GET_TESTMONY_FAILURE,
      payload: errorMessage,
    });
  }
};

export const updateTestmony = (formData, testId) => async (dispatch) => {
  dispatch({
    type: UPDTAE_TESTMONY_START,
  });

  try {
    const { data } = await api.updateTestmony(formData, testId);

    return dispatch({
      type: UPDTAE_TESTMONY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: UPDTAE_TESTMONY_FAILURE,
      payload: errorMessage,
    });
  }
};
