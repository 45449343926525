import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableHead from "@material-ui/core/TableHead";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import Tooltip from "@material-ui/core/Tooltip";
import PauseIcon from "@mui/icons-material/Pause";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import Switch from "@material-ui/core/Switch";
import AupairModal from "./AupairDetailsModal";
import AupairDocuments from "./AupairDocuments";
import Gallery from "./GalleryModal";
import { connect, useSelector } from "react-redux";
import { getAllAupairs } from "./../../../Redux/action/aupairForms";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import UndoIcon from "@mui/icons-material/Undo";
import CancelIcon from "@material-ui/icons/Cancel";
import { Empty } from "antd";
import { toast } from "react-toastify";

import {
  ApproveUser,
  DisapproveUser,
  HoldUser,
  UnHoldUser,
  MatchUser,
  rejectUser,
  unRejectUser,
} from "./../../../Redux/action/auparAndHostActions";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#3f3f3f",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 11,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#9e9e9e",
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    background: "#464852a9",
    boxShadow: "rgba(0, 0, 0, 0.5) 0px 4px 12px",
  },
});

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const AupairsList = ({
  allAupairs,
  getAllAupairs,
  approved,
  disapproved,
  hold,
  unhold,
  match,
  ApproveUser,
  DisapproveUser,
  HoldUser,
  UnHoldUser,
  MatchUser,
  rejectUser,
  unRejectUser,
  rejcted,
  restore,
  setSearchableData,
}) => {
  const tableStyles = useStyles();
  const { message: searchResult } = useSelector(
    (state) => state.search.searchResults
  );
  const [page, setPage] = useState(0);
  const [aupairs, setAupairs] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);

  const handleApproveUser = (userId) => {
    ApproveUser(userId);
  };

  const handleDisApproveUser = (userId) => {
    DisapproveUser(userId);
  };

  const HandleHoldUser = (userId) => {
    HoldUser(userId);
  };

  const HandleUnHoldUser = (userId) => {
    UnHoldUser(userId);
  };

  const HandleMatchUser = (userId) => {
    MatchUser(userId);
  };

  const handleRejectUser = (userId) => {
    rejectUser(userId);
  };

  const handelRestoreUser = (userId) => {
    unRejectUser(userId);
  };

  const handleActivation = () => {
    setChecked((prev) => !prev);
  };
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, aupairs?.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const getAupairs = () => {
      getAllAupairs();
    };
    if (
      approved?.success ||
      disapproved?.success ||
      hold?.success ||
      unhold?.success ||
      rejcted?.success ||
      restore?.success ||
      match?.success
    ) {
      getAupairs();
    } else {
      getAupairs();
    }
  }, [
    getAllAupairs,
    approved?.success,
    disapproved?.success,
    hold?.success,
    unhold?.success,
    rejcted?.success,
    restore?.success,
    match?.success,
  ]);

  useEffect(() => {
    if (!allAupairs.success) {
      setOpen(true);
    }
  }, [allAupairs.success]);

  useEffect(() => {
    if (allAupairs.success) {
      setOpen(false);
      setAupairs(allAupairs?.message?.data?.aupairs);
      setSearchableData(allAupairs?.message?.data?.aupairs);
    }
  }, [
    allAupairs.success,
    allAupairs?.message?.data?.aupairs,
    setSearchableData,
  ]);

  useEffect(() => {
    if (searchResult) {
      setAupairs(searchResult);
    } else if (allAupairs.success) {
      setAupairs(allAupairs?.message?.data?.aupairs);
    }
  }, [searchResult, allAupairs.success, allAupairs?.message?.data?.aupairs]);

  useEffect(() => {
    const toastify = () => {
      if (approved?.success) {
        toast.success(approved?.message?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    toastify();
  }, [approved?.message?.message, approved?.success]);

  useEffect(() => {
    const toastify = () => {
      if (disapproved?.success) {
        toast.success(disapproved?.message?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    toastify();
  }, [disapproved?.message?.message, disapproved?.success]);

  useEffect(() => {
    const toastify = () => {
      if (hold?.success) {
        toast.success(hold?.message?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    toastify();
  }, [hold?.message?.message, hold?.success]);

  useEffect(() => {
    const toastify = () => {
      if (unhold?.success) {
        toast.success(unhold?.message?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    toastify();
  }, [unhold?.message?.message, unhold?.success]);

  useEffect(() => {
    const toastify = () => {
      if (rejcted?.success) {
        toast.success(rejcted?.message?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    toastify();
  }, [rejcted?.message?.message, rejcted?.success]);
  useEffect(() => {
    const toastify = () => {
      if (restore?.success) {
        toast.success(restore?.message?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    toastify();
  }, [restore?.message?.message, restore?.success]);

  return (
    <Fragment>
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      {aupairs?.length === 0 ? (
        <Empty />
      ) : (
        <TableContainer component={Paper}>
          <Table
            className={tableStyles.table}
            aria-label="custom pagination table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell align="right">Full Name</StyledTableCell>
                <StyledTableCell align="right">Role</StyledTableCell>
                <StyledTableCell align="right">Phone</StyledTableCell>
                <StyledTableCell align="right">Email</StyledTableCell>
                <StyledTableCell align="right">Preview</StyledTableCell>
                <StyledTableCell align="right">Docs</StyledTableCell>
                <StyledTableCell align="right">Gallery</StyledTableCell>
                <StyledTableCell align="right">Approve</StyledTableCell>
                <StyledTableCell align="right">Hold</StyledTableCell>
                <StyledTableCell align="right">Match</StyledTableCell>
                <StyledTableCell align="right">Reject</StyledTableCell>
                <StyledTableCell align="right">Status</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsPerPage > 0
                ? aupairs
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row) => (
                      <StyledTableRow key={row.uuid}>
                        <StyledTableCell component="th" scope="row">
                          {row.createdAt.split("T")[0]}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.fullName}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.role}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.phone}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.email}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <AupairModal userId={row.uuid} />
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <AupairDocuments userId={row.uuid} />
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <Gallery userId={row.uuid} />
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {!row.approved ? (
                            <Tooltip title="Approve" arrow>
                              <IconButton
                                color="default"
                                component="span"
                                size="small"
                                style={{
                                  backgroundColor: "#3f3f3f",
                                  marginRight: "10px",
                                }}
                                onClick={() => handleApproveUser(row.uuid)}
                              >
                                <DoneAllIcon
                                  style={{ color: "#FFA500", fontSize: "20px" }}
                                />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="DisApprove" arrow>
                              <IconButton
                                color="default"
                                component="span"
                                size="small"
                                style={{ backgroundColor: "#FFA500" }}
                                onClick={() => handleDisApproveUser(row.uuid)}
                              >
                                <UndoIcon
                                  style={{ color: "green", fontSize: "20px" }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {!row.onHold ? (
                            <Tooltip title="onHold" arrow>
                              <IconButton
                                color="default"
                                component="span"
                                size="small"
                                style={{
                                  backgroundColor: "#3f3f3f",
                                  marginRight: "10px",
                                }}
                                onClick={() => HandleHoldUser(row.uuid)}
                              >
                                <PauseIcon
                                  style={{ color: "#FFA500", fontSize: "20px" }}
                                />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="unHold" arrow>
                              <IconButton
                                color="default"
                                component="span"
                                size="small"
                                style={{ backgroundColor: "#FFA500" }}
                                onClick={() => HandleUnHoldUser(row.uuid)}
                              >
                                <UndoIcon
                                  style={{ color: "green", fontSize: "20px" }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <Tooltip title="Match" arrow>
                            <IconButton
                              color="default"
                              component="span"
                              size="small"
                              style={{ backgroundColor: "#3f3f3f" }}
                              disabled={row?.matched}
                              onClick={() => HandleMatchUser(row.uuid)}
                            >
                              <OpenInFullIcon
                                style={{ color: "#FFA500", fontSize: "20px" }}
                              />
                            </IconButton>
                          </Tooltip>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row?.rejected ? (
                            <Tooltip title="restore" arrow>
                              <IconButton
                                color="default"
                                component="span"
                                size="small"
                                style={{ backgroundColor: "#FFA500" }}
                                onClick={() => handelRestoreUser(row.uuid)}
                              >
                                <UndoIcon
                                  style={{ color: "green", fontSize: "20px" }}
                                />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Reject" arrow>
                              <IconButton
                                color="default"
                                component="span"
                                size="small"
                                onClick={() => handleRejectUser(row.uuid)}
                                style={{ backgroundColor: "#3f3f3f" }}
                              >
                                <CancelIcon
                                  style={{ color: "#FFA500", fontSize: "20px" }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <Switch
                            checked={checked}
                            onClick={handleActivation}
                            color="secondary"
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                : aupairs?.map((row) => (
                    <StyledTableRow key={row.uuid}>
                      <StyledTableCell component="th" scope="row">
                        {row.createdAt.split("T")[0]}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.fullName}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.role}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.phone}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.email}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <AupairModal userId={row.uuid} />
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <AupairDocuments userId={row.uuid} />
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Gallery userId={row.uuid} />
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {!row.approved ? (
                          <Tooltip title="Approve" arrow>
                            <IconButton
                              color="default"
                              component="span"
                              size="small"
                              style={{
                                backgroundColor: "#3f3f3f",
                                marginRight: "10px",
                              }}
                              onClick={() => handleApproveUser(row.uuid)}
                            >
                              <DoneAllIcon
                                style={{ color: "#FFA500", fontSize: "20px" }}
                              />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title="DisApprove" arrow>
                            <IconButton
                              color="default"
                              component="span"
                              size="small"
                              style={{ backgroundColor: "#FFA500" }}
                              onClick={() => handleDisApproveUser(row.uuid)}
                            >
                              <UndoIcon
                                style={{ color: "green", fontSize: "20px" }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </StyledTableCell>

                      <StyledTableCell align="right">
                        {!row.onHold ? (
                          <Tooltip title="onHold" arrow>
                            <IconButton
                              color="default"
                              component="span"
                              size="small"
                              style={{
                                backgroundColor: "#3f3f3f",
                                marginRight: "10px",
                              }}
                              onClick={() => HandleHoldUser(row.uuid)}
                            >
                              <PauseIcon
                                style={{ color: "#FFA500", fontSize: "20px" }}
                              />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title="unHold" arrow>
                            <IconButton
                              color="default"
                              component="span"
                              size="small"
                              style={{ backgroundColor: "#FFA500" }}
                              onClick={() => HandleUnHoldUser(row.uuid)}
                            >
                              <UndoIcon
                                style={{ color: "green", fontSize: "20px" }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Tooltip title="Match" arrow>
                          <IconButton
                            color="default"
                            component="span"
                            size="small"
                            style={{ backgroundColor: "#3f3f3f" }}
                            disabled={row?.matched}
                            onClick={() => HandleMatchUser(row.uuid)}
                          >
                            <OpenInFullIcon
                              style={{ color: "#FFA500", fontSize: "20px" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row?.rejected ? (
                          <Tooltip title="restore" arrow>
                            <IconButton
                              color="default"
                              component="span"
                              size="small"
                              style={{ backgroundColor: "#FFA500" }}
                              onClick={() => handelRestoreUser(row.uuid)}
                            >
                              <UndoIcon
                                style={{ color: "green", fontSize: "20px" }}
                              />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Reject" arrow>
                            <IconButton
                              color="default"
                              component="span"
                              size="small"
                              onClick={() => handleRejectUser(row.uuid)}
                              style={{ backgroundColor: "#3f3f3f" }}
                            >
                              <CancelIcon
                                style={{ color: "#FFA500", fontSize: "20px" }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Switch
                          checked={checked}
                          onClick={handleActivation}
                          color="warning"
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}

              {emptyRows > 0 && (
                <StyledTableRow style={{ height: 53 * emptyRows }}>
                  <StyledTableCell colSpan={6} />
                </StyledTableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={3}
                  count={aupairs.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    allAupairs: state.allAupairs.aupairs,
    approved: state.approveUser.approved,
    disapproved: state.disapproveUser.disapproved,
    hold: state.holdUser.hold,
    unhold: state.unHoldUser.unHold,
    match: state.matchUser.matched,
    rejcted: state.rejectUser.rejected,
    restore: state.restoreUser.restored,
  };
};

export default connect(mapStateToProps, {
  getAllAupairs,
  ApproveUser,
  DisapproveUser,
  HoldUser,
  UnHoldUser,
  MatchUser,
  rejectUser,
  unRejectUser,
})(AupairsList);
