import React, { Fragment } from "react";
import PersonalInformation from "./PersonalIdentification";
import ContactInformation from "./ContactInfo";
import EducationBackground from "./Education";
import MoreAboutMe from "./More.js";
import FamilyPreference from "./Preference";
import Expectations from "./Expectation";
import Wishes from "./Wishes";
import { Divider } from "antd";
import "./style.css";

export const AupairDetails = React.forwardRef((props, ref) => {
  return (
    <Fragment>
      <div ref={ref} className="aupair_printable_documents">
        <PersonalInformation
          Data={props?.user?.message?.data?.user?.aupairprofile}
        />
        <Divider />
        <ContactInformation
          Data={props?.user?.message?.data?.user?.aupaircontacts}
        />
        <Divider />
        <EducationBackground
          Data={props?.user?.message?.data?.user?.education}
        />
        <Divider />
        <MoreAboutMe Data={props?.user?.message?.data?.user?.aupairmoreinfo} />
        <Divider />
        <FamilyPreference
          Data={props?.user?.message?.data?.user?.aupairpreferences}
        />
        <Divider />
        <Expectations
          Data={props?.user?.message?.data?.user?.aupairexpectations}
        />
        <Divider />
        <Wishes Data={props?.user?.message?.data?.user?.aupairWishes} />
        <Divider />
      </div>
    </Fragment>
  );
});
