import {
  GET_MATCH_START,
  GET_MATCH_SUCCESS,
  GET_MATCH_FAILURE,
  CREATE_MATCH_START,
  CREATE_MATCH_SUCCESS,
  CREATE_MATCH_FAILURE,
  GET_ALL_MATCHES_START,
  GET_ALL_MATCHES_SUCCESS,
  GET_ALL_MATCHES_FAILURE,
  APPROVE_MATCH_START,
  APPROVE_MATCH_SUCCESS,
  APPROVE_MATCH_FAILURE,
} from "./actionTypes/types";

import * as api from "../../api/index";

export const findMatching = (userId) => async (dispatch) => {
  dispatch({ type: GET_MATCH_START });

  try {
    const { data } = await api.getMatches(userId);

    return dispatch({
      type: GET_MATCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error?.response?.data?.error;

    return dispatch({
      type: GET_MATCH_FAILURE,
      payload: errorMessage,
    });
  }
};

export const createMatching = (matchData) => async (dispatch) => {
  dispatch({ type: CREATE_MATCH_START });

  try {
    const { data } = await api.createMatch(matchData);
    return dispatch({
      type: CREATE_MATCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error?.response?.data?.error;

    return dispatch({
      type: CREATE_MATCH_FAILURE,
      payload: errorMessage,
    });
  }
};

export const getAllMatchingConnections = (matchData) => async (dispatch) => {
  dispatch({ type: GET_ALL_MATCHES_START });

  try {
    const { data } = await api.allConnections(matchData);
    return dispatch({
      type: GET_ALL_MATCHES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error?.response?.data?.error;

    return dispatch({
      type: GET_ALL_MATCHES_FAILURE,
      payload: errorMessage,
    });
  }
};

export const approveMatching = (matchId) => async (dispatch) => {
  dispatch({ type: APPROVE_MATCH_START });

  try {
    const { data } = await api.approveMatching(matchId);
    return dispatch({
      type: APPROVE_MATCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error?.response?.data?.error;

    return dispatch({
      type: APPROVE_MATCH_FAILURE,
      payload: errorMessage,
    });
  }
};
