import React from "react";
import "./Profile.css";
import ScrollableTabsButtonAuto from "./TopNav/Tabs";

const Profile = () => {
  return (
    <React.Fragment>
      <main className="main__container">
        <ScrollableTabsButtonAuto />
      </main>
    </React.Fragment>
  );
};

export default Profile;
