import * as type from "../action/actionTypes/types";

const iniitialState = {
  profile: {
    message: null,
    loading: false,
    success: false,
    error: null,
  },
};

const getAupairProfileReducer = (state = iniitialState, action) => {
  switch (action.type) {
    case type.GET_AUPAIR_PROFILE_START:
      return {
        ...state,
        profile: {
          message: null,
          loading: true,
          success: false,
          error: null,
        },
      };
    case type.GET_AUPAIR_PROFILE_SUCCESS:
      return {
        ...state,
        profile: {
          message: action.payload,
          loading: false,
          success: true,
          error: null,
        },
      };
    case type.GET_AUPAIR_PROFILE_FAILURE:
      return {
        ...state,
        profile: {
          message: null,
          loading: false,
          success: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default getAupairProfileReducer;
