import React, { useState } from "react";
import Logo from "../../Logo/Logo";
import NavigationItems from "../NavigationItems/NavigationItems";
import "./Toolbar.css";
import Contact from "../Contacts/Contacts";
import { NavLink } from "react-router-dom";
import { Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";

const Toolbar = (props) => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  return (
    <React.Fragment>
      <Drawer
        title="AUPAIR SERVICES"
        placement="left"
        onClose={onClose}
        visible={visible}
        width={300}
        bodyStyle={{ backgroundColor: "#077" }}
      >
        <nav className="toggle_navigation">
          <NavLink to="/home">Aupair</NavLink>
          <NavLink to="/families">Host Family</NavLink>
          <NavLink to="/faq">FQ</NavLink>
          <NavLink to="/contact">Contact</NavLink>
          <NavLink to="/registration" className="Loginbtn">
            <button>Login</button>
          </NavLink>
          <NavLink to="contact" className="toggle_buttons">
            <button>Logout</button>
          </NavLink>
        </nav>
      </Drawer>
      <div className="navigation__container">
        <Contact />
        <header className="Toolbar">
          <Logo />
          <nav className="DesktopOnly">
            <NavigationItems />
          </nav>
          <div className="menu__bar__mobo__devices">
            <MenuOutlined onClick={showDrawer} className="MenuIcon" />
          </div>
        </header>
      </div>
    </React.Fragment>
  );
};

export default Toolbar;
