import React, { Fragment } from "react";

const Preference = ({ Data }) => {
  return (
    <Fragment>
      <div className="personal__data">
        <div className="personal__data__header">
          <h1>Family Preference</h1>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>I am searching for a host Family From:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.hostFamilyFrom}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>I wish to work with Family Experienced With Aupair:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.experiencedFamily}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Host Family With Pets Animals:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.familyWithPetsAnimals}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Smoking Host Family:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.smokingHostFamily}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Host family with children with special needs:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.specialNeeds}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>I wish to work with host family with children from:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{`${Data?.childrenFrom} To ${Data?.toChildrenNumber}`}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Children with age from:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{`${Data?.agedFrom} To ${Data?.agedTo}`}</label>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Preference;
