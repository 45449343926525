export const MenueData = [
  {
    title: "FOR AUPAIRS",
    url: "/aupair",
    cName: "mynav-links",
  },
  {
    title: "FOR HOST FAMILIES",
    url: "/families",
    cName: "mynav-links",
  },
  {
    title: "GET INVOLVED",
    url: "/involved",
    cName: "mynav-links",
  },
  // {
  //   title: "BLOG",
  //   url: "/blog",
  //   cName: "mynav-links",
  // },
  {
    title: "FAQ",
    url: "/faq",
    cName: "mynav-links",
  },
  {
    title: "CONTACT",
    url: "/contact",
    cName: "mynav-links",
  },
  {
    title: "ENG",
    url: "#",
    cName: "mynav-links languages",
  },
  {
    title: "LOGIN",
    url: "#",
    cName: "nav-links-mobile",
  },
];
