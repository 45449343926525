import React from "react";
import "./NavigationItems.css";
import NavigationItem from "./NavigationItem/NavigationItem";
import { useStyles } from "./NavigationItem/style";

const NavigationItems = () => {
  const classes = useStyles();

  const [itemOne, setItemOne] = React.useState(true);
  const [itemTwo, setItemTwo] = React.useState(false);
  const [itemThree, setItemThree] = React.useState(false);
  const [itemFour, setItemFour] = React.useState(false);

  const handleItemOne = () => {
    setItemOne(true);
    setItemTwo(false);
    setItemThree(false);
    setItemFour(false);
  };

  const handleItemTwo = () => {
    setItemOne(false);
    setItemTwo(true);
    setItemThree(false);
    setItemFour(false);
  };

  const handleItemThree = () => {
    setItemOne(false);
    setItemTwo(false);
    setItemThree(true);
    setItemFour(false);
  };

  const handleItemFour = () => {
    setItemOne(false);
    setItemTwo(false);
    setItemThree(false);
    setItemFour(true);
  };

  return (
    <React.Fragment>
      <ul className="NavigationItems">
        <NavigationItem link="/home" exact>
          <div className="dropdown" onClick={handleItemOne}>
            <span className={itemOne ? `${classes.border}` : "language"}>
              Aupairs
            </span>
          </div>
        </NavigationItem>
        <NavigationItem link="/families">
          <div className="dropdown" onClick={handleItemTwo}>
            <span className={itemTwo ? `${classes.border}` : "language"}>
              Host Families
            </span>
          </div>
        </NavigationItem>
        <NavigationItem link="/faq">
          <div className="dropdown" onClick={handleItemThree}>
            <span className={itemThree ? `${classes.border}` : "language"}>
              FAQ
            </span>
          </div>
        </NavigationItem>
        <NavigationItem link="/contact">
          <div className="dropdown" onClick={handleItemFour}>
            <span className={itemFour ? `${classes.border}` : "language"}>
              Contact
            </span>
          </div>
        </NavigationItem>
      </ul>
    </React.Fragment>
  );
};
export default NavigationItems;
