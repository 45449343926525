import React, { useEffect } from "react";
import { connect } from "react-redux";
import { uploadHostDocument } from "./../../../../../Redux/action/Documents";
import { toast } from "react-toastify";

const CloudinaryUploadWidget = ({ uploadHostDocument, newDocument }) => {
  const cloudName = "dsb0gu6km";
  const uploadPreset = "dasaupair";

  var myWidget = window.cloudinary.createUploadWidget(
    {
      cloudName: cloudName,
      uploadPreset: uploadPreset,
    },
    (error, result) => {
      if (!error && result && result.event === "success") {
        if (result?.info?.secure_url) {
          uploadHostDocument({
            documentName: "RESISDENCE CERTIFICATE",
            document: result?.info?.secure_url,
          });
        }
      }
    }
  );

  const uplaodFile = () => {
    myWidget.open();
  };

  useEffect(() => {
    if (newDocument?.success) {
      toast.success(newDocument?.message?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [newDocument?.message?.message, newDocument?.success]);

  return (
    <button className="cloudinary-button" onClick={uplaodFile}>
      Add Residence Certificate
    </button>
  );
};

const mapStateToProps = (state) => {
  return {
    newDocument: state.addHostDocument.document,
  };
};

export default connect(mapStateToProps, { uploadHostDocument })(
  CloudinaryUploadWidget
);
