import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useStyles } from "./style";
import Grid from "@material-ui/core/Grid";
import "./profile.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  hostProfile as hostFamilyProfile,
  updateHostProfile,
} from "./../../../../Redux/action/hostFroms";
import Layout from "./../../../Layouts/hostFamily/Layout";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getUser } from "./../../../../Redux/action/staffMembers";

const initialValues = {
  firstName: "",
  lastName: "",
  birthDate: "",
  familyStatus: "",
  nationality: "",
  religion: "",
  religionImpt: "",
  foodPreference: "",
};

const HostProfile = ({
  hostFamilyProfile,
  getUser,
  updateHostProfile,
  host,
  profile,
  updatedProfile,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [createMode, setCreateMode] = useState(true);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [disabled, setDisabled] = useState(false);

  const user = JSON.parse(localStorage.getItem("profile"))?.data?.user;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));

    if (Object.keys(formErrors).length === 0) {
      if (createMode) {
        setOpen(true);
        hostFamilyProfile(formValues);
        clear();
      } else {
        setOpen(true);
        updateHostProfile(
          formValues,
          host?.message?.data?.user?.hostProfile?.uuid
        );
      }
    }
  };

  const clear = () => {
    setFormValues(initialValues);
  };

  useEffect(() => {
    const fetchUser = () => {
      getUser(user?.uuid);
    };
    if (user?.uuid || profile?.success || updatedProfile?.success) {
      fetchUser();
    } else {
      return;
    }
  }, [user?.uuid, getUser, profile?.success, updatedProfile?.success]);

  useEffect(() => {
    if (profile?.success) {
      setOpen(false);
    } else if (!profile.success || profile.error !== null) {
      setOpen(false);
    }
  }, [profile.success, profile.error, profile?.message?.message]);

  useEffect(() => {
    if (updatedProfile?.success) {
      setOpen(false);
    } else if (!updatedProfile?.success && updatedProfile?.error !== null) {
      setOpen(false);
    }
  }, [
    updatedProfile?.success,
    updatedProfile?.error,
    updatedProfile?.message?.message,
  ]);

  const validate = (values) => {
    const errors = {};

    if (!values.firstName) {
      errors.firstName = "This field is required!";
    }
    if (!values.lastName) {
      errors.lastName = "This field is required!";
    }
    if (!values.birthDate) {
      errors.birthDate = "This field is required!";
    }
    if (!values.familyStatus) {
      errors.familyStatus = "This field is required!";
    }
    if (!values.nationality) {
      errors.nationality = "This field is required!";
    }
    if (!values.religion) {
      errors.religion = "This field is required!";
    }

    if (!values.religionImpt) {
      errors.religionImpt = "This field is required!";
    }
    if (!values.foodPreference) {
      errors.foodPreference = "This field is required!";
    }

    return errors;
  };

  useEffect(() => {
    if (host?.message?.data?.user?.hostProfile) {
      setFormValues({
        firstName: host?.message?.data?.user?.hostProfile?.firstName,
        lastName: host?.message?.data?.user?.hostProfile?.lastName,
        birthDate:
          host?.message?.data?.user?.hostProfile?.birthDate.split("T")[0],
        familyStatus: host?.message?.data?.user?.hostProfile?.familyStatus,
        nationality: host?.message?.data?.user?.hostProfile?.nationality,
        religion: host?.message?.data?.user?.hostProfile?.religion,
        religionImpt: host?.message?.data?.user?.hostProfile?.religionImpt,
        foodPreference: host?.message?.data?.user?.hostProfile?.foodPreference,
      });
      setCreateMode(false);
    } else if (!host?.message?.data?.user?.hostProfile) {
      setCreateMode(true);
      setFormValues(initialValues);
    }
  }, [host]);

  useEffect(() => {
    if (
      formValues.firstName === "" ||
      formValues.lastName === "" ||
      formValues.birthDate === "" ||
      formValues.familyStatus === "" ||
      formValues.nationality === "" ||
      formValues.religion === "" ||
      formValues.religionImpt === "" ||
      formValues.foodPreference === ""
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formValues]);

  return (
    <Layout>
      <CssBaseline />
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <Container maxWidth="md" className="form_container">
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="personalInformationGroup">
            <h3 className="InnerTitles">PERSONAL IDENTIFICATION</h3>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    First Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="John"
                    name="firstName"
                    required
                    value={formValues.firstName}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.firstName}</small>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Last Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Doe"
                    name="lastName"
                    required
                    value={formValues.lastName}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.lastName}</small>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Date of Birth <span className="required">*</span>
                  </label>
                  <input
                    required
                    type="Date"
                    name="birthDate"
                    value={formValues.birthDate}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.birthDate}</small>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Family Status <span className="required">*</span>
                  </label>

                  <select
                    name="familyStatus"
                    value={formValues.familyStatus}
                    onChange={handleChange}
                  >
                    <option value="">--select---</option>
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                    <option value="Fiancé">Fiancé</option>
                    <option value="Divorced">Divorced</option>
                    <option value="Widower">Widower</option>
                  </select>
                </div>
                <small className="formError">{formErrors.familyStatus}</small>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Nationality <span className="required">*</span>
                  </label>
                  <select
                    name="nationality"
                    value={formValues.nationality}
                    onChange={handleChange}
                  >
                    <option value="">---select---</option>
                    <option value="Germany">Germany</option>
                    <option value="Schweiz">Schweiz</option>
                    <option value="Luxamburg">Luxamburg</option>
                    <option value="France">France</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Austria">Austria</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <small className="formError">{formErrors.nationality}</small>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Religion<span className="required">*</span>
                  </label>
                  <select
                    name="religion"
                    value={formValues.religion}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Catholic">Catholic</option>
                    <option value="Evangelic">Evangelic</option>
                    <option value="Muslim">Muslim</option>
                    <option value="No Religion">No Religion</option>
                    <option value="Other christian">Other christian</option>
                  </select>
                </div>
                <small className="formError">{formErrors.religion}</small>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Is Religion Important <span className="required">*</span>
                  </label>
                  <select
                    name="religionImpt"
                    value={formValues.religionImpt}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="No">No</option>
                    <option value="Very">Very</option>
                    <option value="Important">Important</option>
                  </select>
                </div>
                <small className="formError">{formErrors.religionImpt}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Food preference at home <span className="required">*</span>
                  </label>
                  <select
                    name="foodPreference"
                    value={formValues.foodPreference}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Eating everything">Eating everything</option>
                    <option value="Only vegetable and milch product">
                      Only vegetable and milch product
                    </option>
                    <option value="Eggs and Fishes">Eggs and Fishes</option>
                    <option value="Vegans">Vegans</option>
                    <option value="Freedom to eat food on your wish">
                      Freedom to eat food on your wish
                    </option>
                    <option value="Special Regime">Special Regime</option>
                  </select>
                </div>
                <small className="formError">{formErrors.foodPreference}</small>
              </Grid>
            </div>
            <div className="user_action_buttons">
              <button
                className={disabled ? "disabledButton" : "submit__btn"}
                type="submit"
                disabled={disabled}
              >
                {createMode ? "Save" : "Update"}
              </button>
              <button className="next__btn" type="button">
                <Link to="/host/contact">Next &rarr;</Link>
              </button>
            </div>
          </div>
        </form>
      </Container>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    host: state.getUser.user,
    profile: state.hostProfile.profile,
    updatedProfile: state.updateHostProfile.hostProfile,
  };
};

export default connect(mapStateToProps, {
  hostFamilyProfile,
  getUser,
  updateHostProfile,
})(HostProfile);
