import React,{useEffect} from "react";
import ContactFont from "../../Components/Page/Contact/ContactFront/ContactFront";
import ContactPage from "../../Components/Page/Contact/Contact";
import Footer from "../../Components/Footer/NewFooter";
import Contact from "../../Components/ResponsiveNavBar/Contacts/Contacts";
import Navigation from "../../Components/myNavbar/navbar";

const ContactUs = () => {

  useEffect(()=>{
    if(typeof window !== "undefined"){
      window.scrollTo(0,0)
    }
  },[])

  return (
    <React.Fragment>
      <Contact />
      <Navigation />
      <ContactFont />
      <ContactPage />
      <Footer />
    </React.Fragment>
  );
};

export default ContactUs;
