import React, { Fragment, useState, useEffect } from "react";
import { useStyles } from "./style";
import "./matchStyles.css";
import { Modal, List, Card, Col, Row, Carousel } from "antd";
import Grid from "@material-ui/core/Grid";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { connect } from "react-redux";
import { createMatching } from "./../../../../Redux/action/matches";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const myDatalist = [
  {
    id: 1,
    from: "Canada",
    children: 2,
    AupairNeed: "Aupair speaking(English,Dush,spanish)",
    familyStatus: "Classic",
    city: "Big City",
  },
];

const MatchDetails = ({ match, createMatching, newMatching }) => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };
  const handleConnection = () => {
    const matchingData = {
      inviteeFirstName: match?.firstName,
      inviteeLastName: match?.lastName,
      inviteePhone: match?.phone,
      inviteeEmail: match?.email,
      inviteeRole: match?.role,
      inviteePicture: match?.profilePicture,
      inviteeId: match?.uuid,
    };
    setOpen(true);
    createMatching(matchingData);
  };

  useEffect(() => {
    if (newMatching?.success) {
      setOpen(false);
    } else if (!newMatching.success || newMatching.error !== null) {
      setOpen(false);
    }
  }, [newMatching?.success, newMatching.error]);

  return (
    <>
      <div>
        <Backdrop style={{ zIndex: 1500, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <IconButton className="view_button" onClick={showModal}>
        <VisibilityIcon />
      </IconButton>
      <Modal
        width={1000}
        title="ONE OF YOUR BEST MATCHES"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        bodyStyle={{ backgroundColor: "#696969" }}
        footer={null}
      >
        <div className={classes.hostInfoContainer}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6} xlg={6}>
              <div className={classes.ImagePart}>
                <img
                  className={classes.Image}
                  alt="example"
                  src={match?.profilePicture}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xlg={6}>
              <div className={classes.InfoPart}>
                <img
                  alt="host avatar"
                  src={match?.profilePicture}
                  style={{
                    width: "45px",
                    height: "45px",
                    borderRadius: "100px",
                    objectFit: "cover",
                  }}
                />
                <span
                  style={{
                    marginTop: "25px",
                    marginLeft: "20px",
                    color: "#fff",
                  }}
                >
                  <strong>
                    {match?.firstName} &nbsp;
                    {match?.lastName}
                  </strong>
                </span>
                <List
                  className={classes.InfoList}
                  size="small"
                  header={
                    <div>
                      <strong className={classes.about}>About me</strong>
                    </div>
                  }
                  bordered
                >
                  {myDatalist.map((item) => (
                    <div key={item.id}>
                      <List.Item>
                        <div>
                          <label className={classes.InfoItemLabel}>
                            I am from {item.from}
                          </label>
                        </div>
                      </List.Item>
                      <List.Item>
                        <div>
                          <label className={classes.InfoItemLabel}>
                            I have {item.children} childrem
                          </label>
                        </div>
                      </List.Item>
                      <List.Item>
                        <div>
                          <label className={classes.InfoItemLabel}>
                            I am interested in {item.AupairNeed}
                          </label>
                        </div>
                      </List.Item>
                      <List.Item>
                        <div>
                          <label className={classes.InfoItemLabel}>
                            Family Status {item.familyStatus}
                          </label>
                        </div>
                      </List.Item>

                      <List.Item>
                        <div>
                          <label className={classes.InfoItemLabel}>
                            City {item.city}
                          </label>
                        </div>
                      </List.Item>
                    </div>
                  ))}
                </List>
                <button onClick={handleConnection} className={classes.ModalBtn}>
                  Connect
                </button>
              </div>
            </Grid>
          </Grid>
        </div>
        <h2 className={classes.GalleyTitle}>My Galley</h2>
        <div>
          <Carousel autoplay arrows>
            <div>
              <div className="container-fluid mt-4">
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  {match?.aupairgallery?.map((item) => (
                    <Col className="gutter-row" span={6} key={item?.uuid}>
                      <Card
                        hoverable
                        style={{ height: "200px" }}
                        cover={
                          <img
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                            alt="match profile"
                            src={item?.photo}
                          />
                        }
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          </Carousel>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    newMatching: state.createMatch.matching,
  };
};

export default connect(mapStateToProps, { createMatching })(MatchDetails);
