import React,{useEffect} from "react";
import OurStory from "../../Components/OurStory/OurStory";
import Aupair from "../../Components/AupairHome/AupairHome";
import Duties from "../../Components/AupairDuties/Duties";
import HouseHold from "../../Components/AupairHouseHold/Cores";
import NotApart from "../../Components/Page/Host/HostNotApart/Apart";
import Footer from "../../Components/Footer/NewFooter";
import Contact from "../../Components/ResponsiveNavBar/Contacts/Contacts";
import Navigation from "../../Components/myNavbar/navbar";

const Home = () => {

  useEffect(()=>{
    if(typeof window !== "undefined"){
      window.scrollTo(0,0)
    }
  },[])

  return (
    <React.Fragment>
      <Contact />
      <Navigation />
      <Aupair />
      <OurStory />
      <Duties />
      <HouseHold />
      <NotApart />
      <Footer />
    </React.Fragment>
  );
};

export default Home;
