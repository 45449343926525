import React, { Fragment } from "react";
import "./style.css";

const More = ({ Data }) => {
  return (
    <Fragment>
      <div className="personal__data">
        <div className="personal__data__header">
          <h1>Living Area</h1>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>We live in:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.welivein}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>State:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.state}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>City:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.city}</label>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default More;
