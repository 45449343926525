import React from "react";
import "./ContactFront.css";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const Contacts = () => (
  <div className="booking-front">
    <div className="booking-with">
      <h1 className="heading__priamry">
        <span className="heading__primary--contact"> Contact us</span>
        <span className="heading__primary--sub">
          Home
          <ArrowForwardIosIcon />
          Contact
        </span>
      </h1>
    </div>
  </div>
);
export default Contacts;
