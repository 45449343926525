import React, { useState, useEffect, Fragment } from "react";
import { Drawer } from "antd";
import "./Drawer.css";
import { connect } from "react-redux";
import { getUser } from "./../../../../../../Redux/action/staffMembers";

const CustomDrawer = (props) => {
  const [visible, setVisible] = useState(false);
  // const [user, setUser] = useState(
  //   JSON.parse(localStorage.getItem("profile")).data.user
  // );
  
const user = JSON.parse(localStorage.getItem("profile")).data.user

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (user?.uuid) {
      const fetchUser = () => {
        getUser(user?.uuid);
      };
      fetchUser();
    } else {
      return;
    }
  }, [user?.uuid]);

  return (
    <Fragment>
      <button className="actionButton" onClick={showDrawer}>
        {props.Label}
      </button>
      <Drawer
        title={`${props.Label}`}
        placement="right"
        onClose={onClose}
        visible={visible}
        width="80%"
        bodyStyle={{ backgroundColor: "#696969" }}
      >
        {props.FORM}
      </Drawer>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    aupair: state.getUser.user,
  };
};

export default connect(mapStateToProps, { getUser })(CustomDrawer);
