import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Expectation from "./hostExpectation";
import Wishes from "./hostWishes";
import Profile from "./hostProfile";
import HostOtherInfo from "./hostOtherInfo";

const HostFamily = () => {
  const [step, setStep] = React.useState(1);
  const [hostProfileData, setHostProfileData] = React.useState({
    fullName: "",
    partnerFullName: "",
    phone: "",
    email: "",
    birthDate: "",
    familyStatus: "",
    country: "",
    religion: "",
    religionImpt: "",
    havingChildren: "",
    stChildFullName: "",
    stChildAge: "",
    ndChildFullName: "",
    ndChildAge: "",
    rdChildFullName: "",
    rdChildAge: "",
    fothChildFullName: "",
    fothChildAge: "",
    fithChildFullName: "",
    fithChildAge: "",
    hobbies: "",
    routine: "",
    uniqueness: "",
    quality: "",
    weakness: "",
    specialNeed: "",
    street: "",
    postCode: "",
    houseNo: "",
    city: "",
    workPreference: "",
  });
  const [expectation, setExpectation] = React.useState({
    aupairExpAupair: "",
    aupairLiveWith: "",
    childrenNo: "",
    childrenAge: "",
    searchingFor: "",
    aupirAge: "",
    aupairCountry: "",
    aupairLanguage: "",
    smokingAupair: "",
    houseWorkPlanned: "",
    WorkingHrs: "",
    timetableDef: "",
    flexibleFam: "",
    integrateFamLife: "",
    eatOnWish: "",
    fullName: "",
  });
  const [hostOtherInfo, setHostOtherInfo] = React.useState({
    drivingLicense: "",
    canRideBike: "",
    canSwim: "",
    firstAidTraining: "",
    houseWork: "",
    childrenCareness: "",
    careAboutPets: "",
    educatLevel: "",
    specialRegime: "",
    foodPreference: "",
    fullName: "",
  });
  const [wishes, setWishes] = React.useState({
    travelExpenceFees: "",
    medicalInsuranceFees: "",
    pocketMoney: "",
    languageCourseFees: "",
    localTranspTicket: "",
    provideCar: "",
    provideBike: "",
    privateRoom: "",
    privateBathroom: "",
    fullName: "",
    letter: "",
    photos: "",
  });
  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  switch (step) {
    case 1:
      return (
        <React.Fragment>
          <CssBaseline />
          <Profile
            nextStep={nextStep}
            hostProfileData={hostProfileData}
            setHostProfileData={setHostProfileData}
          />
        </React.Fragment>
      );
    case 2:
      return (
        <React.Fragment>
          <CssBaseline />
          <Expectation
            nextStep={nextStep}
            prevStep={prevStep}
            expectation={expectation}
            setExpectation={setExpectation}
          />
        </React.Fragment>
      );
    case 3:
      return (
        <React.Fragment>
          <CssBaseline />
          <HostOtherInfo
            nextStep={nextStep}
            hostOtherInfo={hostOtherInfo}
            setHostOtherInfo={setHostOtherInfo}
            prevStep={prevStep}
          />
        </React.Fragment>
      );
    case 4:
      return (
        <React.Fragment>
          <CssBaseline />
          <Wishes wishes={wishes} setWishes={setWishes} prevStep={prevStep} />
        </React.Fragment>
      );
    case 5:
      return <h1>Success</h1>;
    default:
      return " ";
  }
};
export default HostFamily;
