import React from "react";
import Footer from "../../Footer/NewFooter";
import HostFamily from "./HostFamily";
import HostFamilyHeader from "./HostFamilyHome";

const HostFamilyData = () => {
  return (
    <React.Fragment>
      <HostFamilyHeader />
      <HostFamily />
      <Footer />
    </React.Fragment>
  );
};

export default HostFamilyData;
