import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { ContactUs } from "./../../../../Redux/action/Contactus";
import { toast } from "react-toastify";
import "./../Contact.css";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
}));

const CntactUs = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
  });

  const { contact } = useSelector((state) => state.contactUs);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!contact.success) {
      setOpen(true);
    }
    dispatch(ContactUs(formData));
    clear();
  };

  const clear = () => {
    setFormData({
      name: "",
      phone: "",
      email: "",
      subject: "",
      message: "",
    });
  };

  useEffect(() => {
    if (contact?.success) {
      setOpen(false);
      setMessage(contact?.message?.message);
    }
  }, [contact?.success, contact?.message?.message]);

  useEffect(() => {
    if (!contact?.success || contact?.error !== null) {
      setOpen(false);
      setMessage("Some thing went wrong!");
    }
  }, [contact?.success, contact?.error]);

  useEffect(() => {
    const notify = () => {
      if (contact?.error && message) {
        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (contact?.success && message) {
        toast.success(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    if (contact?.success && message) {
      notify();
    } else if (!contact?.success || (contact?.error !== null && message)) {
      notify();
    } else {
      return;
    }
  }, [contact?.success, contact?.error, message]);

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <div className="contact__form">
        <strong style={{ color: "orange", fontSize: "30px" }}>
          GET IN TOUCH !
        </strong>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <input
                name="Name"
                required
                id="firstName"
                placeholder="Full Name"
                value={formData.name}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    name: event.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                required
                id="Phone"
                name="Phone"
                placeholder="Phone"
                value={formData.phone}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    phone: event.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <input
                required
                size="small"
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                placeholder="Your email please"
                value={formData.email}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    email: event.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                required
                id="subject"
                name="subject"
                placeholder="Subject"
                value={formData.subject}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    subject: event.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <textarea
                id="message"
                rows={5}
                required
                name="message"
                value={formData.message}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    message: event.target.value,
                  })
                }
              />
            </Grid>
          </Grid>

          <button type="submit" className="submit">
            Submit Now
          </button>
        </form>
      </div>
    </Container>
  );
};

export default CntactUs;
