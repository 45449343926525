import React, { Fragment } from "react";
import Layout from "./../../Layouts/Dashboard";
import AllRoletList from "./Role";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CreateRole from "./CreateRole";
import { Divider } from "antd";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    fontSize: "20px",
    textAlign: "center",
    fontWeight: 700,
    color: "#9e9e9e",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const AllRoles = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <Layout>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} xlg={12}>
              <h1 className={classes.title}>ROLES MANAGEMENT</h1>
            </Grid>
            <Divider />
            <Grid item xs={12} sm={12} md={12} lg={12} xlg={12}>
              <CreateRole />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xlg={12}>
              <AllRoletList />
            </Grid>
          </Grid>
        </div>
      </Layout>
    </Fragment>
  );
};
export default AllRoles;
