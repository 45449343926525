import { configureStore } from "@reduxjs/toolkit";

import SignupReducer from "./Redux/Reducers/SignUp";
import SigninReducer from "./Redux/Reducers/SignIn";
import aupairContactsReducer from "./Redux/Reducers/AupairContacts";
import aupairEducationReducer from "./Redux/Reducers/aupairEducationBackground";
import aupairMoreInfoReducer from "./Redux/Reducers/AupairOtherInfo";
import aupairExpectationReducer from "./Redux/Reducers/AupairExpectation";
import aupairProfileReducer from "./Redux/Reducers/AUpairProfile";
import getAupairProfileReducer from "./Redux/Reducers/getAupairProfile";
import aupairPreferenceReducer from "./Redux/Reducers/aupairPreferences";
import aupairWishesReducer from "./Redux/Reducers/AupairWishes";
import aupairDocumentReducer from "./Redux/aupairForms/documentSlice";
import aupairGalleryReducer from "./Redux/aupairForms/gallerySlice";
import allAupairReducer from "./Redux/Reducers/getAllAupairs";
import updateAupairProfileReducer from "./Redux/Reducers/updateAupairProfile";
import updateAupairContactsReducer from "./Redux/Reducers/updateAupairContacts";
import updateAupairEducationReducer from "./Redux/Reducers/updateAupairEducation";
import updateAupairMoreInfoReducer from "./Redux/Reducers/updateAupairMoreInfo";
import updateAupairPreferenceReducer from "./Redux/Reducers/updateAupairPreferences";
import updateAupairExpectationsReducer from "./Redux/Reducers/updateAupairExpectations";
import updateAupairWishesReducer from "./Redux/Reducers/updateAupairWishes";

import hostProfileReducer from "./Redux/Reducers/hostProfile";
import hostContactReducer from "./Redux/Reducers/hostContact";
import hostExpectationsReducer from "./Redux/Reducers/hostExpectation";
import hostPreferencesReducer from "./Redux/Reducers/HostPreferences";
import hostLivingAreaReducer from "./Redux/Reducers/hostLivingArea";
import hostDocumentReducer from "./Redux/HostFamilyForms/documentSlice";
import hostGalleryReducer from "./Redux/HostFamilyForms/gallerySlice";
import hostOffersReducer from "./Redux/Reducers/hostOffer";
import hostMoreInfoReducer from "./Redux/Reducers/hostOtherInfo";
import hostProfessionalReducer from "./Redux/Reducers/hostProfessionalBackground";
import allHostReducer from "./Redux/Reducers/getAllHost";
import updateHostProfileReducer from "./Redux/Reducers/updateHostProfile";
import updateHostContactReducer from "./Redux/Reducers/updateHostContact";
import updateHostBackgroundReducer from "./Redux/Reducers/updateHostBackground";
import updateHostLivingReducer from "./Redux/Reducers/updateHostLivingArea";
import updateHostPreferenceReducer from "./Redux/Reducers/updateHostPreferences";
import updateHosOtherInfoReducer from "./Redux/Reducers/UpdatehostOtherInfo";
import updateHostExpectationsReducer from "./Redux/Reducers/UpdatehostExpectations";
import updateHostOfferReducer from "./Redux/Reducers/updatehostOffer";

import contactusReducer from "./Redux/Reducers/contactus";
import allRolesReducer from "./Redux/Reducers/getRoles";
import createRoleReducer from "./Redux/Reducers/createRole";
import updateRoleReducer from "./Redux/Reducers/updateRole";
import getRoleReducer from "./Redux/Reducers/getRole";
import deleteRoleReducer from "./Redux/Reducers/deleteRole";
import staffMembersReducer from "./Redux/Reducers/staffMembers";
import registerStaffMembersReducer from "./Redux/Reducers/registerStaffMembers";
import updateStaffMembersReducer from "./Redux/Reducers/updateStaffMember";
import getUserReducer from "./Redux/Reducers/getUser";
import deleteUserReducer from "./Redux/Reducers/deleteUser";
import contactMessageReducer from "./Redux/Reducers/messages";
import deleteMessageReducer from "./Redux/Reducers/deleteMessage";

import createCategoryReducer from "./Redux/Reducers/createCategory";
import getCategoriesReducer from "./Redux/Reducers/allCategories";
import getCategoryReducer from "./Redux/Reducers/getCategory";
import updateCategoryReducer from "./Redux/Reducers/updateCategory";
import deleteCategoryReducer from "./Redux/Reducers/deleteCategory";

import createBlogReducer from "./Redux/Reducers/createBlog";
import getAllBlogsReducer from "./Redux/Reducers/fetchAllBlogs";
import getBlogReducer from "./Redux/Reducers/getBlog";
import updateBlogReducer from "./Redux/Reducers/updateBlog";
import deleteBlogReducer from "./Redux/Reducers/deleteBlog";

import allAnnouncementsReducer from "./Redux/Reducers/getAllAnnouncements";
import deleteAnnouncementsReducer from "./Redux/Reducers/deleteAnnouncements";
import createAnnouncementsReducer from "./Redux/Reducers/createAnnouncement";
import updateAnnouncementsReducer from "./Redux/Reducers/updateAnnouncement";

import createTestmonyReducer from "./Redux/Reducers/createTestmony";
import allTestmonialsReducer from "./Redux/Reducers/getAllTestmonials";
import updateTestmonyReducer from "./Redux/Reducers/updateTestmony";
import deleteTestmonyReducer from "./Redux/Reducers/deleteTestmony";

import addAupairPhotoReducer from "./Redux/Reducers/addAupairPhoto";
import allAupairPhotosReducer from "./Redux/Reducers/getAllAupairPhotos";
import deleteAupairPhotoReducer from "./Redux/Reducers/deleteAupairPhoto";
import updateAupairPhotoReducer from "./Redux/Reducers/updatedAupairPhoto";
import getAupairPhotoReducer from "./Redux/Reducers/getAupairPhoto";
import rejectAupairPhotoReducer from "./Redux/Reducers/rejectAupairGallery";
import restoreAupairPhotoReducer from "./Redux/Reducers/restoreAupairGallery";

import allHostPhotosReducer from "./Redux/Reducers/getHostPhotos";
import deleteHostPhotosReducer from "./Redux/Reducers/deleteHostPhoto";
import createHostPhotosReducer from "./Redux/Reducers/createHostPhoto";
import getHostPhotosReducer from "./Redux/Reducers/getHostPhoto";
import updateHostPhotosReducer from "./Redux/Reducers/updateHostPhoto";
import rejectHostPhotoReducer from "./Redux/Reducers/rejecthostGallery";
import restoreHostPhotoReducer from "./Redux/Reducers/restoreHostGallery";

import forgotPasswordReducer from "./Redux/Reducers/forgotPassword";
import resetPasswordReducer from "./Redux/Reducers/resetPassword";

import uploadAupairDocumentReducer from "./Redux/Reducers/uploadAupairDocument";
import allAupairDocumentsReducer from "./Redux/Reducers/getAllAupairDocuments";
import updateAupairDocumentReducer from "./Redux/Reducers/updateAupairDocument";
import deleteAupairDocumentReducer from "./Redux/Reducers/deteleAupairDocument";
import rejectAupairDocumentReducer from "./Redux/Reducers/rejectAupairDocument";
import unRejectAupairReducer from "./Redux/Reducers/unRejectAupairDocument";

import uploadHostDocumentReducer from "./Redux/Reducers/uploadHostDocument";
import getAllHostDocumentsReducer from "./Redux/Reducers/getAllHostDocuments";
import getHostDocumentReducer from "./Redux/Reducers/getHostDocument";
import updateHostDocumentReducer from "./Redux/Reducers/updateHostDocument";
import deleteHostDocumentReducer from "./Redux/Reducers/deleteHostDocument";
import rejectHostDocumentReducer from "./Redux/Reducers/rejectHostDocument";

import subscribeReducer from "./Redux/Reducers/subscribe";
import subscribersReducer from "./Redux/Reducers/fetchSubscribers";
import deleteSubscribersReducer from "./Redux/Reducers/deleteSubscriber";

import approveUserReducer from "./Redux/Reducers/ApproveUser";
import disapproveUserReducer from "./Redux/Reducers/DisapproveUser";
import holdUserReducer from "./Redux/Reducers/HoldUser";
import unHoldUserReducer from "./Redux/Reducers/UnHoldUser";
import matchUserReducer from "./Redux/Reducers/MatchUser";
import rejectUserReducer from "./Redux/Reducers/rejectUser";
import unRejectUserReducer from "./Redux/Reducers/unRejectUser";
import updateProfilePictureReducer from "./Redux/Reducers/updateProfilePicture";
import SearhReducer from "./Redux/Reducers/Search";
import TabsReducer from "./Redux/Reducers/navTabs";
import getLayoutTabReducer from "./Redux/Reducers/getLayoutTabIndex";
import adminNotificationsReducer from "./Redux/Reducers/adminNotifications";
import readNotificationReducer from "./Redux/Reducers/readNotification";
import redirectionLinkReducer from "./Redux/Reducers/paymentRedirectionLink";
import transactionReducer from "./Redux/Reducers/getPaymentTransaction";
import matchingReducer from "./Redux/Reducers/findMatching";
import allPaymentsReducer from "./Redux/Reducers/getAllPayments";
import createMatchingReducer from "./Redux/Reducers/createMatching";
import allConnectionsReducer from "./Redux/Reducers/getAllConnetions";
import approveMatchingReducer from "./Redux/Reducers/approveMatching";
import updateProfileReducer from "./Redux/Reducers/updateProfile";
import purchasePlanReducer from "./Redux/Reducers/purchasePlan";
import getAdminBlogReducer from "./Redux/Reducers/fetchBlogsForAdmin";
import publishBlogReducer from "./Redux/Reducers/publishBlog";

const store = configureStore({
  reducer: {
    signup: SignupReducer,
    login: SigninReducer,
    staff: staffMembersReducer,
    register: registerStaffMembersReducer,
    updateProfile: updateProfileReducer,
    updateStaff: updateStaffMembersReducer,
    getUser: getUserReducer,
    deletedUser: deleteUserReducer,
    forgotPassword: forgotPasswordReducer,
    resetPassword: resetPasswordReducer,

    aupairProfile: aupairProfileReducer,
    getAupairProfile: getAupairProfileReducer,
    aupairContacts: aupairContactsReducer,
    aupairEducation: aupairEducationReducer,
    aupairMoreInfo: aupairMoreInfoReducer,
    aupairExpectation: aupairExpectationReducer,
    aupairPreferences: aupairPreferenceReducer,
    aupairWishes: aupairWishesReducer,
    aupairDocument: aupairDocumentReducer,
    aupairGallery: aupairGalleryReducer,
    allAupairs: allAupairReducer,
    updateAupairProfile: updateAupairProfileReducer,
    updateAupairContacts: updateAupairContactsReducer,
    updatedAupairEducation: updateAupairEducationReducer,
    updateAupairOtherInfo: updateAupairMoreInfoReducer,
    updateAupairPreferences: updateAupairPreferenceReducer,
    updateAupairExpectations: updateAupairExpectationsReducer,
    updateAupairWishes: updateAupairWishesReducer,

    hostProfile: hostProfileReducer,
    hostContact: hostContactReducer,
    hostLivingArea: hostLivingAreaReducer,
    hostPreferences: hostPreferencesReducer,
    hostMoreInfo: hostMoreInfoReducer,
    hostExpectations: hostExpectationsReducer,
    hostOffers: hostOffersReducer,
    hostProfessionalBackground: hostProfessionalReducer,
    hostDocument: hostDocumentReducer,
    hostGallery: hostGalleryReducer,
    allHosts: allHostReducer,
    updateHostProfile: updateHostProfileReducer,
    updateHostContact: updateHostContactReducer,
    updateHostBackground: updateHostBackgroundReducer,
    updateHostLivingArea: updateHostLivingReducer,
    updateHostPreferences: updateHostPreferenceReducer,
    updateHostOtherInfo: updateHosOtherInfoReducer,
    updateHostExpectations: updateHostExpectationsReducer,
    updateHostOffer: updateHostOfferReducer,

    contactUs: contactusReducer,

    Allroles: allRolesReducer,
    createRole: createRoleReducer,
    updateRole: updateRoleReducer,
    getRole: getRoleReducer,
    deleteRole: deleteRoleReducer,

    messages: contactMessageReducer,
    deleteMessage: deleteMessageReducer,

    createCategory: createCategoryReducer,
    allCategories: getCategoriesReducer,
    getCategory: getCategoryReducer,
    updateCategory: updateCategoryReducer,
    deleteCategory: deleteCategoryReducer,

    createBlog: createBlogReducer,
    allBlogs: getAllBlogsReducer,
    getBlog: getBlogReducer,
    updateBlog: updateBlogReducer,
    deleteBlog: deleteBlogReducer,

    announcements: allAnnouncementsReducer,
    deleteAnnouncement: deleteAnnouncementsReducer,
    createAnnouncment: createAnnouncementsReducer,
    updateAnnouncement: updateAnnouncementsReducer,

    createTestmony: createTestmonyReducer,
    allTestmonials: allTestmonialsReducer,
    updateTestmony: updateTestmonyReducer,
    deletedTestmony: deleteTestmonyReducer,

    addAupairPhoto: addAupairPhotoReducer,
    allAupairPhotos: allAupairPhotosReducer,
    deletedAupairPhoto: deleteAupairPhotoReducer,
    updatedAupairPhoto: updateAupairPhotoReducer,
    getAupairPhoto: getAupairPhotoReducer,
    rejectAupairPhoto: rejectAupairPhotoReducer,
    restoreAupairPhoto: restoreAupairPhotoReducer,

    allHostPhotos: allHostPhotosReducer,
    deleteHostPhoto: deleteHostPhotosReducer,
    createHostPhoto: createHostPhotosReducer,
    getHostPhoto: getHostPhotosReducer,
    updateHostPhoto: updateHostPhotosReducer,
    rejectHostPhoto: rejectHostPhotoReducer,
    restoreHostPhoto: restoreHostPhotoReducer,

    uplaodAupairDocument: uploadAupairDocumentReducer,
    allAupairDocuments: allAupairDocumentsReducer,
    updateAupairDocument: updateAupairDocumentReducer,
    deleteAupairDocument: deleteAupairDocumentReducer,
    rejectAupairDocument: rejectAupairDocumentReducer,
    restoreAupairDocument: unRejectAupairReducer,

    addHostDocument: uploadHostDocumentReducer,
    allHostDocuments: getAllHostDocumentsReducer,
    gethostDocument: getHostDocumentReducer,
    updateHostDocument: updateHostDocumentReducer,
    deleteHostDocument: deleteHostDocumentReducer,
    rejectHostDocument: rejectHostDocumentReducer,

    subscribe: subscribeReducer,
    subscribers: subscribersReducer,
    deleteSubscriber: deleteSubscribersReducer,

    approveUser: approveUserReducer,
    disapproveUser: disapproveUserReducer,
    holdUser: holdUserReducer,
    unHoldUser: unHoldUserReducer,
    matchUser: matchUserReducer,
    rejectUser: rejectUserReducer,
    restoreUser: unRejectUserReducer,
    search: SearhReducer,
    profilePicture: updateProfilePictureReducer,
    tab: TabsReducer,
    layoutTabs: getLayoutTabReducer,
    adminNotifications: adminNotificationsReducer,
    readAdminNotification: readNotificationReducer,
    redirectionLink: redirectionLinkReducer,
    transaction: transactionReducer,
    matching: matchingReducer,
    createMatch: createMatchingReducer,
    payments: allPaymentsReducer,
    allConnections: allConnectionsReducer,
    approveMatching: approveMatchingReducer,
    purchasePlan: purchasePlanReducer,
    adminBlog: getAdminBlogReducer,
    publishedBlog: publishBlogReducer,
  },
});

export default store;
