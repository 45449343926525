import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useStyles } from "./style";
import Grid from "@material-ui/core/Grid";
import "./Host.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import {
  hostProfessionalBackground,
  updateHostBackground,
} from "./../../../../../../../Redux/action/hostFroms";
import { toast } from "react-toastify";

const initialValues = {
  languageSkills: [""],
  selfEmployed: "",
  privateSectorEmployed: "",
  publicSectorEmployed: "",
};

const EducationBackground = ({ hostEducationData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [createMode, setCreateMode] = useState(true);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [message, setMessage] = useState("");

  const { background } = useSelector(
    (state) => state.hostProfessionalBackground
  );
  const { hostBackground } = useSelector((state) => state.updateHostBackground);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "languageSkills") {
      setFormValues({
        ...formValues,
        [name]: formValues.languageSkills?.length !== 0 && [
          ...formValues?.languageSkills,
          value,
        ],
      });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));
    if (Object.keys(formErrors).length === 0) {
      if (createMode) {
        setOpen(true);
        dispatch(hostProfessionalBackground(formValues));
        clear();
      } else {
        setOpen(true);
        dispatch(updateHostBackground(formValues, hostEducationData.uuid));
      }
    }
  };

  const clear = () => {
    setFormValues(initialValues);
  };

  useEffect(() => {
    if (background.success) {
      setOpen(false);
      setMessage(background?.message?.message);
    } else if (!background.success && background.error !== null) {
      setOpen(false);
      setMessage("There was an error,try again");
    }
  }, [background?.success, background?.message?.message, background.error]);

  useEffect(() => {
    if (!background.success && background.error !== null) {
      setOpen(false);
    }
  }, [background.success, background.error]);

  useEffect(() => {
    if (hostBackground.success) {
      setOpen(false);
      setMessage(hostBackground?.message?.message);
    } else if (!hostBackground.success && hostBackground.error !== null) {
      setOpen(false);
      setMessage("There was an error,try again");
    }
  }, [
    hostBackground.success,
    hostBackground.error,
    hostBackground?.message?.message,
  ]);

  useEffect(() => {
    const notify = () => {
      if (background?.error && message) {
        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (background?.success && message) {
        toast.success(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    if (background?.success && message) {
      notify();
    } else if (!background?.success && background?.error !== null && message) {
      notify();
    } else {
      return;
    }
  }, [background?.success, background?.error, message]);

  useEffect(() => {
    const notify = () => {
      if (hostBackground?.error && message) {
        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (hostBackground?.success && message) {
        toast.success(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    if (hostBackground?.success && message) {
      notify();
    } else if (
      !hostBackground?.success &&
      hostBackground?.error !== null &&
      message
    ) {
      notify();
    } else {
      return;
    }
  }, [hostBackground?.success, hostBackground?.error, message]);

  const validate = (values) => {
    const errors = {};

    if (!values.languageSkills?.length === 0) {
      errors.languageSkills = "Language Skills Please !";
    }
    if (!values.languageSkills?.length > 3) {
      errors.languageSkills = "Maximum is three !";
    }
    if (!values.selfEmployed) {
      errors.selfEmployed = "This is required !";
    }
    if (!values.privateSectorEmployed) {
      errors.privateSectorEmployed = "This is required !";
    }
    if (!values.publicSectorEmployed) {
      errors.publicSectorEmployed = "This is required !";
    }

    return errors;
  };

  useEffect(() => {
    if (hostEducationData) {
      setFormValues({
        selfEmployed: hostEducationData?.selfEmployed,
        privateSectorEmployed: hostEducationData?.privateSectorEmployed,
        publicSectorEmployed: hostEducationData?.publicSectorEmployed,
      });
      setCreateMode(false);
    } else if (!hostEducationData) {
      setCreateMode(true);
      setFormValues(initialValues);
    }
  }, [hostEducationData]);

  useEffect(() => {
    if (
      formValues.languageSkills?.length === 0 ||
      formValues.selfEmployed === "" ||
      formValues.privateSectorEmployed === "" ||
      formValues.publicSectorEmployed === ""
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formValues]);

  return (
    <React.Fragment>
      <CssBaseline />
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Container maxWidth="md" className="form_container">
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="personalInformationGroup">
            <h3 className="InnerTitles">Professional Background</h3>
            <div className="FieldContainer">
              <Grid container spacing={3} className="education__skills">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div>
                    <label style={{ textAlign: "center", display: "block" }}>
                      Language skills <span className="required">*</span>
                    </label>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div className="checkbox__container">
                    <input
                      type="checkbox"
                      id="german_native"
                      name="languageSkills"
                      value="German Native Language"
                      onChange={handleChange}
                      className="checkbox__input"
                    />
                    <label htmlFor="german_native">
                      German Native Language
                    </label>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div className="checkbox__container">
                    <input
                      type="checkbox"
                      id="good_german"
                      name="languageSkills"
                      value="Good Knowledge of German"
                      onChange={handleChange}
                      className="checkbox__input"
                    />
                    <label htmlFor="good_german">
                      Good Knowledge of German
                    </label>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div className="checkbox__container">
                    <input
                      type="checkbox"
                      id="basic_german"
                      name="languageSkills"
                      value="Basic Knowledge of German"
                      onChange={handleChange}
                      className="checkbox__input"
                    />
                    <label htmlFor="basic_german">
                      Basic Knowledge of German
                    </label>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div className="checkbox__container">
                    <input
                      type="checkbox"
                      id="good_english"
                      name="languageSkills"
                      value="Good Knowledge of English"
                      onChange={handleChange}
                      className="checkbox__input"
                    />
                    <label htmlFor="good_english">
                      Good Knowledge of English
                    </label>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div className="checkbox__container">
                    <input
                      type="checkbox"
                      id="basic_english"
                      name="languageSkills"
                      value="Basic Knowledge of English"
                      onChange={handleChange}
                      className="checkbox__input"
                    />
                    <label htmlFor="basic_english">
                      Basic Knowledge of English
                    </label>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div className="checkbox__container">
                    <input
                      type="checkbox"
                      id="good_french"
                      name="languageSkills"
                      value="Good Knowledge of French"
                      onChange={handleChange}
                      className="checkbox__input"
                    />
                    <label htmlFor="good_french">
                      Good Knowledge of French
                    </label>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div className="checkbox__container">
                    <input
                      type="checkbox"
                      id="basic_french"
                      name="languageSkills"
                      value="Basic Knowledge of French"
                      onChange={handleChange}
                      className="checkbox__input"
                    />
                    <label htmlFor="basic_french">
                      Basic Knowledge of French
                    </label>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div>
                    <label>
                      Self Employed
                      <span className="required">*</span>
                    </label>
                    <select
                      name="selfEmployed"
                      value={formValues.selfEmployed}
                      onChange={handleChange}
                    >
                      <option value={null}>--select--</option>
                      <option value="Yes">Yes</option>
                      <option value="Not Yet">Not Yet</option>
                      <option value="None">None</option>
                    </select>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div>
                    <label>
                      Private Sector Employed
                      <span className="required">*</span>
                    </label>
                    <select
                      name="privateSectorEmployed"
                      value={formValues.privateSectorEmployed}
                      onChange={handleChange}
                    >
                      <option value={null}>--select--</option>
                      <option value="Yes">Yes</option>
                      <option value="Not Yet">Not Yet</option>
                      <option value="None">None</option>
                    </select>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div>
                    <label>
                      Public Sector Employed
                      <span className="required">*</span>
                    </label>
                    <select
                      name="publicSectorEmployed"
                      value={formValues.publicSectorEmployed}
                      onChange={handleChange}
                    >
                      <option value={null}>--select--</option>
                      <option value="Yes">Yes</option>
                      <option value="Not Yet">Not Yet</option>
                      <option value="None">None</option>
                    </select>
                  </div>
                </Grid>
              </Grid>
            </div>
            <button
              className={disabled ? "disabledButton" : "submit__btn"}
              type="submit"
              disabled={disabled}
            >
              {createMode ? "Create" : "Update"}
            </button>
          </div>
        </form>
      </Container>
    </React.Fragment>
  );
};

export default EducationBackground;
