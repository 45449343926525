import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "20ch",
    },
  },
  personalInformationGroup: {
    border: "3px solid orange",
    borderRadius: "5px",
    padding: "20px",
    margin: "0px 0px 20px 0px",
  },
  OuterTitle: {
    textTransform: "uppercase",
    fontSize: "30px",
    margin: "0px 0px 20px 0px",
    lineHeight: 1.7,
    fontWeight: 700,
  },
  InnerTitles: {
    textAlign: "center",
    color: " #000000",
    fontWeight: 700,
    margin: "0px 0px 20px 0px",
    textTransform: "uppercase",
  },
  container: {
    marginTop: "40px",
  },
  Label: {
    marginLeft: "20px",
  },
  Continue: {
    backgroundColor: "orange",
    borderRadius: "5px",
    width: "100px",
    padding: "5px",
    color: "#FFFFFF",
    borderStyle: "none",
    marginBottom: "100px",
    cursor: "pointer",
  },
  Back: {
    marginRight: "200px",
    backgroundColor: "rgba(0,0,0,0.8)",
    borderRadius: "5px",
    width: "100px",
    padding: "5px",
    color: "#FFFFFF",
    borderStyle: "none",
    marginBottom: "100px",
    cursor: "pointer",
  },
  children: {
    display: "block",
  },
  noChildren: {
    display: "none",
  },
  match: {
    marginTop: "100px",
  },
  matchTitle: {
    textTransform: "uppercase",
    fontSize: "20px",
    margin: "0px 0px 20px 0px",
    lineHeight: 1.7,
    fontWeight: 700,
    textAlign: "center",
    borderBottom: "2px solid orange",
  },
  connectBtn: {
    cursor: "pointer",
    backgroundColor: "orange",
    borderStyle: "none",
    width: "100px",
    fontSize: "17px",
    borderRadius: "5px",
    color: "#000",
    fontWeight: 500,
  },
  ModalBtn: {
    cursor: "pointer",
    backgroundColor: "orange",
    borderStyle: "none",
    width: "100px",
    fontSize: "17px",
    borderRadius: "5px",
    color: "#000000",
    fontWeight: 500,
    marginTop: "10px",
  },
  avatar: {
    border: "3px solid orange",
  },
  Name: {
    marginTop: "-100px",
  },
  about: {
    color: "orange",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: 1.7,
  },
  GalleyTitle: {
    textTransform: "uppercase",
    fontSize: "20px",
    margin: "0px 0px 20px 0px",
    lineHeight: 1.7,
    fontWeight: 700,
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    color: "#000000",
  },
  Galley: {
    display: "flex",
  },
  GalleyImg: {
    width: "200px",
    height: "190px",
    marginRight: "20px",
  },
  hostInfoContainer: {
    display: "flex",
  },
  ImagePart: {
    width: "50%",
  },
  Image: {
    width: "450px",
    height: "350px",
  },
  InfoPart: {
    width: "50%",
  },
  InfoList: {
    marginTop: "20px",
  },
  hostAvatar: {
    marginTop: "-5px",
    border: "3px solid orange",
    padding: "5px",
  },
  ArrowLeft: {
    position: "absolute",
  },
  ArrowRight: {
    position: "absolute",
    height: "100px",
    top: 610,
    left: 980,
    color: "#000000",
  },
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
  dropZone: {
    width: "95%",
    border: "1px dotted #3e3e3e",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "3px",
    cursor: "pointer",
    color: "#077",
    padding: "8px",
  },
}));
