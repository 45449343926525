import React, { useState, useEffect } from "react";
import Layout from "./MyProfile";
import "./MyProfile.css";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import PersonalDrawer from "./Actions/Drawer";
import HostProfileForm from "./Forms/HostForms/Profile";
import HostContactForm from "./Forms/HostForms/Contact";
import HostExpectationForm from "./Forms/HostForms/Expectation";
import HostProfessionalBackground from "./Forms/HostForms/ProfessionalBackground";
import HostPreferenceForm from "./Forms/HostForms/HostPreference";
import HostLivingArea from "./Forms/HostForms/LivingArea";
import OtherInfo from "./Forms/HostForms/OtherInfo";
import HostOfferToAupair from "./Forms/HostForms/OfferToAupair";
import EditAcountInfo from "./Actions/HostAcountDrawer";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { connect } from "react-redux";
import { updateProfilePicture } from "./../../../../../Redux/action/Auth";
import { getUser } from "./../../../../../Redux/action/staffMembers";
import IconButton from "@mui/material/IconButton";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import SchoolIcon from "@material-ui/icons/School";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import InfoIcon from "@material-ui/icons/Info";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import HandshakeIcon from "@mui/icons-material/Handshake";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "#464852",
    padding: "0px 20px",
  },
  paper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "#696969",
    height: "450px",
  },
  iconColor: {
    color: "orange",
  },
  applicationInfo: {
    width: "100%",
    background: "#464852",
  },
}));

const ProfileInfo = ({
  updateProfilePicture,
  profilePicture,
  getUser,
  host,
}) => {
  const classes = useStyles();

  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("profile")).data.user
  );

  const cloudName = "dsb0gu6km";
  const uploadPreset = "dasaupair";

  var myWidget = window.cloudinary.createUploadWidget(
    {
      cloudName: cloudName,
      uploadPreset: uploadPreset,
      folder: "profile_Pictures",
    },
    (error, result) => {
      if (!error && result && result.event === "success") {
        if (result?.info?.secure_url) {
          updateProfilePicture(
            { profilePicture: result.info.secure_url },
            user?.uuid
          );
        }
      }
    }
  );

  const uplaodFile = () => {
    myWidget.open();
  };

  useEffect(() => {
    if (user?.uuid || profilePicture.success) {
      const fetchUser = () => {
        getUser(user?.uuid);
      };
      fetchUser();
    } else {
      return;
    }
  }, [user?.uuid, getUser, profilePicture.success]);

  return (
    <Layout>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Paper className={classes.paper} elevation={0}>
              <div className="userProfilePic">
                {host?.message?.data?.user?.profilePicture ? (
                  <img
                    className="userProfilePicImg"
                    src={host?.message?.data?.user.profilePicture}
                    alt="user profile"
                  />
                ) : (
                  <img
                    className="userProfilePicImg"
                    src="https://cdn.pixabay.com/photo/2013/07/13/10/07/man-156584_960_720.png"
                    alt="user profilepic"
                  />
                )}
                <CameraAltIcon className="cameraIcon" onClick={uplaodFile} />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Paper className={classes.paper} elevation={0}>
              <div
                className="account__info"
                style={{ backgroundColor: "#464852", color: "#fff" }}
              >
                <div className="account__info__form">
                  <div>
                    <label>Full Name</label>
                    <div className="user_fullname">
                      <input
                        type="text"
                        value={host?.message?.data?.user?.firstName}
                        disabled
                      />
                      <input
                        type="text"
                        value={host?.message?.data?.user?.lastName}
                        disabled
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="host_email">Email</label>
                    <input
                      type="email"
                      value={host?.message?.data?.user?.email}
                      disabled
                      id="host_email"
                    />
                  </div>
                  <div>
                    <label htmlFor="host_phone">Phone</label>
                    <input
                      type="text"
                      value={host?.message?.data?.user?.phone}
                      disabled
                      id="host_phone"
                    />
                  </div>

                  <div>
                    <EditAcountInfo user={user} setUser={setUser} />
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Paper className={classes.paper} elevation={0}>
              <div className={classes.applicationInfo}>
                <List
                  component="nav"
                  className={classes.root}
                  aria-label="contacts"
                >
                  <ListItem button>
                    <ListItemIcon className="information_iconBtn">
                      <IconButton
                        size="small"
                        style={{ background: "#696969" }}
                      >
                        <PersonIcon className={classes.iconColor} />
                      </IconButton>
                    </ListItemIcon>
                    <PersonalDrawer
                      Label="IDENTIFICATION"
                      FORM={
                        <HostProfileForm
                          hostProfileData={
                            host?.message?.data?.user?.hostProfile
                          }
                        />
                      }
                    />
                  </ListItem>
                  <ListItem button>
                    <ListItemIcon className="information_iconBtn">
                      <IconButton
                        size="small"
                        style={{ background: "#696969" }}
                      >
                        <PhoneIcon className={classes.iconColor} />
                      </IconButton>
                    </ListItemIcon>
                    <PersonalDrawer
                      Label="CONTACTS"
                      FORM={
                        <HostContactForm
                          hostContactData={
                            host?.message?.data?.user?.hostContacts
                          }
                        />
                      }
                    />
                  </ListItem>
                  <ListItem button>
                    <ListItemIcon className="information_iconBtn">
                      <IconButton
                        size="small"
                        style={{ background: "#696969" }}
                      >
                        <SchoolIcon className={classes.iconColor} />
                      </IconButton>
                    </ListItemIcon>
                    <PersonalDrawer
                      Label="BACKGROUND"
                      FORM={
                        <HostProfessionalBackground
                          hostEducationData={
                            host?.message?.data?.user?.professionalBackground
                          }
                        />
                      }
                    />
                  </ListItem>
                  <ListItem button>
                    <ListItemIcon className="information_iconBtn">
                      <IconButton
                        size="small"
                        style={{ background: "#696969" }}
                      >
                        <LocationOnIcon className={classes.iconColor} />
                      </IconButton>
                    </ListItemIcon>
                    <PersonalDrawer
                      Label="LIVING AREA"
                      FORM={
                        <HostLivingArea
                          hostLivingData={host?.message?.data?.user?.livingArea}
                        />
                      }
                    />
                  </ListItem>
                  <ListItem button>
                    <ListItemIcon className="information_iconBtn">
                      <IconButton
                        size="small"
                        style={{ background: "#696969" }}
                      >
                        <SupervisedUserCircleIcon
                          className={classes.iconColor}
                        />
                      </IconButton>
                    </ListItemIcon>
                    <PersonalDrawer
                      Label="PREFERENCES"
                      FORM={
                        <HostPreferenceForm
                          hostPreferencesData={
                            host?.message?.data?.user?.hostPreferences
                          }
                        />
                      }
                    />
                  </ListItem>
                  <ListItem button>
                    <ListItemIcon className="information_iconBtn">
                      <IconButton
                        size="small"
                        style={{ background: "#696969" }}
                      >
                        <InfoIcon className={classes.iconColor} />
                      </IconButton>
                    </ListItemIcon>
                    <PersonalDrawer
                      Label="ABOUT AUPAIR"
                      FORM={
                        <OtherInfo
                          hostotherInfoData={
                            host?.message?.data?.user?.hostotherInfo
                          }
                        />
                      }
                    />
                  </ListItem>
                  <ListItem button>
                    <ListItemIcon className="information_iconBtn">
                      <IconButton
                        size="small"
                        style={{ background: "#696969" }}
                      >
                        <CardGiftcardIcon className={classes.iconColor} />
                      </IconButton>
                    </ListItemIcon>
                    <PersonalDrawer
                      Label="EXPECTATIONS"
                      FORM={
                        <HostExpectationForm
                          hostExpectationData={
                            host?.message?.data?.user?.hostExpectations
                          }
                        />
                      }
                    />
                  </ListItem>

                  <ListItem button>
                    <ListItemIcon className="information_iconBtn">
                      <IconButton
                        size="small"
                        style={{ background: "#696969" }}
                      >
                        <HandshakeIcon className={classes.iconColor} />
                      </IconButton>
                    </ListItemIcon>
                    <PersonalDrawer
                      Label="OUR OFFER"
                      FORM={
                        <HostOfferToAupair
                          hostOfferData={
                            host?.message?.data?.user?.offerToAupair
                          }
                        />
                      }
                    />
                  </ListItem>
                </List>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    profilePicture: state.profilePicture.picture,
    host: state.getUser.user,
  };
};

export default connect(mapStateToProps, { updateProfilePicture, getUser })(
  ProfileInfo
);
