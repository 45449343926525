import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";

const PaymentCard = ({ payment }) => {
  const { user, transaction } = payment;

  return (
    <Card sx={{ background: "#464852", color: "#fff" }}>
      <CardHeader
        avatar={<Avatar src={user?.profilePicture} aria-label="recipe" />}
        action={
          <IconButton
            aria-label="settings"
            style={{ backgroundColor: "orange" }}
            size="small"
          >
            <MoreVertIcon sx={{ color: "#000" }} />
          </IconButton>
        }
        title={`${user?.firstName}  ${user.lastName}`}
      />
      <CardContent
        style={{
          textAlign: "left",
        }}
      >
        <Typography variant="body2">Role: Aupair</Typography>
        <Typography variant="body2">
          Package
          {
            JSON.parse(
              transaction
            )?.subscription?.transactions[0]?.planId?.split("_")[0]
          }
        </Typography>
        <Typography variant="body2">
          Amount: &euro;
          {JSON.parse(transaction)?.subscription?.transactions[0]?.amount}
        </Typography>
        <Typography variant="body2">
          {moment(payment.createdAt).fromNow()}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default PaymentCard;
