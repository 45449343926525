import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(50),
  },
  submit: {
    margin: theme.spacing(3, 3, 2),
    backgroundColor: "orange",
    color: "#000",
    float: "right",
    borderRadius: "3px",
    width: "150px",
  },

  verifyBtn: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "orange",
    color: "#FFFFFF",
    borderRadius: "50px",
    width: "150px",
  },
  verifyBtnContainer: {
    padding: "20px",
  },
}));
