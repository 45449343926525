import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { connect } from "react-redux";
import { deleteBlog } from "./../../../Redux/action/blog";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const DeleteModal = ({ blogId, deleteBlog, deletedBlog }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDelete = () => {
    setOpen(true);
    deleteBlog(blogId);
  };

  useEffect(() => {
    if (deletedBlog?.success) {
      setOpen(false);
    } else if (!deletedBlog?.success && deletedBlog?.error !== null) {
      setOpen(false);
    }
  }, [deletedBlog?.success, deletedBlog?.error]);

  return (
    <>
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Tooltip title="Delete" arrow>
        <IconButton onClick={showModal}>
          <DeleteIcon style={{ color: "orange" }} />
        </IconButton>
      </Tooltip>
      <Modal
        title="DELETE BLOG"
        visible={isModalVisible}
        onOk={handleOk}
        okButtonProps={{ backgroundColor: "red" }}
        onCancel={handleCancel}
        okText="Delete"
        bodyStyle={{
          backgroundColor: "#464852",
        }}
        width="20%"
        footer={null}
      >
        <p style={{ color: "#fff", fontWeight: "bold" }}>
          Are sure you want to delete this row?
        </p>
        <button
          style={{
            background: "red",
            border: "1px solid red",
            marginTop: "20px",
            fontWeight: "bold",
            broderRadius: "3px",
          }}
          onClick={handleDelete}
        >
          YES DELETE
        </button>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    deletedBlog: state.deleteBlog.blog,
  };
};

export default connect(mapStateToProps, { deleteBlog })(DeleteModal);
