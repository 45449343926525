import React, { Fragment } from "react";
import "./style.css";

const Wishes = ({ Data }) => {
  return (
    <Fragment>
      <div className="personal__data">
        <div className="personal__data__header">
          <h1>Wished Offer</h1>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>The family is willing to pay the travel expenses:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.travelExpenses}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Family will Pay the monthly medical insuarance:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.medicalInsurance}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>The family will pay the pocket money:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.pocketMoney}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>The family will pay the language course:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.languageCourse}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>The family will pay the local transport ticket:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.transportationTicket}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>They family will Provide the car:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.provideCar}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>They family will Provide the bike:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.provideBike}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong> The Family will provide a private room</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.privateRoom}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>
              The Family will provide the separate toilet and bathroom
            </strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.bathroom}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>
              The basic offer as provided by law is enough for me:
            </strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.basicOffer}</label>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Wishes;
