import React, { useState, useEffect } from "react";
import Layout from "./MyProfile";
import "./MyProfile.css";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import PersonalDrawer from "./Actions/Drawer";
import AupairProfileForm from "./Forms/AupairForms/Profile";
import AupairContactForm from "./Forms/AupairForms/Contact";
// import AupairExpectationForm from "./Forms/AupairForms/Expectation";
import AupairEducation from "./Forms/AupairForms/EducationBackground";
// import AupairFamilyPreferenceForm from "./Forms/AupairForms/FamilyPreference";
import AupairOtherInfo from "./Forms/AupairForms/OtherInfo";
// import AupairWishes from "./Forms/AupairForms/Wishes";
import EditAcountInfo from "./Actions/AupairAcountDrawer";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { connect } from "react-redux";
import { updateProfilePicture } from "./../../../../../Redux/action/Auth";
import { getUser } from "./../../../../../Redux/action/staffMembers";
import IconButton from "@mui/material/IconButton";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import SchoolIcon from "@material-ui/icons/School";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import InfoIcon from "@material-ui/icons/Info";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import PreferenceDrawer from "./Actions/PreferenceDrawer";
import ExpectationDrawer from "./Actions/ExpectationDrawer";
import WishesDrawer from "./Actions/WishesDrawer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "#464852",
    padding: "0px 20px",
  },
  paper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "#696969",
    height: "400px",
  },
  iconColor: {
    color: "orange",
  },
  applicationInfo: {
    width: "100%",
    background: "#464852",
  },
}));

const ProfileInfo = ({
  updateProfilePicture,
  profilePicture,
  getUser,
  aupair,
}) => {
  const classes = useStyles();

  const [profileData, setProfileData] = useState({
    firstName: "",
    lastName: "",
    birthDate: "",
    familyStatus: "",
    nationality: "",
    religion: "",
    religionImpt: "",
    foodPreference: "",
  });

  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("profile")).data.user
  );

  const cloudName = "dsb0gu6km";
  const uploadPreset = "dasaupair";

  var myWidget = window.cloudinary.createUploadWidget(
    {
      cloudName: cloudName,
      uploadPreset: uploadPreset,
      folder: "profile_Pictures",
    },
    (error, result) => {
      if (!error && result && result.event === "success") {
        if (result?.info?.secure_url) {
          updateProfilePicture(
            { profilePicture: result.info.secure_url },
            user?.uuid
          );
        }
      }
    }
  );

  const uplaodFile = () => {
    myWidget.open();
  };

  useEffect(() => {
    if (user?.uuid || profilePicture?.success) {
      const fetchUser = () => {
        getUser(user?.uuid);
      };
      fetchUser();
    } else {
      return;
    }
  }, [user?.uuid, getUser, profilePicture?.success]);

  return (
    <Layout>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Paper className={classes.paper} elevation={0}>
              <div className="userProfilePic">
                {aupair?.message?.data?.user?.profilePicture ? (
                  <img
                    className="userProfilePicImg"
                    src={aupair?.message?.data?.user?.profilePicture}
                    alt="user profile"
                  />
                ) : (
                  <img
                    className="userProfilePicImg"
                    src="https://cdn.pixabay.com/photo/2013/07/13/10/07/man-156584_960_720.png"
                    alt="user profilepic"
                  />
                )}
                <CameraAltIcon className="cameraIcon" onClick={uplaodFile} />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Paper className={classes.paper} elevation={0}>
              <div
                className="account__info"
                style={{ backgroundColor: "#464852", color: "#fff" }}
              >
                <div className="account__info__form">
                  <div>
                    <label>Full Name</label>
                    <div className="user_fullname">
                      <input
                        type="text"
                        value={aupair?.message?.data?.user?.firstName}
                        disabled
                      />
                      <input
                        type="text"
                        value={aupair?.message?.data?.user?.lastName}
                        disabled
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      value={aupair?.message?.data?.user?.email}
                      disabled
                      id="email"
                    />
                  </div>
                  <div>
                    <label htmlFor="phone">Phone</label>
                    <input
                      type="text"
                      value={aupair?.message?.data?.user?.phone}
                      disabled
                      id="phone"
                    />
                  </div>

                  <div>
                    <EditAcountInfo user={user} setUser={setUser} />
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Paper className={classes.paper} elevation={0}>
              <div className={classes.applicationInfo}>
                <List
                  component="nav"
                  className={classes.root}
                  aria-label="contacts"
                >
                  <ListItem button>
                    <ListItemIcon className="information_iconBtn">
                      <IconButton
                        size="small"
                        style={{ background: "#696969" }}
                      >
                        <PersonIcon className={`${classes.iconColor}`} />
                      </IconButton>
                    </ListItemIcon>
                    <PersonalDrawer
                      Label="IDENTIFICATION"
                      FORM={
                        <AupairProfileForm
                          profileData={profileData}
                          setProfileData={setProfileData}
                          aupairProfileData={
                            aupair?.message?.data?.user?.aupairprofile
                          }
                        />
                      }
                    />
                  </ListItem>
                  <ListItem button>
                    <ListItemIcon className="information_iconBtn">
                      <IconButton
                        size="small"
                        style={{ background: "#696969" }}
                        className="information_iconBtn"
                      >
                        <PhoneIcon className={`${classes.iconColor}`} />
                      </IconButton>
                    </ListItemIcon>
                    <PersonalDrawer
                      Label="CONTACT"
                      FORM={
                        <AupairContactForm
                          aupairContactData={
                            aupair?.message?.data?.user?.aupaircontacts
                          }
                        />
                      }
                    />
                  </ListItem>
                  <ListItem button>
                    <ListItemIcon className="information_iconBtn">
                      <IconButton
                        size="small"
                        style={{ background: "#696969" }}
                        className="information_iconBtn"
                      >
                        <SchoolIcon className={`${classes.iconColor}`} />
                      </IconButton>
                    </ListItemIcon>
                    <PersonalDrawer
                      Label="EDUCATION"
                      FORM={
                        <AupairEducation
                          aupairEducData={
                            aupair?.message?.data?.user?.education
                          }
                        />
                      }
                    />
                  </ListItem>
                  <ListItem button>
                    <ListItemIcon className="information_iconBtn">
                      <IconButton
                        size="small"
                        style={{ background: "#696969" }}
                        className="information_iconBtn"
                      >
                        <InfoIcon className={`${classes.iconColor}`} />
                      </IconButton>
                    </ListItemIcon>
                    <PersonalDrawer
                      Label="MORE ABOUT ME"
                      FORM={
                        <AupairOtherInfo
                          aupairMoreInfoData={
                            aupair?.message?.data?.user?.aupairmoreinfo
                          }
                        />
                      }
                    />
                  </ListItem>
                  <ListItem button>
                    <ListItemIcon className="information_iconBtn">
                      <IconButton
                        size="small"
                        style={{ background: "#696969" }}
                        className="information_iconBtn"
                      >
                        <SupervisedUserCircleIcon
                          className={`${classes.iconColor}`}
                        />
                      </IconButton>
                    </ListItemIcon>
                    {/*<PersonalDrawer
                      Label="PREFERENCES"
                      FORM={<AupairFamilyPreferenceForm />}
                    />*/}
                    <PreferenceDrawer />
                  </ListItem>
                  <ListItem button>
                    <ListItemIcon className="information_iconBtn">
                      <IconButton
                        size="small"
                        style={{ background: "#696969" }}
                        className="information_iconBtn"
                      >
                        <CardGiftcardIcon className={`${classes.iconColor}`} />
                      </IconButton>
                    </ListItemIcon>
                    {/*<PersonalDrawer
                      Label="EXPECTATIONS"
                      FORM={<AupairExpectationForm />}
                  />*/}
                    <ExpectationDrawer />
                  </ListItem>

                  <ListItem button>
                    <ListItemIcon className="information_iconBtn">
                      <IconButton
                        size="small"
                        style={{ background: "#696969" }}
                        className="information_iconBtn"
                      >
                        <HomeWorkIcon className={`${classes.iconColor}`} />
                      </IconButton>
                    </ListItemIcon>
                    {/* <PersonalDrawer Label="WISHES" FORM={<AupairWishes />} />*/}
                    <WishesDrawer />
                  </ListItem>
                </List>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    profilePicture: state.profilePicture.picture,
    aupair: state.getUser.user,
  };
};

export default connect(mapStateToProps, { updateProfilePicture, getUser })(
  ProfileInfo
);
