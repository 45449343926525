import React, { useState } from "react";
import PhotoIcon from "@material-ui/icons/Photo";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Modal } from "antd";
import Gallery from "./Gallery";

const GallleryModal = ({ userId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Tooltip title="View Gallery" arrow>
        <IconButton
          onClick={showModal}
          size="small"
          style={{ backgroundColor: "#3f3f3f" }}
        >
          <PhotoIcon style={{ color: "#FFA500", fontSize: "20px" }} />
        </IconButton>
      </Tooltip>
      <Modal
        title="GALLERY"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width="80%"
        bodyStyle={{ backgroundColor: "#464852" }}
      >
        <Gallery userId={userId} />
      </Modal>
    </>
  );
};

export default GallleryModal;
