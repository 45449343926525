import React, { useState, Fragment, useEffect } from "react";
import MatchingCards from "./matchingCards";
import HostFamilyList from "./AupairMatches";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import { getPayments } from "./../../../../../../../../Redux/action/payment";
import { Loader } from "./../../../../../../../../utils/loader";

const AupairMacthes = ({ getPayments, payments }) => {
  const [hasSubscription, setHasSubscription] = useState(false);
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasSubscribed, setHasSubscribed] = useState(false);
  const [hasCanceled, setHasCanceled] = useState(false);
  const [paidUser, setPaidUser] = useState(null);
  const { user } = useSelector((state) => state.getUser);

  useEffect(() => {
    const fetchAllPayments = () => {
      getPayments();
    };
    fetchAllPayments();
  }, [getPayments]);

  useEffect(() => {
    const paymentChecker = () => {
      setPaidUser(
        payments?.message?.payments?.find(
          (payment) =>
            payment?.user?.uuid === user?.message?.data?.user?.uuid &&
            payment.subStatus === "active"
        )
      );
    };

    if (hasSubscribed || hasCanceled) {
      paymentChecker();
    } else {
      paymentChecker();
    }

    if (paidUser) {
      setHasSubscription(true);
      setTransaction(paidUser);
    } else if (hasCanceled) {
      setHasSubscription(false);
      setTransaction(null);
    } else {
      setHasSubscription(false);
      setTransaction(null);
    }
  }, [
    payments?.message?.payments,
    user?.message?.data?.user?.uuid,
    hasSubscribed,
    hasCanceled,
    paidUser,
  ]);

  useEffect(() => {
    if (hasSubscribed || hasCanceled) {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, [hasSubscribed, hasCanceled]);

  return (
    <Fragment>
      <>
        {loading ? (
          <Loader />
        ) : (
          <Fragment>
            {!hasSubscription ? (
              <MatchingCards setHasSubscribed={setHasSubscribed} />
            ) : (
              <HostFamilyList
                transaction={transaction}
                setHasCanceled={setHasCanceled}
                hasCanceled={hasCanceled}
              />
            )}
          </Fragment>
        )}
      </>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    payments: state.payments.payments,
  };
};

export default connect(mapStateToProps, { getPayments })(AupairMacthes);
