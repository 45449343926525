import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { styles } from "./styles";
import Divider from "@material-ui/core/Divider";
import MainListItems from "./SideBar";
import List from "@material-ui/core/List";
import Avatar from "@material-ui/core/Avatar";
import { useNavigate } from "react-router-dom";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Marquee from "react-fast-marquee";
import Profile from "../../Profile/HostFamiliy/account/profile";
import { connect } from "react-redux";
import { getUser } from "./../../../Redux/action/staffMembers";

const useStyles = makeStyles(styles);

const DashboardLayout = ({
  children,
  profilePicture,
  getUser,
  logedinUser,
}) => {
  const classes = useStyles();
  const naviagte = useNavigate();
  const [isOpened, setIsOpened] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("profile"))?.data?.user
  );

  useEffect(() => {
    if (user === null || user === undefined || user?.role !== "hostfamily") {
      naviagte("/");
    }
  }, [user, naviagte]);

  const handleLogout = () => {
    setUser(null);
    localStorage.setItem("profile", null);
    naviagte("/");
  };

  const handleToggle = () => {
    setOpenSettings((prev) => !prev);
  };

  useEffect(() => {
    const fetchUser = () => {
      getUser(user?.uuid);
    };
    if (user?.uuid || profilePicture?.success) {
      fetchUser();
    } else {
      fetchUser();
    }
  }, [user?.uuid, getUser, profilePicture?.success]);

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            onClick={() => setIsOpened(!isOpened)}
            className={classes.icon}
          >
            {isOpened ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
          <Marquee gradient={false}>
            {logedinUser?.message?.data?.user?.firstName}
          </Marquee>

          <Badge color="secondary" badgeContent={0} showZero>
            <NotificationsIcon className={classes.appBarIcon} />
          </Badge>
          {logedinUser?.message?.data?.user?.profilePicture ? (
            <>
              <div className={classes.settings}>
                <Avatar
                  className={classes.profilePic}
                  alt="profile"
                  src={logedinUser?.message?.data?.user?.profilePicture}
                  sx={{ width: 40, height: 40, bgcolor: "#012241" }}
                  onClick={handleToggle}
                />
                <IconButton size="small">
                  {!openSettings ? (
                    <ArrowDropDownIcon
                      style={{ color: "#FFA500" }}
                      onClick={handleToggle}
                    />
                  ) : (
                    <ArrowDropUpIcon
                      style={{ color: "#FFA500" }}
                      onClick={handleToggle}
                    />
                  )}
                </IconButton>
                {openSettings && (
                  <div className={classes.dropDown}>
                    {/*<Settings />*/}
                    <Profile />
                    <button
                      onClick={handleLogout}
                      className={classes.logoutBtn}
                    >
                      Logout
                    </button>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className={classes.settings}>
                <Avatar
                  className={classes.profilePic}
                  alt="profile"
                  sx={{
                    width: 50,
                    height: 50,
                    bgcolor: "#012241",
                    fontSize: "20px",
                  }}
                  onClick={handleToggle}
                >
                  {logedinUser?.message?.data?.user?.firstName
                    .charAt(0)
                    .toUpperCase()}
                </Avatar>
                <IconButton size="small">
                  {!openSettings ? (
                    <ArrowDropDownIcon
                      style={{ color: "#FFA500" }}
                      onClick={handleToggle}
                    />
                  ) : (
                    <ArrowDropUpIcon
                      style={{ color: "#FFA500" }}
                      onClick={handleToggle}
                    />
                  )}
                </IconButton>
                {openSettings && (
                  <div className={classes.dropDown}>
                    {/* <Settings />*/}
                    <Profile />
                    <button
                      onClick={handleLogout}
                      className={classes.logoutBtn}
                    >
                      Logout
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
        </Toolbar>
      </AppBar>
      <div className={classes.container}>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawer, {
              [classes.closed]: !isOpened,
              [classes.opened]: isOpened,
            }),
          }}
        >
          <Divider />
          <List>
            <MainListItems />
          </List>
        </Drawer>
        <main className={classes.main}>{children}</main>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profilePicture: state.profilePicture.picture,
    logedinUser: state.getUser.user,
  };
};

export default connect(mapStateToProps, { getUser })(DashboardLayout);
