import React, { useState } from "react";
import "./HowToProceed.css";
import Grid from "@material-ui/core/Grid";
import Eligibility from "./../../../../assets/candidate.png";
import CreateProfile from "./../../../../assets/createprofile.png";
import Suucess from "./../../../../assets/success.png";
import Zoom from "react-reveal/Zoom";
import { Modal } from "antd";
import Auth from "./../../../Account/Authentication";

const HowToProceed = () => {
  const [visible, setVisible] = useState(false);
  const [isSignup, setIsSignUp] = useState(true);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };
  return (
    <React.Fragment>
      <Modal
        title="LOGIN/SIGNUP"
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        width={700}
      >
        <Auth isSignup={isSignup} setIsSignUp={setIsSignUp} />
      </Modal>
      <div className="aupair-how-to-proceed">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} xlg={12}>
            <Zoom>
              <div className="aupair-how-to-proceed-heading">
                <h1> How to proceed</h1>
              </div>
            </Zoom>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xlg={4}>
            <Zoom>
              <div className="procceed__item__one">
                <div className="proceed__item__one__img">
                  <img src={Eligibility} alt="ability" />
                </div>
                <div className="proceed__item__one__title">
                  <strong>Check your elegibitily</strong>
                </div>
                <div className="proceed__item__one__contents">
                  <p>
                    Das Aupair Matching assesses your elegibilty to the aupair
                    experience as a candidate or a host family for free of
                    charge using our digital automated assessment instrument.
                    Please click here to check your eligibility.
                    <button
                      onClick={showModal}
                      className="how_to_procceed__btn"
                    >
                      Check your eligibility now &rarr;
                    </button>
                  </p>
                </div>
              </div>
            </Zoom>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xlg={4}>
            <Zoom>
              <div className="proceed__item__two">
                <div className="proceed__item__two__img">
                  <img src={CreateProfile} alt="ability" />
                </div>
                <div className="proceed__item__two__title">
                  <strong>Create your Profile</strong>
                </div>
                <div className="proceed__item__two__contents">
                  <p>
                    After the assessement has successed, proceed to the creation
                    of your profile so that you can be matched to the
                    potentional candidate. Activiate your profile and stay
                    intouch for further queries and requests.
                    <button
                      onClick={showModal}
                      className="how_to_procceed__btn"
                    >
                      Create your profile now &rarr;
                    </button>
                  </p>
                </div>
              </div>
            </Zoom>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xlg={4}>
            <Zoom>
              <div className="proceed__item__three">
                <div className="proceed__item__three__img">
                  <img src={Suucess} alt="ability" />
                </div>
                <div className="proceed__item__three__title">
                  <strong> Matching Succeed</strong>
                </div>
                <div className="proceed__item__three__contents">
                  <p>
                    In case your matching successed the next step will be the
                    preparation to get the contract and other documents to
                    proccess the application of the visa and other procedure
                    regarding the travel and arrival.
                    <button
                      onClick={showModal}
                      className="how_to_procceed__btn"
                    >
                      Aupair visa check list &rarr;
                    </button>
                  </p>
                </div>
              </div>
            </Zoom>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};
export default HowToProceed;
