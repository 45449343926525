import React, { useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { useStyles } from "./style";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./spiner.css";
import {
  registerUser as createUser,
  login as LoginInUser,
} from "../../Redux/action/Auth";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const Role = [
  {
    value: "---select---",
    label: "---select---",
  },
  {
    value: "aupair",
    label: "aupair",
  },
  {
    value: "hostfamily",
    label: "hostfamily",
  },
];

const Auth = ({
  Login,
  Signup,
  signup,
  setIsLogin,
  setIsSignUp,
  createUser,
  LoginInUser,
  signin,
  setVisible,
  setUser,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const [formData, setFormData] = React.useState({
    fullName: "",
    phone: "",
    email: "",
    role: "",
    password: "",
    confimPassword: "",
  });

  const handleClose = () => {
    setOpen(false);
  };

  const switchAuth = () => {
    if (Login) {
      setIsLogin(false);
      setIsSignUp(true);
    } else if (Signup) {
      setIsSignUp(false);
      setIsLogin(true);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // setSpiner(true)
    if (Login) {
      await LoginInUser(formData, navigate);
      setVisible(false);
      setUser(JSON.parse(localStorage.getItem("profile"))?.data);
    } else {
      await createUser(formData, navigate);
      setVisible(false);
    }
    clear();
  };

  const clear = () => {
    setFormData({
      fullName: "",
      phone: "",
      email: "",
      role: "",
      password: "",
      confimPassword: "",
    });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <div>
        <Backdrop
          style={{ zIndex: 1000, color: "#fff" }}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {Signup && (
                <>
                  <Grid item xs={12}>
                    <label>
                      Full Name <span className="required">*</span>
                    </label>
                    <input
                      style={{ width: "100%" }}
                      required
                      id="fullName"
                      name="fullname"
                      placeholder="Full Name"
                      value={formData.fullName}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          fullName: event.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <label>
                      Phone <span className="required">*</span>
                    </label>
                    <input
                      style={{ width: "100%" }}
                      required
                      id="phone"
                      name="phone"
                      placeholder="Phone"
                      value={formData.phone}
                      onChange={(event) =>
                        setFormData({ ...formData, phone: event.target.value })
                      }
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <label>
                  Email <span className="required">*</span>
                </label>
                <input
                  style={{ width: "100%" }}
                  required
                  id="email"
                  name="email"
                  placeholder="Email Address"
                  value={formData.email}
                  onChange={(event) =>
                    setFormData({ ...formData, email: event.target.value })
                  }
                />
              </Grid>
              {Signup && (
                <Grid item xs={12}>
                  <label>
                    Role <span className="required">*</span>
                  </label>
                  <select
                    style={{ width: "100%" }}
                    id="role"
                    value={formData.role}
                    onChange={(event) =>
                      setFormData({ ...formData, role: event.target.value })
                    }
                  >
                    {Role.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </Grid>
              )}
              <Grid item xs={12}>
                <label>
                  Password <span className="required">*</span>
                </label>
                <input
                  style={{ width: "100%" }}
                  required
                  name="password"
                  type="password"
                  id="password"
                  placeholder="password"
                  value={formData.password}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      password: event.target.value,
                    })
                  }
                />
              </Grid>
              {Signup && (
                <Grid item xs={12}>
                  <label>
                    Confirm Password<span className="required">*</span>
                  </label>
                  <input
                    style={{ width: "100%" }}
                    required
                    name="confimPassword"
                    type="password"
                    id="password"
                    placeholder="Confirm Password"
                    value={formData.confimPassword}
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        confimPassword: event.target.value,
                      })
                    }
                  />
                </Grid>
              )}
            </Grid>
            <button type="submit" className={classes.submit}>
              {Login ? "Login" : "Sign up"}
            </button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="#" variant="body2" onClick={switchAuth}>
                  {Login
                    ? "Don't have an Account? Sign up"
                    : "Already have an account? Sign in"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = ({ signupReducer, SignInReducer }) => {
  const { signup } = signupReducer;
  const { signin } = SignInReducer;
  return { signup, signin };
};
export default connect(mapStateToProps, { createUser, LoginInUser })(Auth);
