import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { NavLink } from "react-router-dom";
import DashboardIcon from "@material-ui/icons/Dashboard";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import GroupIcon from "@material-ui/icons/Group";
import ForumIcon from "@material-ui/icons/Forum";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { styles } from "./styles";
import Tooltip from "@material-ui/core/Tooltip";
import SubjectIcon from "@material-ui/icons/Subject";
import { NotificationOutlined } from "@ant-design/icons";
import Collapse from "@material-ui/core/Collapse";
import CategoryIcon from "@material-ui/icons/Category";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AddBoxIcon from "@material-ui/icons/AddBox";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import { useDispatch, useSelector } from "react-redux";
import { getLayoutTabIndex } from "./../../Redux/action/tabs";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

const useStyles = makeStyles(styles);

const MainListItems = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [expandBlog, setExpandBlog] = useState(false);

  const { index } = useSelector((state) => state.layoutTabs.tab);

  const handleExpandBlogClick = (indexNumber) => {
    setExpandBlog(!expandBlog);
    dispatch(getLayoutTabIndex(indexNumber));
  };

  const handleTabs = (indexNumber) => {
    dispatch(getLayoutTabIndex(indexNumber));
  };

  return (
    <div className={classes.sideBar}>
      <Tooltip title="DASHBOARD" arrow>
        <NavLink
          to="/dashboard"
          className={classes.sideBarLink}
          onClick={() => handleTabs(1)}
        >
          <ListItem
            button
            className={
              index === 1
                ? `${classes.sideBarLink} ${classes.activeLink}`
                : `${classes.sideBarLink}`
            }
          >
            <ListItemIcon
              className={
                index === 1 ? `${classes.activeIcon}` : `${classes.sideBarIcon}`
              }
            >
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="DASHBOARD" />
          </ListItem>
        </NavLink>
      </Tooltip>
      <Tooltip title="AUPAIRS" arrow>
        <NavLink
          to="/dashboard/aupairs"
          className={classes.sideBarLink}
          onClick={() => handleTabs(2)}
        >
          <ListItem
            button
            className={
              index === 2
                ? `${classes.sideBarLink} ${classes.activeLink}`
                : `${classes.sideBarLink}`
            }
          >
            <ListItemIcon
              className={
                index === 2 ? `${classes.activeIcon}` : `${classes.sideBarIcon}`
              }
            >
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary="AUPAIRS" />
          </ListItem>
        </NavLink>
      </Tooltip>
      <Tooltip title="HOST FAMILIES" arrow>
        <NavLink
          to="/dashboard/hosts"
          className={classes.sideBarLink}
          onClick={() => handleTabs(3)}
        >
          <ListItem
            button
            className={
              index === 3
                ? `${classes.sideBarLink} ${classes.activeLink}`
                : `${classes.sideBarLink}`
            }
          >
            <ListItemIcon
              className={
                index === 3 ? `${classes.activeIcon}` : `${classes.sideBarIcon}`
              }
            >
              <HomeWorkIcon />
            </ListItemIcon>
            <ListItemText primary="HOSTS" />
          </ListItem>
        </NavLink>
      </Tooltip>
      <Tooltip title="MATCH LIST" arrow>
        <NavLink
          to="/dashboard/match"
          className={classes.sideBarLink}
          onClick={() => handleTabs(4)}
        >
          <ListItem
            button
            className={
              index === 4
                ? `${classes.sideBarLink} ${classes.activeLink}`
                : `${classes.sideBarLink}`
            }
          >
            <ListItemIcon
              className={
                index === 4 ? `${classes.activeIcon}` : `${classes.sideBarIcon}`
              }
            >
              <GroupWorkIcon />
            </ListItemIcon>
            <ListItemText primary="MATCHES" />
          </ListItem>
        </NavLink>
      </Tooltip>
      <Tooltip title="ROLES" arrow>
        <NavLink
          to="/dashboard/roles"
          className={classes.sideBarLink}
          onClick={() => handleTabs(5)}
        >
          <ListItem
            button
            className={
              index === 5
                ? `${classes.sideBarLink} ${classes.activeLink}`
                : `${classes.sideBarLink}`
            }
          >
            <ListItemIcon
              className={
                index === 5 ? `${classes.activeIcon}` : `${classes.sideBarIcon}`
              }
            >
              <CheckCircleOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="ROLES" />
          </ListItem>
        </NavLink>
      </Tooltip>
      <Tooltip title="MENTORS" arrow>
        <NavLink
          to="/dashboard/mentors"
          className={classes.sideBarLink}
          onClick={() => handleTabs(6)}
        >
          <ListItem
            button
            className={
              index === 6
                ? `${classes.sideBarLink} ${classes.activeLink}`
                : `${classes.sideBarLink}`
            }
          >
            <ListItemIcon
              className={
                index === 6 ? `${classes.activeIcon}` : `${classes.sideBarIcon}`
              }
            >
              <AttachMoneyIcon />
            </ListItemIcon>
            <ListItemText primary="MENTORS" />
          </ListItem>
        </NavLink>
      </Tooltip>
      <Tooltip title="USERS" arrow>
        <NavLink
          to="/dashboard/users"
          className={classes.sideBarLink}
          onClick={() => handleTabs(7)}
        >
          <ListItem
            button
            className={
              index === 7
                ? `${classes.sideBarLink} ${classes.activeLink}`
                : `${classes.sideBarLink}`
            }
          >
            <ListItemIcon
              className={
                index === 7 ? `${classes.activeIcon}` : `${classes.sideBarIcon}`
              }
            >
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary="USERS" />
          </ListItem>
        </NavLink>
      </Tooltip>
      <Tooltip title="Messages" arrow>
        <NavLink
          to="/dashboard/messages"
          onClick={() => handleTabs(8)}
          className={classes.sideBarLink}
        >
          <ListItem
            button
            className={
              index === 8
                ? `${classes.sideBarLink} ${classes.activeLink}`
                : `${classes.sideBarLink}`
            }
          >
            <ListItemIcon
              className={
                index === 8 ? `${classes.activeIcon}` : `${classes.sideBarIcon}`
              }
            >
              <ForumIcon />
            </ListItemIcon>
            <ListItemText primary="MESSAGES" />
          </ListItem>
        </NavLink>
      </Tooltip>
      <Tooltip title="Blogs" arrow>
        <ListItem
          button
          onClick={() => handleExpandBlogClick(9)}
          className={
            index === 9
              ? `${classes.sideBarLink} ${classes.activeLink}`
              : `${classes.sideBarLink}`
          }
        >
          <ListItemIcon
            className={
              index === 9 ? `${classes.activeIcon}` : `${classes.sideBarIcon}`
            }
          >
            <SubjectIcon />
          </ListItemIcon>
          <ListItemText primary="BLOGS" />
          {expandBlog ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </Tooltip>
      <Collapse in={expandBlog} timeout="auto" unmountOnExit>
        <NavLink
          to="/dashboard/blog/category"
          className={classes.sideBarLink}
          onClick={() => handleTabs(10)}
        >
          <ListItem
            button
            className={
              index === 10
                ? `${classes.sideBarLink} ${classes.activeLink}`
                : `${classes.sideBarLink}`
            }
          >
            <ListItemIcon
              className={
                index === 10
                  ? `${classes.activeIcon}`
                  : `${classes.sideBarIcon}`
              }
            >
              <CategoryIcon />
            </ListItemIcon>
            <ListItemText primary="CATEGORY" />
          </ListItem>
        </NavLink>
        <NavLink
          to="/dashboard/blogs"
          className={classes.sideBarLink}
          onClick={() => handleTabs(11)}
        >
          <ListItem
            button
            className={
              index === 11
                ? `${classes.sideBarLink} ${classes.activeLink}`
                : `${classes.sideBarLink}`
            }
          >
            <ListItemIcon
              className={
                index === 11
                  ? `${classes.activeIcon}`
                  : `${classes.sideBarIcon}`
              }
            >
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="ADD BLOG" />
          </ListItem>
        </NavLink>
      </Collapse>

      <Tooltip title="announcements" arrow>
        <NavLink
          to="/dashboard/announcements"
          className={classes.sideBarLink}
          onClick={() => handleTabs(12)}
        >
          <ListItem
            button
            className={
              index === 12
                ? `${classes.sideBarLink} ${classes.activeLink}`
                : `${classes.sideBarLink}`
            }
          >
            <ListItemIcon
              className={
                index === 12
                  ? `${classes.activeIcon}`
                  : `${classes.sideBarIcon}`
              }
            >
              <NotificationOutlined />
            </ListItemIcon>
            <ListItemText primary="PUBLISHING" />
          </ListItem>
        </NavLink>
      </Tooltip>
      <Tooltip title="Testmony" arrow>
        <NavLink
          to="/dashboard/testmony"
          className={classes.sideBarLink}
          onClick={() => handleTabs(13)}
        >
          <ListItem
            button
            className={
              index === 13
                ? `${classes.sideBarLink} ${classes.activeLink}`
                : `${classes.sideBarLink}`
            }
          >
            <ListItemIcon
              className={
                index === 13
                  ? `${classes.activeIcon}`
                  : `${classes.sideBarIcon}`
              }
            >
              <FormatQuoteIcon />
            </ListItemIcon>
            <ListItemText primary="TESTMONY" />
          </ListItem>
        </NavLink>
      </Tooltip>
      <Tooltip title="Subscribers" arrow>
        <NavLink
          to="/dashboard/subscribers"
          className={classes.sideBarLink}
          onClick={() => handleTabs(14)}
        >
          <ListItem
            button
            className={
              index === 14
                ? `${classes.sideBarLink} ${classes.activeLink}`
                : `${classes.sideBarLink}`
            }
          >
            <ListItemIcon
              className={
                index === 14
                  ? `${classes.activeIcon}`
                  : `${classes.sideBarIcon}`
              }
            >
              <Diversity1Icon />
            </ListItemIcon>
            <ListItemText primary="SUBSCRIBERS" />
          </ListItem>
        </NavLink>
      </Tooltip>
      <Tooltip title="Payments" arrow>
        <NavLink
          to="/dashboard/payments"
          className={classes.sideBarLink}
          onClick={() => handleTabs(15)}
        >
          <ListItem
            button
            className={
              index === 15
                ? `${classes.sideBarLink} ${classes.activeLink}`
                : `${classes.sideBarLink}`
            }
          >
            <ListItemIcon
              className={
                index === 15
                  ? `${classes.activeIcon}`
                  : `${classes.sideBarIcon}`
              }
            >
              <AccountBalanceWalletIcon />
            </ListItemIcon>
            <ListItemText primary="PAYMENTS" />
          </ListItem>
        </NavLink>
      </Tooltip>
    </div>
  );
};

export default MainListItems;
