import React, { useCallback } from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useStyles } from "./style";
import "./HostFamilyHome.css";
import { hostWishes } from "../../../Redux/action/hostFroms";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Navigation from "./../../Page/Landing/Navigation/Navigation";
import Contact from "./../../Page/Landing/Contacts/Contacts";
import HostHeader from "./HostFamilyHome";
import Footer from "../../Footer/NewFooter";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useDropzone } from "react-dropzone";
import { Image, Transformation } from "cloudinary-react";

const travalExpense = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];

const medical = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];
const transportTicket = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];
const car = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];

const provideBikes = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];
const privateRooms = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];
const separateToilet = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];
const HostWishesForm = ({
  formData,
  setFormData,
  navigation,
  setHostUploads,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [uploadedFiles, setUploadedFiles] = React.useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const {
    travelExpenceFees,
    medicalInsuranceFees,
    pocketMoney,
    languageCourseFees,
    localTranspTicket,
    provideCar,
    provideBike,
    privateRoom,
    privateBathroom,
    // letter,
  } = formData;

  const Next = (event) => {
    event.preventDefault();
    setOpen(!open);
    setTimeout(() => {
      setOpen(false);
      navigation.next();
    }, 1000);
  };

  const Previous = (event) => {
    event.preventDefault();
    setOpen(!open);
    setTimeout(() => {
      setOpen(false);
      navigation.previous();
    }, 1000);
  };

  const onDrop = useCallback((acceptedFiles) => {
    const url = "https://api.cloudinary.com/v1_1/dsb0gu6km/image/upload";

    acceptedFiles.forEach(async (acceptedFile) => {
      const formData = new FormData();
      formData.append("file", acceptedFile);
      formData.append("upload_preset", "cvo1ylzx");
      const { data } = await axios({
        url,
        method: "POST",
        data: formData,
      });

      setUploadedFiles((old) => [...old, data]);
    });
  }, []);

  React.useEffect(() => {
    if (uploadedFiles.length === 5) {
      setHostUploads([...uploadedFiles]);
    }
  }, [uploadedFiles, setHostUploads]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accepts: "image/*",
    multiple: false,
  });

  return (
    <React.Fragment>
      <Contact />
      <Navigation />
      <HostHeader />
      <CssBaseline />
      <div>
        <Backdrop
          style={{ zIndex: 1000, color: "#fff" }}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Container maxWidth="md" className="form_container">
        <form className={classes.root} noValidate autoComplete="off">
          <div className="personalInformationGroup">
            <h1 className="InnerTitles">Your wished offer</h1>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Travel Expence Fees
                    <span className="required">*</span>
                  </label>
                  <select
                    name="travelExpenceFees"
                    value={travelExpenceFees}
                    onChange={setFormData}
                  >
                    {travalExpense.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Medical Insurance Fees.
                    <span className="required">*</span>
                  </label>
                  <select
                    name="medicalInsuranceFees"
                    value={medicalInsuranceFees}
                    onChange={setFormData}
                  >
                    {medical.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Pocket Money
                    <span className="required">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="pocketMoney"
                    placeholder="Ex: 1000-1500 $"
                    defaultValue={pocketMoney}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Language Course Fees
                    <span className="required">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    placeholder="Ex: 1000-1500 $"
                    name="languageCourseFees"
                    value={languageCourseFees}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Local Transport Ticket
                    <span className="required">*</span>
                  </label>

                  <select
                    name="localTranspTicket"
                    value={localTranspTicket}
                    onChange={setFormData}
                  >
                    {transportTicket.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>

              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    They will provide the car.
                    <span className="required">*</span>
                  </label>

                  <select
                    name="provideCar"
                    value={provideCar}
                    onChange={setFormData}
                  >
                    {car.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    They will provide a bike
                    <span className="required">*</span>
                  </label>

                  <select
                    name="provideBike"
                    value={provideBike}
                    onChange={setFormData}
                  >
                    {provideBikes.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Provide a private room
                    <span className="required">*</span>
                  </label>

                  <select
                    name="privateRoom"
                    value={privateRoom}
                    onChange={setFormData}
                  >
                    {privateRooms.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>

              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Provide Private Bathroom.
                    <span className="required">*</span>
                  </label>

                  <select
                    name="privateBathroom"
                    value={privateBathroom}
                    onChange={setFormData}
                  >
                    {separateToilet.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            </div>
          </div>

          <div className="personalInformationGroup">
            <h3 className="InnerTitles">
              The letter and Photo to the Host Family
            </h3>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label htmlFor="letter">
                    Letter
                    <span className="required">*</span>
                  </label>
                  <input
                    id="letter"
                    required
                    name="letter"
                    type="file"
                    onChange={setFormData}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label htmlFor="imageOne">
                    Upload First Image <span className="required">*</span>
                  </label>
                  <div {...getRootProps()} className={classes.dropZone}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the files here ...</p>
                    ) : (
                      <>
                        Drag &nbsp;
                        <CloudUploadIcon />
                        &nbsp;or Click
                      </>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label htmlFor="imageTwo">
                    Upload second Image <span className="required">*</span>
                  </label>
                  <div {...getRootProps()} className={classes.dropZone}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the files here ...</p>
                    ) : (
                      <>
                        Drag &nbsp;
                        <CloudUploadIcon />
                        &nbsp;or Click
                      </>
                    )}
                  </div>
                </div>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label htmlFor="imageThree">
                    Upload Third Image<span className="required">*</span>
                  </label>
                  <div {...getRootProps()} className={classes.dropZone}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the files here ...</p>
                    ) : (
                      <>
                        Drag &nbsp;
                        <CloudUploadIcon />
                        &nbsp;or Click
                      </>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label htmlFor="imageFour">
                    Upload Fourth Image <span className="required">*</span>
                  </label>
                  <div {...getRootProps()} className={classes.dropZone}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the files here ...</p>
                    ) : (
                      <>
                        Drag &nbsp;
                        <CloudUploadIcon />
                        &nbsp;or Click
                      </>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label htmlFor="imageFive">
                    Upload Fith Image <span className="required">*</span>
                  </label>
                  <div {...getRootProps()} className={classes.dropZone}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the files here ...</p>
                    ) : (
                      <>
                        Drag &nbsp;
                        <CloudUploadIcon />
                        &nbsp;or Click
                      </>
                    )}
                  </div>
                </div>
              </Grid>
              <div className="uploadedImages">
                {uploadedFiles?.map((file) => (
                  <div key={file.public_id}>
                    <Image cloudName="dsb0gu6km" publicId={file.public_id}>
                      <Transformation crop="scale" width="200" />
                    </Image>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <button className="Back" onClick={Previous}>
            <ArrowBackIcon />
            &nbsp;Back
          </button>
          <button className="Continue" onClick={Next}>
            Next &nbsp;
            <ArrowForwardIcon />
          </button>
        </form>
      </Container>
      <Footer />
    </React.Fragment>
  );
};
const mapStateToProps = ({ hostWishesReducer }) => {
  const { wish } = hostWishesReducer;
  return { wish };
};
export default connect(mapStateToProps, { hostWishes })(HostWishesForm);
