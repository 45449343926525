import React from "react";
import "./Header.css";
import Slides from "./../../../Slider/Slider";

const Header = () => {
  return (
    <header className="landing__header">
      <div className="landing__header__overlay"></div>
      <video
        src="https://res.cloudinary.com/dsb0gu6km/video/upload/v1671344591/aupair%20assets/german_rhein_river_lzlg5p.mp4"
        autoPlay
        loop
        muted
        type="video.mp4"
      />
      <div className="landing__header__content">
        <Slides />
      </div>
    </header>
  );
};
export default Header;
