import React,{useEffect} from "react";
import Expectations from "./../../Components/Page/Host/Expectations/Expectations";
import ExpectationsParTwo from "../../Components/Page/Host/Expectations/ExpectationPartTwo";
import NotExpectations from "./../../Components/Page/Host/NotExpectations/NotExpecations";
import ServicesPage from "../../Components/Page/Host/Host";
import ServicesFont from "../../Components/Page/Host/HostFront/HostFront";
import Footer from "../../Components/Footer/NewFooter";
import Contact from "../../Components/ResponsiveNavBar/Contacts/Contacts";
import Navigation from "../../Components/myNavbar/navbar";

const Home = () => {

  useEffect(()=>{
    if(typeof window !== "undefined"){
      window.scrollTo(0,0)
    }
  },[])

  return (
    <React.Fragment>
      <Contact />
      <Navigation />
      <ServicesFont />
      <ServicesPage />
      <Expectations />
      <ExpectationsParTwo />
      <NotExpectations />
      <Footer />
    </React.Fragment>
  );
};

export default Home;
