import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useStyles } from "./style";
import Grid from "@material-ui/core/Grid";
import "./Aupair.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  aupairPreferences,
  updateAupairPreferences,
} from "./../../../../../../../Redux/action/aupairForms";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const initialValues = {
  hostFamilyFrom: "",
  experiencedFamily: "",
  familyLivingModel: "",
  familyWithPetsAnimals: "",
  smokingHostFamily: "",
  specialNeeds: "",
  childrenFrom: "",
  toChildrenNumber: "",
  agedFrom: "",
  agedTo: "",
};

const FamilyPreference = ({ preferencesData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [createMode, setCreateMode] = useState(true);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [message, setMessage] = useState("");

  const { preferences } = useSelector((state) => state.aupairPreferences);

  const { preferences: updatedPreferences } = useSelector(
    (state) => state.updateAupairPreferences
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));
    if (Object.keys(formErrors).length === 0) {
      if (createMode) {
        setOpen(true);
        dispatch(aupairPreferences(formValues));
        clear();
      } else {
        setOpen(true);
        dispatch(updateAupairPreferences(formValues, preferencesData.uuid));
      }
    }
  };

  const clear = () => {
    setFormValues(initialValues);
  };

  useEffect(() => {
    if (preferences?.success) {
      setOpen(false);
      setMessage(preferences?.message?.message);
    } else if (!preferences.success && preferences.error !== null) {
      setOpen(false);
      setMessage("There was an error,try again");
    }
  }, [preferences.success, preferences?.message?.message, preferences.error]);

  useEffect(() => {
    if (updatedPreferences.success) {
      setOpen(false);
      setMessage(updatedPreferences?.message?.message);
    } else if (
      !updatedPreferences.success &&
      updatedPreferences.error !== null
    ) {
      setOpen(false);
      setMessage("There was an error,try again");
    }
  }, [
    updatedPreferences.success,
    updatedPreferences.error,
    updatedPreferences?.message?.message,
  ]);

  useEffect(() => {
    const notify = () => {
      if (preferences?.error && message) {
        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (preferences?.success && message) {
        toast.success(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    if (preferences?.success && message) {
      notify();
    } else if (
      !preferences?.success &&
      preferences?.error !== null &&
      message
    ) {
      notify();
    } else {
      return;
    }
  }, [preferences?.success, preferences?.error, message]);

  useEffect(() => {
    const notify = () => {
      if (updatedPreferences?.error && message) {
        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (updatedPreferences?.success && message) {
        toast.success(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    if (updatedPreferences?.success && message) {
      notify();
    } else if (
      !updatedPreferences?.success &&
      updatedPreferences?.error !== null &&
      message
    ) {
      notify();
    } else {
      return;
    }
  }, [updatedPreferences?.success, updatedPreferences?.error, message]);

  const validate = (values) => {
    const errors = {};

    if (!values.hostFamilyFrom) {
      errors.hostFamilyFrom = "This field is required!";
    }
    if (!values.experiencedFamily) {
      errors.experiencedFamily = "This field is required!";
    }
    if (!values.familyLivingModel) {
      errors.familyLivingModel = "This field is required!";
    }
    if (!values.familyWithPetsAnimals) {
      errors.familyWithPetsAnimals = "This field is required!";
    }
    if (!values.smokingHostFamily) {
      errors.smokingHostFamily = "This field is required!";
    }
    if (!values.specialNeeds) {
      errors.specialNeeds = "This field is required!";
    }
    if (!values.childrenFrom) {
      errors.childrenFrom = "This field is required!";
    }

    if (!values.toChildrenNumber) {
      errors.toChildrenNumber = "This field is required!";
    }
    if (!values.agedFrom) {
      errors.agedFrom = "This field is required!";
    }
    if (!values.agedTo) {
      errors.agedTo = "This field is required!";
    }

    return errors;
  };

  useEffect(() => {
    if (preferencesData) {
      setFormValues({
        hostFamilyFrom: preferencesData?.hostFamilyFrom,
        experiencedFamily: preferencesData?.experiencedFamily,
        familyLivingModel: preferencesData?.familyLivingModel,
        familyWithPetsAnimals: preferencesData?.familyWithPetsAnimals,
        smokingHostFamily: preferencesData?.smokingHostFamily,
        specialNeeds: preferencesData?.specialNeeds,
        childrenFrom: preferencesData?.childrenFrom,
        toChildrenNumber: preferencesData?.toChildrenNumber,
        agedFrom: preferencesData?.agedFrom,
        agedTo: preferencesData?.agedTo,
      });
      setCreateMode(false);
    } else if (!preferencesData) {
      setCreateMode(true);
      setFormValues(initialValues);
    }
  }, [preferencesData]);

  useEffect(() => {
    if (
      formValues.hostFamilyFrom === "" ||
      formValues.experiencedFamily === "" ||
      formValues.familyLivingModel === "" ||
      formValues.smokingHostFamily === "" ||
      formValues.familyWithPetsAnimals === "" ||
      formValues.specialNeeds === "" ||
      formValues.childrenFrom === "" ||
      formValues.toChildrenNumber === "" ||
      formValues.agedFrom === "" ||
      formValues.agedTo === ""
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formValues]);

  return (
    <React.Fragment>
      <CssBaseline />
      <div>
        <Backdrop style={{ zIndex: 1000, color: "#fff" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Container maxWidth="md" className="form_container">
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="personalInformationGroup">
            <h3 className="InnerTitles">MY FAMILY PREFERENCE</h3>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    I'm searching a host Family from
                    <span className="required">*</span>
                  </label>
                  <select
                    name="hostFamilyFrom"
                    value={formValues.hostFamilyFrom}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Germany">Germany</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Austria">Austria</option>
                    <option value="France">France</option>
                    <option value="Luxamburg">Luxamburg</option>
                    <option value="Netherland">Netherland</option>
                    <option value="Danmark">Danmark</option>
                    <option value="No preference">No preference</option>
                  </select>
                </div>
                <small className="formError">{formErrors.hostFamilyFrom}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Family experienced with Aupair.
                    <span className="required">*</span>
                  </label>
                  <select
                    name="experiencedFamily"
                    value={formValues.experiencedFamily}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Not Necessary">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">
                  {formErrors.experiencedFamily}
                </small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Host Family living model.
                    <span className="required">*</span>
                  </label>
                  <select
                    name="familyLivingModel"
                    value={formValues.familyLivingModel}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Classic Couple">Classic Couple</option>
                    <option value="A Single Mother">A Single Mother</option>
                    <option value="Single Father">Single Father</option>
                    <option value="Divers Family">Divers Family</option>
                    <option value="Family constellation">
                      Family constellation
                    </option>
                  </select>
                </div>
                <small className="formError">
                  {formErrors.familyLivingModel}
                </small>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Host Family With Pets Animals
                    <span className="required">*</span>
                  </label>

                  <select
                    name="familyWithPetsAnimals"
                    value={formValues.familyWithPetsAnimals}
                    onChange={handleChange}
                  >
                    <option value="">--select---</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Maybe">Maybe</option>
                  </select>
                </div>
                <small className="formError">
                  {formErrors.familyWithPetsAnimals}
                </small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Smoking Host family <span className="required">*</span>
                  </label>
                  <select
                    name="smokingHostFamily"
                    value={formValues.smokingHostFamily}
                    onChange={handleChange}
                  >
                    <option value="">---select---</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Yes Not At Home">Yes Not At Home</option>
                  </select>
                </div>
                <small className="formError">
                  {formErrors.smokingHostFamily}
                </small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Host family with children with special needs
                    <span className="required">*</span>
                  </label>
                  <select
                    name="specialNeeds"
                    value={formValues.specialNeeds}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Catholic">Catholic</option>
                    <option value="Evangelic">Evangelic</option>
                    <option value="Muslim">Muslim</option>
                    <option value="Other christian">Other christian</option>
                  </select>
                </div>
                <small className="formError">{formErrors.specialNeeds}</small>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    I wish to work with host family with children from
                    <span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    min="1"
                    name="childrenFrom"
                    placeholder="Ex:1"
                    value={formValues.childrenFrom}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.childrenFrom}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    To<span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    min="1"
                    name="toChildrenNumber"
                    placeholder="Ex:5"
                    value={formValues.toChildrenNumber}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">
                  {formErrors.toChildrenNumber}
                </small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Children with agefrom <span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    min="0"
                    max="17"
                    name="agedFrom"
                    placeholder="Ex:5"
                    value={formValues.agedFrom}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.agedFrom}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    To<span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    min="1"
                    max="17"
                    name="agedTo"
                    placeholder="Ex:5"
                    value={formValues.agedTo}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.agedTo}</small>
              </Grid>
            </div>
            <button
              className={disabled ? "disabledButton" : "submit__btn"}
              type="submit"
              disabled={disabled}
            >
              {createMode ? "Create" : "Update"}
            </button>
          </div>
        </form>
      </Container>
    </React.Fragment>
  );
};

export default FamilyPreference;
