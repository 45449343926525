import React, { useState } from "react";
import { Modal } from "antd";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import UpdateForm from "./EditForm";

const EditModal = ({ role }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Tooltip title="Delete" arrow>
        <IconButton
          color="default"
          component="span"
          size="small"
          onClick={showModal}
          style={{
            backgroundColor: "#3f3f3f",
          }}
        >
          <EditIcon style={{ color: "#FFA500", fontSize: "20px" }} />
        </IconButton>
      </Tooltip>
      <Modal
        title="EDIT ROLE"
        visible={isModalVisible}
        onOk={handleOk}
        okButtonProps={{ backgroundColor: "red" }}
        onCancel={handleCancel}
        okText="Delete"
        bodyStyle={{
          backgroundColor: "#464852",
        }}
        width="26%"
        footer={null}
      >
        <UpdateForm role={role} />
      </Modal>
    </>
  );
};

export default EditModal;
