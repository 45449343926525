import {
  GET_ALL_ANNOUNCEMENTS_START,
  GET_ALL_ANNOUNCEMENTS_SUCCESS,
  GET_ALL_ANNOUNCEMENTS_FAILURE,
  GET_ANNOUNCEMENT_START,
  GET_ANNOUNCEMENT_SUCCESS,
  GET_ANNOUNCEMENT_FAILURE,
  CREATE_ANNOUNCEMENT_START,
  CREATE_ANNOUNCEMENT_SUCCESS,
  CREATE_ANNOUNCEMENT_FAILURE,
  UPDTAE_ANNOUNCEMENT_START,
  UPDTAE_ANNOUNCEMENT_SUCCESS,
  UPDTAE_ANNOUNCEMENT_FAILURE,
  DELETE_ANNOUNCEMENT_START,
  DELETE_ANNOUNCEMENT_SUCCESS,
  DELETE_ANNOUNCEMENT_FAILURE,
} from "../action/actionTypes/types";
import * as api from "../../api/index";

export const fetchAllAnnouncements = () => async (dispatch) => {
  dispatch({
    type: GET_ALL_ANNOUNCEMENTS_START,
  });
  try {
    const { data } = await api.getAnnouncements();
    return dispatch({
      type: GET_ALL_ANNOUNCEMENTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: GET_ALL_ANNOUNCEMENTS_FAILURE,
      payload: errorMessage,
    });
  }
};

export const deleteAnnouncement = (anId) => async (dispatch) => {
  dispatch({
    type: DELETE_ANNOUNCEMENT_START,
  });
  try {
    const { data } = await api.deleteAnnouncement(anId);
    return dispatch({
      type: DELETE_ANNOUNCEMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: DELETE_ANNOUNCEMENT_FAILURE,
      payload: errorMessage,
    });
  }
};

export const createAnnouncement = (formData) => async (dispatch) => {
  dispatch({
    type: CREATE_ANNOUNCEMENT_START,
  });

  try {
    const { data } = await api.createAnnouncement(formData);

    return dispatch({
      type: CREATE_ANNOUNCEMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: CREATE_ANNOUNCEMENT_FAILURE,
      payload: errorMessage,
    });
  }
};

export const getAnnouncement = (uuid) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ANNOUNCEMENT_START,
    });

    const { data } = await api.getAnnouncement(uuid);

    return dispatch({ type: GET_ANNOUNCEMENT_SUCCESS, payload: data });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: GET_ANNOUNCEMENT_FAILURE,
      payload: errorMessage,
    });
  }
};

export const updateAnnouncement = (formData, anId) => async (dispatch) => {
  dispatch({
    type: UPDTAE_ANNOUNCEMENT_START,
  });

  console.log("formDta", formData, anId);
  try {
    const { data } = await api.updateAnnouncement(formData, anId);
    console.log("updated announcement", data);
    return dispatch({
      type: UPDTAE_ANNOUNCEMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: UPDTAE_ANNOUNCEMENT_FAILURE,
      payload: errorMessage,
    });
  }
};
