import React from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useStyles } from "./style";
import { connect } from "react-redux";
import { aupairProfile } from "../../../Redux/action/aupairForms";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Grid from "@material-ui/core/Grid";
import "./AupairHome.css";
import Navigation from "./../../Page/Landing/Navigation/Navigation";
import Contact from "./../../Page/Landing/Contacts/Contacts";
import AupairHeader from "./AupairHeader";
import Footer from "../../Footer/NewFooter";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const religionImport = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "No",
    label: "No",
  },
  {
    value: "Very",
    label: "Very",
  },
  {
    value: "Important",
    label: "Important",
  },
];

const workPreferences = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Contry Side ",
    label: "Country Side ",
  },
  {
    value: "Village",
    label: "Village",
  },
  {
    value: "Town",
    label: "Town",
  },
  {
    value: "Big City",
    label: "Big City",
  },
  {
    value: "MetropoleRegion",
    label: "MetropoleRegion",
  },
  {
    value: "AllPossibilities",
    label: "AllPossibilities",
  },
];

const AupairProfile = ({ formData, setFormData, navigation }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const {
    fullName,
    phone,
    email,
    birthDate,
    familyStatus,
    country,
    religion,
    religionImpt,
    hobbies,
    routine,
    uniqueness,
    quality,
    weakness,
    specialNeed,
    street,
    postCode,
    houseNo,
    city,
    workPreference,
  } = formData;

  const Next = (event) => {
    event.preventDefault();
    setOpen(!open);
    setTimeout(() => {
      setOpen(false);
      navigation.next();
    }, 1000);
  };

  return (
    <React.Fragment>
      <Contact />
      <Navigation />
      <AupairHeader />
      <CssBaseline />
      <div>
        <Backdrop
          style={{ zIndex: 1000, color: "#fff" }}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <Container maxWidth="md" className="form_container">
        <form className={classes.root} noValidate autoComplete="off">
          <div className="personalInformationGroup">
            <h3 className="InnerTitles">Your personal information</h3>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Full Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Full Name"
                    name="fullName"
                    value={fullName}
                    onChange={setFormData}
                    required
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Date of Birth <span className="required">*</span>
                  </label>
                  <input
                    required
                    type="Date"
                    name="birthDate"
                    value={birthDate}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Family Status <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    name="familyStatus"
                    placeholder="Family Status"
                    value={familyStatus}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Religion <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    name="religion"
                    placeholder="Religion"
                    value={religion}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Is Religion Important <span className="required">*</span>
                  </label>
                  <select
                    name="religionImpt"
                    value={religionImpt}
                    onChange={setFormData}
                  >
                    {religionImport.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Hobbie and Interest <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    name="hobbies"
                    placeholder="Hobbie and Interest"
                    value={hobbies}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Your special routine <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    name="routine"
                    placeholder="Your special routine"
                    value={routine}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Your uniqueness <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    name="uniqueness"
                    placeholder="The uniqueness of you"
                    value={uniqueness}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Your quality <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    name="quality"
                    placeholder="Your quality"
                    value={quality}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Your weakness <span className="required">*</span>
                  </label>
                  <input
                    style={{ width: "95%" }}
                    required
                    name="weakness"
                    placeholder="Your weakness"
                    value={weakness}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Your special need and wish{" "}
                    <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    name="specialNeed"
                    placeholder="Your special need and wish"
                    value={specialNeed}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
            </div>
          </div>
          <div className="personalInformationGroup">
            <h3 className="InnerTitles">Contact information</h3>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Street <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    name="street"
                    placeholder="Street"
                    value={street}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Post Code <span className="required">*</span>
                  </label>
                  <input
                    style={{ width: "95%" }}
                    type="text"
                    name="postCode"
                    placeholder="Post Code"
                    value={postCode}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Country <span className="required">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="country"
                    placeholder="Country"
                    value={country}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    House Number <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    name="houseNo"
                    placeholder="House Number"
                    value={houseNo}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    City <span className="required">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="city"
                    placeholder="City"
                    value={city}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Email <span className="required">*</span>
                  </label>
                  <input
                    required
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={email}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Telephone <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    name="phone"
                    placeholder="Telephone"
                    value={phone}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Work Preference <span className="required">*</span>
                  </label>
                  <select
                    name="workPreference"
                    value={workPreference}
                    onChange={setFormData}
                  >
                    {workPreferences.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            </div>
          </div>
          <button className="Continue" type="submit" onClick={Next}>
            Next &nbsp;
            <ArrowForwardIcon />
          </button>
        </form>
      </Container>
      <Footer />
    </React.Fragment>
  );
};
const mapStateToProps = ({ AupairProfileReducer }) => {
  const { profile } = AupairProfileReducer;
  return { profile };
};
export default connect(mapStateToProps, { aupairProfile })(AupairProfile);
