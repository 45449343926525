import React, { useState } from "react";
import { Modal } from "antd";
import EditBlog from "./editBlogForm";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

const initialValues = {
  category: "",
  title: "",
  popular: "",
  trending: "",
  blogText: "",
  picture: "",
};

const EditModal = ({ blog }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);

  const showModal = () => {
    setIsModalVisible(true);
    setFormValues({
      blogText: blog?.blogText,
      category: blog?.category,
      title: blog?.title,
      popular: blog?.popular,
      trending: blog?.trending,
      picture: blog?.picture,
    });
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Tooltip title="Edit" arrow>
        <IconButton onClick={showModal}>
          <EditIcon style={{ color: "orange" }} />
        </IconButton>
      </Tooltip>
      <Modal
        title="EDIT BLOG"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        bodyStyle={{
          backgroundColor: "#464852",
        }}
        width="80%"
      >
        <EditBlog
          blogId={blog?.uuid}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      </Modal>
    </>
  );
};

export default EditModal;
