import React, { Fragment } from "react";
import PersonalInformation from "./PersonalInformation";
import ContactInformation from "./ContactInfo";
import PersonalBackground from "./Background";
import LivingArea from "./LivingArea";
import MoreAboutAupair from "./More.js";
import FamilyPreference from "./Preference";
import Expectations from "./Expectation";
import WishedOffer from "./Offer";
import { Divider } from "antd";
import "./style.css";

const HostDetails = React.forwardRef((props, ref) => {
  return (
    <Fragment>
      <div ref={ref} className="aupair_printable_documents">
        <PersonalInformation
          Data={props?.user?.message?.data?.user?.hostProfile}
        />
        <Divider />
        <ContactInformation
          Data={props?.user?.message?.data?.user?.hostContacts}
        />
        <Divider />
        <PersonalBackground
          Data={props?.user?.message?.data?.user?.professionalBackground}
        />
        <Divider />
        <LivingArea Data={props?.user?.message?.data?.user?.livingArea} />
        <Divider />
        <MoreAboutAupair
          Data={props?.user?.message?.data?.user?.hostotherInfo}
        />
        <Divider />
        <FamilyPreference
          Data={props?.user?.message?.data?.user?.hostPreferences}
        />
        <Divider />
        <Expectations
          Data={props?.user?.message?.data?.user?.hostExpectations}
        />
        <Divider />
        <WishedOffer Data={props?.user?.message?.data?.user?.offerToAupair} />
        <Divider />
      </div>
    </Fragment>
  );
});

export default HostDetails;
