import React, { Fragment, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import EditTestmony from "./EditModal";
import DeleteModal from "./DeleteModal";
import { connect, useSelector } from "react-redux";
import { fetchAllTestmonials } from "./../../../Redux/action/Testmonials";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Empty } from "antd";
import "./testmony.css";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    backgroundColor: "#696969",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 10px 50px",
    paddingRight: "20px",
    borderRadius: "3px",
    color: "#fff",
  },
  inline: {
    display: "inline",
  },
  title: {
    fontSize: "20px",
    textAlign: "center",
    fontWeight: 700,
    color: "#9e9e9e",
    marginBottom: "50px",
  },
}));

const Testmonials = ({
  testmonials,
  fetchAllTestmonials,
  setSearchableData,
  newTestmony,
  updatedTestmony,
  deleteTestmony,
}) => {
  const classes = useStyles();
  const { message: searchResult } = useSelector(
    (state) => state.search.searchResults
  );
  const [open, setOpen] = useState(false);
  const [testmony, setTestmony] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      fetchAllTestmonials();
    };

    if (
      newTestmony?.success ||
      updatedTestmony?.success ||
      deleteTestmony?.success
    ) {
      fetchData();
    } else {
      fetchData();
    }
  }, [
    fetchAllTestmonials,
    newTestmony?.success,
    updatedTestmony?.success,
    deleteTestmony?.success,
  ]);

  useEffect(() => {
    if (!testmonials.success) {
      setOpen(true);
    } else if (testmonials.success) {
      setOpen(false);
      setTestmony(testmonials?.message?.data?.allTestmonials);
      setSearchableData(testmonials?.message?.data?.allTestmonials);
    } else if (!testmonials.success && testmonials.error !== null) {
      setOpen(true);
    }
  }, [
    testmonials.success,
    testmonials.error,
    testmonials?.message?.data?.allTestmonials,
    setSearchableData,
  ]);

  useEffect(() => {
    if (searchResult) {
      setTestmony(searchResult);
    } else if (testmonials.success) {
      setTestmony(testmonials?.message?.data?.allTestmonials);
    }
  }, [
    searchResult,
    testmonials.success,
    testmonials?.message?.data?.allTestmonials,
  ]);

  useEffect(() => {
    const toastify = () => {
      if (newTestmony?.success) {
        toast.success("Added Successfully!!👍", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    toastify();
  }, [newTestmony?.success]);

  useEffect(() => {
    const toastify = () => {
      if (updatedTestmony?.success) {
        toast.success(updatedTestmony?.message?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    toastify();
  }, [updatedTestmony?.message?.message, updatedTestmony?.success]);

  useEffect(() => {
    const toastify = () => {
      if (deleteTestmony?.success) {
        toast.success(deleteTestmony?.message?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    toastify();
  }, [deleteTestmony?.message?.message, deleteTestmony?.success]);

  return (
    <Fragment>
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      {testmony?.length === 0 ? (
        <Empty />
      ) : (
        <List className={classes.root}>
          {testmony?.map((testmony) => (
            <>
              <ListItem alignItems="flex-start" key={testmony.uuid}>
                <ListItemAvatar>
                  <Avatar alt={testmony.fullName} src={testmony.userPicture} />
                </ListItemAvatar>
                <ListItemText
                  className="message__body"
                  primary={`Name: ${testmony.fullName}`}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        <small className="user_email">
                          {testmony.jobTitle}
                        </small>
                      </Typography>
                      &nbsp; &nbsp;
                      <p
                        className="message__paragraph"
                        dangerouslySetInnerHTML={{ __html: testmony.testmony }}
                      ></p>
                      <div
                        style={{
                          position: "absolute",
                          top: 50,
                          right: 0,
                          display: "flex",
                          flexDirection: "column",
                          gap: "2px",
                        }}
                      >
                        <EditTestmony testmony={testmony} />
                        <DeleteModal testId={testmony.uuid} />
                      </div>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </>
          ))}
        </List>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    testmonials: state?.allTestmonials?.testmonials,
    newTestmony: state.createTestmony.testmony,
    updatedTestmony: state.updateTestmony.testmony,
    deleteTestmony: state.deletedTestmony.testmony,
  };
};

export default connect(mapStateToProps, { fetchAllTestmonials })(Testmonials);
