import React from "react";
import "./OurStory.css";


const OurStory = () => {
  return (
    <div className="OurStory">
   
        <h1>What does Aupair mean? </h1>
     
      
        <blockquote>
          <p>
            The term "au pair" is derived from the French "au pair", which means
            "mutual". An aupair decides to go abroad with the aim of improving
            the language skills and new things of the host country. A host
            family offers an aupair pocket money,free board and lodging. In
            return, the aupair helps with childcare and light household chores.
            Both parties benefit from an aupair experience. As an aupair You
            become more independent and learn to stand on your own two feet
            without having completely renounce the family environment.
          </p>
        </blockquote>
     
    </div>
  );
};
export default OurStory;
