import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableHead from "@material-ui/core/TableHead";
import DeleteModal from "./DeleteModal";
import { connect } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import EditCategory from "./EditModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import { fetchCtegories } from "./../../../Redux/action/blog";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#3f3f3f",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#9e9e9e",
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    background: "#464852a9",
    boxShadow: "rgba(0, 0, 0, 0.5) 0px 4px 12px",
  },
});

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const AllCategories = ({
  categories,
  fetchCtegories,
  newCategory,
  updatedCategory,
  deletedCategory,
}) => {
  const tableStyles = useStyles();
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      categories?.message?.data?.categories?.length - page * rowsPerPage
    );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const getAllCategories = () => {
      fetchCtegories();
    };

    if (
      newCategory?.success ||
      updatedCategory?.success ||
      deletedCategory?.success
    ) {
      getAllCategories();
    } else {
      getAllCategories();
    }
  }, [
    fetchCtegories,
    newCategory?.success,
    updatedCategory?.success,
    deletedCategory?.success,
  ]);

  useEffect(() => {
    if (!categories.success) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [categories.success]);

  return (
    <Fragment>
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <TableContainer component={Paper}>
        <Table
          className={tableStyles.table}
          aria-label="custom pagination table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell align="right">Category Name</StyledTableCell>
              <StyledTableCell align="right">Edit</StyledTableCell>
              <StyledTableCell align="right">Delete</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsPerPage > 0
              ? categories?.message?.data?.categories
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((category) => (
                    <StyledTableRow key={category.uuid}>
                      <StyledTableCell component="th" scope="row">
                        {category?.createdAt.split("T")[0]}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {category.categoryName}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <EditCategory category={category} />
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <DeleteModal categoryId={category.uuid} />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
              : categories?.message?.data?.categories.map((category) => (
                  <StyledTableRow key={category.uuid}>
                    <StyledTableCell component="th" scope="row">
                      {category.createdAt.split("T")[0]}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {category.categoryName}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <EditCategory category={category} />
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <DeleteModal categoryId={category.uuid} />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}

            {emptyRows > 0 && (
              <StyledTableRow style={{ height: 53 * emptyRows }}>
                <StyledTableCell colSpan={6} />
              </StyledTableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={categories?.message?.data?.categories?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    categories: state.allCategories.categories,
    newCategory: state.createCategory.category,
    updatedCategory: state.updateCategory.category,
    deletedCategory: state.deleteCategory.category,
  };
};

export default connect(mapStateToProps, { fetchCtegories })(AllCategories);
