import { Modal } from "antd";
import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import PrintDoc from "./PrintableDocument";

const AupairModal = ({ userId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Tooltip title="More Info" arrow>
        <IconButton
          color="default"
          component="span"
          size="small"
          onClick={showModal}
          style={{ backgroundColor: "#3f3f3f" }}
        >
          <InfoIcon style={{ color: "#FFA500", fontSize: "20px" }} />
        </IconButton>
      </Tooltip>

      <Modal
        title={
          <h6 style={{ textAlign: "center", color: "#555" }}>
            PRINTABLE DOCUMENT
          </h6>
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width="80%"
      >
        <PrintDoc userId={userId} />
      </Modal>
    </>
  );
};

export default AupairModal;
