import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import MyProfileTab from "./MyProfile/HostProfileInfo";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
// import MatchingAupairs from "./HostMatches";
import MyGallery from "./MyProfile/Forms/HostForms/MyGallery";
import MyDocuments from "./MyProfile/Forms/HostForms/MyDocuments";
import { useNavigate } from "react-router-dom";
import MatchingAupairs from "./../TopNav/MyProfile/Forms/HostForms/HostMatches/index";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
}));

const ScrollableTabsButtonAuto = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          TabIndicatorProps={{
            style: {
              backgroundColor: "orange",
              color: "green",
            },
          }}
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="My PROFILE" {...a11yProps(0)} />
          <Tab label="My Gallery" {...a11yProps(1)} />
          <Tab label="My Documents" {...a11yProps(2)} />
          <Tab label="MY MATCH" {...a11yProps(3)} />

          <Tab
            label={
              <IconButton aria-label="cart">
                <StyledBadge badgeContent={5} color="secondary">
                  <NotificationsIcon />
                </StyledBadge>
              </IconButton>
            }
            {...a11yProps(4)}
          />
          <Tab label="MY SETTINGS" {...a11yProps(5)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <MyProfileTab />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MyGallery />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <MyDocuments />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <MatchingAupairs />
      </TabPanel>
      <TabPanel value={value} index={4}>
        MY Nottifications
      </TabPanel>
      <TabPanel value={value} index={5}>
        <button onClick={() => navigate("/")}>Logout</button>
      </TabPanel>
    </div>
  );
};

export default ScrollableTabsButtonAuto;
