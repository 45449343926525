import React, { useState, Fragment, useEffect } from "react";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Divider } from "antd";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { connect } from "react-redux";
import { getUser } from "./../../../Redux/action/staffMembers";
import {
  rejectAupairGallery,
  restoreAupairGallery,
} from "./../../../Redux/action/Gallery";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const MyGallery = ({
  userId,
  user,
  getUser,
  rejectAupairGallery,
  rejected,
  restoreAupairGallery,
  restored,
}) => {
  const [slideNumber, setSlideNumber] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchUser = () => {
      getUser(userId);
    };

    if (rejected?.success || restored?.success) {
      fetchUser();
    } else {
      fetchUser();
    }
  }, [userId, getUser, restored?.success, rejected?.success]);

  useEffect(() => {
    if (!user?.success) {
      setOpen(true);
    } else if (user?.success) {
      setOpen(false);
    } else if (!user?.success && user?.error !== null) {
      setOpen(false);
    }
  }, [user?.success, user?.error]);

  const handleOpenModal = (index) => {
    setSlideNumber(index);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const prevSlide = () => {
    slideNumber === 0
      ? setSlideNumber(user?.message?.data?.user?.aupairgallery.length - 1)
      : setSlideNumber(slideNumber - 1);
  };

  const nextSlide = () => {
    slideNumber + 1 === user?.message?.data?.user?.aupairgallery.length
      ? setSlideNumber(0)
      : setSlideNumber(slideNumber + 1);
  };

  const rejectPhoto = (photoId) => {
    rejectAupairGallery(photoId);
  };

  const restorePhoto = (photoId) => {
    restoreAupairGallery(photoId);
  };

  return (
    <Fragment>
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      {openModal && (
        <div className="slideWrapper">
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="btnClose"
            onClick={handleCloseModal}
          />
          <FontAwesomeIcon
            icon={faCircleArrowLeft}
            className="btnPrev"
            onClick={prevSlide}
          />
          <FontAwesomeIcon
            icon={faCircleArrowRight}
            className="btnNext"
            onClick={nextSlide}
          />
          <div className="fullScreenImage">
            <img
              src={user?.message?.data?.user?.aupairgallery[slideNumber].photo}
              alt=""
            />
            <div className="imageOverlay">
              <div className="host_image_action_buttons">
                {!user?.message?.data?.user?.aupairgallery[slideNumber]
                  .rejected ? (
                  <Tooltip title="Reject" arrow>
                    <IconButton
                      aria-label="edit"
                      size="small"
                      onClick={() =>
                        rejectPhoto(
                          user?.message?.data?.user?.aupairgallery[slideNumber]
                            ?.uuid
                        )
                      }
                    >
                      <ThumbDownAltIcon style={{ color: "#000" }} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Restore" arrow>
                    <IconButton
                      aria-label="edit"
                      size="small"
                      onClick={() =>
                        restorePhoto(
                          user?.message?.data?.user?.aupairgallery[slideNumber]
                            ?.uuid
                        )
                      }
                    >
                      <ThumbDownOffAltIcon style={{ color: "#000" }} />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Edit Document" arrow>
                  <IconButton aria-label="edit" size="small">
                    <a
                      href={
                        user?.message?.data?.user?.aupairgallery[slideNumber]
                          .photo
                      }
                      download
                      target="_blank"
                      rel="noreferrer"
                    >
                      <DownloadForOfflineIcon style={{ color: "#000" }} />
                    </a>
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      )}

      <Divider />
      <div className="galleryWrapper">
        {user?.success &&
          user?.message?.data?.user?.aupairgallery?.map((slide, index) => (
            <div
              className="single"
              key={slide.uuid}
              onClick={() => handleOpenModal(index)}
            >
              <img src={slide.photo} alt={`aupairphoto-${index}`} />
            </div>
          ))}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.getUser.user,
    rejected: state.rejectAupairPhoto.rejected,
    restored: state.restoreAupairPhoto.restored,
  };
};

export default connect(mapStateToProps, {
  getUser,
  rejectAupairGallery,
  restoreAupairGallery,
})(MyGallery);
