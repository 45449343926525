import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useStyles } from "./style";
import Grid from "@material-ui/core/Grid";
import "./profile.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  aupairProfile,
  updateAupairProfile,
} from "./../../../../Redux/action/aupairForms";
import Layout from "./../../../../Components/Layouts/Profile/Layout";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getUser } from "./../../../../Redux/action/staffMembers";

const initialValues = {
  firstName: "",
  lastName: "",
  birthDate: "",
  familyStatus: "",
  nationality: "",
  religion: "",
  religionImpt: "",
  foodPreference: "",
};

const AupairProfile = ({
  getUser,
  aupair,
  aupairProfile,
  profile,
  updateAupairProfile,
  updatedProfile,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [createMode, setCreateMode] = useState(true);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [disabled, setDisabled] = useState(false);

  const user = JSON.parse(localStorage.getItem("profile"))?.data?.user;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));
    if (Object.keys(formErrors).length === 0) {
      if (createMode) {
        setOpen(true);
        aupairProfile(formValues);
        clear();
      } else {
        setOpen(true);
        updateAupairProfile(
          formValues,
          aupair?.message?.data?.user?.aupairprofile?.uuid
        );
      }
    }
  };

  const clear = () => {
    setFormValues(initialValues);
  };

  useEffect(() => {
    const fetchUser = () => {
      getUser(user?.uuid);
    };
    if (user?.uuid || profile.success || updatedProfile?.success) {
      fetchUser();
    } else {
      return;
    }
  }, [user?.uuid, getUser, profile?.success, updatedProfile?.success]);

  useEffect(() => {
    if (profile?.success) {
      setOpen(false);
    } else if (!profile?.success || profile?.error !== null) {
      setOpen(false);
    }
  }, [profile?.success, profile?.error]);

  useEffect(() => {
    if (updatedProfile?.success) {
      setOpen(false);
    } else if (!updatedProfile?.success || updatedProfile?.error !== null) {
      setOpen(false);
    }
  }, [updatedProfile.success, updatedProfile.error]);

  const validate = (values) => {
    const errors = {};

    if (!values.firstName) {
      errors.firstName = "This field is required!";
    }
    if (!values.lastName) {
      errors.lastName = "This field is required!";
    }
    if (!values.birthDate) {
      errors.birthDate = "This field is required!";
    }
    if (!values.familyStatus) {
      errors.familyStatus = "This field is required!";
    }
    if (!values.nationality) {
      errors.nationality = "This field is required!";
    }
    if (!values.religion) {
      errors.religion = "This field is required!";
    }

    if (!values.religionImpt) {
      errors.religionImpt = "This field is required!";
    }
    if (!values.foodPreference) {
      errors.foodPreference = "This field is required!";
    }

    return errors;
  };

  useEffect(() => {
    if (aupair?.message?.data?.user?.aupairprofile) {
      setFormValues({
        firstName: aupair?.message?.data?.user?.aupairprofile?.firstName,
        lastName: aupair?.message?.data?.user?.aupairprofile?.lastName,
        birthDate:
          aupair?.message?.data?.user?.aupairprofile?.birthDate?.split("T")[0],
        familyStatus: aupair?.message?.data?.user?.aupairprofile?.familyStatus,
        nationality: aupair?.message?.data?.user?.aupairprofile?.nationality,
        religion: aupair?.message?.data?.user?.aupairprofile?.religion,
        religionImpt: aupair?.message?.data?.user?.aupairprofile?.religionImpt,
        foodPreference:
          aupair?.message?.data?.user?.aupairprofile?.foodPreference,
      });
      setCreateMode(false);
    } else if (!aupair?.message?.data?.user?.aupairprofile) {
      setCreateMode(true);
      setFormValues(initialValues);
    }
  }, [aupair]);

  useEffect(() => {
    if (
      formValues.firstName === "" ||
      formValues.lastName === "" ||
      formValues.birthDate === "" ||
      formValues.familyStatus === "" ||
      formValues.nationality === "" ||
      formValues.religion === "" ||
      formValues.religionImpt === "" ||
      formValues.foodPreference === ""
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formValues]);

  return (
    <Layout>
      <CssBaseline />
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <Container maxWidth="md" className="form_container">
        <small className="error">{profile.error}</small>
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="personalInformationGroup">
            <h3 className="InnerTitles">My Personal Information</h3>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    First Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="John"
                    name="firstName"
                    required
                    value={formValues.firstName}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.firstName}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Last Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Doe"
                    name="lastName"
                    required
                    value={formValues.lastName}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.lastName}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Date of Birth <span className="required">*</span>
                  </label>
                  <input
                    required
                    type="Date"
                    name="birthDate"
                    value={formValues.birthDate}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.birthDate}</small>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Family Status <span className="required">*</span>
                  </label>

                  <select
                    name="familyStatus"
                    value={formValues.familyStatus}
                    onChange={handleChange}
                  >
                    <option value={null}>--select---</option>
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                    <option value="Fiancé">Fiancé</option>
                    <option value="Divorced">Divorced</option>
                    <option value="Widower">Widower</option>
                  </select>
                </div>
                <small className="formError">{formErrors.familyStatus}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Nationality <span className="required">*</span>
                  </label>
                  <select
                    name="nationality"
                    value={formValues.nationality}
                    onChange={handleChange}
                  >
                    <option value={null}>---select---</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Uganda">Uganda</option>
                    <option value="DRC">DRC</option>
                    <option value="Tanzania">Tanzania</option>
                    <option value="No preference">No preference</option>
                  </select>
                </div>
                <small className="formError">{formErrors.nationality}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Religion<span className="required">*</span>
                  </label>
                  <select
                    name="religion"
                    value={formValues.religion}
                    onChange={handleChange}
                  >
                    <option value={null}>--select--</option>
                    <option value="Catholic">Catholic</option>
                    <option value="Evangelic">Evangelic</option>
                    <option value="Muslim">Muslim</option>
                    <option value="Other christian">Other christian</option>
                  </select>
                </div>
                <small className="formError">{formErrors.religion}</small>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Is Religion Important <span className="required">*</span>
                  </label>
                  <select
                    name="religionImpt"
                    value={formValues.religionImpt}
                    onChange={handleChange}
                  >
                    <option value={null}>---select---</option>
                    <option value="No">No</option>
                    <option value="Very">Very</option>
                    <option value="Important">Important</option>
                  </select>
                </div>
                <small className="formError">{formErrors.religionImpt}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Food preference <span className="required">*</span>
                  </label>
                  <select
                    name="foodPreference"
                    value={formValues.foodPreference}
                    onChange={handleChange}
                  >
                    <option value={null}>--select--</option>
                    <option value="Eating everything">Eating everything</option>
                    <option value="Only vegetable and milch product">
                      Only vegetable and milch product
                    </option>
                    <option value="Eggs and Fishes">Eggs and Fishes</option>
                    <option value="Vegans">Vegans</option>
                    <option value="Freedom to eat food on your wish">
                      Freedom to eat food on your wish
                    </option>
                    <option value="Special Regime">Special Regime</option>
                  </select>
                </div>
                <small className="formError">{formErrors.foodPreference}</small>
              </Grid>
            </div>
            <div className="user_action_buttons">
              <button
                className={disabled ? "disabledButton" : "submit__btn"}
                type="submit"
                disabled={disabled}
              >
                {createMode ? "Save" : "Update"}
              </button>
              <button className="next__btn" type="button">
                <Link to="/aupair/contact">Next &rarr;</Link>
              </button>
            </div>
          </div>
        </form>
      </Container>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    aupair: state.getUser.user,
    profile: state.aupairProfile.profile,
    updatedProfile: state.updateAupairProfile.profile,
  };
};

export default connect(mapStateToProps, {
  aupairProfile,
  getUser,
  updateAupairProfile,
})(AupairProfile);
