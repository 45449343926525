import React, { useCallback } from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useStyles } from "./style";
import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "./AupairHome.css";
import Navigation from "./../../Page/Landing/Navigation/Navigation";
import Contact from "./../../Page/Landing/Contacts/Contacts";
import AupairHeader from "./AupairHeader";
import Footer from "../../Footer/NewFooter";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useDropzone } from "react-dropzone";
import { Image, Transformation } from "cloudinary-react";

const travelingExpenceFees = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];

const MedicalInsuranceFees = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];

const LocalTranspTicke = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];

const providingCar = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];

const providingBike = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];

const providePrivateRoom = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];

const providePrivateBathroom = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];

const basicOfferingIsEnough = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];

const AupairWishes = ({
  formData,
  setFormData,
  navigation,
  setAupairUploads,
}) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [uploadedFiles, setUploadedFiles] = React.useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const Next = (event) => {
    event.preventDefault();
    setOpen(!open);
    setTimeout(() => {
      setOpen(false);
      navigation.next();
    }, 1000);
  };
  const Previous = (event) => {
    event.preventDefault();
    setOpen(!open);
    setTimeout(() => {
      setOpen(false);
      navigation.previous();
    }, 1000);
  };

  const {
    travelExpenceFees,
    medicalInsuranceFees,
    pocketMoney,
    languageCourseFees,
    localTranspTicket,
    provideCar,
    provideBike,
    privateRoom,
    privateBathroom,
    basicOfferIsEnough,
    // letter,
  } = formData;

  const onDrop = useCallback((acceptedFiles) => {
    const url = "https://api.cloudinary.com/v1_1/dsb0gu6km/image/upload";

    acceptedFiles.forEach(async (acceptedFile) => {
      const formData = new FormData();
      formData.append("file", acceptedFile);
      formData.append("upload_preset", "cvo1ylzx");
      const { data } = await axios({
        url,
        method: "POST",
        data: formData,
      });

      setUploadedFiles((old) => [...old, data]);
    });
  }, []);

  React.useEffect(() => {
    if (uploadedFiles.length === 5) {
      setAupairUploads([...uploadedFiles]);
    }
  }, [uploadedFiles, setAupairUploads]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accepts: "image/*",
    multiple: false,
  });

  return (
    <React.Fragment>
      <Contact />
      <Navigation />
      <AupairHeader />
      <CssBaseline />
      <div>
        <Backdrop
          style={{ zIndex: 1000, color: "#fff" }}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Container maxWidth="md" className="form_container">
        <form className={classes.root} noValidate autoComplete="off">
          <div className="personalInformationGroup">
            <h3 className="InnerTitles">Your wished offer</h3>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label htmlFor="travelExpenceFees">
                    Travel Expence Fees<span className="required">*</span>
                  </label>
                  <select
                    name="travelExpenceFees"
                    value={travelExpenceFees}
                    onChange={setFormData}
                  >
                    {travelingExpenceFees.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label htmlFor="medicalInsuranceFees">
                    Medical Insurance Fees<span className="required">*</span>
                  </label>
                  <select
                    name="medicalInsuranceFees"
                    value={medicalInsuranceFees}
                    onChange={setFormData}
                  >
                    {MedicalInsuranceFees.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label htmlFor="pocketMoney">
                    Pocket Money <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    name="pocketMoney"
                    placeholder="Ex: 1000-1500 $"
                    value={pocketMoney}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label htmlFor="languageCourseFees">
                    Language Course Fees <span className="required">*</span>
                  </label>
                  <input
                    name="languageCourseFees"
                    placeholder="Language Course Fees Ex: 1000-1500 $"
                    value={languageCourseFees}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label htmlFor="localTranspTicket">
                    Local Transport Ticket <span className="required">*</span>
                  </label>
                  <select
                    name="localTranspTicket"
                    value={localTranspTicket}
                    onChange={setFormData}
                  >
                    {LocalTranspTicke.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label htmlFor="privateCar">
                    They will provide the car{" "}
                    <span className="required">*</span>
                  </label>
                  <select
                    name="provideCar"
                    value={provideCar}
                    onChange={setFormData}
                  >
                    {providingCar.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label htmlFor="privateBike">
                    The family will provide a bike
                    <span className="required">*</span>
                  </label>
                  <select
                    name="provideBike"
                    value={provideBike}
                    onChange={setFormData}
                  >
                    {providingBike.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label htmlFor="privateRoom">
                    Private Room <span className="required">*</span>
                  </label>
                  <select
                    name="privateRoom"
                    value={privateRoom}
                    onChange={setFormData}
                  >
                    {providePrivateRoom.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label htmlFor="privateBathroom">
                    Private Bathroom <span className="required">*</span>
                  </label>
                  <select
                    name="privateBathroom"
                    value={privateBathroom}
                    onChange={setFormData}
                  >
                    {providePrivateBathroom.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label htmlFor="basicOffer">
                    Basic Offer Is Enough <span className="required">*</span>
                  </label>
                  <select
                    name="basicOfferIsEnough"
                    value={basicOfferIsEnough}
                    onChange={setFormData}
                  >
                    {basicOfferingIsEnough.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            </div>
          </div>

          <div className="personalInformationGroup">
            <h3 className="InnerTitles">
              The letter and Photo to the Host Family
            </h3>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label htmlFor="letter">
                    Letter
                    <span className="required">*</span>
                  </label>
                  <input
                    id="letter"
                    required
                    name="letter"
                    type="file"
                    onChange={setFormData}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label htmlFor="imageOne">
                    Upload First Image <span className="required">*</span>
                  </label>
                  <div {...getRootProps()} className={classes.dropZone}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the files here ...</p>
                    ) : (
                      <>
                        Drag &nbsp;
                        <CloudUploadIcon />
                        &nbsp;or Click
                      </>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label htmlFor="imageTwo">
                    Upload second Image <span className="required">*</span>
                  </label>
                  <div {...getRootProps()} className={classes.dropZone}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the files here ...</p>
                    ) : (
                      <>
                        Drag &nbsp;
                        <CloudUploadIcon />
                        &nbsp;or Click
                      </>
                    )}
                  </div>
                </div>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label htmlFor="imageThree">
                    Upload Third Image<span className="required">*</span>
                  </label>
                  <div {...getRootProps()} className={classes.dropZone}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the files here ...</p>
                    ) : (
                      <>
                        Drag &nbsp;
                        <CloudUploadIcon />
                        &nbsp;or Click
                      </>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label htmlFor="imageFour">
                    Upload Fourth Image <span className="required">*</span>
                  </label>
                  <div {...getRootProps()} className={classes.dropZone}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the files here ...</p>
                    ) : (
                      <>
                        Drag &nbsp;
                        <CloudUploadIcon />
                        &nbsp;or Click
                      </>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label htmlFor="imageFive">
                    Upload Fith Image <span className="required">*</span>
                  </label>
                  <div {...getRootProps()} className={classes.dropZone}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the files here ...</p>
                    ) : (
                      <>
                        Drag &nbsp;
                        <CloudUploadIcon />
                        &nbsp;or Click
                      </>
                    )}
                  </div>
                </div>
              </Grid>

              <div className="uploadedImages">
                {uploadedFiles?.map((file) => (
                  <div key={file.public_id}>
                    <Image cloudName="dsb0gu6km" publicId={file.public_id}>
                      <Transformation crop="scale" width="200" />
                    </Image>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <button className="Back" onClick={Previous}>
            <ArrowBackIcon /> &nbsp; Back
          </button>
          <button className="Continue" type="submit" onClick={Next}>
            Next
          </button>
        </form>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default AupairWishes;
