import React, { Fragment, useEffect } from "react";
import "./Blog.css";
import { Divider } from "antd";
import RelatedPost from "./RelatedBlog";
import Footer from "./../../Footer/NewFooter";
import Contact from "../../../Components/ResponsiveNavBar/Contacts/Contacts";
import Navigation from "../../../Components/myNavbar/navbar";
import {
  LinkedinShareButton,
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  TwitterShareButton,
} from "react-share";
import {
  LinkedinIcon,
  EmailIcon,
  FacebookIcon,
  WhatsappIcon,
  TelegramIcon,
  TwitterIcon,
} from "react-share";
import { getBlog as getSingleBlog } from "./../../../Redux/action/blog";
import { connect } from "react-redux";

const SingleBlog = ({ getSingleBlog, blog }) => {
  const url = window.location.href.split("/")[4];

  useEffect(() => {
    const fetchBlog = () => {
      getSingleBlog(url);
    };
    if (url) {
      fetchBlog();
    }
  }, [getSingleBlog, url]);

  return (
    <Fragment>
      <Contact />
      <Navigation />
      <div className="single__blog">
        <div className="single__blog__wrapper">
          <div className="single__blog__content">
            <h2 className="single__blog__heading">
              <strong>{blog?.message?.data?.blog?.title}</strong>
            </h2>

            <div className="single__blog__img">
              <img
                className="d-block w-100"
                src={blog?.message?.data?.blog?.picture}
                alt="rhein"
              />
            </div>
            <div className="single__blog__content__text">
              <p
                className="single__blog__content__text_paragraph"
                dangerouslySetInnerHTML={{
                  __html: blog?.message?.data?.blog?.blogText,
                }}
              />
            </div>

            <Divider />
            <h1> share</h1>
            <div className="react__social__media__share">
              <FacebookShareButton
                url={window.location.href}
                title={blog?.message?.data?.blog?.title}
              >
                <FacebookIcon size={32} round={true} />
              </FacebookShareButton>
              <LinkedinShareButton
                url={window.location.href}
                title={blog?.message?.data?.blog?.title}
              >
                <LinkedinIcon size={32} round={true} />
              </LinkedinShareButton>
              <TwitterShareButton
                url={window.location.href}
                title={blog?.message?.data?.blog?.title}
              >
                <TwitterIcon size={32} round={true} />
              </TwitterShareButton>
              <EmailShareButton
                url={window.location.href}
                title={blog?.message?.data?.blog?.title}
              >
                <EmailIcon size={32} round={true} />
              </EmailShareButton>
              <TelegramShareButton
                url={window.location.href}
                title={blog?.message?.data?.blog?.title}
              >
                <TelegramIcon size={32} round={true} />
              </TelegramShareButton>
              <WhatsappShareButton
                url={window.location.href}
                title={blog?.message?.data?.blog?.title}
              >
                <WhatsappIcon size={32} round={true} />
              </WhatsappShareButton>
            </div>
            <Divider />
          </div>
        </div>

        <RelatedPost blog={blog?.message?.data?.blog} />
      </div>
      <Footer />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    blog: state.getBlog.blog,
  };
};

export default connect(mapStateToProps, { getSingleBlog })(SingleBlog);
