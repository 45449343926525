import React from "react";
import "./Team.css";
import TeamImg from "./../../../../assets/Discussion.png";
import Grid from "@material-ui/core/Grid";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import VolumeDownIcon from "@material-ui/icons/VolumeDown";
import * as Speech from "./../Speeches/SpeechText";

const Team = () => {
  const msg = new SpeechSynthesisUtterance();
  const speechHandler = () => {
    msg.text = Speech.TeamTextToSpeech;
    window.speechSynthesis.speak(msg);
  };

  return (
    <div className="aupair__team">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xlg={12}>
          <Zoom>
            <h1 className="aupair__team__primary-heading">Our Team</h1>
          </Zoom>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xlg={4}>
          <Fade left>
            <div className="aupair__team__svg">
              <img src={TeamImg} alt="team__members_one" />
            </div>
          </Fade>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8} xlg={8}>
          <Fade right>
            <div className="aupair__team__description">
              Litsen
              <VolumeDownIcon
                style={{ cursor: "pointer" }}
                onClick={speechHandler}
              />
              <p>
                Our team of dedicated professionals, volunteers, mentors and
                coaches dispatched especially around in the countries of Akagera
                and Rhine rivers' regions, are aimed to provide through their
                service, a qualified support to our customers, to make the right
                decisions not just when applying for an aupair experience or
                seaching a sweetable candidate or a matching host family but
                also accompanying them during the placement's time and till it
                is completed and successed. We care that our customers can best
                use the matching to make the most of the unique opportunity,
                ensuring their adventure a sustainable experience and contribute
                to the personal development and leave a positive impact to the
                involved parties. We consider aupair experience as sustainable
                development instrument to live.
              </p>
            </div>
          </Fade>
        </Grid>
      </Grid>
    </div>
  );
};
export default Team;
