import React, { Fragment, useEffect, useState } from "react";
import DateRangeIcon from "@material-ui/icons/DateRange";
import EditAnnouncement from "./EditModal";
import DeleteModal from "./DeleteModal";
import "./Announcement.css";
import { connect, useSelector } from "react-redux";
import { fetchAllAnnouncements } from "./../../../Redux/action/Announcements";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Empty } from "antd";
import { toast } from "react-toastify";

const AnnouncementCard = ({
  fetchAllAnnouncements,
  announcements,
  setSearchableData,
  newAnnouncement,
  deleted,
  updated,
}) => {
  const { message: searchResult } = useSelector(
    (state) => state.search.searchResults
  );
  const [open, setOpen] = useState(false);
  const [publishing, setPublishing] = useState([]);

  useEffect(() => {
    const getAnnouncements = () => {
      fetchAllAnnouncements();
    };

    if (newAnnouncement?.success || deleted?.success || updated?.success) {
      getAnnouncements();
    } else {
      getAnnouncements();
    }
  }, [
    fetchAllAnnouncements,
    newAnnouncement?.success,
    deleted?.success,
    updated?.success,
  ]);

  useEffect(() => {
    if (!announcements?.success) {
      setOpen(true);
    }
  }, [announcements?.success]);

  useEffect(() => {
    if (announcements?.success) {
      setOpen(false);
      setPublishing(announcements?.message?.data?.announcements);
      setSearchableData(announcements?.message?.data?.announcements);
    }
  }, [
    announcements?.success,
    announcements?.message?.data?.announcements,
    setSearchableData,
  ]);

  useEffect(() => {
    if (!announcements.success && announcements.error !== null) {
      setOpen(false);
    }
  }, [announcements.error, announcements.success]);

  useEffect(() => {
    if (searchResult) {
      setPublishing(searchResult);
    } else if (announcements.success) {
      setPublishing(announcements?.message?.data?.announcements);
    }
  }, [
    searchResult,
    announcements.success,
    announcements?.message?.data?.announcements,
  ]);

  useEffect(() => {
    const toastify = () => {
      if (updated?.success) {
        toast.success(updated?.message?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    toastify();
  }, [updated?.message?.message, updated?.success]);

  useEffect(() => {
    const toastify = () => {
      if (newAnnouncement?.success) {
        toast.success("Added Successfully!!👍", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    toastify();
  }, [newAnnouncement?.success]);

  useEffect(() => {
    const toastify = () => {
      if (deleted?.success) {
        toast.success(deleted?.message?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    toastify();
  }, [deleted?.message?.message, deleted?.success]);

  return (
    <Fragment>
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      {publishing?.length === 0 ? (
        <Empty />
      ) : (
        <div className="dashannouncements">
          {publishing?.map((announcement) => (
            <div key={announcement.uuid}>
              <div className="dashannouncement__header">
                <span>
                  <DateRangeIcon />
                  {announcement.createdAt.split("T")[0]}
                </span>
                <div style={{ display: "flex" }}>
                  <EditAnnouncement body={announcement} />
                  <DeleteModal anId={announcement.uuid} />
                </div>
              </div>
              <div className="dashannouncement__heading">
                <h1>{announcement.title}</h1>
              </div>
              <div className="dashannouncement__content">
                <p
                  dangerouslySetInnerHTML={{
                    __html: announcement.description,
                  }}
                ></p>
              </div>
            </div>
          ))}
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    announcements: state.announcements.announcements,
    newAnnouncement: state.createAnnouncment.announcement,
    deleted: state.deleteAnnouncement.announcement,
    updated: state.updateAnnouncement.announcement,
  };
};
export default connect(mapStateToProps, { fetchAllAnnouncements })(
  AnnouncementCard
);
