import React, { Fragment, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { updateRole as updateRoleAction } from "./../../../Redux/action/roles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  form: {
    background: "#696969",
  },
  paper: {
    width: "300px",
    padding: "5px",
    borderRadius: "0px",
    borderTopLeftRadius: "3px",
    borderBottomLeftRadius: "3px",
    background: "#9e9e9e",
    color: "#000",
  },
  button: {
    width: "150px",
    padding: "5px",
    borderRadius: "0px",
    borderTopRightRadius: "3px",
    borderBottomRightRadius: "3px",
    background: "#464852",
    color: "#fff",
  },
}));

const UpdateRole = ({ updateRoleAction, role, updateRole }) => {
  const classes = useStyles();
  const [roleName, setRoleName] = useState({
    role: role?.role,
  });
  const handleSubmit = async (event) => {
    event.preventDefault();

    updateRoleAction(roleName.role, role?.uuid);
    clear();
  };

  const clear = () => {
    setRoleName({
      role: "",
    });
  };

  return (
    <Fragment>
      <div className="create__role">
        <Paper
          component="form"
          className={classes.form}
          elevation={0}
          onSubmit={handleSubmit}
        >
          <Paper
            className={classes.paper}
            component="input"
            type="text"
            placeholder="Edit Role here...✍️"
            value={roleName.role}
            onChange={(event) =>
              setRoleName({
                ...roleName,
                role: event.target.value,
              })
            }
          />
          <Paper className={classes.button} component="button">
            UPDATE
          </Paper>
        </Paper>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    updateRole: state.updateRole.updateRole,
  };
};

export default connect(mapStateToProps, {
  updateRoleAction,
})(UpdateRole);
