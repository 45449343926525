import {
  GET_ADMIN_NOTIFICATIONS_START,
  GET_ADMIN_NOTIFICATIONS_SUCCESS,
  GET_ADMIN_NOTIFICATIONS_FAILURE,
  READ_ADMIN_NOTIFICATIONS_START,
  READ_ADMIN_NOTIFICATIONS_SUCCESS,
  READ_ADMIN_NOTIFICATIONS_FAILURE,
} from "./../action/actionTypes/types";
import * as api from "../../api/index";

export const getAllNotifications = () => async (dispatch) => {
  dispatch({
    type: GET_ADMIN_NOTIFICATIONS_START,
  });
  try {
    const { data } = await api.getNotifications();
    return dispatch({
      type: GET_ADMIN_NOTIFICATIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: GET_ADMIN_NOTIFICATIONS_FAILURE,
      payload: errorMessage,
    });
  }
};

export const readNotification = (notId) => async (dispatch) => {
  dispatch({
    type: READ_ADMIN_NOTIFICATIONS_START,
  });
  try {
    const { data } = await api.readNotification(notId);
    return dispatch({
      type: READ_ADMIN_NOTIFICATIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: READ_ADMIN_NOTIFICATIONS_FAILURE,
      payload: errorMessage,
    });
  }
};
