import React, { Fragment } from "react";
import "./Announcement.css";
import AnnouncementCard from "./../Announcement/AnnouncementCard";
import Footer from "./../../Footer/NewFooter";
import Contact from "../../../Components/ResponsiveNavBar/Contacts/Contacts";
import Navigation from "../../../Components/myNavbar/navbar";

const Announcement = () => {
  return (
    <Fragment>
      <Contact />
      <Navigation />
      <div className="announcement__wrapper">
        <div className="announcement__primary__heading">
          <h1>Announcements</h1>
        </div>
        <AnnouncementCard />
      </div>
      <Footer />
    </Fragment>
  );
};

export default Announcement;
