import * as type from "../action/actionTypes/types";
const initialState = {
  notifications: {
    loading: false,
    message: null,
    success: false,
    error: null,
  },
};

const adminNotificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.GET_ADMIN_NOTIFICATIONS_START:
      return {
        ...state,
        notifications: {
          loading: true,
          message: null,
          success: false,
          error: null,
        },
      };
    case type.GET_ADMIN_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: {
          loading: false,
          message: action.payload,
          success: true,
          error: null,
        },
      };
    case type.GET_ADMIN_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        notifications: {
          loading: false,
          message: null,
          success: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default adminNotificationsReducer;
