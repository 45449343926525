import {
  TAB_NUMBER_START,
  TAB_NUMBER_SUCCESS,
  TAB_NUMBER_FAILURE,
  GET_TAB_INDEX_START,
  GET_TAB_INDEX_SUCCESS,
  GET_TAB_INDEX_FAILURE,
} from "../action/actionTypes/types";

export const getTab = (TabNumber) => async (dispatch) => {
  dispatch({
    type: TAB_NUMBER_START,
  });
  try {
    return dispatch({
      type: TAB_NUMBER_SUCCESS,
      payload: TabNumber,
    });
  } catch (error) {
    return dispatch({
      type: TAB_NUMBER_FAILURE,
      payload: "Nav item not found",
    });
  }
};

export const getLayoutTabIndex = (TabNumber) => async (dispatch) => {
  dispatch({
    type: GET_TAB_INDEX_START,
  });
  try {
    return dispatch({
      type: GET_TAB_INDEX_SUCCESS,
      payload: TabNumber,
    });
  } catch (error) {
    return dispatch({
      type: GET_TAB_INDEX_FAILURE,
      payload: "Nav item not found",
    });
  }
};
