import React from "react";
import "./Task.css";

const OurUniqueness = () => {
  return (
    <div className="OurUniqueness-last">
      <div className="task-container">
      
          <blockquote>
            <p>
              The tasks expected of the aupair vary from family to family,
              depending on the age and number of children and the parents' work
              situation. For example, if the children are older and out of the
              house a lot, the aupair can of course be expected to do more
              housework than childcare, so that, for example, ironing the
              parents laundry can be added. Tasks that are expected from the
              aupair are discussed before the placement, but flexibility,
              helpfulness, and willingness to compromise are expected from the
              both parties.
            </p>
          </blockquote>
       
      </div>
    </div>
  );
};
export default OurUniqueness;
