import React, { useState, useEffect, Fragment } from "react";
import { Drawer } from "antd";
import "./Drawer.css";
import { connect } from "react-redux";
import { getUser } from "./../../../../../../Redux/action/staffMembers";
import Expectations from "./../Forms/AupairForms/Expectation";

const CustomDrawer = ({ aupair }) => {
  const [visible, setVisible] = useState(false);
  const user = JSON.parse(localStorage.getItem("profile")).data.user;

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (user?.uuid) {
      const fetchUser = () => {
        getUser(user?.uuid);
      };
      fetchUser();
    } else {
      return;
    }
  }, [user?.uuid]);

  return (
    <Fragment>
      <button className="actionButton" onClick={showDrawer}>
        EXPECTATIONS
      </button>
      <Drawer
        title={`EXPECTATIONS`}
        placement="right"
        onClose={onClose}
        visible={visible}
        width="80%"
        bodyStyle={{ backgroundColor: "#696969" }}
      >
        <Expectations
          expectationData={aupair?.message?.data?.user?.aupairexpectations}
        />
      </Drawer>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    aupair: state.getUser.user,
  };
};

export default connect(mapStateToProps, { getUser })(CustomDrawer);
