import React, { Fragment, useState, useEffect } from "react";
import Navigation from "./../../myNavbar/navbar";
import Footer from "./../../Footer/NewFooter";
import "./style.css";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { resetPassword } from "./../../../Redux/action/Auth";
import { connect } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";

const ResetPassword = ({ resetPassword, reset }) => {
  const [message, setMessage] = useState("");
  const [showPawword, setShowPassword] = useState(false);
  const [showConfirmPawword, setShowConfirmPassword] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleResetPassword = (event) => {
    event.preventDefault();
    setOpen(true);
    resetPassword(formData, window.location.href.split("/")[4]);
    clear();
  };

  const clear = () => {
    setFormData({
      password: "",
      confirmPassword: "",
    });
  };

  useEffect(() => {
    if (reset.success) {
      setOpen(false);
      setMessage(reset?.message?.message);
    } else if (!reset.success && reset.error !== null) {
      setOpen(false);
      setMessage("Something went wrong !");
    }
  }, [reset.success, reset.error, reset?.message?.message]);

  useEffect(() => {
    if (
      formData.password !== "" &&
      formData.confirmPassword !== "" &&
      formData.password === formData.confirmPassword
    ) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [formData.password, formData.confirmPassword]);

  useEffect(() => {
    const notify = () => {
      if (reset?.error && message) {
        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (reset?.success && message) {
        toast.success(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    if (reset?.success && message) {
      notify();
    } else if (!reset?.success && reset?.error !== null && message) {
      notify();
    } else {
      return;
    }
  }, [reset?.success, reset?.error, message]);

  return (
    <Fragment>
      <Navigation />
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <div className="resetPassword">
        <div className="resetpassword_form">
          {!reset.success ? (
            <form onSubmit={handleResetPassword}>
              <h3>Reset your password</h3>

              <div>
                <lable>
                  <strong>New Password</strong>
                </lable>
                <span className="showEyeIcon">
                  {showPawword ? (
                    <RemoveRedEyeIcon
                      onClick={() => setShowPassword((prev) => !prev)}
                    />
                  ) : (
                    <VisibilityOffIcon
                      onClick={() => setShowPassword((prev) => !prev)}
                    />
                  )}
                </span>
                <input
                  type={showPawword ? "text" : "password"}
                  placeholder="Enter a new password"
                  required
                  value={formData.password}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      password: event.target.value,
                    })
                  }
                />
              </div>
              <div>
                <lable>
                  <strong>Confirm New Password</strong>
                </lable>
                <span className="showEyeIconConfirm">
                  {showConfirmPawword ? (
                    <RemoveRedEyeIcon
                      onClick={() => setShowConfirmPassword((prev) => !prev)}
                    />
                  ) : (
                    <VisibilityOffIcon
                      onClick={() => setShowConfirmPassword((prev) => !prev)}
                    />
                  )}
                </span>
                <input
                  type={showConfirmPawword ? "text" : "password"}
                  placeholder="Confirm your new password"
                  required
                  value={formData.confirmPassword}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      confirmPassword: event.target.value,
                    })
                  }
                />
              </div>
              <div>
                <button
                  disabled={buttonDisable}
                  className={buttonDisable ? "saveDisabled" : "Savebtn"}
                  type="submit"
                >
                  Reset my password
                </button>
              </div>
            </form>
          ) : (
            <p style={{ color: "orange", textAlign: "center" }}>
              Your password has been reset successfully, you can now login with
              your new password
            </p>
          )}
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    reset: state.resetPassword.resetPassword,
  };
};

export default connect(mapStateToProps, { resetPassword })(ResetPassword);
