import * as type from "../action/actionTypes/types";

const initialState = {
  tab: {
    loading: false,
    index: 1,
    success: false,
    error: null,
  },
};

const TabsReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.TAB_NUMBER_START:
      return {
        ...state,
        tab: {
          loading: true,
          index: 1,
          success: false,
          error: null,
        },
      };
    case type.TAB_NUMBER_SUCCESS:
      return {
        ...state,
        tab: {
          loading: false,
          index: action.payload,
          success: true,
          error: null,
        },
      };
    case type.TAB_NUMBER_FAILURE:
      return {
        ...state,
        tab: {
          loading: false,
          index: null,
          success: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default TabsReducer;
