import React, { useState } from "react";
import { Modal } from "antd";
import EditTestmony from "./EditTestmony";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";

const initialValues = {
  photo: "",
  title: "",
  name: "",
  testmony: "",
};

const EditModal = ({ testmony }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);

  const showModal = () => {
    setIsModalVisible(true);
    setFormValues({
      photo: "",
      title: testmony?.jobTitle,
      name: testmony?.fullName,
      testmony: testmony?.testmony,
    });
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Tooltip title="Edit Testmony">
        <IconButton
          size="small"
          onClick={showModal}
          style={{ backgroundColor: "#3f3f3f" }}
        >
          <EditIcon style={{ color: "#FFA500", fontSize: "20px" }} />
        </IconButton>
      </Tooltip>
      <Modal
        title="EDIT TESTMONY"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        bodyStyle={{
          backgroundColor: "#464852",
        }}
        width="80%"
      >
        <EditTestmony
          testId={testmony?.uuid}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      </Modal>
    </>
  );
};

export default EditModal;
