import React from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useStyles } from "./style";
import { connect } from "react-redux";
import { hostProfile } from "../../../Redux/action/hostFroms";
import Grid from "@material-ui/core/Grid";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import "./HostFamilyHome.css";
import Navigation from "./../../Page/Landing/Navigation/Navigation";
import Contact from "./../../Page/Landing/Contacts/Contacts";
import HostHeader from "./HostFamilyHome";
import Footer from "../../Footer/NewFooter";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const religions = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Not",
    label: "Not",
  },
  {
    value: "Very",
    label: "Very",
  },
  {
    value: "Important",
    label: "Important",
  },
];
const workPreferences = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Country Side",
    label: "Country Side",
  },
  {
    value: "Village",
    label: "Village",
  },
  {
    value: "Town",
    label: "Town",
  },
  {
    value: "Big city",
    label: "Big city",
  },
  {
    value: "MetropoleRegion",
    label: "MetropoleRegion",
  },
  {
    value: "All possibilities",
    label: "All possibilities",
  },
];

const children = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];
const HostProfileForm = ({ formData, setFormData, navigation }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [childStatus, setChildStatus] = React.useState("No");

  const {
    fullName,
    partnerFullName,
    phone,
    email,
    birthDate,
    familyStatus,
    country,
    religion,
    religionImpt,
    havingChildren,
    stChildFullName,
    stChildAge,
    ndChildFullName,
    ndChildAge,
    rdChildFullName,
    rdChildAge,
    fothChildFullName,
    fothChildAge,
    fithChildFullName,
    fithChildAge,
    hobbies,
    routine,
    uniqueness,
    quality,
    weakness,
    specialNeed,
    street,
    postCode,
    houseNo,
    city,
    workPreference,
  } = formData;

  React.useEffect(() => {
    if (formData.havingChildren === "Yes") {
      setChildStatus(formData.havingChildren);
    } else {
      setChildStatus(formData.havingChildren);
    }
  }, [formData.havingChildren]);

  const Next = (event) => {
    event.preventDefault();
    setOpen(!open);
    setTimeout(() => {
      setOpen(false);
      navigation.next();
    }, 1000);
  };

  return (
    <React.Fragment>
      <Contact />
      <Navigation />
      <HostHeader />
      <CssBaseline />
      <div>
        <Backdrop
          style={{ zIndex: 1000, color: "#fff" }}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Container maxWidth="md" className="form_container">
        <form className={classes.root} noValidate autoComplete="off">
          <div className="personalInformationGroup">
            <h3 className="InnerTitles">Family and Contact information</h3>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Full Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    name="fullName"
                    value={fullName}
                    placeholder="Full Name"
                    onChange={setFormData}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Partner Full Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    name="partnerFullName"
                    value={partnerFullName}
                    placeholder="Partner Full Name"
                    onChange={setFormData}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Phone <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    name="phone"
                    value={phone}
                    placeholder="Phone"
                    onChange={setFormData}
                  />
                </div>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Email <span className="required">*</span>
                  </label>
                  <input
                    required
                    name="email"
                    type="email"
                    value={email}
                    placeholder="Ex: example@gmail.com"
                    onChange={setFormData}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Date Of Birth<span className="required">*</span>
                  </label>
                  <input
                    type="Date"
                    name="birthDate"
                    value={birthDate}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Family Status <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    name="familyStatus"
                    value={familyStatus}
                    placeholder="Family Status"
                    onChange={setFormData}
                  />
                </div>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Country <span className="required">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="country"
                    value={country}
                    placeholder="Country"
                    onChange={setFormData}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Religion <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    name="religion"
                    value={religion}
                    placeholder="Religion"
                    onChange={setFormData}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Is the religion important?
                    <span className="required">*</span>
                  </label>

                  <select
                    name="religionImpt"
                    value={religionImpt}
                    onChange={setFormData}
                  >
                    {religions.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Have Children <span className="required">*</span>
                  </label>
                  <select
                    name="havingChildren"
                    value={havingChildren}
                    onChange={setFormData}
                  >
                    {children.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              {childStatus === "Yes" && (
                <>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <div>
                      <label>
                        First Child Name <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        name="stChildFullName"
                        value={stChildFullName}
                        placeholder="First ChildName"
                        onChange={setFormData}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <div>
                      <label>
                        First Child Age <span className="required">*</span>
                      </label>
                      <input
                        type="number"
                        name="stChildAge"
                        value={stChildAge}
                        placeholder="Age"
                        onChange={setFormData}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <div>
                      <label>
                        Second Child Name <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        name="ndChildFullName"
                        value={ndChildFullName}
                        placeholder="Second Child Name"
                        onChange={setFormData}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <div>
                      <label>
                        Second Child Age <span className="required">*</span>
                      </label>
                      <input
                        type="number"
                        name="ndChildAge"
                        placeholder="Age"
                        value={ndChildAge}
                        onChange={setFormData}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <div>
                      <label>
                        Third Child Name <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Third Child Name"
                        name="rdChildFullName"
                        value={rdChildFullName}
                        onChange={setFormData}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <div>
                      <label>
                        Third Child Age <span className="required">*</span>
                      </label>
                      <input
                        type="number"
                        placeholder="Age"
                        name="rdChildAge"
                        value={rdChildAge}
                        onChange={setFormData}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <div>
                      <label>
                        Fourth Child Name <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        name="fothChildFullName"
                        placeholder="Fourth Child Name"
                        value={fothChildFullName}
                        onChange={setFormData}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <div>
                      <label>
                        Fourth Child Age <span className="required">*</span>
                      </label>
                      <input
                        type="number"
                        name="fothChildAge"
                        placeholder="Age"
                        value={fothChildAge}
                        onChange={setFormData}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <div>
                      <label>
                        Fifth Child Name <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        name="fithChildFullName"
                        placeholder="Fifth Child Name"
                        value={fithChildFullName}
                        onChange={setFormData}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <div>
                      <label>
                        Fifth Child Age <span className="required">*</span>
                      </label>
                      <input
                        type="number"
                        name="fithChildAge"
                        placeholder="Age"
                        value={fithChildAge}
                        onChange={setFormData}
                      />
                    </div>
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={4}>
                <div>
                  <label>
                    Hobbies <span className="required">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="hobbies"
                    value={hobbies}
                    placeholder="Hobbies"
                    onChange={setFormData}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div>
                  <label>
                    Your special routine
                    <span className="required">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="routine"
                    placeholder="Your special routine"
                    value={routine}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
            </div>
          </div>
          <div className="personalInformationGroup">
            <div className="FieldContainer">
              <Grid item xs={12} sm={4}>
                <div>
                  <label>
                    Uniqueness <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    name="uniqueness"
                    placeholder="Uniqueness"
                    value={uniqueness}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div>
                  <label>
                    Quality <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    name="quality"
                    placeholder="Quality"
                    value={quality}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div>
                  <label>
                    Weakness <span className="required">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="weakness"
                    placeholder="Weakness"
                    value={weakness}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4}>
                <div>
                  <label>
                    Special Need <span className="required">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="specialNeed"
                    placeholder="Special Need"
                    value={specialNeed}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div>
                  <label>
                    Street
                    <span className="required">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="street"
                    placeholder="Street"
                    value={street}
                    onChange={setFormData}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={4}>
                <div>
                  <label>
                    Post Code <span className="required">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="postCode"
                    placeholder="Post Code"
                    value={postCode}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4}>
                <div>
                  <label>
                    House Number <span className="required">*</span>
                  </label>

                  <input
                    required
                    name="houseNo"
                    placeholder="houseNo"
                    value={houseNo}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div>
                  <label>
                    City
                    <span className="required">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="city"
                    placeholder="City"
                    value={city}
                    onChange={setFormData}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={4}>
                <div>
                  <label>
                    Work preference <span className="required">*</span>
                  </label>
                  <select
                    name="workPreference"
                    value={workPreference}
                    onChange={setFormData}
                  >
                    {workPreferences.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            </div>
          </div>
          <button className="Continue" onClick={Next}>
            Next &nbsp; <ArrowForwardIcon />
          </button>
        </form>
      </Container>
      <Footer />
    </React.Fragment>
  );
};
const mapStateToProps = ({ hostProfileReducer }) => {
  const { profile } = hostProfileReducer;
  return { profile };
};
export default connect(mapStateToProps, { hostProfile })(HostProfileForm);
