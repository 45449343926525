import {
  HOST_PROFILE_START,
  HOST_PROFILE_SUCCESS,
  HOST_PROFILE_FAILURE,
  HOST_EXPECTATION_START,
  HOST_EXPECTATION_SUCCESS,
  HOST_EXPECTATION_FAILURE,
  HOST_OTHER_INFO_START,
  HOST_OTHER_INFO_SUCCESS,
  HOST_OTHER_INFO_FAILURE,
  HOST_WISHES_START,
  HOST_WISHES_SUCCESS,
  HOST_WISHES_FAILURE,
  HOST_CONTACTS_START,
  HOST_CONTACTS_SUCCESS,
  HOST_CONTACTS_FAILURE,
  HOST_LIVING_START,
  HOST_LIVING_SUCCESS,
  HOST_LIVING_FAILURE,
  HOST_PREFERENCES_START,
  HOST_PREFERENCES_SUCCESS,
  HOST_PREFERENCES_FAILURE,
  HOST_OFFER_START,
  HOST_OFFER_SUCCESS,
  HOST_OFFER_FAILURE,
  HOST_BACKGROUND_START,
  HOST_BACKGROUND_SUCCESS,
  HOST_BACKGROUND_FAILURE,
  GET_HOSTS_START,
  GET_HOSTS_SUCCESS,
  GET_HOSTS_FAILURE,
  UPDATE_HOST_PROFILE_START,
  UPDATE_HOST_PROFILE_SUCCESS,
  UPDATE_HOST_PROFILE_FAILURE,
  UPDATE_HOST_CONTACTS_START,
  UPDATE_HOST_CONTACTS_SUCCESS,
  UPDATE_HOST_CONTACTS_FAILURE,
  UPDATE_HOST_BACKGROUND_START,
  UPDATE_HOST_BACKGROUND_SUCCESS,
  UPDATE_HOST_BACKGROUND_FAILURE,
  UPDATE_HOST_LIVING_START,
  UPDATE_HOST_LIVING_SUCCESS,
  UPDATE_HOST_LIVING_FAILURE,
  UPDATE_HOST_PREFERENCES_START,
  UPDATE_HOST_PREFERENCES_SUCCESS,
  UPDATE_HOST_PREFERENCES_FAILURE,
  UPDATE_HOST_OTHER_INFO_START,
  UPDATE_HOST_OTHER_INFO_SUCCESS,
  UPDATE_HOST_OTHER_INFO_FAILURE,
  UPDATE_HOST_EXPECTATION_START,
  UPDATE_HOST_EXPECTATION_SUCCESS,
  UPDATE_HOST_EXPECTATION_FAILURE,
  UPDATE_HOST_OFFER_START,
  UPDATE_HOST_OFFER_SUCCESS,
  UPDATE_HOST_OFFER_FAILURE,
} from "./actionTypes/types";

import * as api from "../../api/index";
import { toast } from "react-toastify";

export const hostProfile = (formData) => async (dispatch) => {
  dispatch({ type: HOST_PROFILE_START });
  try {
    const { data } = await api.hostProfile(formData);
    if (data) {
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    return dispatch({
      type: HOST_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    toast.error("There was an Error", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return dispatch({
      type: HOST_PROFILE_FAILURE,
      payload: errorMessage,
    });
  }
};

export const hostContacts = (formData) => async (dispatch) => {
  dispatch({ type: HOST_CONTACTS_START });
  try {
    const { data } = await api.hostContacts(formData);
    if (data) {
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    return dispatch({
      type: HOST_CONTACTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    toast.error("There was an Error", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return dispatch({
      type: HOST_CONTACTS_FAILURE,
      payload: errorMessage,
    });
  }
};

export const hostLivingArea = (formData) => async (dispatch) => {
  dispatch({ type: HOST_LIVING_START });
  try {
    const { data } = await api.hostLivingArea(formData);
    if (data) {
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    return dispatch({
      type: HOST_LIVING_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    toast.error("There was an Error", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return dispatch({
      type: HOST_LIVING_FAILURE,
      payload: errorMessage,
    });
  }
};

export const hostPreferences = (formData) => async (dispatch) => {
  dispatch({ type: HOST_PREFERENCES_START });
  try {
    const { data } = await api.hostPreferences(formData);
    if (data) {
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    return dispatch({
      type: HOST_PREFERENCES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    toast.error("There was an Error", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return dispatch({
      type: HOST_PREFERENCES_FAILURE,
      payload: errorMessage,
    });
  }
};

export const hostExpectation = (formData) => async (dispatch) => {
  dispatch({ type: HOST_EXPECTATION_START });
  try {
    const { data } = await api.hostExpectation(formData);
    if (data) {
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    return dispatch({
      type: HOST_EXPECTATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    toast.error("There was an Error", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return dispatch({
      type: HOST_EXPECTATION_FAILURE,
      payload: errorMessage,
    });
  }
};

export const hostOtherInfo = (formData) => async (dispatch) => {
  dispatch({ type: HOST_OTHER_INFO_START });
  try {
    const { data } = await api.hostOtherInfo(formData);
    if (data) {
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    return dispatch({
      type: HOST_OTHER_INFO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    toast.error("There was an Error", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return dispatch({
      type: HOST_OTHER_INFO_FAILURE,
      payload: errorMessage,
    });
  }
};
export const hostOfferToAupair = (formData) => async (dispatch) => {
  dispatch({ type: HOST_OFFER_START });
  try {
    const { data } = await api.hostOffers(formData);
    if (data) {
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    return dispatch({
      type: HOST_OFFER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    toast.error("There was an Error", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return dispatch({
      type: HOST_OFFER_FAILURE,
      payload: errorMessage,
    });
  }
};

export const hostProfessionalBackground = (formData) => async (dispatch) => {
  dispatch({ type: HOST_BACKGROUND_START });
  try {
    const { data } = await api.professionalBackground(formData);
    if (data) {
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    return dispatch({
      type: HOST_BACKGROUND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    toast.error("There was an Error", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return dispatch({
      type: HOST_BACKGROUND_FAILURE,
      payload: errorMessage,
    });
  }
};

export const hostWishes = (formData) => async (dispatch) => {
  dispatch({ type: HOST_WISHES_START });
  try {
    const { data } = await api.hostWishesData(formData);

    return dispatch({
      type: HOST_WISHES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: HOST_WISHES_FAILURE,
      payload: errorMessage,
    });
  }
};

export const getAllHostFamilies = () => async (dispatch) => {
  dispatch({ type: GET_HOSTS_START });
  try {
    const { data } = await api.allHostFamilies();

    return dispatch({
      type: GET_HOSTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: GET_HOSTS_FAILURE,
      payload: errorMessage,
    });
  }
};

export const updateHostProfile = (formData, profId) => async (dispatch) => {
  dispatch({ type: UPDATE_HOST_PROFILE_START });
  try {
    const { data } = await api.updateHostProfile(formData, profId);
    if (data) {
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    return dispatch({
      type: UPDATE_HOST_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    toast.error("There was an Error", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return dispatch({
      type: UPDATE_HOST_PROFILE_FAILURE,
      payload: errorMessage,
    });
  }
};

export const updateHostContact = (formData, contId) => async (dispatch) => {
  dispatch({ type: UPDATE_HOST_CONTACTS_START });
  try {
    const { data } = await api.updateHostContact(formData, contId);
    if (data) {
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    return dispatch({
      type: UPDATE_HOST_CONTACTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    toast.error("There was an Error", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return dispatch({
      type: UPDATE_HOST_CONTACTS_FAILURE,
      payload: errorMessage,
    });
  }
};

export const updateHostBackground = (formData, backId) => async (dispatch) => {
  dispatch({ type: UPDATE_HOST_BACKGROUND_START });
  try {
    const { data } = await api.updateHostProfessionalBack(formData, backId);
    if (data) {
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    return dispatch({
      type: UPDATE_HOST_BACKGROUND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    toast.error("There was an Error", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return dispatch({
      type: UPDATE_HOST_BACKGROUND_FAILURE,
      payload: errorMessage,
    });
  }
};
export const updateHostLivingArea = (formData, livId) => async (dispatch) => {
  dispatch({ type: UPDATE_HOST_LIVING_START });
  try {
    const { data } = await api.updateHostLivingArea(formData, livId);
    if (data) {
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    return dispatch({
      type: UPDATE_HOST_LIVING_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    toast.error("There was an Error", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return dispatch({
      type: UPDATE_HOST_LIVING_FAILURE,
      payload: errorMessage,
    });
  }
};

export const updateHostPreferences = (formData, livId) => async (dispatch) => {
  dispatch({ type: UPDATE_HOST_PREFERENCES_START });
  try {
    const { data } = await api.updateHostPreferences(formData, livId);
    if (data) {
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    return dispatch({
      type: UPDATE_HOST_PREFERENCES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    toast.error("There was an Error", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return dispatch({
      type: UPDATE_HOST_PREFERENCES_FAILURE,
      payload: errorMessage,
    });
  }
};

export const UpdatehostOtherInfo = (formData, infoId) => async (dispatch) => {
  dispatch({ type: UPDATE_HOST_OTHER_INFO_START });
  try {
    const { data } = await api.updateHostOtherInfo(formData, infoId);
    if (data) {
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    return dispatch({
      type: UPDATE_HOST_OTHER_INFO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    toast.error("There was an Error", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return dispatch({
      type: UPDATE_HOST_OTHER_INFO_FAILURE,
      payload: errorMessage,
    });
  }
};

export const UpdatehostExpectations = (formData, expId) => async (dispatch) => {
  dispatch({ type: UPDATE_HOST_EXPECTATION_START });
  try {
    const { data } = await api.updateHostExpectations(formData, expId);
    if (data) {
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    return dispatch({
      type: UPDATE_HOST_EXPECTATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    toast.error("There was an Error", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return dispatch({
      type: UPDATE_HOST_EXPECTATION_FAILURE,
      payload: errorMessage,
    });
  }
};

export const updatehostOffer = (formData, offId) => async (dispatch) => {
  dispatch({ type: UPDATE_HOST_OFFER_START });
  try {
    const { data } = await api.updateHostOfferToAupair(formData, offId);
    if (data) {
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    return dispatch({
      type: UPDATE_HOST_OFFER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    toast.error("There was an Error", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return dispatch({
      type: UPDATE_HOST_OFFER_FAILURE,
      payload: errorMessage,
    });
  }
};
