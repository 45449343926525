import React, { Fragment, useState, useEffect } from "react";
import Navigation from "./../../myNavbar/navbar";
import Footer from "./../../Footer/NewFooter";
import "./styles.css";
import { forGotPassword } from "./../../../Redux/action/Auth";
import { connect } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";

const ForgotPassword = ({ forGotPassword, forgot }) => {
  const [open, setOpen] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [message, setMessage] = useState("");
  const [forgotMessage, setForgotMessage] = useState("");
  const [formData, setFormData] = useState({
    email: "",
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true);
    forGotPassword(formData);
    setFormData({
      email: "",
    });
    setTimeout(() => setButtonDisable(false), 30000);
    setSeconds(30);
  };

  const secondCounter = () => {
    if (buttonDisable) {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }
  };

  useEffect(() => {
    let myInterval = setInterval(() => {
      secondCounter();
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  useEffect(() => {
    if (formData.email !== "" && regex.test(formData.email)) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [formData.email, regex]);

  useEffect(() => {
    if (forgot.success) {
      setOpen(false);
      setForgotMessage(forgot?.message?.message);
    } else if (!forgot.success || forgot.error !== null) {
      setOpen(false);
      setForgotMessage("Some thing went wrong, try againn");
    }
  }, [forgot.success, forgot.error, forgot?.message?.message]);

  useEffect(() => {
    if (!regex.test(formData.email)) {
      setMessage("Enter a valid email address");
    } else {
      setMessage("correct ✔");
    }
  }, [regex, formData.email]);

  useEffect(() => {
    const notify = () => {
      if (forgot?.error && forgotMessage) {
        toast.error(forgotMessage, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (forgot?.success && forgotMessage) {
        toast.success(forgotMessage, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    if (forgot?.success && forgotMessage) {
      notify();
    } else if (!forgot?.success && forgot?.error !== null && forgotMessage) {
      notify();
    } else {
      return;
    }
  }, [forgot?.success, forgot?.error, forgotMessage]);

  return (
    <Fragment>
      <Navigation />
      <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="forgotPassword">
        <div className="forgotpassword_form">
          <form onSubmit={handleSubmit}>
            <h3>Did you forgot Your Password?</h3>
            <p>
              Enter your email address you're using for your account, then we
              will send you a password reset link &nbsp;
              {seconds === 0 && (
                <small style={{ color: "orange" }}>{message}</small>
              )}
            </p>
            <div>
              <input
                type="email"
                placeholder="Enter your email address"
                required
                autoComplete
                disabled={seconds !== 0}
                value={formData.email}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    email: event.target.value,
                  })
                }
              />
            </div>
            <div>
              {seconds === 0 && (
                <button
                  disabled={buttonDisable}
                  className={buttonDisable ? "saveDisabled" : "Savebtn"}
                  type="submit"
                >
                  Send me a reset link.
                </button>
              )}
            </div>
            <div>
              {seconds !== 0 && (
                <p>
                  You can resend email after&nbsp;
                  <strong style={{ color: "orange" }}>
                    {seconds} seconds
                  </strong>{" "}
                </p>
              )}
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    forgot: state.forgotPassword.forgotPassword,
  };
};

export default connect(mapStateToProps, { forGotPassword })(ForgotPassword);
