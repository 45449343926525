import React from "react";
import "./Contract.css";

const Page_two = () => {
  return (
    <div className="page">
      <h4 className="page__two__primary__heading">I. GENERAL CONDITIONS</h4>

      <main className="page__two__main_body">
        <small className="page__two__main_body_pagraph">
          The au pair shall be received by the host family for a period of
          ................ months/unspecified period 1(delete whichever does not
          apply) under the conditions laid down hereinafter. During this period,
          the au pair shall be given the opportunity to improve her/his
          education, in particular with regard to the
          ........................................ language, and to increase
          her/his general cultural development.
        </small>
        <small className="page__two__main_body_pagraph">
          The agreement shall take effect from
          ................................................................................
        </small>
        <h4 className="page__two__primary__heading">II. DUTIES OF THE HOST</h4>
        <small className="page__two__main_body_pagraph">
          II. 1. The host undertakes to receive the au pair into the family and
          to let her/him share the daily family life; in this connection, he
          makes the following declaration of which the au pair takes note:
        </small>
        <small className="page__two__main_body_pagraph">
          .That the family consists of ........................ including
          ................................ adults ..........................
          boys, aged of
          ….......................................................................……………
          .......................... girls, aged of
          .......................................................................……………….
        </small>
        <small className="page__two__main_body_pagraph">
          .That the family lives in a house/a flat (delete whichever does not
          apply) consisting of....... rooms including
          .....................bathroom(s), situated ..........................
          from a shopping centre, (distance) ........................ from an
          educational institution where appropriate courses in the
          ............................................... language are
          available.
        </small>
        <small className="page__two__main_body_pagraph">
          ∙ Occupation of the host
          ..............................................................................................
        </small>
        <small className="page__two__main_body_pagraph">
          . Occupation of the host's
          spouse....................................................................
        </small>
        <small className="page__two__main_body_pagraph">
          ∙ The host employs the following domestic staff in the home:
        </small>
        <small className="page__two__main_body_pagraph">
          ...................................................................................................................................
        </small>
        <small className="page__two__main_body_pagraph">
          ∙ The language normally spoken in the household is
          .......................................................
        </small>
        <small className="page__two__main_body_pagraph">
          II. 2. The host shall provide board and lodging for the au pair and
          shall place at her/his disposal a separate suitable room/a suitable
          room shared with ................................................
          (delete whichever does not apply)
        </small>
      </main>
    </div>
  );
};

export default Page_two;
