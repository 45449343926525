import React, { Fragment } from "react";

const Preference = ({ Data }) => {
  return (
    <Fragment>
      <div className="personal__data">
        <div className="personal__data__header">
          <h1>Family Preference</h1>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>I am searching for a host Aupair From:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.aupairFrom}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Our family is experienced with Aupair:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.experiencedHost}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Our family wish Aupair experienced with Children:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.experiencedaupair}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Our family Living Model:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.familyLivingModel}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>We live with pets animals:</strong>
          </div>
          <div className="personal__list__item__value">
            <div className="personal__list__item__value">
              <label>{Data?.liveWithPets}</label>
            </div>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Smoking Host Family:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.smokinghostfamily}</label>
          </div>
        </div>

        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>We are a family with children with special needs:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.specialNeeds}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Our family has children from:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{`${Data?.ChildrenFrom} To ${Data?.toChildrenNumber}`}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Children with age from:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{`${Data?.agedfrom} To ${Data?.agedto}`}</label>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Preference;
