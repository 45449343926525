import React from "react";
import "./Faq.css";

const faqAkagera = () => {
  return (
    <div className="faquestion">
      <div className="faquestion__container">
        <div className="faquestion__item">
          <span className="faq-akagera-item-question1">
            Who do I contact if I have problems with the Host Family?{" "}
          </span>
          <p>
            Akagera & Rhein® Aupair Service works with selected and trustworthy
            partner agencies in every host country. The partner agencies are the
            interface between the Aupairs and the Host Families. You get the
            contact details of our partner and a contact person after the
            matching succeed. If you have any problems with the Host Family, or
            another problem you can contact this contact person at any time.
            Akagera & Rhein® Aupair Service will also stay in contact with you
            after you leave the country.
          </p>
        </div>

        <div className="faquestion__item">
          <span className="faq-akagera-item-question2">
            When should I apply?
          </span>
          <p>
            Ideally, your application should be received thought this online
            platform by activating your profile 3 to 4 months before the desired
            departure. During the whole year.
          </p>
        </div>

        <div className="faquestion__item">
          <span className="faq-akagera-item-question3">
            How long should an au pair stay last?
          </span>
          <p>
            The minimum stay in all host countries is 6 months. Unfortunately,
            we cannot arrange stays of less than 6 months. You have the best
            placement chances if you can stay in your Host Family for a whole
            year.
          </p>
        </div>

        <div className="faquestion__item">
          <span className="faq-akagera-item-question4">
            {" "}
            How expensive is a language course?
          </span>
          <p>
            Unfortunately, we cannot answer that across the board. The cost of
            the language course depends on many factors, for example the host
            country, the type of language school you have, the type and level of
            your course, the duration of the language course and the number of
            hours you want.
          </p>
        </div>

        <div className="faquestion__item">
          <span className="faq-akagera-item-question5">
            {" "}
            Do you also accept male applicants?{" "}
          </span>
          <p>
            Of cause! Placement as an Aupair is generally possible for boys and
            girls. However, the placement of male applicants is more difficult,
            as most families want a female Aupair for their children. If you
            would like to apply as a male applicant, we look forward to
            receiving your application and will try to find you successfully.
            However, we cannot promise a placement and ask you to submit your
            application to us well in advance of your desired departure so that
            we have enough time to search.{" "}
          </p>
        </div>
        <div className="faquestion__item">
          <span className="faq-akagera-item-question6">
            {" "}
            Do I have to be a non-smoker?{" "}
          </span>
          <p>
            No. However, very many families do not accept a smoking Aupair. As a
            smoker, you will in most cases at least have to undertake to never
            smoke in the presence of the children. We strongly recommend that
            you quit smoking before your stay.{" "}
          </p>
        </div>

        {/* <div className="faquestion__more">Read more</div>
        <div className="faquestion__aupair__hostfamily">
          <div className="faquestion__aupair--btn">
            <span>Aupar Asked Questions</span>
          </div>

          <div className="faquestion__histfamily--btn">
            <span>Host Family Asked Questions</span>
          </div>
          </div>*/}
      </div>
    </div>
  );
};
export default faqAkagera;
