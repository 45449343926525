import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import "./matchStyles.css";
import MatchingPreview from "./matchDetails";
import { connect } from "react-redux";
import { findMatching } from "./../../../../../../../../Redux/action/matches";
import { useSelector } from "react-redux";

const MatchingList = ({ findMatching, matching, userTransactions }) => {
  const [slicingNumber, setSlicingNumber] = useState(0);

  const { user } = useSelector((state) => state.getUser);

  useEffect(() => {
    const fetchMatches = () => {
      findMatching(user?.message?.data?.user?.uuid);
    };
    fetchMatches();
  }, [findMatching, user?.message?.data?.user?.uuid]);

  useEffect(() => {
    if (
      JSON.parse(userTransactions)?.subscription?.planId?.split("_")[0] ===
      "basic"
    ) {
      setSlicingNumber(1);
    } else if (
      JSON.parse(userTransactions)?.subscription?.planId?.split("_")[0] ===
      "standard"
    ) {
      setSlicingNumber(3);
    } else if (
      JSON.parse(userTransactions)?.subscription?.planId?.split("_")[0] ===
      "glod"
    ) {
      setSlicingNumber(10);
    }
  }, [userTransactions]);

  return (
    <List
      sx={{
        width: "100%",
        background: "#9e9e9e",
        borderRadius: "2px",
        color: "#000",
      }}
    >
      {JSON.parse(userTransactions)?.subscription?.planId?.split("_")[0] ===
      "premium"
        ? matching?.message?.data?.matchingUsers?.map((person) => (
            <ListItem
              alignItems="flex-start"
              className="matchingList"
              key={person?.uuid}
            >
              <ListItemAvatar>
                <Avatar
                  sx={{ width: 50, height: 50, border: "3px solid #464852" }}
                  alt="Remy Sharp"
                  src={person?.profilePicture}
                />
              </ListItemAvatar>
              <ListItemText
                primary={`${person?.firstName} ${person?.lastName}`}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {person?.email}
                    </Typography>
                  </React.Fragment>
                }
              />
              <MatchingPreview match={person} />
            </ListItem>
          ))
        : matching?.message?.data?.matchingUsers
            ?.slice(0, slicingNumber)
            .map((person) => (
              <ListItem
                alignItems="flex-start"
                className="matchingList"
                key={person?.uuid}
              >
                <ListItemAvatar>
                  <Avatar
                    sx={{ width: 50, height: 50, border: "3px solid #464852" }}
                    alt="Remy Sharp"
                    src={person?.profilePicture}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={`${person?.firstName} ${person?.lastName}`}
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {person?.email}
                      </Typography>
                    </React.Fragment>
                  }
                />
                <MatchingPreview match={person} />
              </ListItem>
            ))}
    </List>
  );
};

const mapStateToProps = (state) => {
  return {
    matching: state.matching.matches,
  };
};

export default connect(mapStateToProps, { findMatching })(MatchingList);
