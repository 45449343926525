import React, { Fragment } from "react";
import { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import { Link } from "react-router-dom";
import "./slider.css";

const LandingSlideShow = () => {
  return (
    <Fragment>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        autoplay={true}
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="landing__slide__container">
            <div className="landing__slide__content__container">
              <p>
                <span className="slide_quotes">&#8220;</span>Some years from now
                you may be more disappointed by the things you didn’t accomplish
                than by the ones you did do. So, get out of your comfort zone.
                Learn to communicate differently. Make the stranger your
                familiar. Discover another culture by serving and learning.
              </p>
              <div className="landing__action_buttons">
                <button className="das_aupairbtn landing__action_buttons_aupair">
                  <Link to="/aupair"> REGISTER AS AUPAIR</Link>
                </button>

                <button className=" das_aupairbtn landing__action_buttons_host">
                  <Link to="/families">REGISTER AS HOST FAMILY</Link>
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="landing__slide__container">
            <div className="landing__slide__content__container">
              <p>
                <span className="slide_quotes">&#8220;</span>You will not be the
                same, having seen the stars shine on the other side of world.
                Cohabits with the starts in this unknown galaxy and leaves orbit
                without losing direction but culturally and intellectually
                enriched. One day your life will flash before your eyes. Make
                sure it’s worth watching it.
              </p>
              <div className="landing__action_buttons">
                <button className="das_aupairbtn landing__action_buttons_aupair">
                  <Link to="/aupair"> REGISTER AS AUPAIR</Link>
                </button>

                <button className=" das_aupairbtn landing__action_buttons_host">
                  <Link to="/families">REGISTER AS HOST FAMILY</Link>
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="landing__slide__container">
            <div className="landing__slide__content__container">
              <p>
                <span className="slide_quotes">&#8220;</span>You will never be
                completely home again. You're leaving without really giving up
                your loved ones. You move away to get closer, which fascinates
                you more. The part of your mind will be sometime elsewhere. That
                is the price you pay for the richness of knowing and loving
                people in more than one place.
              </p>
              <div className="landing__action_buttons">
                <button className="das_aupairbtn landing__action_buttons_aupair">
                  <Link to="/aupair"> REGISTER AS AUPAIR</Link>
                </button>

                <button className=" das_aupairbtn landing__action_buttons_host">
                  <Link to="/families">REGISTER AS HOST FAMILY</Link>
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="landing__slide__container">
            <div className="landing__slide__content__container">
              <p>
                <span className="slide_quotes">&#8220;</span>Take a child by the
                hand and let yourself be guided by him. Look at the stones that
                it picks up and listen to what it tells you. As a reward, it
                will show you a world that you have already forgotten. Immerse
                yourself in your childhood to remember that you have grown so
                much now.
              </p>
              <div className="landing__action_buttons">
                <button className="das_aupairbtn landing__action_buttons_aupair">
                  <Link to="/aupair"> REGISTER AS AUPAIR</Link>
                </button>

                <button className=" das_aupairbtn landing__action_buttons_host">
                  <Link to="/families">REGISTER AS HOST FAMILY</Link>
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="landing__slide__container">
            <div className="landing__slide__content__container">
              <p>
                <span className="slide_quotes">&#8220;</span>Hospitality means
                primarily the creation of free space where the stranger can
                enter and become a friend instead of an enemy. Hospitality is
                not to change people, but to offer them space where change can
                take place. Henri J. M. Nouwen
              </p>
              <div className="landing__action_buttons">
                <button className="das_aupairbtn landing__action_buttons_aupair">
                  <Link to="/aupair"> REGISTER AS AUPAIR</Link>
                </button>

                <button className=" das_aupairbtn landing__action_buttons_host">
                  <Link to="/families">REGISTER AS HOST FAMILY</Link>
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </Fragment>
  );
};

export default LandingSlideShow;
