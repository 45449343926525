import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  document: {
    message: null,
    success: false,
    error: null,
  },
};

export const documentSlice = createSlice({
  name: "aupairDocument",
  initialState: initialState,
  reducers: {
    createDocument: (state, action) => {
      return {
        ...state,
        document: {
          message: action.payload,
          success: true,
          error: null,
        },
      };
    },
    fetchDocument: (state, action) => {
      return {
        ...state,
        document: {
          message: action.payload,
          success: true,
          error: null,
        },
      };
    },

    updateDocument: (state, action) => {
      return {
        ...state,
        document: {
          message: action.payload,
          success: true,
          error: null,
        },
      };
    },

    onError: (state, action) => {
      return {
        ...state,
        document: {
          message: null,
          success: false,
          error: action.payload,
        },
      };
    },
  },
});

export const { createDocument, fetchDocument, updateDocument, onError } =
  documentSlice.actions;
export default documentSlice.reducer;
