import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useStyles } from "./style";
import Grid from "@material-ui/core/Grid";
import "./preferences.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  hostPreferences,
  updateHostPreferences,
} from "./../../../../Redux/action/hostFroms";
import { Link } from "react-router-dom";
import Layout from "./../../../Layouts/hostFamily/Layout";
import { connect } from "react-redux";
import { getUser } from "./../../../../Redux/action/staffMembers";

const initialValues = {
  aupairFrom: "",
  experiencedHost: "",
  experiencedaupair: "",
  familyLivingModel: "",
  liveWithPets: "",
  smokinghostfamily: "",
  specialNeeds: "",
  ChildrenFrom: "",
  toChildrenNumber: "",
  agedfrom: "",
  agedto: "",
};

const FamilyPreference = ({
  hostPreferences,
  updateHostPreferences,
  getUser,
  host,
  preferences,
  updatedPreferences,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [createMode, setCreateMode] = useState(true);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [disabled, setDisabled] = useState(false);

  const user = JSON.parse(localStorage.getItem("profile"))?.data?.user;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
    console.log(formValues);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));
    if (Object.keys(formErrors).length === 0) {
      if (createMode) {
        setOpen(true);
        hostPreferences(formValues);
        clear();
      } else {
        setOpen(true);
        updateHostPreferences(
          formValues,
          host?.message?.data?.user?.hostPreferences?.uuid
        );
      }
    }
  };

  const clear = () => {
    setFormValues(initialValues);
  };

  useEffect(() => {
    const fetchUser = () => {
      getUser(user?.uuid);
    };
    if (user?.uuid || preferences?.success || updatedPreferences?.success) {
      fetchUser();
    } else {
      return;
    }
  }, [user?.uuid, getUser, preferences?.success, updatedPreferences?.success]);

  useEffect(() => {
    if (preferences?.success) {
      setOpen(false);
    } else if (!preferences?.success || preferences?.error !== null) {
      setOpen(false);
    }
  }, [preferences?.success, preferences?.error]);

  useEffect(() => {
    if (updatedPreferences?.success) {
      setOpen(false);
    } else if (
      !updatedPreferences?.success ||
      updatedPreferences?.error !== null
    ) {
      setOpen(false);
    }
  }, [updatedPreferences?.success, updatedPreferences?.error]);

  const validate = (values) => {
    const errors = {};

    if (!values.aupairFrom) {
      errors.aupairFrom = "This field is required!";
    }
    if (!values.experiencedHost) {
      errors.experiencedHost = "This field is required!";
    }
    if (!values.experiencedaupair) {
      errors.experiencedaupair = "This field is required!";
    }
    if (!values.familyLivingModel) {
      errors.familyLivingModel = "This field is required!";
    }
    if (!values.liveWithPets) {
      errors.liveWithPets = "This field is required!";
    }
    if (!values.smokinghostfamily) {
      errors.smokinghostfamily = "This field is required!";
    }

    if (!values.specialNeeds) {
      errors.specialNeeds = "This field is required!";
    }
    if (!values.ChildrenFrom) {
      errors.ChildrenFrom = "This field is required!";
    }
    if (!values.toChildrenNumber) {
      errors.toChildrenNumber = "This field is required!";
    }
    if (!values.agedfrom) {
      errors.agedfrom = "This field is required!";
    }
    if (!values.agedto) {
      errors.agedto = "This field is required!";
    }

    return errors;
  };

  useEffect(() => {
    if (host?.message?.data?.user?.hostPreferences) {
      setFormValues({
        aupairFrom: host?.message?.data?.user?.hostPreferences?.aupairFrom,
        experiencedHost:
          host?.message?.data?.user?.hostPreferences?.experiencedHost,
        experiencedaupair:
          host?.message?.data?.user?.hostPreferences?.experiencedaupair,
        familyLivingModel:
          host?.message?.data?.user?.hostPreferences?.familyLivingModel,
        liveWithPets: host?.message?.data?.user?.hostPreferences?.liveWithPets,
        smokinghostfamily:
          host?.message?.data?.user?.hostPreferences?.smokinghostfamily,
        specialNeeds: host?.message?.data?.user?.hostPreferences?.specialNeeds,
        ChildrenFrom: host?.message?.data?.user?.hostPreferences?.ChildrenFrom,
        toChildrenNumber:
          host?.message?.data?.user?.hostPreferences?.toChildrenNumber,
        agedfrom: host?.message?.data?.user?.hostPreferences?.agedfrom,
        agedto: host?.message?.data?.user?.hostPreferences?.agedto,
      });
      setCreateMode(false);
    } else if (!host?.message?.data?.user?.hostPreferences) {
      setCreateMode(true);
      setFormValues(initialValues);
    }
  }, [host]);

  useEffect(() => {
    if (
      formValues.aupairFrom === 0 ||
      formValues.experiencedHost === "" ||
      formValues.experiencedaupair === "" ||
      formValues.familyLivingModel === "" ||
      formValues.liveWithPets === "" ||
      formValues.smokinghostfamily === "" ||
      formValues.specialNeeds === "" ||
      formValues.ChildrenFrom === "" ||
      formValues.toChildrenNumber === "" ||
      formValues.agedfrom === "" ||
      formValues.agedto === ""
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formValues]);

  return (
    <Layout>
      <CssBaseline />
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Container maxWidth="md" className="form_container">
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="personalInformationGroup">
            <h3 className="InnerTitles">OUR AUPAIR PREFERENCE</h3>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    I'm searching an Aupair From
                    <span className="required">*</span>
                  </label>
                  <select
                    name="aupairFrom"
                    value={formValues.aupairFrom}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Uganda">Uganda</option>
                    <option value="DRCongo">DRCongo</option>
                    <option value="Tanzania">Tanzania</option>
                    <option value="No preference">No preference</option>
                  </select>
                </div>
                <small className="formError">{formErrors.aupairFrom}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Our family is experienced with Aupair.
                    <span className="required">*</span>
                  </label>
                  <select
                    name="experiencedHost"
                    value={formValues.experiencedHost}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Not Necessary">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">
                  {formErrors.experiencedHost}
                </small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Aupair with children experienced.
                    <span className="required">*</span>
                  </label>
                  <select
                    name="experiencedaupair"
                    value={formValues.experiencedaupair}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Not Necessary">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">
                  {formErrors.experiencedaupair}
                </small>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Our Family Living Model.
                    <span className="required">*</span>
                  </label>
                  <select
                    name="familyLivingModel"
                    value={formValues.familyLivingModel}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Classic Couple">Classic Couple</option>
                    <option value="A Single Mother">A Single Mother</option>
                    <option value="Single Father">Single Father</option>
                    <option value="Divers Family">Divers Family</option>
                    <option value="Other Family constellation">
                      Other Family constellation
                    </option>
                  </select>
                </div>
                <small className="formError">
                  {formErrors.familyLivingModel}
                </small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    We live with pets animals
                    <span className="required">*</span>
                  </label>

                  <select
                    name="liveWithPets"
                    value={formValues.liveWithPets}
                    onChange={handleChange}
                  >
                    <option value="">--select---</option>
                    <option value="Yes">Yes</option>
                    <option value="Never">Never</option>
                    <option value="Not Given">Not Given</option>
                  </select>
                </div>
                <small className="formError">{formErrors.liveWithPets}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Smoking Host family <span className="required">*</span>
                  </label>
                  <select
                    name="smokinghostfamily"
                    value={formValues.smokinghostfamily}
                    onChange={handleChange}
                  >
                    <option value="">---select---</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Yes Not At Home">Yes Not At Home</option>
                  </select>
                </div>
                <small className="formError">
                  {formErrors.smokinghostfamily}
                </small>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Children with special needs
                    <span className="required">*</span>
                  </label>
                  <select
                    name="specialNeeds"
                    value={formValues.specialNeeds}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="Never">Never</option>
                    <option value="Maybe">Maybe</option>
                  </select>
                </div>
                <small className="formError">{formErrors.specialNeeds}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Our Family Has Children From
                    <span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    min="1"
                    name="ChildrenFrom"
                    placeholder="Ex:1"
                    value={formValues.ChildrenFrom}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.ChildrenFrom}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    To<span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    min="1"
                    name="toChildrenNumber"
                    placeholder="Ex:5"
                    value={formValues.toChildrenNumber}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">
                  {formErrors.toChildrenNumber}
                </small>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Children with age From <span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    min="1"
                    name="agedfrom"
                    placeholder="Ex:1"
                    value={formValues.agedfrom}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.agedfrom}</small>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    To<span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    min="1"
                    name="agedto"
                    placeholder="Ex:10"
                    value={formValues.agedto}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.agedto}</small>
              </Grid>
            </div>
            <div className="user_action_buttons">
              <button
                className={disabled ? "disabledButton" : "submit__btn"}
                type="submit"
                disabled={disabled}
              >
                {createMode ? "Save" : "Update"}
              </button>
              <button className="next__btn" type="button">
                <Link to="/host/livingarea"> &larr; Prev</Link>
              </button>
              <button className="next__btn" type="button">
                <Link to="/host/aboutaupair"> Next &rarr;</Link>
              </button>
            </div>
          </div>
        </form>
      </Container>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    host: state.getUser.user,
    preferences: state.hostPreferences.preferences,
    updatedPreferences: state.updateHostPreferences.hostPreferences,
  };
};

export default connect(mapStateToProps, {
  hostPreferences,
  updateHostPreferences,
  getUser,
})(FamilyPreference);
