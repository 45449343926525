import React from "react";

const Page_five = () => {
  return (
    <div className="page">
      <main className="page__five__main__body">
        <h4 className="page__five__primary__heading">
          IV. MISCELLANEOUS PROVISIONS
        </h4>
        <small className="page__five__main_body_pagraph">
          IV. 1. If the agreement has been concluded for an unspecified period,
          it may be terminated by either party with two weeks' notice.
        </small>
        <small className="page__five__main_body_pagraph">
          Regardless of whether the period concerned is specified or not in the
          event of serious misconduct by one of the parties, the other party may
          immediately terminate the agreement. Either of the parties may also
          terminate the agreement with immediate effect if serious circumstances
          make such instant termination necessary.
        </small>
        <small className="page__five__main_body_pagraph">
          IV. 2. The parties also agree on the following
        </small>
        <small className="page__five__main_body_pagraph">
          ..............................................................................................................................................................
        </small>
        <small className="page__five__main_body_pagraph">
          This agreement has been drawn up in three copies:
        </small>
        <span className="page__five__main_body_copies">
          - one to be retained by the host,
        </span>
        <span className="page__five__main_body_copies">
          - one to be retained by the au pair,
        </span>
        <span className="page__five__main_body_copies last-copy">
          - one to be deposited
          with........................................................................................
        </span>
        <small className="page__five__main_body_pagraph">
          <strong>1</strong> If the au pair is a minor, a further copy is to be
          handed over to her/his legal representative
        </small>
        <small className="page__five__main_body_right">Done at , on</small>
        <small className="page__five__main_body_pagraph-signature">
          Signature of the au pair Signature of the host
        </small>
        <small className="page__five__main_body_pagraph">
          (if the au pair is a minor, signature of her/his legal representative)
        </small>
        <small className="page__five__main_body_right stamp">
          Stamp of the competent authority or the body designated by it
        </small>
        <small className="page__five__main_body_pagraph">
          <strong>1</strong>The name of the national competent authority or of
          the public body appointed by such authority or the private body
          authorised to deal with au pair placement.
        </small>
      </main>
    </div>
  );
};

export default Page_five;
