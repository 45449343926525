import React,{useEffect} from "react";
import GetPage from "../../Components/Page/GetInvolved/GetInvolved";
import GetFont from "../../Components/Page/GetInvolved/InvolvedFront/InvolvedFront";
import Footer from "../../Components/Footer/NewFooter";
import Contact from "../../Components/ResponsiveNavBar/Contacts/Contacts";
import Navigation from "../../Components/myNavbar/navbar";

const GetInvolved = () => {

  useEffect(()=>{
    if(typeof window !== "undefined"){
      window.scrollTo(0,0)
    }
  },[])

  return (
    <React.Fragment>
      <Contact />
      <Navigation />
      <GetFont />
      <GetPage />
      <Footer />
    </React.Fragment>
  );
};

export default GetInvolved;
