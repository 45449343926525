import React from "react";
import "./Duties.css";

const Duties = () => {
  return (
    <div className="duties">
        <h1>Aupair duties </h1>

      <div className="duties-container">
        <div className="duties-item--1">
          <div className="duties-item--1-panel">
            <h2>
              As an aupair, your most important task is taking care of the
              children. Depending on their age, the childcare responsibilities
              may include:
            </h2>
          </div>
        </div>

        <div
          whileHover={{ scale: 1, rotate: -5, backgroundColor: "#000" }}
          className="duties-item--2"
        >
          <ul>
            <li> Get up with the kids </li>
            <li>Wash and dress children </li>
            <li>Make breakfast for the kids</li>
            <li>Bring the children to kindergarten / school </li>
            <li>Pick up the children from kindergarten / school </li>
            <li> Accompany the children to leisure activities </li>
            <li>Playing with the children</li>
            <li> Do homework with the kids </li>
            <li>Prepare food for the children </li>
            <li>Read to the children</li>
            <li>Make the children's beds</li>
            <li>Tidy up the children's room </li>
            <li>Put the kids to bed </li>
            <li>Put the children's laundry in the cupboards</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Duties;
