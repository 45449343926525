import axios from "axios";

const API = axios.create({
  // baseURL: "http://localhost:8000/api/v1",
  baseURL: "https://das-aupair-new-app-4481db6b895a.herokuapp.com/api/v1",
});

API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("profile"))?.token
    }`;
  }

  return req;
});

//AUTH

export const signUp = (formData) => API.post("/users/register", formData);
export const signIn = (formData) => API.post("/users/login", formData);
export const allAupairsData = () => API.get("/users/aupairs");
export const allHostFamilies = () => API.get("/users/hostfamilies");
export const loadStaffMembers = () => API.get("/users/staff");
export const addStaffMembers = (formDta) =>
  API.post("/users/register", formDta);
export const getUser = (userId) => API.get(`/users/${userId}`);
export const updateProfile = (formDta, userId) =>
  API.patch(`/users/${userId}`, formDta);

export const updateStaffMembers = (formDta, userId) =>
  API.patch(`/users/staff/${userId}`, formDta);

export const deleteUser = (userId) => API.delete(`/users/${userId}`);
export const forgotPassword = (formData) =>
  API.put("/users/forgotpassword", formData);

export const resetPassword = (formData, token) =>
  API.put(`users/resetpassword/${token}`, formData);

export const Approve = (userId) => API.put(`/users/approve/${userId}`, userId);
export const Disapprove = (userId) =>
  API.put(`/users/disapprove/${userId}`, userId);
export const Hold = (userId) => API.put(`/users/hold/${userId}`, userId);
export const UnHold = (userId) => API.put(`/users/unHold/${userId}`, userId);
export const Matched = (userId) => API.put(`/users/matched/${userId}`, userId);
export const rejectUser = (userId) =>
  API.put(`/users/reject/${userId}`, userId);

export const unrejectUser = (userId) =>
  API.put(`/users/unreject/${userId}`, userId);

export const updateProfilePicture = (picture, userId) =>
  API.patch(`/users/profilepicture/${userId}`, picture);

//AUPAIR

export const aupairProfileData = (formData) =>
  API.post("/aupair/profiles", formData);
export const getAupairProfile = (userId) =>
  API.get(`/aupair/profiles/${userId}`);

export const aupairExpectation = (formData) =>
  API.post("/aupair/expectations", formData);
export const aupairContactsData = (formData) =>
  API.post("/aupair/contacts", formData);
export const aupairEducationData = (formData) =>
  API.post("/aupair/educations", formData);
export const aupairOtherInfo = (formData) =>
  API.post("/aupair/otherinfo", formData);
export const aupairPreferences = (formData) =>
  API.post("/aupair/familypreferences", formData);
export const aupairWishesData = (formData) =>
  API.post("/aupair/wishes", formData);

export const updateAupairProfile = (formData, profileId) =>
  API.patch(`/aupair/profiles/${profileId}`, formData);

export const updateAupairContacts = (formData, contactId) =>
  API.patch(`/aupair/contacts/${contactId}`, formData);

export const updateAupairEducation = (formData, educationId) =>
  API.patch(`/aupair/educations/${educationId}`, formData);

export const updateAupairMoreInfo = (formData, infoId) =>
  API.patch(`/aupair/otherinfo/${infoId}`, formData);

export const updateAupairPreferences = (formData, prefId) =>
  API.patch(`/aupair/familypreferences/${prefId}`, formData);

export const updateAupairExpectations = (formData, expId) =>
  API.patch(`/aupair/expectations/${expId}`, formData);

export const updateAupairWishes = (formData, wishId) =>
  API.patch(`/aupair/wishes/${wishId}`, formData);

//HOST FAMILY

export const hostProfile = (formData) => API.post("/host/profiles", formData);
export const hostContacts = (formData) => API.post("/host/contacts", formData);
export const hostLivingArea = (formData) =>
  API.post("/host/livingareas", formData);
export const hostPreferences = (formData) =>
  API.post("/host/preferences", formData);
export const hostExpectation = (formData) =>
  API.post("/host/expectations", formData);
export const hostOtherInfo = (formData) =>
  API.post("/host/moreinformation ", formData);
export const hostOffers = (formData) =>
  API.post("/host/offertoaupairs ", formData);
export const professionalBackground = (formData) =>
  API.post("/host/professionalbackgrounds ", formData);
export const hostWishesData = (formData) =>
  API.post("/hostfamwishies ", formData);

export const updateHostProfile = (formData, profId) =>
  API.patch(`/host/profiles/${profId}`, formData);

export const updateHostContact = (formData, contId) =>
  API.patch(`/host/contacts/${contId}`, formData);

export const updateHostProfessionalBack = (formData, backId) =>
  API.patch(`/host/professionalbackgrounds/${backId}`, formData);

export const updateHostLivingArea = (formData, livId) =>
  API.patch(`/host/livingareas/${livId}`, formData);

export const updateHostPreferences = (formData, prefId) =>
  API.patch(`/host/preferences/${prefId}`, formData);

export const updateHostOtherInfo = (formData, infoId) =>
  API.patch(`/host/moreinformation/${infoId}`, formData);

export const updateHostExpectations = (formData, expId) =>
  API.patch(`/host/expectations/${expId}`, formData);

export const updateHostOfferToAupair = (formData, offId) =>
  API.patch(`/host/offertoaupairs/${offId}`, formData);

//MESSAGES

export const contactData = (formData) => API.post("/messages", formData);
export const getMessages = () => API.get("/messages");
export const deleteMessage = (msgId) => API.delete(`/messages/${msgId}`);

// ROLES

export const fetchAllRoles = () => API.get("/roles");
export const createRole = (formData) => API.post("/roles", formData);
export const updateRole = (formData, roleId) =>
  API.patch(`/roles/${roleId}`, { role: formData });
export const getRole = (uuid) => API.get(`/roles/${uuid}`);
export const deleteRole = (uuid) => API.delete(`/roles/${uuid}`);

// BLOG CATEGORY

export const fetchAllCategory = () => API.get("/blog/categories");
export const createCategory = (formData) =>
  API.post("/blog/categories", formData);
export const getCategory = (catId) => API.get(`/blog/categories/${catId}`);
export const updateCategory = (formData, catId) =>
  API.patch(`/blog/categories/${catId}`, formData);
export const deleteCategory = (catId) =>
  API.delete(`/blog/categories/${catId}`);

// BLOG

export const fetchAllBlog = () => API.get("/blogs");
export const fetchAllBlogForAdmin = () => API.get("/blogs/admin/blogs");
export const createBlog = (formData) => API.post("/blogs", formData);
export const getBlog = (blogId) => API.get(`/blogs/${blogId}`);
export const updateBlog = (formData, blogId) =>
  API.patch(`/blogs/${blogId}`, formData);
export const publishBlog = (blogId) => API.patch(`/blogs/publish/${blogId}`);
export const deleteBlog = (blogId) => API.delete(`/blogs/${blogId}`);

// ANNOUNCEMENTS

export const getAnnouncements = () => API.get("/announcements");
export const deleteAnnouncement = (anId) =>
  API.delete(`/announcements/${anId}`);
export const createAnnouncement = (formData) =>
  API.post("/announcements", formData);
export const getAnnouncement = (anId) => API.get(`/announcements/${anId}`);
export const updateAnnouncement = (formData, anId) =>
  API.patch(`/announcements/${anId}`, formData);

// TESTMONILAS

export const getTestmonials = () => API.get("/testmonials");
export const deleteTestmony = (testId) => API.delete(`/testmonials/${testId}`);
export const createTestmony = (formData) => API.post("/testmonials", formData);
export const getTestmony = (testId) => API.get(`/testmonials/${testId}`);
export const updateTestmony = (formData, testId) =>
  API.patch(`/testmonials/${testId}`, formData);

// AUPAIR GALLERY

export const addGallery = (photo) => API.post("/aupair/galleries", photo);
export const getAupairPhotos = () => API.get("/aupair/galleries");
export const deleteAupairPhoto = (photoId) =>
  API.delete(`/aupair/galleries/${photoId}`);
export const getAupairPhoto = (photoId) =>
  API.get(`/aupair/galleries/${photoId}`);
export const updateAupairPhoto = (photo, photoId) =>
  API.patch(`/aupair/galleries/${photoId}`, photo);

export const rejectAupairPhoto = (photoId) =>
  API.put(`/aupair/galleries/${photoId}`);

export const restoreAupairPhoto = (photoId) =>
  API.put(`/aupair/galleries/restore/${photoId}`);

// AUPAIR DOCUMENTS

export const addAupairDocument = (formData) =>
  API.post("/aupair/documents", formData);
export const getAllAupairDocumentS = () => API.get("/aupair/documents");
export const getAupairDocument = (docId) =>
  API.get(`/aupair/documents/${docId}`);
export const updateAupairDocument = (formData, docId) =>
  API.patch(`/aupair/documents/${docId}`, formData);
export const deleteAupairDocument = (docId) =>
  API.delete(`/aupair/documents/${docId}`);

export const rejectAupairDocument = (docId) =>
  API.put(`/aupair/documents/${docId}`);

export const unRejectAupairDocument = (docId) =>
  API.put(`/aupair/documents/unreject/${docId}`);
// HOST GALLERY

export const addHostGallery = (photo) => API.post("/host/galleries", photo);
export const getHostPhotos = () => API.get("/host/galleries");
export const getHostPhoto = (photoId) => API.get(`/host/galleries/${photoId}`);
export const deleteHostPhoto = (photoId) =>
  API.delete(`/host/galleries/${photoId}`);
export const updateHostPhoto = (photo, photoId) =>
  API.patch(`/host/galleries/${photoId}`, photo);
export const rejectHostPhoto = (photoId) =>
  API.put(`/host/galleries/${photoId}`);

export const restoreHostPhoto = (photoId) =>
  API.put(`/host/galleries/restore/${photoId}`);

// HOST DOCUMENTS

export const addHostDocument = (formData) =>
  API.post("/host/documents", formData);
export const getAllHostDocumentS = () => API.get("/host/documents");
export const getHostDocument = (docId) => API.get(`/host/documents/${docId}`);
export const updateHostDocument = (formData, docId) =>
  API.patch(`/host/documents/${docId}`, formData);
export const deleteHostDocument = (docId) =>
  API.delete(`/host/documents/${docId}`);

export const rejectHostDocument = (docId) =>
  API.put(`/host/documents/${docId}`);

// SUBSCRIBERS

export const subscribe = (formData) => API.post("/subscribers", formData);
export const fetchSubscribers = () => API.get("/subscribers");
export const deleteSubscriber = (subId) => API.delete(`/subscribers/${subId}`);

// NOTIFICATIONS

export const getNotifications = () => API.get("/adminNotifications");
export const readNotification = (notId) => API.patch(`/${notId}`);

// MATCHING

export const createMatch = (matchData) => API.post("/matches", matchData);
export const getMatches = (userId) => API.get(`/matches/matching/${userId}`);
export const allConnections = () => API.get("/matches");
export const approveMatching = (matchId) => API.patch(`/matches/${matchId}`);

export const getAllPayments = () =>
  API.get("/payments/subscriptions/AllPayments");
