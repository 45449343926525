import React, { useRef, Fragment, useState, useEffect } from "react";
import ReactToPrint from "react-to-print";
import { AupairDetails } from "./AupairDetails";
import LocalPrintshopIcon from "@material-ui/icons/LocalPrintshop";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { getUser } from "./../../../Redux/action/staffMembers";

const PrintAupairDetalis = ({ userId, user, getUser }) => {
  const componentRef = useRef();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchUser = () => {
      getUser(userId);
    };
    fetchUser();
  }, [userId, getUser]);

  useEffect(() => {
    if (!user?.success) {
      setOpen(true);
    } else if (user?.success) {
      setOpen(false);
    } else if (!user?.success && user?.error !== null) {
      setOpen(false);
    }
  }, [user?.success, user?.error]);

  return (
    <Fragment>
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <div>
        <ReactToPrint
          trigger={() => (
            <Tooltip title="Print This Document" arrow>
              <IconButton style={{ float: "right", color: "#000" }}>
                <LocalPrintshopIcon />
              </IconButton>
            </Tooltip>
          )}
          content={() => componentRef.current}
        />
        <AupairDetails ref={componentRef} user={user} />
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.getUser.user,
  };
};

export default connect(mapStateToProps, { getUser })(PrintAupairDetalis);
