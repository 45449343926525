import React, { useState, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { useStyles } from "./style";
import Backdrop from "@material-ui/core/Backdrop";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./registration.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { registerUser, login } from "./../../Redux/action/Auth";
import { toast } from "react-toastify";

const initialValues = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  role: "",
  password: "",
  confimPassword: "",
};

const Auth = ({ isSignup, setIsSignUp }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [showPassword, setPassword] = useState(false);
  const [message, setMessage] = useState("");

  const { signup } = useSelector((state) => state.signup);
  const { signin } = useSelector((state) => state.login);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));

    if (formValues && Object.keys(formErrors).length === 0 && isSignup) {
      if (!signup.success) {
        setOpen(true);
      }
      dispatch(registerUser(formValues, navigate));
      clear();
    } else if (
      formValues.email !== "" &&
      formValues.password !== "" &&
      !isSignup
    ) {
      if (!signin.success) {
        setOpen(true);
      }
      dispatch(login(formValues, navigate));
      clear();
    }
  };

  const clear = () => {
    setFormValues(initialValues);
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.firstName) {
      errors.firstName = "First Name Please!";
    }
    if (!values.lastName) {
      errors.lastName = "last Name Please!";
    }
    if (!values.phone) {
      errors.phone = "Your Phone Please!";
    }
    if (!values.email) {
      errors.email = "Your Email Please!";
    } else if (!regex.test(values.email)) {
      errors.email = "this is not a valid email address";
    }

    if (!values.role) {
      errors.role = "Role is required!";
    }
    if (!values.password) {
      errors.password = "Password is required!";
    } else if (values.password.length < 8) {
      errors.password = "password must be 8 characters";
    }

    if (!values.confimPassword) {
      errors.confimPassword = "This field is required!";
    } else if (values.confimPassword !== values.password) {
      errors.confimPassword = "Password not matching!";
    }

    return errors;
  };
  const switchAuth = () => {
    setIsSignUp((prev) => !prev);
  };

  useEffect(() => {
    if (signup.success) {
      setOpen(false);
      setMessage(signup?.message?.message);
    } else if (!signup.success && signup.error !== null) {
      setOpen(false);

      setMessage(signup?.success);
    }
  }, [signup?.success, signup?.message?.message, signup?.error, navigate]);

  useEffect(() => {
    if (signin.success) {
      setOpen(false);

      setMessage(signin?.message?.message);
    }
  }, [signin?.success, signin?.message?.message]);

  useEffect(() => {
    if (!signin.success && signin.error !== null) {
      setOpen(false);

      setMessage(signin?.error);
    }
  }, [signin?.success, signin?.error]);

  const handleToggle = () => {
    setPassword((prev) => !prev);
  };

  useEffect(() => {
    const notify = () => {
      if ((signin?.error || signup?.error) && message) {
        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if ((signin?.success || signup?.success) && message) {
        toast.success(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };

    if ((signin?.success || signup?.success) && message) {
      notify();
    } else if (!signin?.success && signin?.error !== null && message) {
      notify();
    } else if (!signup?.success && signup?.error !== null && message) {
      notify();
    } else {
      return;
    }
  }, [signin?.success, signup?.success, message, signin?.error, signup?.error]);

  return (
    <React.Fragment>
      <CssBaseline />
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <div className="registration__container">
        <Container component="main" maxWidth="sm">
          <div className="paper">
            <form className="form" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                {isSignup && (
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div>
                      <label>
                        FirstName <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        placeholder="First Name"
                        value={formValues.firstName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <small className="formError">{formErrors.firstName}</small>
                  </Grid>
                )}
                {isSignup && (
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div>
                      <label>
                        Last Name <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        placeholder="Last Name"
                        value={formValues.lastName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <small className="formError">{formErrors.lastName}</small>
                  </Grid>
                )}

                {isSignup && (
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div>
                      <label>
                        Phone <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        placeholder="Phone"
                        value={formValues.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <small className="formError">{formErrors.phone}</small>
                  </Grid>
                )}
                {isSignup && (
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div>
                      <label>
                        Role <span className="required">*</span>
                      </label>
                      <select
                        id="role"
                        name="role"
                        value={formValues.role}
                        onChange={handleChange}
                      >
                        <option value="">---select---</option>
                        <option value="aupair">aupair</option>
                        <option value="hostfamily">hostfamily</option>
                      </select>
                    </div>
                    <small className="formError">{formErrors.role}</small>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div>
                    <label>
                      Email <span className="required">*</span>
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email Address"
                      value={formValues.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <small className="formError">{formErrors.email}</small>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="password">
                    <label>
                      Password <span className="required">*</span>
                    </label>
                    <span>
                      {showPassword ? (
                        <RemoveRedEyeIcon
                          onClick={handleToggle}
                          className="passwordeyeIcon"
                        />
                      ) : (
                        <VisibilityOffIcon
                          onClick={handleToggle}
                          className="passwordeyeIcon"
                        />
                      )}
                    </span>
                    <input
                      required
                      name="password"
                      type={showPassword ? "text" : "password"}
                      id="password"
                      placeholder="password"
                      value={formValues.password}
                      onChange={handleChange}
                    />
                  </div>
                  <small className="formError">{formErrors.password}</small>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  {!isSignup && <Link href="/forgot">Forgot Password?</Link>}
                </Grid>

                <Grid item xs={6}>
                  <button
                    type="submit"
                    className={classes.submit}
                    disabled={open}
                  >
                    {!isSignup ? "Login" : "Sign up"}
                  </button>
                </Grid>
              </Grid>
              {isSignup && (
                <Grid container>
                  <Grid item xs={12}>
                    <div>
                      <p>
                        By signing up, you agree to the
                        <Link href="/terms">Terms & conditions</Link> of
                        services and <Link href="/privacy">Privacy Policy</Link>
                        , including
                        <Link href="/cookies">Cookie Use</Link>.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              )}
              <Grid container>
                <Grid item>
                  <Link href="#" variant="body2" onClick={switchAuth}>
                    {!isSignup
                      ? "Don't have an Account? Sign up"
                      : "Already have an account? Sign in"}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Auth;
