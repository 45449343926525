import "./dashboard.css";
import React from "react";
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";

const data = [
  {
    month: "Jan",
    HostFamilies: 1000,
  },
  {
    month: "Feb",
    HostFamilies: 1400,
  },
  {
    month: "Marc",
    HostFamilies: 500,
  },
  {
    month: "Apir",
    HostFamilies: 250,
  },
  {
    month: "May",
    HostFamilies: 3000,
  },
  {
    month: "June",
    HostFamilies: 4500,
  },
  {
    month: "Jul",
    HostFamilies: 7000,
  },
  {
    month: "Aug",
    HostFamilies: 4000,
  },
  {
    month: "Sept",
    HostFamilies: 3500,
  },
  {
    month: "Oct",
    HostFamilies: 2000,
  },
  {
    month: "Nov",
    HostFamilies: 3500,
  },
  {
    month: "Dec",
    HostFamilies: 7000,
  },
];
export default function HostFamilies() {
  return (
    <div style={{ width: "100%", height: 300 }}>
      <ResponsiveContainer>
        <ComposedChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <XAxis dataKey="month" scale="band" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Area
            type="monotone"
            dataKey="HostFamilies"
            fill="#8884d8"
            stroke="#8884d8"
          />
          <Bar dataKey="HostFamilies" barSize={20} fill="#ffff" />
          <Line type="monotone" dataKey="HostFamilies" stroke="#ff7300" />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}
