import React, { useState, useEffect, Fragment } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { updateCategory } from "./../../../Redux/action/blog";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  form: {
    background: "#696969",
  },
  paper: {
    width: "320px",
    padding: "5px",
    borderRadius: "0px",
    borderTopLeftRadius: "3px",
    borderBottomLeftRadius: "3px",
    background: "#9e9e9e",
    color: "#000",
  },
  button: {
    width: "150px",
    padding: "5px",
    borderRadius: "0px",
    borderTopRightRadius: "3px",
    borderBottomRightRadius: "3px",
    background: "#464852",
    color: "#fff",
  },
}));

const EditCategory = ({
  updateCategory,
  updatedCategory,
  category,
  formData,
  setFormData,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true);
    updateCategory(formData, category.uuid);
    clear();
  };

  const clear = () => {
    setFormData({
      categoryName: "",
    });
  };

  useEffect(() => {
    if (updatedCategory.success) {
      setOpen(false);
    }
  }, [updatedCategory.success]);

  useEffect(() => {
    if (!updatedCategory.success && updatedCategory.error !== null) {
      setOpen(false);
    }
  }, [updatedCategory.success, updatedCategory.error]);

  return (
    <Fragment>
      <div>
        <Backdrop style={{ zIndex: 1000, color: "#fff" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <div className="create__role">
        <Paper
          component="form"
          className={classes.form}
          elevation={0}
          onSubmit={handleSubmit}
        >
          <Paper
            className={classes.paper}
            component="input"
            type="text"
            placeholder="Create or Edit Category here...✍️"
            value={formData.categoryName}
            onChange={(event) =>
              setFormData({
                ...formData,
                categoryName: event.target.value,
              })
            }
          />
          <Paper className={classes.button} component="button">
            Update
          </Paper>
        </Paper>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    updatedCategory: state.updateCategory.category,
  };
};

export default connect(mapStateToProps, { updateCategory })(EditCategory);
