import {
  CONTACT_START,
  CONTACT_SUCCESS,
  CONTACT_FAILURE,
} from "../action/actionTypes/types";

import * as api from "../../api/index";

export const ContactUs = (formData) => async (dispatch) => {
  dispatch({
    type: CONTACT_START,
  });
  try {
    const { data } = await api.contactData(formData);
    return dispatch({
      type: CONTACT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: CONTACT_FAILURE,
      payload: errorMessage,
    });
  }
};
