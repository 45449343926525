import React, { useState, useEffect, Fragment } from "react";
import { updateUser } from "./../.../../../../../../../../Redux/action/staffMembers";
import { connect, useSelector } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";

const RegistrationForm = ({ updateUser, updatedProfile }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const { user } = useSelector((state) => state.getUser);

  const [formData, setFormData] = useState({
    firstName: user?.message?.data?.user?.firstName,
    lastName: user?.message?.data?.user?.lastName,
    email: user?.message?.data?.user?.email,
    phone: user?.message?.data?.user?.phone,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true);
    updateUser(formData, user?.message?.data?.user?.uuid);
  };
  useEffect(() => {
    if (updatedProfile.success) {
      setOpen(false);
      setMessage(updatedProfile?.message?.message);
    } else if (!updatedProfile.success && updatedProfile.error !== null) {
      setOpen(false);
      setMessage("There was an error, try again later");
    }
  }, [
    updatedProfile.success,
    updatedProfile.error,
    updatedProfile?.message?.message,
  ]);

  useEffect(() => {
    const notify = () => {
      if (updatedProfile?.error && message) {
        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (updatedProfile?.success && message) {
        toast.success(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    if (updatedProfile?.success && message) {
      notify();
    } else if (
      !updatedProfile?.success ||
      (updatedProfile?.error !== null && message)
    ) {
      notify();
    } else {
      return;
    }
  }, [updatedProfile?.success, updatedProfile?.error, message]);

  return (
    <Fragment>
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <div className="account__info container">
        <form className="account__info__form" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="fullname">First Name</label>
            <input
              type="text"
              id="firstname"
              value={formData.firstName}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  firstName: event.target.value,
                })
              }
            />
          </div>
          <div>
            <label htmlFor="lastname">Last Name</label>
            <input
              type="text"
              id="lastname"
              value={formData.lastName}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  lastName: event.target.value,
                })
              }
            />
          </div>
          <div>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              disabled
              value={formData.email}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  email: event.target.value,
                })
              }
            />
          </div>
          <div>
            <label htmlFor="phone"></label>
            <input
              type="Number"
              id="phone"
              value={formData.phone}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  phone: event.target.value,
                })
              }
            />
          </div>
          <div>
            <button className="update__btn" type="submit">
              UPDATE
            </button>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    updatedProfile: state.updateProfile.updatedProfile,
  };
};
export default connect(mapStateToProps, { updateUser })(RegistrationForm);
