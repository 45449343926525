import React, { useState, useEffect, Fragment } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { fetchAllRoles } from "./../../../Redux/action/roles";
import { registerStaffMembers } from "./../../../Redux/action/staffMembers";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  form: {
    padding: "20px",
    background: "#464852",
  },
  paper: {
    display: "block",
    width: "100%",
    padding: "5px",
    marginBottom: "5px",
    borderRadius: "3px",
    background: "#9e9e9e",
    color: "#000",
  },
  button: {
    display: "block",
    width: "150px",
    padding: "5px",
    borderRadius: "0px",
    borderTopRightRadius: "3px",
    borderBottomRightRadius: "3px",
    background: "#3f3f3f",
    color: "#fff",
    marginTop: "10px",
  },
}));

const CreateUser = ({
  fetchAllRoles,
  roles,
  registerStaffMembers,
  register,
  getUser,
  user,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
    role: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!register.success) {
      setOpen(true);
    }
    registerStaffMembers(formData);
    clear();
  };

  const clear = () => {
    setFormData({
      fullName: "",
      phone: "",
      email: "",
      password: "",
      confirmPassword: "",
      role: "",
    });
  };

  useEffect(() => {
    const loadAllRoles = () => {
      fetchAllRoles();
    };
    loadAllRoles();
  }, [fetchAllRoles]);

  useEffect(() => {
    if (register.success) {
      setOpen(false);
    }
  }, [register.success]);

  return (
    <Fragment>
      <div>
        <Backdrop style={{ zIndex: 1000, color: "#fff" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <div className="create__user">
        <Paper
          component="form"
          className={classes.form}
          elevation={0}
          onSubmit={handleSubmit}
        >
          <Paper
            className={classes.paper}
            component="input"
            type="text"
            required
            placeholder="Full Name"
            value={formData.fullName}
            onChange={(event) =>
              setFormData({
                ...formData,
                fullName: event.target.value,
              })
            }
          />
          <Paper
            className={classes.paper}
            component="input"
            type="text"
            placeholder="phone"
            required
            value={formData.phone}
            onChange={(event) =>
              setFormData({
                ...formData,
                phone: event.target.value,
              })
            }
          />
          <Paper
            className={classes.paper}
            component="input"
            type="password"
            required
            placeholder="password"
            value={formData.password}
            onChange={(event) =>
              setFormData({
                ...formData,
                password: event.target.value,
              })
            }
          />
          <Paper
            className={classes.paper}
            component="input"
            type="password"
            required
            placeholder="confirm password"
            value={formData.confirmPassword}
            onChange={(event) =>
              setFormData({
                ...formData,
                confirmPassword: event.target.value,
              })
            }
          />
          <Paper
            className={classes.paper}
            component="input"
            type="email"
            required
            placeholder="Email"
            value={formData.email}
            onChange={(event) =>
              setFormData({
                ...formData,
                email: event.target.value,
              })
            }
          />
          <Paper
            className={classes.paper}
            component="select"
            value={formData.role}
            onChange={(event) =>
              setFormData({
                ...formData,
                role: event.target.value,
              })
            }
          >
            <option value={null}>assign role</option>
            {roles?.message?.data?.roles?.map((role) => (
              <option value={role.role} key={role.uuid}>
                {role.role}
              </option>
            ))}
          </Paper>
          <Paper className={classes.button} component="button">
            Create User
          </Paper>
        </Paper>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    roles: state.Allroles.roles,
    register: state.register.register,
  };
};

export default connect(mapStateToProps, {
  fetchAllRoles,
  registerStaffMembers,
})(CreateUser);
