import React from "react";
import "./About.css";
import Grid from "@material-ui/core/Grid";
import AboutSvg from "./../../../../assets/aboutus.png";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import VolumeDownIcon from "@material-ui/icons/VolumeDown";
import * as Speech from "./../Speeches/SpeechText";

const About = () => {
  const msg = new SpeechSynthesisUtterance();
  const speechHandler = () => {
    msg.text = Speech.AboutTextToSpeech;
    window.speechSynthesis.speak(msg);
  };

  return (
    <React.Fragment>
      <div className="about__aupair ">
        <div className="about__aupair__content">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} xlg={12}>
              <Zoom>
                <h1 className="about__aupair__primary__heading">ABOUT US</h1>
              </Zoom>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xlg={8}>
              <Fade left>
                <div className="description">
                  Litsen
                  <VolumeDownIcon
                    style={{ cursor: "pointer" }}
                    onClick={speechHandler}
                  />
                  <p>
                    Das Aupair Matching is a product of Akagera Rhein Center.
                    Das Aupair Matching was created in 2022 by the idea of
                    former aupairs and some host families wanting to create
                    something different and exceptional to make the aupair
                    epxerience more creative, accessible and easy. The core is
                    to put in place a dynamic and experienced intermediary to
                    facilitate contact between the two sides de- siring one or
                    the other. The accompaniment of the candidates consists of
                    the intercultural trainings, to provide the information and
                    the documentations and impeccable service. This platform is
                    a cost-effective service for aupairs and host families with
                    the aim of offering the quickest possible placement of
                    aupairs and host families, through direct communication with
                    registered users who want to get in touch one to each other.
                    Das Aupair Matching searches,selects, finds, and trains the
                    candidates.We provide the information about the aupair
                    epxerience and support the candidates before, during and
                    after the placement has taken place.
                  </p>
                </div>
              </Fade>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xlg={4}>
              <Fade right>
                <div className="about__aupair__image">
                  <img src={AboutSvg} alt="get to know us" />
                </div>
              </Fade>
            </Grid>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
};
export default About;
