import React, { Fragment, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import "./Blog.css";
import { useNavigate } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { fetchBlogs } from "./../../../Redux/action/blog";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "#f8f9fa",
    borderRadius: "10px",
    padding: "10px",
  },
  blogTitle: {
    textAlign: "center",
    fontSize: "20px",
    fontWeight: "bold",
  },
  paper: {
    padding: theme.spacing(2),
    background: "green",
  },
}));

const Tranding = ({ fetchBlogs, blogs }) => {
  const classes = useStyles();

  const navigate = useNavigate();

  const handleNavigation = (blogId) => {
    navigate(`/singleblog/${blogId}`);
  };

  useEffect(() => {
    const fetchData = () => {
      fetchBlogs();
    };
    fetchData();
  }, [fetchBlogs]);

  return (
    <Fragment>
      <div className={classes.root}>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          autoplay={true}
          modules={[Pagination, Navigation, Autoplay]}
          className="mySwiper"
        >
          {blogs?.message?.data?.blogs
            ?.filter((blog) => blog.trending === true)
            .map((blog) => (
              <SwiperSlide key={blog.uuid}>
                <Grid container spacing={3}>
                  <Fragment>
                    <Grid item xs={12} sm={12} md={6} lg={6} xlg={6}>
                      <Fade left>
                        <div className="trendingBlogImage">
                          <img
                            onClick={() => handleNavigation(blog.uuid)}
                            className="blog__img"
                            src={blog.picture}
                            alt="rhein"
                          />
                        </div>
                      </Fade>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xlg={6}>
                      <Fade right>
                        <div className="blog__category">
                          <span className="blog__category__name">
                            {blog.category}
                          </span>
                          <span className="blog__category__separator"></span>
                          <span>{blog.createdAt.split("T")[0]}</span>
                          <small
                            style={{
                              color: "orange",
                              marginLeft: "200px",
                              fontWeight: "bold",
                            }}
                          >
                            New trending
                          </small>
                        </div>
                        <h2
                          className="blog__title"
                          onClick={() => handleNavigation(blog.uuid)}
                        >
                          {blog.title}{" "}
                        </h2>
                        <p
                          className="blog__description"
                          dangerouslySetInnerHTML={{
                            __html: blog.blogText.substr(0, 200) + " ...",
                          }}
                        />
                        <button
                          className="readmoreBtn"
                          onClick={() => handleNavigation(blog.uuid)}
                        >
                          Read more &rarr;
                        </button>
                        <div className="author">
                          <div>
                            <span className="blog__author__name">
                              Das Aupair Matching
                            </span>
                            <span className="blog__author__jobtitle">
                              We do it for you.
                            </span>
                          </div>
                        </div>
                      </Fade>
                    </Grid>
                  </Fragment>
                </Grid>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    blogs: state.allBlogs.blogs,
  };
};

export default connect(mapStateToProps, { fetchBlogs })(Tranding);
