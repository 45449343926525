import React, { Fragment } from "react";
import "./Contact.css";
import GetInTouch from "./GetInTouch/GetInTouch";

const ContactAkagera = () => {
  return (
    <Fragment>
      <div className="contact-akagera">
        <div className="contact-akagera-item">
          <GetInTouch />
        </div>
        <div className="contact-akagera-item-map">
          <h2>Information</h2>
          <h3 style={{ fontSize: "18px" }}>E-mail</h3>
          <p style={{ fontSize: "16px", color: "#555" }}>
            <a
              href={`mailto:contact@das-aupair-matching.de`}
              className="email__me"
            >
              Click to 👉Inbox Us
            </a>
          </p>
          <h3 style={{ fontSize: "18px" }}>Phone</h3>
          <p style={{ fontSize: "16px", color: "#555" }}>+250790139823</p>
          <h3 style={{ fontSize: "18px" }}>Address</h3>
          <p style={{ fontSize: "16px", color: "#555" }}>
            KG 1 St.,12 Kigali-Rwanda
          </p>
        </div>
      </div>
      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4864.98957272453!2d30.126898315268193!3d-1.9422556372425186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19dca7090591cf37%3A0x29887a20969ca80!2sDie%20Sprache%20Hub!5e1!3m2!1sen!2srw!4v1661274124285!5m2!1sen!2srw"
          style={{ width: "100%", height: "600px", border: "0px" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          title="Aupair"
        ></iframe>
      </div>
    </Fragment>
  );
};
export default ContactAkagera;
