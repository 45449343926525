import React from "react";
import "./Contract.css";

const Page_one = () => {
  return (
    <div className="page">
      <header className="page__header">
        <h4 className="page__header-title">Model Text</h4>
        <p className="page_header-dateofapproval">
          approved by the Committee of Ministers of the Council of Europe on 18
          January 1972
        </p>
        <h4 className="page__header-agreement">AGREEMENT</h4>
        <p className="page_header_agreement-description">
          relating to an “aupair” Placement subject to the European Agreement on
          “aupair” Placement of 24 November 1969
        </p>
      </header>
      <main className="page_mainBody">
        <small className="page_mainBody_pagraph">
          This agreement relating to an “au pair” placement is concluded
          between:
        </small>
        <small className="page_mainBody_pagraph">
          - (Mr) or (Ms)
          ...........................................................................................
          (name)
        </small>
        <small className="page_mainBody_pagraph">
          - Place of residence
          ..............................................................................................
        </small>
        <small className="page_mainBody_pagraph">
          hereinafter referred to as "<strong>the host</strong>" and
        </small>
        <small className="page_mainBody_pagraph">
          -...................................................................................................
          (name)
        </small>
        <small className="page_mainBody_pagraph">
          hereinafter referred to as "<strong>the au pair</strong>"
        </small>
        <small className="page_mainBody_pagraph">
          -...................................................................................
          (name)
        </small>
        <small className="page_mainBody_pagraph">
          - born on 1
          .........................................................................................................
        </small>
        <small className="page_mainBody_pagraph">
          - at
          ...................................................................................................
        </small>
        <small className="page_mainBody_pagraph">
          - nationality
          ......................................................................................................
        </small>
        <small className="page_mainBody_pagraph">
          - place of residence 2
          .............................................................................
        </small>
        <small className="page_mainBody_pagraph">
          - and (if the au pair is still a minor) her/his legal representative:
        </small>
        <small className="page_mainBody_pagraph">
          .................................................................................................................
        </small>

        <article className="papge_mainBody_article">
          <small className="papge_mainBody_article_content">
            <strong>1.</strong> According to Article 4 of the European Agreement
            on “au pair” Placement, the au pair shall not be less than 17 or
            more than 30 years of age. Nevertheless, exceptions to the upper
            age-limit may be granted by the competent authority of the receiving
            country in individual cases when justified
          </small>
          <small className="papge_mainBody_article_content">
            <strong>2.</strong> If the place of residence differs from the place
            of permanent residence, please state both addresses.
          </small>
        </article>
      </main>
    </div>
  );
};

export default Page_one;
