import { Modal } from "antd";
import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@mui/material/Typography";

const MatchModal = ({ connection }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Tooltip title="Invitee Info" arrow>
        <IconButton
          color="default"
          component="span"
          size="small"
          onClick={showModal}
          style={{
            backgroundColor: "#3f3f3f",
          }}
        >
          <InfoIcon style={{ color: "#FFA500", fontSize: "20px" }} />
        </IconButton>
      </Tooltip>
      <Modal
        title="Invitee Info"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width="30%"
        bodyStyle={{
          backgroundColor: "#464852",
          color: "#fff",
        }}
      >
        <Typography>FirstName:&nbsp;{connection?.inviteeFirstName}</Typography>
        <Typography>LastName:&nbsp; {connection?.inviteeLastName}</Typography>
        <Typography>Phone:&nbsp;{connection?.inviteePhone}</Typography>
        <Typography>Email:&nbsp;{connection?.inviteeEmail}</Typography>
        <Typography>Role:&nbsp;{connection?.inviteeRole}</Typography>
      </Modal>
    </>
  );
};

export default MatchModal;
