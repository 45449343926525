import React, { useState, Fragment, useRef, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Editor } from "@tinymce/tinymce-react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { createAnnouncement } from "./../../../Redux/action/Announcements";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  form: {
    padding: "20px",
    background: "#464852",
  },
  paper: {
    display: "block",
    width: "100%",
    padding: "5px",
    marginBottom: "5px",
    borderRadius: "3px",
    background: "#9e9e9e",
    color: "#000",
  },
  button: {
    display: "block",
    width: "150px",
    padding: "5px",
    borderRadius: "0px",
    borderTopRightRadius: "3px",
    borderBottomRightRadius: "3px",
    background: "#3f3f3f",
    color: "#fff",
    marginTop: "10px",
  },
}));

const initialValues = {
  date: "",
  title: "",
};

const CreateAnouncement = ({ createAnnouncement, announcement }) => {
  const classes = useStyles();
  const editorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (editorRef.current) {
      setOpen(true);
      createAnnouncement({
        title: formValues.title,
        description: editorRef.current.getContent(),
      });
    }
    clear();
  };

  const clear = () => {
    setFormValues(initialValues);
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  useEffect(() => {
    if (announcement.success) {
      setOpen(false);
    } else if (!announcement.success && announcement.error !== null) {
      setOpen(false);
    }
  }, [announcement.success, announcement.error]);

  return (
    <Fragment>
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Paper
        component="form"
        className={classes.form}
        elevation={0}
        onSubmit={handleSubmit}
      >
        <Paper
          className={classes.paper}
          component="input"
          type="text"
          name="title"
          placeholder="Title"
          value={formValues.title}
          onChange={handleChange}
        />
        <Editor
          apiKey="efkjfq1mftenm8s7fwqa9nd4vnw94i0zr8246x1v5qcd143v"
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue="<p>Das aupair matching text editor</p>"
          init={{
            height: 500,
            menubar: true,
            plugins: [
              "advlist",
              "searchreplace",
              "insertdatetime",
              "wordcount",
              "media",
              "paste",
              "table",
              "autolink ",
              "image ",
              "lists ",
              "link ",
              "charmap ",
              "print ",
              "preview ",
              "anchor ",
              "visualblocks ",
              "fullscreen ",
              "code ",
              "help",
            ],
            toolbar:
              "undo" +
              "redo" +
              "formatselect" +
              " bold italic underline code copy paste cut backcolor  | alignleft aligncenter  " +
              " alignright alignjustify alignnone | bullist numlist outdent indent | blockquote " +
              " removeformat remove | help " +
              " fontselect fontsizeselect " +
              " h1 h2 h3 h4 h5 h6 ",

            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
        />
        <Paper className={classes.button} component="button">
          Create Announcment
        </Paper>
      </Paper>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    announcement: state.createAnnouncment.announcement,
  };
};

export default connect(mapStateToProps, { createAnnouncement })(
  CreateAnouncement
);
