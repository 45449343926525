import React from "react";
import { useForm, useStep } from "react-hooks-helper";
import Profile from "./hostProfile";
import Expectation from "./hostExpectation";
import HostWishes from "./hostWishes";
import HostOtherInfo from "./hostOtherInfo";
import Review from "./Review";
import Submit from "./Submit";

const defaultData = {
  //Profiles
  fullName: "",
  partnerFullName: "",
  phone: "",
  email: "",
  birthDate: "",
  familyStatus: "",
  country: "",
  religion: "",
  religionImpt: "",
  havingChildren: "",
  stChildFullName: "",
  stChildAge: "",
  ndChildFullName: "",
  ndChildAge: "",
  rdChildFullName: "",
  rdChildAge: "",
  fothChildFullName: "",
  fothChildAge: "",
  fithChildFullName: "",
  fithChildAge: "",
  hobbies: "",
  routine: "",
  uniqueness: "",
  quality: "",
  weakness: "",
  specialNeed: "",
  street: "",
  postCode: "",
  houseNo: "",
  city: "",
  workPreference: "",

  //Expectations
  aupairExpAupair: "",
  aupairLiveWith: "",
  childrenNo: "",
  childrenAge: "",
  searchingFor: "",
  aupirAge: "",
  aupairCountry: "",
  aupairLanguage: "",
  smokingAupair: "",
  WorkingHrs: "",
  houseWorkPlanned: "",
  timetableDef: "",
  flexibleFam: "",
  integrateFamLife: "",
  eatOnWish: "",

  //Other Info
  drivingLicense: "",
  canRideBike: "",
  canSwim: "",
  firstAidTraining: "",
  houseWork: "",
  childrenCareness: "",
  careAboutPets: "",
  educatLevel: "",
  specialRegime: "",
  foodPreference: "",

  //Wishes
  travelExpenceFees: "",
  medicalInsuranceFees: "",
  pocketMoney: "",
  languageCourseFees: "",
  localTranspTicket: "",
  provideCar: "",
  provideBike: "",
  privateRoom: "",
  privateBathroom: "",
  letter: "",
  imageOne: "",
  imageTwo: "",
  imageThree: "",
  imageFour: "",
  imageFive: "",
};

const steps = [
  { id: "profiles" },
  { id: "expectations" },
  { id: "otherinfos" },
  { id: "wishes" },
  { id: "review" },
];

export const HostMultForms = () => {
  const [formData, setFormData] = useForm(defaultData);
  const [hostUploads, setHostUploads] = React.useState([]);

  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });

  const props = {
    formData,
    setFormData,
    navigation,
    hostUploads,
    setHostUploads,
  };

  switch (step.id) {
    case "profiles":
      return <Profile {...props} />;
    case "expectations":
      return <Expectation {...props} />;
    case "otherinfos":
      return <HostOtherInfo {...props} />;
    case "wishes":
      return <HostWishes {...props} />;
    case "review":
      return <Review {...props} />;
    case "submit":
      return <Submit {...props} />;
    default:
      break;
  }
};
