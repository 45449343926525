import {
  GET_ROLES_START,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILURE,
  CREATE_ROLE_START,
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_FAILURE,
  UPDATE_ROLE_START,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAILURE,
  GET_ROLE_START,
  GET_ROLE_SUCCESS,
  GET_ROLE_FAILURE,
  DELETE_ROLE_START,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILURE,
} from "../action/actionTypes/types";

import * as api from "../../api/index";

export const fetchAllRoles = () => async (dispatch) => {
  dispatch({
    type: GET_ROLES_START,
  });
  try {
    const { data } = await api.fetchAllRoles();
    return dispatch({
      type: GET_ROLES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: GET_ROLES_FAILURE,
      payload: errorMessage,
    });
  }
};

export const createRole = (formData) => async (dispatch) => {
  dispatch({
    type: CREATE_ROLE_START,
  });

  try {
    const { data } = await api.createRole(formData);

    return dispatch({
      type: CREATE_ROLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: CREATE_ROLE_FAILURE,
      payload: errorMessage,
    });
  }
};

export const getRole = (uuid) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ROLE_START,
    });

    const { data } = await api.getRole(uuid);

    return dispatch({ type: GET_ROLE_SUCCESS, payload: data });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: GET_ROLE_FAILURE,
      payload: errorMessage,
    });
  }
};

export const updateRole = (formData, roleId) => async (dispatch) => {
  dispatch({
    type: UPDATE_ROLE_START,
  });

  console.log("formDta", formData);
  try {
    const { data } = await api.updateRole(formData, roleId);
    console.log("updated role", data);
    return dispatch({
      type: UPDATE_ROLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: UPDATE_ROLE_FAILURE,
      payload: errorMessage,
    });
  }
};

export const deleteRole = (uuid) => async (dispatch) => {
  console.log("data", uuid);
  try {
    dispatch({
      type: DELETE_ROLE_START,
    });

    const { data } = await api.deleteRole(uuid);

    console.log("data", data);

    return dispatch({ type: DELETE_ROLE_SUCCESS, payload: data });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: DELETE_ROLE_FAILURE,
      payload: errorMessage,
    });
  }
};
