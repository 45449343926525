import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useStyles } from "./style";
import Grid from "@material-ui/core/Grid";
import "./Aupair.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import {
  aupairProfile,
  updateAupairProfile,
} from "./../../../../../../../Redux/action/aupairForms";
import { toast } from "react-toastify";

const initialValues = {
  firstName: "",
  lastName: "",
  birthDate: "",
  familyStatus: "",
  nationality: "",
  religion: "",
  religionImpt: "",
  foodPreference: "",
};

const AupairProfile = ({ aupairProfileData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [createMode, setCreateMode] = useState(true);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [message, setMessage] = useState("");

  const { profile } = useSelector((state) => state.aupairProfile);
  const { profile: updatedProfile } = useSelector(
    (state) => state.updateAupairProfile
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));
    if (Object.keys(formErrors).length === 0) {
      if (createMode) {
        setOpen(true);
        dispatch(aupairProfile(formValues));
        clear();
      } else {
        setOpen(true);
        dispatch(updateAupairProfile(formValues, aupairProfileData.uuid));
      }
    }
  };

  const clear = () => {
    setFormValues(initialValues);
  };

  useEffect(() => {
    if (profile.success) {
      setOpen(false);
      setMessage(profile?.message?.message);
    } else if (!profile.success && profile.error !== null) {
      setOpen(false);
      setMessage("There was an error,try again");
    }
  }, [profile.success, profile.error, profile?.message?.message]);

  useEffect(() => {
    const notify = () => {
      if (profile?.error && message) {
        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (profile?.success && message) {
        toast.success(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    if (profile?.success && message) {
      notify();
    } else if (!profile?.success && profile?.error !== null && message) {
      notify();
    } else {
      return;
    }
  }, [profile?.success, profile?.error, message]);

  useEffect(() => {
    if (updatedProfile.success) {
      setOpen(false);
      setMessage(updatedProfile?.message?.message);
    } else if (!updatedProfile.success && updatedProfile.error !== null) {
      setOpen(false);
      setMessage("There was an error,try again");
    }
  }, [
    updatedProfile.success,
    updatedProfile.error,
    updatedProfile?.message?.message,
  ]);

  useEffect(() => {
    const notify = () => {
      if (updatedProfile?.error && message) {
        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (updatedProfile?.success && message) {
        toast.success(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    if (updatedProfile?.success && message) {
      notify();
    } else if (
      !updatedProfile?.success &&
      updatedProfile?.error !== null &&
      message
    ) {
      notify();
    } else {
      return;
    }
  }, [updatedProfile?.success, updatedProfile?.error, message]);

  const validate = (values) => {
    const errors = {};

    if (!values.firstName) {
      errors.firstName = "This field is required!";
    }
    if (!values.lastName) {
      errors.lastName = "This field is required!";
    }
    if (!values.birthDate) {
      errors.birthDate = "This field is required!";
    }
    if (!values.familyStatus) {
      errors.familyStatus = "This field is required!";
    }
    if (!values.nationality) {
      errors.nationality = "This field is required!";
    }
    if (!values.religion) {
      errors.religion = "This field is required!";
    }

    if (!values.religionImpt) {
      errors.religionImpt = "This field is required!";
    }
    if (!values.foodPreference) {
      errors.foodPreference = "This field is required!";
    }

    return errors;
  };

  useEffect(() => {
    if (aupairProfileData) {
      setFormValues({
        firstName: aupairProfileData?.firstName,
        lastName: aupairProfileData?.lastName,
        birthDate: aupairProfileData?.birthDate.split("T")[0],
        familyStatus: aupairProfileData?.familyStatus,
        nationality: aupairProfileData?.nationality,
        religion: aupairProfileData?.religion,
        religionImpt: aupairProfileData?.religionImpt,
        foodPreference: aupairProfileData?.foodPreference,
      });
      setCreateMode(false);
    } else if (!aupairProfileData) {
      setCreateMode(true);
      setFormValues(initialValues);
    }
  }, [aupairProfileData]);

  useEffect(() => {
    if (
      formValues.firstName === "" ||
      formValues.lastName === "" ||
      formValues.birthDate === "" ||
      formValues.familyStatus === "" ||
      formValues.nationality === "" ||
      formValues.religion === "" ||
      formValues.religionImpt === "" ||
      formValues.foodPreference === ""
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formValues]);

  return (
    <React.Fragment>
      <CssBaseline />
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <Container maxWidth="md" className="form_container">
        <small className="error">{profile.error}</small>
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="personalInformationGroup">
            <h3 className="InnerTitles">My Personal Information</h3>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    First Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="John"
                    name="firstName"
                    required
                    value={formValues.firstName}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.firstName}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Last Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Doe"
                    name="lastName"
                    required
                    value={formValues.lastName}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.lastName}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Date of Birth <span className="required">*</span>
                  </label>
                  <input
                    required
                    type="Date"
                    name="birthDate"
                    value={formValues.birthDate}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.birthDate}</small>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Family Status <span className="required">*</span>
                  </label>

                  <select
                    name="familyStatus"
                    value={formValues.familyStatus}
                    onChange={handleChange}
                  >
                    <option value={null}>--select---</option>
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                    <option value="Fiancé">Fiancé</option>
                    <option value="Divorced">Divorced</option>
                    <option value="Widower">Widower</option>
                  </select>
                </div>
                <small className="formError">{formErrors.familyStatus}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Nationality <span className="required">*</span>
                  </label>
                  <select
                    name="nationality"
                    value={formValues.nationality}
                    onChange={handleChange}
                  >
                    <option value={null}>---select---</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Uganda">Uganda</option>
                    <option value="DRC">DRC</option>
                    <option value="Tanzania">Tanzania</option>
                    <option value="No preference">No preference</option>
                  </select>
                </div>
                <small className="formError">{formErrors.nationality}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Religion<span className="required">*</span>
                  </label>
                  <select
                    name="religion"
                    value={formValues.religion}
                    onChange={handleChange}
                  >
                    <option value={null}>--select--</option>
                    <option value="Catholic">Catholic</option>
                    <option value="Evangelic">Evangelic</option>
                    <option value="Muslim">Muslim</option>
                    <option value="Other christian">Other christian</option>
                  </select>
                </div>
                <small className="formError">{formErrors.religion}</small>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Is Religion Important <span className="required">*</span>
                  </label>
                  <select
                    name="religionImpt"
                    value={formValues.religionImpt}
                    onChange={handleChange}
                  >
                    <option value={null}>---select---</option>
                    <option value="No">No</option>
                    <option value="Very">Very</option>
                    <option value="Important">Important</option>
                  </select>
                </div>
                <small className="formError">{formErrors.religionImpt}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Food preference <span className="required">*</span>
                  </label>
                  <select
                    name="foodPreference"
                    value={formValues.foodPreference}
                    onChange={handleChange}
                  >
                    <option value={null}>--select--</option>
                    <option value="Eating everything">Eating everything</option>
                    <option value="Only vegetable and milch product">
                      Only vegetable and milch product
                    </option>
                    <option value="Eggs and Fishes">Eggs and Fishes</option>
                    <option value="Vegans">Vegans</option>
                    <option value="Freedom to eat food on your wish">
                      Freedom to eat food on your wish
                    </option>
                    <option value="Special Regime">Special Regime</option>
                  </select>
                </div>
                <small className="formError">{formErrors.foodPreference}</small>
              </Grid>
            </div>
            <button
              className={disabled ? "disabledButton" : "submit__btn"}
              type="submit"
              disabled={disabled}
            >
              {createMode ? "Create" : "Update"}
            </button>
          </div>
        </form>
      </Container>
    </React.Fragment>
  );
};

export default AupairProfile;
