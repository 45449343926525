import React from "react";

const uploadBlog = ({ formValues, setFormValues }) => {
  const cloudName = "dsb0gu6km";
  const uploadPreset = "dasaupair";

  var myWidget = window.cloudinary.createUploadWidget(
    {
      cloudName: cloudName,
      uploadPreset: uploadPreset,
      folder: "blog pictures",
    },
    (error, result) => {
      if (result?.info?.secure_url) {
        setFormValues({
          ...formValues,
          picture: result?.info?.secure_url,
        });
      }
    }
  );

  const uplaodFile = () => {
    myWidget.open();
  };

  return (
    <button className="uploadBlogBtn" onClick={uplaodFile}>
      Upload Picture
    </button>
  );
};

export default uploadBlog;
