import React, { useState, useEffect, Fragment } from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useStyles } from "./style";
import Grid from "@material-ui/core/Grid";
import "./Aupair.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import {
  aupairEducations,
  updateAupairEducation,
} from "./../../../../../../../Redux/action/aupairForms";
import { toast } from "react-toastify";

const initialValues = {
  languageSkills: [""],
  highSchoolCertificate: "",
  professionalTraining: "",
  university: "",
};

const EducationBackground = ({ aupairEducData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [createMode, setCreateMode] = useState(true);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [message, setMessage] = useState("");

  const { education } = useSelector((state) => state.aupairEducation);
  const { education: updatedEducation } = useSelector(
    (state) => state.updatedAupairEducation
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "languageSkills") {
      setFormValues({
        ...formValues,
        [name]: formValues?.languageSkills?.length !== 0 && [
          ...formValues?.languageSkills,
          value,
        ],
      });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));
    if (Object.keys(formErrors).length === 0) {
      if (createMode) {
        setOpen(true);
        dispatch(aupairEducations(formValues));
        clear();
      } else {
        setOpen(true);
        dispatch(updateAupairEducation(formValues, aupairEducData.uuid));
      }
    }
  };

  const clear = () => {
    setFormValues(initialValues);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.languageSkills?.length === 0) {
      errors.languageSkills = "Language Skills Please !";
    }
    if (!values.languageSkills?.length > 3) {
      errors.languageSkills = "Maximum is three !";
    }
    if (!values.highSchoolCertificate) {
      errors.highSchoolCertificate = "This field is required!";
    }
    if (!values.professionalTraining) {
      errors.professionalTraining = "This field is required!";
    }
    if (!values.university) {
      errors.university = "This field is required!";
    }

    return errors;
  };

  useEffect(() => {
    if (education.success) {
      setOpen(false);
      setMessage(education?.message?.message);
    } else if (!education.success && education.error !== null) {
      setOpen(false);
      setMessage("There was an error,try again");
    }
  }, [education.success, education.error, education?.message?.message]);

  useEffect(() => {
    const notify = () => {
      if (education?.error && message) {
        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (education?.success && message) {
        toast.success(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    if (education?.success && message) {
      notify();
    } else if (!education?.success && education?.error !== null && message) {
      notify();
    } else {
      return;
    }
  }, [education?.success, education?.error, message]);

  useEffect(() => {
    if (updatedEducation.success) {
      setOpen(false);
      setMessage(updatedEducation?.message?.message);
    } else if (!updatedEducation.success && updatedEducation.error !== null) {
      setOpen(false);
      setMessage("There was an error,try again");
    }
  }, [
    updatedEducation.success,
    updatedEducation.error,
    updatedEducation?.message?.message,
  ]);

  useEffect(() => {
    const notify = () => {
      if (updatedEducation?.error && message) {
        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (updatedEducation?.success && message) {
        toast.success(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    if (updatedEducation?.success && message) {
      notify();
    } else if (
      !updatedEducation?.success &&
      updatedEducation?.error !== null &&
      message
    ) {
      notify();
    } else {
      return;
    }
  }, [updatedEducation?.success, updatedEducation?.error, message]);

  useEffect(() => {
    if (aupairEducData) {
      setFormValues({
        highSchoolCertificate: aupairEducData?.highSchoolCertificate,
        professionalTraining: aupairEducData?.professionalTraining,
        university: aupairEducData?.university,
      });
      setCreateMode(false);
    } else if (!aupairEducData) {
      setCreateMode(true);
      setFormValues(initialValues);
    }
  }, [aupairEducData]);

  useEffect(() => {
    if (
      formValues.languageSkills?.length === 0 ||
      formValues.highSchoolCertificate === "" ||
      formValues.professionalTraining === "" ||
      formValues.university === ""
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formValues]);

  return (
    <Fragment>
      <CssBaseline />
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Container maxWidth="md" className="form_container">
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="personalInformationGroup">
            <h3 className="InnerTitles">Education Background</h3>
            <div className="FieldContainer">
              <Grid container spacing={3} className="education__skills">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div>
                    <label style={{ textAlign: "center", display: "block" }}>
                      Language skills <span className="required">*</span>
                    </label>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div className="checkbox__container">
                    <input
                      type="checkbox"
                      id="Good_knowledge_German"
                      name="languageSkills"
                      value="Good knowledge of German"
                      onChange={handleChange}
                      className="checkbox__input"
                    />
                    <label htmlFor="Good_knowledge_German">
                      Good knowledge of German
                    </label>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div className="checkbox__container">
                    <input
                      type="checkbox"
                      id="Basic_knowledge_of_German"
                      name="languageSkills"
                      value="Basic knowledge of German"
                      onChange={handleChange}
                      className="checkbox__input"
                    />
                    <label htmlFor="Basic_knowledge_of_German">
                      Basic knowledge of German
                    </label>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div className="checkbox__container">
                    <input
                      type="checkbox"
                      id="Good_knowledge_of_English"
                      name="languageSkills"
                      value="Good knowledge of English"
                      onChange={handleChange}
                      className="checkbox__input"
                    />
                    <label htmlFor="Good_knowledge_of_English">
                      Good knowledge of English
                    </label>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div className="checkbox__container">
                    <input
                      type="checkbox"
                      id="basic_knowledge_of_English"
                      name="languageSkills"
                      value="Basic knowledge of English"
                      onChange={handleChange}
                      className="checkbox__input"
                    />
                    <label htmlFor="basic_knowledge_of_English">
                      Basic knowledge of English
                    </label>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div className="checkbox__container">
                    <input
                      type="checkbox"
                      id="Good_knowledge_of_French"
                      name="languageSkills"
                      value="Good_knowledge_of_French"
                      onChange={handleChange}
                      className="checkbox__input"
                    />
                    <label htmlFor="Good_knowledge_of_French">
                      Good knowledge of French
                    </label>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div className="checkbox__container">
                    <input
                      type="checkbox"
                      id="Basic_knowledge_of_French"
                      name="languageSkills"
                      value="Basic_knowledge_of_French"
                      onChange={handleChange}
                      className="checkbox__input"
                    />
                    <label htmlFor="Basic_knowledge_of_French">
                      Basic knowledge of French
                    </label>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div>
                    <label>
                      Highschool Certificate
                      <span className="required">*</span>
                    </label>
                    <select
                      name="highSchoolCertificate"
                      value={formValues.highSchoolCertificate}
                      onChange={handleChange}
                    >
                      <option value="">--select--</option>
                      <option value="Yes">Yes</option>
                      <option value="Not Yet">Not Yet</option>
                      <option value="None">None</option>
                    </select>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div>
                    <label>
                      Professional Trainings <span className="required">*</span>
                    </label>
                    <select
                      name="professionalTraining"
                      value={formValues.professionalTraining}
                      onChange={handleChange}
                    >
                      <option value="">--select--</option>
                      <option value="Yes">Yes</option>
                      <option value="Not Yet">Not Yet</option>
                      <option value="None">None</option>
                    </select>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div>
                    <label>
                      University
                      <span className="required">*</span>
                    </label>
                    <select
                      name="university"
                      value={formValues.university}
                      onChange={handleChange}
                    >
                      <option value="">--select--</option>
                      <option value="Yes">Yes</option>
                      <option value="Not Yet">Not Yet</option>
                      <option value="None">None</option>
                    </select>
                  </div>
                </Grid>
              </Grid>
            </div>
            <button
              className={disabled ? "disabledButton" : "submit__btn"}
              type="submit"
              disabled={disabled}
            >
              {createMode ? "Create" : "Update"}
            </button>
          </div>
        </form>
      </Container>
    </Fragment>
  );
};

export default EducationBackground;
