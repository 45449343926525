import React, { useState } from "react";
import { Modal } from "antd";
import UpdateAnnouncement from "./editAnnouncement";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";

const initialValues = {
  title: "",
};

const EditModal = ({ body }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);

  const showModal = () => {
    setIsModalVisible(true);
    setFormValues({
      title: body?.title,
    });
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Tooltip title="Edit Announcment">
        <IconButton size="small" onClick={showModal}>
          <EditIcon style={{ color: "#FFA500" }} />
        </IconButton>
      </Tooltip>
      <Modal
        title="EDIT ANNOUNCEMENT"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        bodyStyle={{
          backgroundColor: "#464852",
        }}
        width="80%"
      >
        <UpdateAnnouncement
          body={body}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      </Modal>
    </>
  );
};

export default EditModal;
