import React, { Fragment, useEffect, useState } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import Zoom from "react-reveal/Zoom";
import EditBlog from "./EditModal";
import DeleteModal from "./DeleteModal";
import { fetchBlogsForAdmin, publishBlog } from "./../../../Redux/action/blog";
import { connect, useSelector } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Empty } from "antd";
import IconButton from "@material-ui/core/IconButton";
import PublishIcon from "@mui/icons-material/Publish";
import Tooltip from "@material-ui/core/Tooltip";

import AutoStoriesIcon from "@mui/icons-material/AutoStories";

const Blogs = ({
  fetchBlogsForAdmin,
  blogs,
  setSearchableData,
  newBlog,
  updatedBlog,
  deletedBlog,
  publishBlog,
  publishedblog,
}) => {
  const { message: searchResult } = useSelector(
    (state) => state.search.searchResults
  );
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [blogData, setBlogData] = useState([]);

  const handleNavigation = (id) => {
    navigate(`/dashboard/singleblog/${id}`);
  };

  const handlePublish = (blogId) => {
    publishBlog(blogId);
  };

  useEffect(() => {
    const fetchData = () => {
      fetchBlogsForAdmin();
    };

    if (
      newBlog?.success ||
      updatedBlog?.success ||
      deletedBlog?.success ||
      publishedblog?.success
    ) {
      fetchData();
    } else {
      fetchData();
    }
  }, [
    fetchBlogsForAdmin,
    newBlog?.success,
    updatedBlog?.success,
    deletedBlog?.success,
    publishedblog?.success,
  ]);

  useEffect(() => {
    if (!blogs?.success) {
      setOpen(true);
    } else if (blogs?.success) {
      setOpen(false);
      setBlogData(blogs?.message?.data?.blogs);
      setSearchableData(blogs?.message?.data?.blogs);
    } else if (!blogs?.success && blogs?.error !== null) {
      setOpen(false);
    }
  }, [
    blogs?.success,
    blogs?.error,
    blogs?.message?.data?.blogs,
    setSearchableData,
  ]);

  useEffect(() => {
    if (searchResult) {
      setBlogData(searchResult);
    } else if (blogs.success) {
      setBlogData(blogs?.message?.data?.blogs);
    }
  }, [searchResult, blogs.success, blogs?.message?.data?.blogs]);

  return (
    <Fragment>
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      {blogData?.length === 0 ? (
        <Empty />
      ) : (
        <div className="blogs">
          <div className="blogs_container">
            {blogData?.map((blog) => (
              <Zoom>
                <div className="blogs_card">
                  <img src={blog.picture} alt="blogImage" />
                  <div className="blogs_blog_title">
                    <span>{blog.title}</span>
                  </div>
                  <div className="blogs_blog_text">
                    <p
                      style={{ color: "white", textAlign: "left" }}
                      dangerouslySetInnerHTML={{
                        __html: blog.blogText.substr(0, 150) + " ....",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "20px",
                    }}
                  >
                    <EditBlog blog={blog} />
                    <Tooltip title="read" arrow>
                      <IconButton onClick={() => handleNavigation(blog.uuid)}>
                        <AutoStoriesIcon style={{ color: "orange" }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Publish" arrow>
                      <IconButton onClick={() => handlePublish(blog.uuid)}>
                        <PublishIcon style={{ color: "orange" }} />
                      </IconButton>
                    </Tooltip>
                    <DeleteModal blogId={blog.uuid} />
                  </div>
                </div>
              </Zoom>
            ))}
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    blogs: state.adminBlog.blogs,
    newBlog: state.createBlog.blog,
    updatedBlog: state.updateBlog.blog,
    deletedBlog: state.deleteBlog.blog,
    publishedblog: state.publishedBlog.published,
  };
};

export default connect(mapStateToProps, {
  fetchBlogsForAdmin,
  publishBlog,
})(Blogs);
