export const TAB_NUMBER_START = "TAB_NUMBER_START";
export const TAB_NUMBER_SUCCESS = "TAB_NUMBER_SUCCESS";
export const TAB_NUMBER_FAILURE = "TAB_NUMBER_FAILURE";

export const SIGNUP_USER_START = "SIGNUP_USER_START";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNUP_USER_FAILURE = "SIGNUP_USER_FAILURE";

export const LOGIN_USER_START = "LOGIN_USER_START";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";

export const GET_USER_START = "GET_USER_START";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const ADD_STAFF_MEMBERS_START = "ADD_STAFF_MEMBERS_START";
export const ADD_STAFF_MEMBERS_SUCCESS = "ADD_STAFF_MEMBERS_SUCCESS";
export const ADD_STAFF_MEMBERS_FAILURE = "ADD_STAFF_MEMBERS_FAILURE";

export const UPDATE_STAFF_MEMBERS_START = "UPDATE_STAFF_MEMBERS_START";
export const UPDATE_STAFF_MEMBERS_SUCCESS = "UPDATE_STAFF_MEMBERS_SUCCESS";
export const UPDATE_STAFF_MEMBERS_FAILURE = "UPDATE_STAFF_MEMBERS_FAILURE";

export const GET_STAFF_MEMBERS_START = "GET_STAFF_MEMBERS_START";
export const GET_STAFF_MEMBERS_SUCCESS = "GET_STAFF_MEMBERS_SUCCESS";
export const GET_STAFF_MEMBERS_FAILURE = "GET_STAFF_MEMBERS_FAILURE";

export const DELETE_USER_START = "DELETE_USER_START";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const AUPAIR_PROFILE_START = "AUPAIR_PROFILE_START";
export const AUPAIR_PROFILE_SUCCESS = "AUPAIR_PROFILE_SUCCESS";
export const AUPAIR_PROFILE_FAILURE = "AUPAIR_PROFILE_FAILURE";

export const GET_AUPAIR_PROFILE_START = "GET_AUPAIR_PROFILE_START";
export const GET_AUPAIR_PROFILE_SUCCESS = "GET_AUPAIR_PROFILE_SUCCESS";
export const GET_AUPAIR_PROFILE_FAILURE = "GET_AUPAIR_PROFILE_FAILURE";

export const UPDATE_AUPAIR_PROFILE_START = "UPDATE_AUPAIR_PROFILE_START";
export const UPDATE_AUPAIR_PROFILE_SUCCESS = "UPDATE_AUPAIR_PROFILE_SUCCESS";
export const UPDATE_AUPAIR_PROFILE_FAILURE = "UPDATE_AUPAIR_PROFILE_FAILURE";

export const AUPAIR_CONTACTS_START = "AUPAIR_CONTACTS_START";
export const AUPAIR_CONTACTS_SUCCESS = "AUPAIR_CONTACTS_SUCCESS";
export const AUPAIR_CONTACTS_FAILURE = "AUPAIR_CONTACTS_FAILURE";

export const UPDATE_AUPAIR_CONTACTS_START = "UPDATE_AUPAIR_CONTACTS_START";
export const UPDATE_AUPAIR_CONTACTS_SUCCESS = "UPDATE_AUPAIR_CONTACTS_SUCCESS";
export const UPDATE_AUPAIR_CONTACTS_FAILURE = "UPDATE_AUPAIR_CONTACTS_FAILURE";

export const AUPAIR_EDUCATION_START = "AUPAIR_EDUCATION_START";
export const AUPAIR_EDUCATION_SUCCESS = "AUPAIR_EDUCATION_SUCCESS";
export const AUPAIR_EDUCATION_FAILURE = "AUPAIR_EDUCATION_FAILURE";

export const UPDATE_AUPAIR_EDUCATION_START = "UPDATE_AUPAIR_EDUCATION_START";
export const UPDATE_AUPAIR_EDUCATION_SUCCESS =
  "UPDATE_AUPAIR_EDUCATION_SUCCESS";
export const UPDATE_AUPAIR_EDUCATION_FAILURE =
  "UPDATE_AUPAIR_EDUCATION_FAILURE";

export const AUPAIR_PREFERENCES_START = "AUPAIR_PREFERENCES_START";
export const AUPAIR_PREFERENCES_SUCCESS = "AUPAIR_PREFERENCES_SUCCESS";
export const AUPAIR_PREFERENCES_FAILURE = "AUPAIR_PREFERENCES_FAILURE";

export const UPDATE_AUPAIR_PREFERENCES_START =
  "UPDATE_AUPAIR_PREFERENCES_START";
export const UPDATE_AUPAIR_PREFERENCES_SUCCESS =
  "UPDATE_AUPAIR_PREFERENCES_SUCCESS";
export const UPDATE_AUPAIR_PREFERENCES_FAILURE =
  "UPDATE_AUPAIR_PREFERENCES_FAILURE";

export const AUPAIR_EXPECTATION_START = "AUPAIR_EXPECTATION_START";
export const AUPAIR_EXPECTAION_SUCCESS = "AUPAIR_EXPECTATION_SUCCESS";
export const AUPAIR_EXPECTATION_FAILURE = "AUPAIR_EXPECTATION_FAILURE";

export const UPDATE_AUPAIR_EXPECTATION_START =
  "UPDATE_AUPAIR_EXPECTATION_START";
export const UPDATE_AUPAIR_EXPECTAION_SUCCESS =
  "UPDATE_AUPAIR_EXPECTATION_SUCCESS";
export const UPDATE_AUPAIR_EXPECTATION_FAILURE =
  "UPDATE_AUPAIR_EXPECTATION_FAILURE";

export const AUPAIRE_OTHER_INFO_START = "AUPAIR_OTHER_INFO_START";
export const AUPAIRE_OTHER_INFO_SUCCESS = "AUPAIR_OTHER_INFO_SUCCESS";
export const AUPAIRE_OTHER_INFO_FAILURE = "AUPAIR_OTHER_INFO_FAILURE";

export const UPDATE_AUPAIRE_OTHER_INFO_START = "UPDATE_AUPAIR_OTHER_INFO_START";
export const UPDATE_AUPAIRE_OTHER_INFO_SUCCESS =
  "UPDATE_AUPAIR_OTHER_INFO_SUCCESS";
export const UPDATE_AUPAIRE_OTHER_INFO_FAILURE =
  "UPDATE_AUPAIR_OTHER_INFO_FAILURE";

export const AUPAIRE_WISHES_START = "AUPAIRE_WISHES_START";
export const AUPAIRE_WISHES_SUCCESS = "AUPAIRE_WISHES_SUCCESS";
export const AUPAIRE_WISHES_FAILURE = "AUPAIRE_WISHES_FAILRE";

export const UPDATE_AUPAIRE_WISHES_START = "UPDATE_AUPAIRE_WISHES_START";
export const UPDATE_AUPAIRE_WISHES_SUCCESS = "UPDATE_AUPAIRE_WISHES_SUCCESS";
export const UPDATE_AUPAIRE_WISHES_FAILURE = "UPDATE_AUPAIRE_WISHES_FAILRE";

export const GET_AUPAIRS_START = "GET_AUPAIRS_START";
export const GET_AUPAIRS_SUCCESS = "GET_AUPAIRS_SUCCESS";
export const GET_AUPAIRS_FAILURE = "GET_AUPAIRS_FAILRE";

export const HOST_PROFILE_START = "HOST_PROFILE_START";
export const HOST_PROFILE_SUCCESS = "HOST_PROFILE_SUCCESS";
export const HOST_PROFILE_FAILURE = "HOST_PROFILE_FAILURE";

export const UPDATE_HOST_PROFILE_START = "UPDATE_HOST_PROFILE_START";
export const UPDATE_HOST_PROFILE_SUCCESS = "UPDATE_HOST_PROFILE_SUCCESS";
export const UPDATE_HOST_PROFILE_FAILURE = "UPDATE_HOST_PROFILE_FAILURE";

export const HOST_CONTACTS_START = "HOST_CONTACTS_START";
export const HOST_CONTACTS_SUCCESS = "HOST_CONTACTS_SUCCESS";
export const HOST_CONTACTS_FAILURE = "HOST_CONTACTS_FAILURE";

export const UPDATE_HOST_CONTACTS_START = "UPDATE_HOST_CONTACTS_START";
export const UPDATE_HOST_CONTACTS_SUCCESS = "UPDATE_HOST_CONTACTS_SUCCESS";
export const UPDATE_HOST_CONTACTS_FAILURE = "UPDATE_HOST_CONTACTS_FAILURE";

export const HOST_LIVING_START = "HOST_LIVING_START";
export const HOST_LIVING_SUCCESS = "HOST_LIVING_SUCCESS";
export const HOST_LIVING_FAILURE = "HOST_LIVING_FAILURE";

export const UPDATE_HOST_LIVING_START = "UPDATE_HOST_LIVING_START";
export const UPDATE_HOST_LIVING_SUCCESS = "UPDATE_HOST_LIVING_SUCCESS";
export const UPDATE_HOST_LIVING_FAILURE = "UPDATE_HOST_LIVING_FAILURE";

export const HOST_PREFERENCES_START = "HOST_PREFERENCES_START";
export const HOST_PREFERENCES_SUCCESS = "HOST_PREFERENCES_SUCCESS";
export const HOST_PREFERENCES_FAILURE = "HOST_PREFERENCES_FAILURE";

export const UPDATE_HOST_PREFERENCES_START = "UPDATE_HOST_PREFERENCES_START";
export const UPDATE_HOST_PREFERENCES_SUCCESS =
  "UPDATE_HOST_PREFERENCES_SUCCESS";
export const UPDATE_HOST_PREFERENCES_FAILURE =
  "UPDATE_HOST_PREFERENCES_FAILURE";

export const HOST_EXPECTATION_START = "HOST_EXPECTATION_START";
export const HOST_EXPECTATION_SUCCESS = "HOST_EXPECTATION_SUCCESS";
export const HOST_EXPECTATION_FAILURE = "HOST_EXPECTATION_FAILURE";

export const UPDATE_HOST_EXPECTATION_START = "UPDATE_HOST_EXPECTATION_START";
export const UPDATE_HOST_EXPECTATION_SUCCESS =
  "UPDATE_HOST_EXPECTATION_SUCCESS";
export const UPDATE_HOST_EXPECTATION_FAILURE =
  "UPDATE_HOST_EXPECTATION_FAILURE";

export const HOST_OTHER_INFO_START = "HOST_INFO_START";
export const HOST_OTHER_INFO_SUCCESS = "HOST_INFO_SUCCESS";
export const HOST_OTHER_INFO_FAILURE = "HOST_INFO_FAILURE";

export const UPDATE_HOST_OTHER_INFO_START = "UPDATE_HOST_INFO_START";
export const UPDATE_HOST_OTHER_INFO_SUCCESS = "UPDATE_HOST_INFO_SUCCESS";
export const UPDATE_HOST_OTHER_INFO_FAILURE = "UPDATE_HOST_INFO_FAILURE";

export const HOST_OFFER_START = "HOST_OFFER_START";
export const HOST_OFFER_SUCCESS = "HOST_OFFER_SUCCESS";
export const HOST_OFFER_FAILURE = "HOST_OFER_FAILURE";

export const UPDATE_HOST_OFFER_START = "UPDATE_HOST_OFFER_START";
export const UPDATE_HOST_OFFER_SUCCESS = "UPDATE_HOST_OFFER_SUCCESS";
export const UPDATE_HOST_OFFER_FAILURE = "UPDATE_HOST_OFER_FAILURE";

export const HOST_BACKGROUND_START = "HOST_BACKGROUND_START";
export const HOST_BACKGROUND_SUCCESS = "HOST_BACKGROUND_SUCCESS";
export const HOST_BACKGROUND_FAILURE = "HOST_BACKGROUND_FAILURE";

export const UPDATE_HOST_BACKGROUND_START = "UPDATE_HOST_BACKGROUND_START";
export const UPDATE_HOST_BACKGROUND_SUCCESS = "UPDATE_HOST_BACKGROUND_SUCCESS";
export const UPDATE_HOST_BACKGROUND_FAILURE = "UPDATE_HOST_BACKGROUND_FAILURE";

export const HOST_WISHES_START = "HOST_WISHES_START";
export const HOST_WISHES_SUCCESS = "HOST_WISHES_SUCCESS";
export const HOST_WISHES_FAILURE = "HOST_WISHES_FAILURE";

export const GET_HOSTS_START = "GET_HOSTS_START";
export const GET_HOSTS_SUCCESS = "GET_HOSTS_SUCCESS";
export const GET_HOSTS_FAILURE = "GET_HOSTS_FAILRE";

export const CONTACT_START = "CONTACT_START";
export const CONTACT_SUCCESS = "CONTACT_SUCCESS";
export const CONTACT_FAILURE = "CONTACT_FAILURE";

export const UPLOAD__AUPAIR__IMAGES__START = "UPLOAD__AUPAIR__IMAGES__START";
export const UPLOAD__AUPAIR__IMAGES__SUCCESS =
  "UPLOAD__AUPAIR__IMAGES__SUCCESS";
export const UPLOAD__AUPAIR__IMAGES__FAILURE =
  "UPLOAD__AUPAIR__IMAGES__FAILURE";

export const UPLOAD__HOST__IMAGES__START = "UPLOAD__HOST__IMAGES__START";
export const UPLOAD__HOST__IMAGES__SUCCESS = "UPLOAD__HOST__IMAGES__SUCCESS";
export const UPLOAD__HOST__IMAGES__FAILURE = "UPLOAD__HOST__IMAGES__FAILURE";

export const CROPPED_URL = "CROPPED_URL";

export const FIREBASE_UPLOAD_PROGRESS = "FIREBASE_UPLOAD_PROGRESS";
export const FIREBASE_UPLOAD_ERROR = "FIREBASE_UPLOAD_ERROR";
export const FIREBASE_UPLOAD_END = "FIREBASE_UPLOAD_END";

export const GET_ROLES_START = "GET_ROLES_START";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAILURE = "GET_ROLES_FAILURE";

export const GET_ROLE_START = "GET_ROLE_START";
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS";
export const GET_ROLE_FAILURE = "GET_ROLE_FAILURE";

export const DELETE_ROLE_START = "DELETE_ROLE_START";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAILURE = "DELETE_ROLE_FAILURE";

export const CREATE_ROLE_START = "CREATE_ROLE_START";
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
export const CREATE_ROLE_FAILURE = "CREATE_ROLE_FAILURE";

export const UPDATE_ROLE_START = "UPDATE_ROLE_START";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_FAILURE = "UPDATE_ROLE_FAILURE";

export const GET_MESSAGES_START = "GET_MESSAGES_START";
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";
export const GET_MESSAGES_FAILURE = "GET_MESSAGES_FAILURE";

export const DELETE_MESSAGE_START = "DELETE_MESSAGE_START";
export const DELETE_MESSAGES_SUCCESS = "DELETE_MESSAGE_SUCCESS";
export const DELETE_MESSAGES_FAILURE = "DELETE_MESSAGE_FAILURE";

export const CREATE_CATEGORIES_START = "CREATE_CATEGORIES_START";
export const CREATE_CATEGORIES_SUCCESS = "CREATE_CATEGORIES_SUCCESS";
export const CREATE_CATEGORIES_FAILURE = "CREATE_CATEGORIES_FAILURE";

export const FETCH_CATEGORIES_START = "FETCH_CATEGORIES_START";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_FAILURE = "FETCH_CATEGORIES_FAILURE";

export const GET_CATEGORY_START = "GET_CATEGORY_START";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAILURE = "GET_CATEGORY_FAILURE";

export const UPDATE_CATEGORY_START = "UPDATE_CATEGORY_START";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAILURE = "UPDATE_CATEGORY_FAILURE";

export const DELETE_CATEGORY_START = "DELETE_CATEGORY_START";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAILURE = "DELETE_CATEGORY_FAILURE";

export const CREATE_BLOG_START = "CREATE_BLOG_START";
export const CREATE_BLOG_SUCCESS = "CREATE_BLOG_SUCCESS";
export const CREATE_BLOG_FAILURE = "CREATE_BLOG_FAILURE";

export const FETCH_BLOGS_START = "FETCH_BLOGS_START";
export const FETCH_BLOGS_SUCCESS = "FETCH_BLOGS_SUCCESS";
export const FETCH_BLOGS_FAILURE = "FETCH_BLOGS_FAILURE";

export const FETCH_AMIN_BLOGS_START = "FETCH_ADMIN_BLOGS_START";
export const FETCH_AMIN_BLOGS_SUCCESS = "FETCH_ADMIN_BLOGS_SUCCESS";
export const FETCH_AMIN_BLOGS_FAILURE = "FETCH_ADMIN_BLOGS_FAILURE";

export const GET_BLOG_START = "GET_BLOG_START";
export const GET_BLOG_SUCCESS = "GET_BLOG_SUCCESS";
export const GET_BLOG_FAILURE = "GET_BLOG_FAILURE";

export const UPDATE_BLOG_START = "UPDATE_BLOG_START";
export const UPDATE_BLOG_SUCCESS = "UPDATE_BLOG_SUCCESS";
export const UPDATE_BLOG_FAILURE = "UPDATE_BLOG_FAILURE";

export const PUBLISH_BLOG_START = "PUBLISH_BLOG_START";
export const PUBLISH_BLOG_SUCCESS = "PUBLISH_BLOG_SUCCESS";
export const PUBLISH_BLOG_FAILURE = "PUBLISH_BLOG_FAILURE";

export const DELETE_BLOG_START = "DELETE_BLOG_START";
export const DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCCESS";
export const DELETE_BLOG_FAILURE = "DELETE_BLOG_FAILURE";

export const GET_ALL_ANNOUNCEMENTS_START = "GET_ALL_ANNOUNCEMENTS_START";
export const GET_ALL_ANNOUNCEMENTS_SUCCESS = "GET_ALL_ANNOUNCEMENTS_SUCCESS";
export const GET_ALL_ANNOUNCEMENTS_FAILURE = "GET_ALL_ANNOUNCEMENTS_FAILURE";

export const GET_ANNOUNCEMENT_START = "GET_ANNOUNCEMENT_START";
export const GET_ANNOUNCEMENT_SUCCESS = "GET_ANNOUNCEMENT_SUCCESS";
export const GET_ANNOUNCEMENT_FAILURE = "GET_ANNOUNCEMENT_FAILURE";

export const CREATE_ANNOUNCEMENT_START = "CREATE_ANNOUNCEMENT_START";
export const CREATE_ANNOUNCEMENT_SUCCESS = "CREATE_ANNOUNCEMENT_SUCCESS";
export const CREATE_ANNOUNCEMENT_FAILURE = "CREATE_ANNOUNCEMENT_FAILURE";

export const UPDTAE_ANNOUNCEMENT_START = "UPDTAE_ANNOUNCEMENT_START";
export const UPDTAE_ANNOUNCEMENT_SUCCESS = "UPDTAE_ANNOUNCEMENT_SUCCESS";
export const UPDTAE_ANNOUNCEMENT_FAILURE = "UPDTAE_ANNOUNCEMENT_FAILURE";

export const DELETE_ANNOUNCEMENT_START = "DELETE_ANNOUNCEMENT_START";
export const DELETE_ANNOUNCEMENT_SUCCESS = "DELETE_ANNOUNCEMENT_SUCCESS";
export const DELETE_ANNOUNCEMENT_FAILURE = "DELETE_ANNOUNCEMENT_FAILURE";

export const GET_ALL_TESTMONIALS_START = "GET_ALL_TESTMONIALS_START";
export const GET_ALL_TESTMONIALS_SUCCESS = "GET_ALL_TESTMONIALS_SUCCESS";
export const GET_ALL_TESTMONIALS_FAILURE = "GET_ALL_TESTMONIALS_FAILURE";

export const GET_TESTMONY_START = "GET_TESTMONY_START";
export const GET_TESTMONY_SUCCESS = "GET_TESTMONY_SUCCESS";
export const GET_TESTMONY_FAILURE = "GET_TESTMONY_FAILURE";

export const CREATE_TESTMONY_START = "CREATE_TESTMONY_START";
export const CREATE_TESTMONY_SUCCESS = "CREATE_TESTMONY_SUCCESS";
export const CREATE_TESTMONY_FAILURE = "CREATE_TESTMONY_FAILURE";

export const UPDTAE_TESTMONY_START = "UPDTAE_TESTMONY_START";
export const UPDTAE_TESTMONY_SUCCESS = "UPDTAE_TESTMONY_SUCCESS";
export const UPDTAE_TESTMONY_FAILURE = "UPDTAE_TESTMONY_FAILURE";

export const DELETE_TESTMONY_START = "DELETE_TESTMONY_START";
export const DELETE_TESTMONY_SUCCESS = "DELETE_TESTMONY_SUCCESS";
export const DELETE_TESTMONY_FAILURE = "DELETE_TESTMONY_FAILURE";

export const CREATE_AUPAIR_GALLERY_START = "CREATE_AUPAIR_GALLERY_START";
export const CREATE_AUPAIR_GALLERY_SUCCESS = "CREATE_AUPAIR_GALLERY_SUCCESS";
export const CREATE_AUPAIR_GALLERY_FAILURE = "CREATE_AUPAIR_GALLERY_FAILURE";

export const GET_AUPAIR_GALLERIES_START = "GET_AUPAIR_GALLERIES_START";
export const GET_AUPAIR_GALLERIES_SUCCESS = "GET_AUPAIR_GALLERIES_SUCCESS";
export const GET_AUPAIR_GALLERIES_FAILURE = "GET_AUPAIR_GALLERIES_FAILURE";

export const GET_AUPAIR_GALLERY_START = "GET_AUPAIR_GALLERY_START";
export const GET_AUPAIR_GALLERY_SUCCESS = "GET_AUPAIR_GALLERY_SUCCESS";
export const GET_AUPAIR_GALLERY_FAILURE = "GET_AUPAIR_GALLERY_FAILURE";

export const UPDATE_AUPAIR_GALLERY_START = "UPDATE_AUPAIR_GALLERY_START";
export const UPDATE_AUPAIR_GALLERY_SUCCESS = "UPDATE_AUPAIR_GALLERY_SUCCESS";
export const UPDATE_AUPAIR_GALLERY_FAILURE = "UPDATE_AUPAIR_GALLERY_FAILURE";

export const REJECT_AUPAIR_GALLERY_START = "REJECT_AUPAIR_GALLERY_START";
export const REJECT_AUPAIR_GALLERY_SUCCESS = "REJECT_AUPAIR_GALLERY_SUCCESS";
export const REJECT_AUPAIR_GALLERY_FAILURE = "REJECT_AUPAIR_GALLERY_FAILURE";

export const RESTORE_AUPAIR_GALLERY_START = "RESTORE_AUPAIR_GALLERY_START";
export const RESTORE_AUPAIR_GALLERY_SUCCESS = "RESTORE_AUPAIR_GALLERY_SUCCESS";
export const RESTORE_AUPAIR_GALLERY_FAILURE = "RESTORE_AUPAIR_GALLERY_FAILURE";

export const DELETE_AUPAIR_GALLERY_START = "DELETE_AUPAIR_GALLERY_START";
export const DELETE_AUPAIR_GALLERY_SUCCESS = "DELETE_AUPAIR_GALLERY_SUCCESS";
export const DELETE_AUPAIR_GALLERY_FAILURE = "DELETE_AUPAIR_GALLERY_FAILURE";

export const CREATE_HOST_GALLERY_START = "CREATE_HOST_GALLERY_START";
export const CREATE_HOST_GALLERY_SUCCESS = "CREATE_HOST_GALLERY_SUCCESS";
export const CREATE_HOST_GALLERY_FAILURE = "CREATE_HOST_GALLERY_FAILURE";

export const GET_HOST_GALLERIES_START = "GET_HOST_GALLERIES_START";
export const GET_HOST_GALLERIES_SUCCESS = "GET_HOST_GALLERIES_SUCCESS";
export const GET_HOST_GALLERIES_FAILURE = "GET_HOST_GALLERIES_FAILURE";

export const GET_HOST_GALLERY_START = "GET_HOST_GALLERY_START";
export const GET_HOST_GALLERY_SUCCESS = "GET_HOST_GALLERY_SUCCESS";
export const GET_HOST_GALLERY_FAILURE = "GET_HOST_GALLERY_FAILURE";

export const UPDATE_HOST_GALLERY_START = "UPDATE_HOST_GALLERY_START";
export const UPDATE_HOST_GALLERY_SUCCESS = "UPDATE_HOST_GALLERY_SUCCESS";
export const UPDATE_HOST_GALLERY_FAILURE = "UPDATE_HOST_GALLERY_FAILURE";

export const REJECT_HOST_GALLERY_START = "REJECT_HOST_GALLERY_START";
export const REJECT_HOST_GALLERY_SUCCESS = "REJECT_HOST_GALLERY_SUCCESS";
export const REJECT_HOST_GALLERY_FAILURE = "REJECT_HOST_GALLERY_FAILURE";

export const RESTORE_HOST_GALLERY_START = "RESTORE_HOST_GALLERY_START";
export const RESTORE_HOST_GALLERY_SUCCESS = "RESTORE_HOST_GALLERY_SUCCESS";
export const RESTORE_HOST_GALLERY_FAILURE = "RESTORE_HOST_GALLERY_FAILURE";

export const DELETE_HOST_GALLERY_START = "DELETE_HOST_GALLERY_START";
export const DELETE_HOST_GALLERY_SUCCESS = "DELETE_HOST_GALLERY_SUCCESS";
export const DELETE_HOST_GALLERY_FAILURE = "DELETE_HOST_GALLERY_FAILURE";

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const CREATE_AUPAIR_DOCUMENT_START = "CREATE_AUPAIR_DOCUMENT_START";
export const CREATE_AUPAIR_DOCUMENT_SUCCESS = "CREATE_AUPAIR_DOCUMENT_SUCCESS";
export const CREATE_AUPAIR_DOCUMENT_FAILURE = "CREATE_AUPAIR_DOCUMENT_FAILURE";

export const GET_AUPAIR_DOCUMENTS_START = "GET_AUPAIR_DOCUMENTS_START";
export const GET_AUPAIR_DOCUMENTS_SUCCESS = "GET_AUPAIR_DOCUMENTS_SUCCESS";
export const GET_AUPAIR_DOCUMENTS_FAILURE = "GET_AUPAIR_DOCUMENTS_FAILURE";

export const GET_AUPAIR_DOCUMENT_START = "GET_AUPAIR_DOCUMENT_START";
export const GET_AUPAIR_DOCUMENT_SUCCESS = "GET_AUPAIR_DOCUMENT_SUCCESS";
export const GET_AUPAIR_DOCUMENT_FAILURE = "GET_AUPAIR_DOCUMENT_FAILURE";

export const UPDATE_AUPAIR_DOCUMENT_START = "UPDATE_AUPAIR_DOCUMENT_START";
export const UPDATE_AUPAIR_DOCUMENT_SUCCESS = "UPDATE_AUPAIR_DOCUMENT_SUCCESS";
export const UPDATE_AUPAIR_DOCUMENT_FAILURE = "UPDATE_AUPAIR_DOCUMENT_FAILURE";

export const REJECT_AUPAIR_DOCUMENT_START = "REJECT_AUPAIR_DOCUMENT_START";
export const REJECT_AUPAIR_DOCUMENT_SUCCESS = "REJECT_AUPAIR_DOCUMENT_SUCCESS";
export const REJECT_AUPAIR_DOCUMENT_FAILURE = "REJECT_AUPAIR_DOCUMENT_FAILURE";

export const RESTORE_AUPAIR_DOCUMENT_START = "RESTORE_AUPAIR_DOCUMENT_START";
export const RESTORE_AUPAIR_DOCUMENT_SUCCESS =
  "RESTORE_AUPAIR_DOCUMENT_SUCCESS";
export const RESTORE_AUPAIR_DOCUMENT_FAILURE =
  "RESTORE_AUPAIR_DOCUMENT_FAILURE";

export const DELETE_AUPAIR_DOCUMENT_START = "DELETE_AUPAIR_DOCUMENT_START";
export const DELETE_AUPAIR_DOCUMENT_SUCCESS = "DELETE_AUPAIR_DOCUMENT_SUCCESS";
export const DELETE_AUPAIR_DOCUMENT_FAILURE = "DELETE_AUPAIR_DOCUMENT_FAILURE";

export const CREATE_HOST_DOCUMENT_START = "CREATE_HOST_DOCUMENT_START";
export const CREATE_HOST_DOCUMENT_SUCCESS = "CREATE_HOST_DOCUMENT_SUCCESS";
export const CREATE_HOST_DOCUMENT_FAILURE = "CREATE_HOST_DOCUMENT_FAILURE";

export const GET_HOST_DOCUMENTS_START = "GET_HOST_DOCUMENTS_START";
export const GET_HOST_DOCUMENTS_SUCCESS = "GET_HOST_DOCUMENTS_SUCCESS";
export const GET_HOST_DOCUMENTS_FAILURE = "GET_HOST_DOCUMENTS_FAILURE";

export const GET_HOST_DOCUMENT_START = "GET_HOST_DOCUMENT_START";
export const GET_HOST_DOCUMENT_SUCCESS = "GET_HOST_DOCUMENT_SUCCESS";
export const GET_HOST_DOCUMENT_FAILURE = "GET_HOST_DOCUMENT_FAILURE";

export const UPDATE_HOST_DOCUMENT_START = "UPDATE_HOST_DOCUMENT_START";
export const UPDATE_HOST_DOCUMENT_SUCCESS = "UPDATE_HOST_DOCUMENT_SUCCESS";
export const UPDATE_HOST_DOCUMENT_FAILURE = "UPDATE_HOST_DOCUMENT_FAILURE";

export const DELETE_HOST_DOCUMENT_START = "DELETE_HOST_DOCUMENT_START";
export const DELETE_HOST_DOCUMENT_SUCCESS = "DELETE_HOST_DOCUMENT_SUCCESS";
export const DELETE_HOST_DOCUMENT_FAILURE = "DELETE_HOST_DOCUMENT_FAILURE";

export const REJECT_HOST_DOCUMENT_START = "REJECT_HOST_DOCUMENT_START";
export const REJECT_HOST_DOCUMENT_SUCCESS = "REJECT_HOST_DOCUMENT_SUCCESS";
export const REJECT_HOST_DOCUMENT_FAILURE = "REJECT_HOST_DOCUMENT_FAILURE";

export const SUBSCRIBE_START = "SUBSCRIBE_START";
export const SUBSCRIBE_SUCCESS = "SUBSCRIBE_SUCCESS";
export const SUBSCRIBE_FAILURE = "SUBSCRIBE_FAILURE";

export const FETCH_SUBSCRIBERS_START = "FETCH_SUBSCRIBERS_START";
export const FETCH_SUBSCRIBERS_SUCCESS = "FETCH_SUBSCRIBERS_SUCCESS";
export const FETCH_SUBSCRIBERS_FAILURE = "FETCH_SUBSCRIBERS_FAILURE";

export const DELETE_SUBSCRIBER_START = "DELETE_SUBSCRIBER_START";
export const DELETE_SUBSCRIBER_SUCCESS = "DELETE_SUBSCRIBER_SUCCESS";
export const DELETE_SUBSCRIBER_FAILURE = "DELETE_SUBSCRIBER_FAILURE";

export const APPROVE_START = "APPROVE_START";
export const APPROVE_SUCCESS = "APPROVE_SUCCESS";
export const APPROVE_FAILURE = "APPROVE_FAILURE";

export const DISAPPROVE_START = "DISAPPROVE_START";
export const DISAPPROVE_SUCCESS = "DISAPPROVE_SUCCESS";
export const DISAPPROVE_FAILURE = "DISAPPROVE_FAILURE";

export const HOLD_START = "HOLD_START";
export const HOLD_SUCCESS = "HOLD_SUCCESS";
export const HOLD_FAILURE = "HOLD_FAILURE";

export const UNHOLD_START = "UNHOLD_START";
export const UNHOLD_SUCCESS = "UNHOLD_SUCCESS";
export const UNHOLD_FAILURE = "UNHOLD_FAILURE";

export const MATCH_START = "MATCH_START";
export const MATCH_SUCCESS = "MATCH_SUCCESS";
export const MATCH_FAILURE = "MATCH_FAILURE";

export const REJECT_START = "REJECT_START";
export const REJECT_SUCCESS = "REJECT_SUCCESS";
export const REJECT_FAILURE = "REJECT_FAILURE";

export const UN_REJECT_START = "UN_REJECT_START";
export const UN_REJECT_SUCCESS = "UN_REJECT_SUCCESS";
export const UN_REJECT_FAILURE = "UN_REJECT_FAILURE";

export const SEARCH_START = "SEARCH_START";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_FAILURE = "SEARCH_FAILURE";

export const UPDATE_PROFILE_START = "UPDATE_PROFILE_START";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";

export const GET_TAB_INDEX_START = "GET_TAB_INDEX_START";
export const GET_TAB_INDEX_SUCCESS = "GET_TAB_INDEX_SUCCESS";
export const GET_TAB_INDEX_FAILURE = "GET_TAB_INDEX_FAILURE";

export const GET_ADMIN_NOTIFICATIONS_START = "GET_ADMIN_NOTIFICATIONS_START";
export const GET_ADMIN_NOTIFICATIONS_SUCCESS =
  "GET_ADMIN_NOTIFICATIONS_SUCCESS";
export const GET_ADMIN_NOTIFICATIONS_FAILURE =
  "GET_ADMIN_NOTIFICATIONS_FAILURE";

export const READ_ADMIN_NOTIFICATIONS_START = "READ_ADMIN_NOTIFICATIONS_START";
export const READ_ADMIN_NOTIFICATIONS_SUCCESS =
  "READ_ADMIN_NOTIFICATIONS_SUCCESS";
export const READ_ADMIN_NOTIFICATIONS_FAILURE =
  "READ_ADMIN_NOTIFICATIONS_FAILURE";

export const GET_REDIRECTION_LINK_START = "GET_REDIRECTION_LINK_START";
export const GET_REDIRECTION_LINK_SUCCESS = "GET_REDIRECTION_LINK_SUCCESS";
export const GET_REDIRECTION_LINK_FAILURE = "GET_REDIRECTION_LINK_FAILURE";

export const GET_TRANSACTION_START = "GET_TRANSACTION_START";
export const GET_TRANSACTION_SUCCESS = "GET_TRANSACTION_SUCCESS";
export const GET_TRANSACTION_FAILURE = "GET_TRANSACTION_FAILURE";

export const CREATE_MATCH_START = "CREATE_MATCH_START";
export const CREATE_MATCH_SUCCESS = "CREATE_MATCH_SUCCESS";
export const CREATE_MATCH_FAILURE = "CREATE_MATCH_FAILURE";

export const GET_MATCH_START = "GET_MATCH_START";
export const GET_MATCH_SUCCESS = "GET_MATCH_SUCCESS";
export const GET_MATCH_FAILURE = "GET_MATCH_FAILURE";

export const GET_ALL_MATCHES_START = "GET_ALL_MATCHES_START";
export const GET_ALL_MATCHES_SUCCESS = "GET_ALL_MATCHES_SUCCESS";
export const GET_ALL_MATCHES_FAILURE = "GET_ALL_MATCHES_FAILURE";

export const APPROVE_MATCH_START = "APPROVE_MATCH_START";
export const APPROVE_MATCH_SUCCESS = "APPROVE_MATCH_SUCCESS";
export const APPROVE_MATCH_FAILURE = "APPROVE_MATCH_FAILURE";

export const GET_PAYMENTS_START = "GET_PAYEMENTS_START";
export const GET_PAYMENTS_SUCCESS = "GET_PAYEMENTS_SUCCESS";
export const GET_PAYMENTS_FAILURE = "GET_PAYEMENTS_FAILURE";

export const PURCHASE_PLAN_START = "PURCHASE_PLAN_START";
export const PURCHASE_PLAN_SUCCESS = "PURCHASE_PLAN_SUCCESS";
export const PURCHASE_PLAN_FAILURE = "PURCHASE_PLAN_FAILURE";
