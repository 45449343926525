import React, { useState, Fragment,} from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@material-ui/core/IconButton";
import "./matchStyles.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  form: {
    background: "#4f4f4f",
    width: "50%",
    position: "relative",
  },
  searchIcon: {
    position: "absolute",
    top: "5px",
    left: "5px",
    cursor: "pointer",
  },
  paper: {
    width: "100%",
    padding: "5px",
    borderRadius: "3px",
    background: "#9e9e9e",
    color: "#000",
    textIndent: "30px",
    caretColor: "#FFA500",
  },
}));

const SearchUser = () => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState({
    term: "",
  });

  return (
    <Fragment>
      <Paper
        component="form"
        className={`${classes.form} search_container`}
        elevation={0}
      >
        <Paper
          className={classes.paper}
          component="input"
          type="search"
          placeholder="Search by country or city"
          autoFocus
          value={searchTerm.term}
          onChange={(event) =>
            setSearchTerm({
              ...searchTerm,
              term: event.target.value,
            })
          }
        />
        <span className={classes.searchIcon}>
          <IconButton size="small">
            <SearchIcon />
          </IconButton>
        </span>
      </Paper>
    </Fragment>
  );
};

export default SearchUser;
