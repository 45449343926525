import { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import "./AupairHome.css";
import Actions from "./Actions";

const Aupair = () => {
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={30}
      loop={true}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      autoplay={true}
      modules={[Pagination, Navigation, Autoplay]}
      className="mySwiper"
    >
      <SwiperSlide>
        <div className="aupair__slide__container">
          <figure className="aupair__slide__img">
            <img
              className="d-block w-100"
              src="https://res.cloudinary.com/dsb0gu6km/image/upload/v1671346850/aupair%20assets/pexels-nappy-3536630_zcqfj4.jpg"
              style={{ width: "100%", height: "100%" }}
              alt="aupair slides"
            />
            <figcaption className="aupair__slide__content">
              <div className="aupair__action__container">
                <div className="aupair__slide__content__wrapper">
                  <h1 className="primary-header">
                    <span className="primary-header-main">
                      DAS AUPAIR MATCHING
                      <strong style={{ color: "orange" }}>®</strong>
                    </span>
                    <span className="primary-header-sub">
                      PLACEMENT SERVICES.
                    </span>
                  </h1>
                </div>
                <div>
                  <Actions />
                </div>
              </div>
            </figcaption>
          </figure>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="aupair__slide__container">
          <figure className="aupair__slide__img">
            <img
              className="d-block w-100"
              src="https://res.cloudinary.com/dsb0gu6km/image/upload/v1671347088/aupair%20assets/pexels-cottonbro-3661452_vw1l3d.jpg"
              alt="aupair slides"
            />
            <figcaption className="aupair__slide__content">
              <div className="aupair__action__container">
                <div className="aupair__slide__content__wrapper">
                  <h1 className="primary-header">
                    <span className="primary-header-main">
                      DAS AUPAIR MATCHING
                      <strong style={{ color: "orange" }}>®</strong>
                    </span>
                    <span className="primary-header-sub">
                      PLACEMENT SERVICES.
                    </span>
                  </h1>
                </div>
                <div>
                  <Actions />
                </div>
              </div>
            </figcaption>
          </figure>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="aupair__slide__container">
          <figure className="aupair__slide__img">
            <img
              className="d-block w-100 slide__images"
              src="https://res.cloudinary.com/dsb0gu6km/image/upload/v1671346945/aupair%20assets/pexels-luna-lovegood-1104014_vbaayx.jpg"
              alt="aupair slides"
            />
            <figcaption className="aupair__slide__content">
              <div className="aupair__action__container">
                <div className="aupair__slide__content__wrapper">
                  <h1 className="primary-header">
                    <span className="primary-header-main">
                      DAS AUPAIR MATCHING
                      <strong style={{ color: "orange" }}>®</strong>
                    </span>
                    <span className="primary-header-sub">
                      PLACEMENT SERVICES.
                    </span>
                  </h1>
                </div>
                <div>
                  <Actions />
                </div>
              </div>
            </figcaption>
          </figure>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="aupair__slide__container">
          <figure className="aupair__slide__img">
            <img
              className="d-block w-100 slide__images"
              src="https://res.cloudinary.com/dsb0gu6km/image/upload/v1671346694/aupair%20assets/pexels-kindel-media-7979773_cgnhdc.jpg"
              alt="aupair slides"
            />
            <figcaption className="aupair__slide__content">
              <div className="aupair__action__container">
                <div className="aupair__slide__content__wrapper">
                  <h1 className="primary-header">
                    <span className="primary-header-main">
                      DAS AUPAIR MATCHING
                      <strong style={{ color: "orange" }}>®</strong>
                    </span>
                    <span className="primary-header-sub">
                      PLACEMENT SERVICES.
                    </span>
                  </h1>
                </div>
                <div>
                  <Actions />
                </div>
              </div>
            </figcaption>
          </figure>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};
export default Aupair;
