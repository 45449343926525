import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Expectation from "./Expectation";
import AupairWishes from "./AupairWishes";
import Profile from "./AupairProfile";
import AupairOtherInfo from "./AupairOtherInfo";

const Aupair = () => {
  const [step, setStep] = React.useState(1);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  switch (step) {
    case 1:
      return (
        <React.Fragment>
          <CssBaseline />
          <Profile nextStep={nextStep} />
        </React.Fragment>
      );
    case 2:
      return (
        <React.Fragment>
          <CssBaseline />
          <Expectation nextStep={nextStep} prevStep={prevStep} />
        </React.Fragment>
      );
    case 3:
      return (
        <React.Fragment>
          <CssBaseline />
          <AupairOtherInfo nextStep={nextStep} prevStep={prevStep} />
        </React.Fragment>
      );
    case 4:
      return (
        <React.Fragment>
          <CssBaseline />
          <AupairWishes prevStep={prevStep} />
        </React.Fragment>
      );
    default:
      return " ";
  }
};

export default Aupair;
