import React from "react";
import { useStyles } from "./style";
import Footer from "../../Footer/NewFooter";
import "./AupairHome.css";
import { Modal, Badge, List, Avatar, Card, Col, Row, Carousel } from "antd";

const myDatalist = [
  {
    id: 1,
    from: "Canada",
    children: 2,
    AupairNeed: "Aupair speaking(English,Dush,spanish)",
    familyStatus: "Classic",
    city: "Big City",
  },
];

const data = [
  {
    id: 1,
    email: "example@gmail.com",
    img: "https://cdn.pixabay.com/photo/2020/08/08/02/56/hacker-5471975_1280.png",
    name: "Jeanndo",
  },
  {
    id: 2,
    email: "example@gmail.com",
    img: "https://cdn.pixabay.com/photo/2022/06/02/08/49/raj-barman-7237422_1280.png",
    name: "Izere",
  },
  {
    id: 3,
    email: "example@gmail.com",
    img: "https://cdn.pixabay.com/photo/2019/12/24/15/38/hacker-4717018_1280.png",
    name: "Chance",
  },
  {
    id: 4,
    email: "example@gmail.com",
    img: "https://cdn.pixabay.com/photo/2020/05/25/11/11/woman-5218160_1280.jpg",
    name: "Didas",
  },
  {
    id: 5,
    email: "example@gmail.com",
    img: "https://cdn.pixabay.com/photo/2017/08/10/21/08/hacker-2627849_1280.png",
    name: "Cyifuzo",
  },
];

const MatchList = () => {
  const classes = useStyles();
  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };

  return (
    <React.Fragment>
      <>
        <Modal
          width={1000}
          title="One Of Your Best Matches"
          visible={visible}
          onOk={handleOk}
          confirmLoading={confirmLoading}
          onCancel={handleCancel}
          footer={null}
        >
          <div className={classes.hostInfoContainer}>
            <div className={classes.ImagePart}>
              <img
                className={classes.Image}
                alt="example"
                src="https://cdn.pixabay.com/photo/2016/11/29/20/22/girl-1871104_960_720.jpg"
              />
            </div>
            <div className={classes.InfoPart}>
              <img
                alt="host avatar"
                src="https://cdn.pixabay.com/photo/2016/11/29/20/22/girl-1871104_960_720.jpg"
                style={{
                  width: "45px",
                  height: "45px",
                  borderRadius: "100px",
                }}
              />
              <span style={{ marginTop: "25px", marginLeft: "20px" }}>
                <strong>Ada Lovelace</strong>
              </span>
              <List
                className={classes.InfoList}
                size="small"
                header={
                  <div>
                    <strong className={classes.about}>About me</strong>
                  </div>
                }
                bordered
              >
                {myDatalist.map((item) => (
                  <div key={item.id}>
                    <List.Item>
                      <div>
                        <label>I am from {item.from}</label>
                      </div>
                    </List.Item>
                    <List.Item>
                      <div>
                        <label>I have {item.children} childrem</label>
                      </div>
                    </List.Item>
                    <List.Item>
                      <div>
                        <label>I am interested in {item.AupairNeed}</label>
                      </div>
                    </List.Item>
                    <List.Item>
                      <div>
                        <label>Family Status {item.familyStatus}</label>
                      </div>
                    </List.Item>

                    <List.Item>
                      <div>
                        <label>City {item.city}</label>
                      </div>
                    </List.Item>
                  </div>
                ))}
              </List>
              <button onClick={handleOk} className={classes.ModalBtn}>
                Connect
              </button>
            </div>
          </div>
          <h2 className={classes.GalleyTitle}>My Galley</h2>
          <div>
            <Carousel autoplay arrows>
              <div>
                <div className="container-fluid mt-4">
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={6}>
                      <Card
                        hoverable
                        cover={
                          <img
                            className="cart-image"
                            alt="example"
                            src="https://cdn.pixabay.com/photo/2016/11/29/20/22/girl-1871104_960_720.jpg"
                          />
                        }
                      />
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <Card
                        hoverable
                        cover={
                          <img
                            className="cart-image"
                            alt="example"
                            src="https://cdn.pixabay.com/photo/2016/11/29/20/22/girl-1871104_960_720.jpg"
                          />
                        }
                      />
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <Card
                        hoverable
                        cover={
                          <img
                            className="cart-image"
                            alt="example"
                            src="https://cdn.pixabay.com/photo/2016/11/29/20/22/girl-1871104_960_720.jpg"
                          />
                        }
                      />
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <Card
                        hoverable
                        cover={
                          <img
                            className="cart-image"
                            alt="example"
                            src="https://cdn.pixabay.com/photo/2016/11/29/20/22/girl-1871104_960_720.jpg"
                          />
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </div>

              <div>
                <div className="container-fluid mt-4 ">
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={6}>
                      <Card
                        hoverable
                        cover={
                          <img
                            className="cart-image"
                            alt="example"
                            src="https://cdn.pixabay.com/photo/2016/11/29/20/22/girl-1871104_960_720.jpg"
                          />
                        }
                      />
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <Card
                        hoverable
                        cover={
                          <img
                            className="cart-image"
                            alt="example"
                            src="https://cdn.pixabay.com/photo/2016/11/29/20/22/girl-1871104_960_720.jpg"
                          />
                        }
                      />
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <Card
                        hoverable
                        cover={
                          <img
                            className="cart-image"
                            alt="example"
                            src="https://cdn.pixabay.com/photo/2016/11/29/20/22/girl-1871104_960_720.jpg"
                          />
                        }
                      />
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <Card
                        hoverable
                        cover={
                          <img
                            className="cart-image"
                            alt="example"
                            src="https://cdn.pixabay.com/photo/2016/11/29/20/22/girl-1871104_960_720.jpg"
                          />
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </Carousel>
          </div>
        </Modal>
      </>
      <div className="match">
        <h1 className="matchTitle">MATCHING HOST FAMILIES</h1>
        <List
          style={{
            padding: "0px 100px 50px 200px",
            width: "90%",
          }}
        >
          {data.map((item) => (
            <div key={item.id}>
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      src={item.img}
                      size={70}
                      className={classes.avatar}
                    />
                  }
                  title={<strong>{item.name}</strong>}
                  description={item.email}
                />
                <div>
                  <Badge status="success" style={{ marginLeft: "-200px" }} />
                </div>
                <div>
                  <button className="connectBtn" onClick={showModal}>
                    View
                  </button>
                </div>
              </List.Item>
            </div>
          ))}
        </List>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default MatchList;
