import React, { useEffect, useState } from "react";
import "./Blog.css";
import { Divider } from "antd";
import RelatedPost from "./RelatedBlog";
import {
  LinkedinShareButton,
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  TwitterShareButton,
} from "react-share";
import {
  LinkedinIcon,
  EmailIcon,
  FacebookIcon,
  WhatsappIcon,
  TelegramIcon,
  TwitterIcon,
} from "react-share";
import Layout from "./../../Layouts/Dashboard";
import { getBlog } from "./../../../Redux/action/blog";
import { connect } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const SingleBlog = ({ getBlog, blog }) => {
  const [open, setOpen] = useState(false);
  const blogId = window.location.href.split("/")[5];

  useEffect(() => {
    const fetchData = () => {
      getBlog(blogId);
    };
    fetchData();
  }, [getBlog, blogId]);

  useEffect(() => {
    if (!blog?.success) {
      setOpen(true);
    } else if (blog?.success) {
      setOpen(false);
    } else if (!blog?.success && blog?.error !== null) {
      setOpen(false);
    }
  }, [blog?.success, blog?.error]);

  return (
    <Layout>
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <div className="single__blog">
        <div className="single__blog__wrapper">
          <div className="single__blog__content">
            <h2 className="single__blog__heading">
              <strong>{blog?.message?.data?.blog?.title}</strong>
            </h2>
            <div className="single__blog__img">
              <img
                className="d-block w-100 h-100"
                src={blog?.message?.data?.blog?.picture}
                alt="rhein"
              />
            </div>
            <div className="single__blog__content__text">
              <div
                className="single__blog__content__text_paragraph"
                style={{ width: "100%" }}
                dangerouslySetInnerHTML={{
                  __html: blog?.message?.data?.blog?.blogText,
                }}
              />
            </div>
            <Divider />
            <h1> share</h1>
            <div className="react__social__media__share">
              <FacebookShareButton
                url={`http://localhost:3000/singleblog/${blogId}`}
                title={`${blog?.message?.data?.blog?.title}<img src={blog?.message?.data?.blog?.picture}>
                `}
              >
                <FacebookIcon size={32} round={true} />
              </FacebookShareButton>
              <LinkedinShareButton
                url={`http://localhost:3000/singleblog/${blogId}`}
                title={`${blog?.message?.data?.blog?.title}<img src={blog?.message?.data?.blog?.picture}>
                `}
              >
                <LinkedinIcon size={32} round={true} />
              </LinkedinShareButton>
              <TwitterShareButton
                url={`http://localhost:3000/singleblog/${blogId}`}
                title={`${blog?.message?.data?.blog?.title}
                `}
              >
                <TwitterIcon size={32} round={true} />
              </TwitterShareButton>
              <EmailShareButton
                url={`http://localhost:3000/singleblog/${blogId}`}
                title={`${blog?.message?.data?.blog?.title}
                `}
              >
                <EmailIcon size={32} round={true} />
              </EmailShareButton>
              <TelegramShareButton
                url={`http://localhost:3000/singleblog/${blogId}`}
                title={`${blog?.message?.data?.blog?.title}
                `}
              >
                <TelegramIcon size={32} round={true} />
              </TelegramShareButton>
              <WhatsappShareButton
                url={`http://localhost:3000/singleblog/${blogId}`}
                title={`${blog?.message?.data?.blog?.title}
                `}
              >
                <WhatsappIcon size={32} round={true} />
              </WhatsappShareButton>
            </div>
            <Divider />
          </div>
        </div>

        <RelatedPost blog={blog?.message?.data?.blog} />
      </div>
    </Layout>
  );
};
const mapStateToProps = (state) => {
  return {
    blog: state.getBlog.blog,
  };
};

export default connect(mapStateToProps, { getBlog })(SingleBlog);
