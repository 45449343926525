import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gallery: {
    message: null,
    success: false,
    error: null,
  },
};

export const gallerySlice = createSlice({
  name: "aupairGallery",
  initialState: initialState,
  reducers: {
    createGallery: (state, action) => {
      return {
        ...state,
        gallery: {
          message: action.payload,
          success: true,
          error: null,
        },
      };
    },
    fetchGallery: (state, action) => {
      return {
        ...state,
        gallery: {
          message: action.payload,
          success: true,
          error: null,
        },
      };
    },

    updateGallery: (state, action) => {
      return {
        ...state,
        gallery: {
          message: action.payload,
          success: true,
          error: null,
        },
      };
    },

    onError: (state, action) => {
      return {
        ...state,
        gallery: {
          message: null,
          success: false,
          error: action.payload,
        },
      };
    },
  },
});

export const { createGallery, fetchGallery, updateGallery, onError } =
  gallerySlice.actions;
export default gallerySlice.reducer;
