import React, { useState, Fragment, useRef, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Editor } from "@tinymce/tinymce-react";
import { connect } from "react-redux";
import { updateTestmony } from "./../../../Redux/action/Testmonials";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  form: {
    padding: "20px",
    background: "#464852",
  },
  paper: {
    display: "block",
    width: "100%",
    padding: "5px",
    marginBottom: "5px",
    borderRadius: "3px",
    background: "#9e9e9e",
    color: "#000",
  },
  button: {
    display: "block",
    width: "150px",
    padding: "5px",
    borderRadius: "0px",
    borderTopRightRadius: "3px",
    borderBottomRightRadius: "3px",
    background: "#3f3f3f",
    color: "#fff",
    marginTop: "10px",
  },
}));

const EditTestmony = ({
  formValues,
  setFormValues,
  updateTestmony,
  testmony,
  testId,
}) => {
  const cloudName = "dsb0gu6km";
  const uploadPreset = "dasaupair";
  const classes = useStyles();
  const editorRef = useRef(null);
  const [open, setOpen] = useState(false);

  var myWidget = window.cloudinary.createUploadWidget(
    {
      cloudName: cloudName,
      uploadPreset: uploadPreset,
      folder: "testmony_images",
    },
    (error, result) => {
      if (!error && result && result.event === "success") {
        if (result?.info?.secure_url) {
          setFormValues({ ...formValues, photo: result?.info?.secure_url });
        }
      }
    }
  );

  const uplaodFile = () => {
    myWidget.open();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true);
    if (editorRef.current) {
      updateTestmony(
        {
          fullName: formValues.name,
          userPicture: formValues.photo,
          jobTitle: formValues.title,
          testmony: editorRef.current.getContent(),
        },
        testId
      );
      clear();
    }
  };

  const clear = () => {
    setFormValues({
      file: "",
      title: "",
      name: "",
      testmony: "",
    });
    editorRef.current.setContent("");
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  useEffect(() => {
    if (testmony?.success) {
      setOpen(false);
    } else if (!testmony?.success && testmony?.error !== null) {
      setOpen(false);
    }
  }, [testmony?.success, testmony?.error]);

  return (
    <Fragment>
      <div>
        <Backdrop style={{ zIndex: 1000, color: "#fff" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Paper
        component="form"
        className={classes.form}
        elevation={0}
        onSubmit={handleSubmit}
      >
        <Tooltip title="upload a photo" arrow>
          <IconButton
            color="default"
            component="span"
            size="small"
            onClick={uplaodFile}
            style={{
              backgroundColor: "#9e9e9e",
              marginBottom: "10px",
            }}
          >
            <CameraAltIcon style={{ color: "#FFA500", fontSize: "20px" }} />
          </IconButton>
        </Tooltip>
        <Paper
          className={classes.paper}
          component="input"
          type="text"
          name="name"
          placeholder="Names"
          value={formValues.name}
          onChange={handleChange}
        />

        <Paper
          className={classes.paper}
          component="input"
          type="text"
          name="title"
          placeholder="Job Title"
          value={formValues.title}
          onChange={handleChange}
        />
        <Editor
          apiKey="q9a2y31zamjbdeaukqwj42rjg6mlm1dlvuxuiujo306mvk3a"
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue={formValues.testmony}
          init={{
            height: 500,
            menubar: true,
            plugins: [
              "advlist",
              "searchreplace",
              "insertdatetime",
              "wordcount",
              "media",
              "paste",
              "table",
              "autolink ",
              "image ",
              "lists ",
              "link ",
              "charmap ",
              "print ",
              "preview ",
              "anchor ",
              "visualblocks ",
              "fullscreen ",
              "code ",
              "help",
            ],
            toolbar:
              "undo" +
              "redo" +
              "formatselect" +
              " bold italic underline code copy paste cut backcolor  | alignleft aligncenter  " +
              " alignright alignjustify alignnone | bullist numlist outdent indent | blockquote " +
              " removeformat remove | help " +
              " fontselect fontsizeselect " +
              " h1 h2 h3 h4 h5 h6 ",

            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
        />

        <Paper className={classes.button} component="button">
          Edit Testmony
        </Paper>
      </Paper>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    testmony: state.updateTestmony.testmony,
  };
};

export default connect(mapStateToProps, { updateTestmony })(EditTestmony);
