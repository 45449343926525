import React, { Fragment, useState, useEffect } from "react";
import "./style.css";
import { Divider } from "antd";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { connect } from "react-redux";
import { getUser } from "./../../../Redux/action/staffMembers";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { rejecthostDocument } from "./../../../Redux/action/Documents";

const MyDocuments = ({
  userId,
  user,
  getUser,
  rejecthostDocument,
  rejected,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchUser = () => {
      getUser(userId);
    };
    if (rejected?.success) {
      fetchUser();
    } else {
      fetchUser();
    }
  }, [userId, getUser, rejected?.success]);

  useEffect(() => {
    if (!user?.success) {
      setOpen(true);
    } else if (user?.success) {
      setOpen(false);
    } else if (!user?.success && user?.error !== null) {
      setOpen(false);
    }
  }, [user?.success, user?.error]);

  const handleRejectDocument = (docId) => {
    rejecthostDocument(docId);
    console.log("rejected", rejected);
  };

  return (
    <Fragment>
      <div>
        <Backdrop style={{ zIndex: 1000, color: "#fff" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Divider />
      <div className="uploader__container__documents">
        {user?.message?.data?.user?.hostDocs?.map((document) => (
          <div key={document?.uuid} className="aupair_document">
            <div className="documentName">
              <h2>{document?.documentName}</h2>
            </div>
            <div
              style={{
                border: "1px solid rgba(0, 0, 0, 0.3)",
                height: "100%",
                width: "500px",
              }}
            >
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
                <Viewer fileUrl={document?.document} initialPage={2} />
              </Worker>
            </div>
            <div className="aupair_document_overlay">
              <div className="document_actions_buttons">
                <Tooltip title="Reject" arrow>
                  <IconButton
                    aria-label="edit"
                    size="small"
                    disabled={document?.rejected}
                    onClick={() => handleRejectDocument(document?.uuid)}
                  >
                    {document?.rejected ? (
                      <ThumbDownAltIcon style={{ color: "#000" }} />
                    ) : (
                      <ThumbDownOffAltIcon style={{ color: "#000" }} />
                    )}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Download" arrow>
                  <IconButton aria-label="edit" size="small">
                    <a
                      href={document?.document}
                      download
                      target="_blank"
                      rel="noreferrer"
                    >
                      <DownloadForOfflineIcon style={{ color: "#000" }} />
                    </a>
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.getUser.user,
    rejected: state.rejectHostDocument.rejected,
  };
};

export default connect(mapStateToProps, { getUser, rejecthostDocument })(
  MyDocuments
);
