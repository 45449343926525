import React, { Fragment } from "react";
import "./style.css";

const PersonalIdentification = ({ Data }) => {
  return (
    <Fragment>
      <div className="personal__data">
        <div className="personal__data__header">
          <h1>Personal Identification</h1>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>First Name:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.firstName}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Last Name:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.lastName}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Date Of Birth:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.birthDate.split("T")[0]}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Family Status:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.familyStatus}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Nationality:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.nationality}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Religion:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.religion}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Is Religion Important:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.religionImpt}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Food preference At Home</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.foodPreference}</label>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default PersonalIdentification;
