import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import Typography from "@material-ui/core/Typography";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";
import {
  getAllNotifications,
  readNotification,
} from "./../../../Redux/action/notifications";
import { connect } from "react-redux";
import "./notifications.css";

const useStyles = makeStyles(styles);

const Notifications = ({
  getAllNotifications,
  notifications,
  readNotification,
  isRead,
}) => {
  const classes = useStyles();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    const loadNotifications = () => {
      getAllNotifications();
    };

    if (isRead?.success) {
      loadNotifications();
    } else {
      loadNotifications();
    }
  }, [getAllNotifications, isRead?.success]);

  const handleReadNotification = (notId) => {
    readNotification(notId);
  };

  return (
    <>
      <Typography variant="h6" onClick={showModal}>
        <Badge
          color="secondary"
          badgeContent={notifications?.message?.Notifications?.length}
          showZero
        >
          <NotificationsIcon className={classes.appBarIcon} />
        </Badge>
      </Typography>
      <Modal
        title="Notifications (Scroll for more)"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={500}
        bodyStyle={{ backgroundColor: "#696969", color: "#fff" }}
      >
        <div className="notificationContainer">
          {notifications?.success &&
            notifications?.message?.Notifications?.map((notification) => (
              <p key={notification?.uuid} className="notification">
                {notification?.notification}
                {!notification?.isRead && <span>new</span>}
                <span
                  className="markAsReady"
                  onClick={() => handleReadNotification(notification?.uuid)}
                >
                  mark as read
                </span>
              </p>
            ))}
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    notifications: state.adminNotifications.notifications,
    isRead: state.readAdminNotification.notification,
  };
};
export default connect(mapStateToProps, {
  getAllNotifications,
  readNotification,
})(Notifications);
