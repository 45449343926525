import {
  SIGNUP_USER_START,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_FAILURE,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_START,
  LOGIN_USER_FAILURE,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  UPDATE_PROFILE_START,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
} from "./actionTypes/types";

import * as api from "../../api/index";
import { toast } from "react-toastify";

export const registerUser = (formData, navigate) => async (dispatch) => {
  dispatch({ type: SIGNUP_USER_START });

  try {
    const { data } = await api.signUp(formData);

    if (data) {
      navigate("/confirm/email");
    }

    return dispatch({
      type: SIGNUP_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error?.response?.data?.error;

    return dispatch({
      type: SIGNUP_USER_FAILURE,
      payload: errorMessage,
    });
  }
};

export const login = (formData, navigate) => async (dispatch) => {
  dispatch({ type: LOGIN_USER_START });
  try {
    const { data } = await api.signIn(formData);

    localStorage.setItem("profile", JSON.stringify(data));

    setTimeout(() => {
      if (data?.data?.user?.role === "aupair") {
        navigate("/aupair/identification");
      } else if (data?.data?.user?.role === "hostfamily") {
        navigate("/host/macthing");
      }

      if (data?.data?.user?.role === "admin") {
        navigate("/dashboard");
      }
    }, 2000);

    return dispatch({
      type: LOGIN_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    let errorMessage = error.message;
    console.log("error", error);
    console.log("errorMessage", errorMessage.split(" ")[5]);

    if (errorMessage.split(" ")[5] === "401") {
      errorMessage = "Unauthorized please signup";
    }
    return dispatch({
      type: LOGIN_USER_FAILURE,
      payload: errorMessage,
    });
  }
};

export const forGotPassword = (formData) => async (dispatch) => {
  dispatch({ type: FORGOT_PASSWORD_START });
  try {
    const { data } = await api.forgotPassword(formData);
    return dispatch({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: FORGOT_PASSWORD_FAILURE,
      payload: errorMessage,
    });
  }
};

export const resetPassword = (formData, token) => async (dispatch) => {
  dispatch({ type: RESET_PASSWORD_START });
  try {
    const { data } = await api.resetPassword(formData, token);
    return dispatch({
      type: RESET_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: RESET_PASSWORD_FAILURE,
      payload: errorMessage,
    });
  }
};

export const updateProfilePicture = (pricture, userId) => async (dispatch) => {
  dispatch({ type: UPDATE_PROFILE_START });
  try {
    const { data } = await api.updateProfilePicture(pricture, userId);

    if (data) {
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    return dispatch({
      type: UPDATE_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    toast.error("There was an Error", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return dispatch({
      type: UPDATE_PROFILE_FAILURE,
      payload: errorMessage,
    });
  }
};
