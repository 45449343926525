import React from "react";
import "./AboutFront.css";
const Contacts = () => (
  <div className="about-front">
    <div className="about-us">
      <h1>About us</h1>
      <p>
        Home <span class="material-icons">arrow_forward_ios</span>
        About Us{" "}
      </p>
    </div>
  </div>
);
export default Contacts;
