import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableHead from "@material-ui/core/TableHead";
import InfoIcon from "@material-ui/icons/Info";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import DeleteModal from "./DeleteModal";
import { useSelector } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Empty } from "antd";
import Tooltip from "@material-ui/core/Tooltip";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#3f3f3f",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#9e9e9e",
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    background: "#464852a9",
    boxShadow: "rgba(0, 0, 0, 0.5) 0px 4px 12px",
  },
});

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(date, fullName, role, phone, email) {
  return { date, fullName, role, phone, email };
}

const rows = [
  createData(
    "2022-08-09",
    "Jean De Dieu UKWITEGETSE",
    "Aupair",
    "0784860836",
    "ukjeando@gmail.com"
  ),
  createData(
    "2022-08-10",
    "Izere Ange Felix",
    "Aupair",
    "0784860836",
    "izere@gmail.com"
  ),
  createData(
    "2022-08-11",
    "Cyifuzo Jean Damascene",
    "Aupair",
    "0784860836",
    "cyifuzo@gmail.com"
  ),
  createData(
    "2022-08-12",
    "Placide TWIRINGIYIMANA",
    "Aupair",
    "0784860836",
    "placide@gmail.com"
  ),
  createData(
    "2022-08-13",
    "Didas NSHIMIYIMANA",
    "Aupair",
    "0784860836",
    "didas@gmail.com"
  ),
  createData(
    "2022-08-14",
    "John Doe",
    "Aupair",
    "0784860836",
    "John@gmail.com"
  ),
];

const MentorsList = ({ setSearchableData }) => {
  const tableStyles = useStyles();
  const { message: searchResult } = useSelector(
    (state) => state.search.searchResults
  );
  const [page, setPage] = useState(0);
  const [mentors, setMentors] = useState(rows);
  const [rowsPerPage, setRowsPerPage] = useState(5);


  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, mentors?.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (rows) {
      setMentors(rows);
      setSearchableData(rows);
    }
  }, [setSearchableData]);

  useEffect(() => {
    if (searchResult) {
      setMentors(searchResult);
    } else if (rows) {
      setMentors(rows);
    }
  }, [searchResult]);

  return (
    <Fragment>
      <div>
        <Backdrop style={{ zIndex: 1000, color: "#fff" }} >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      {mentors?.length === 0 ? (
        <Empty />
      ) : (
        <TableContainer component={Paper}>
          <Table
            className={tableStyles.table}
            aria-label="custom pagination table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell align="right">Full Name</StyledTableCell>
                <StyledTableCell align="right">Role</StyledTableCell>
                <StyledTableCell align="right">Phone</StyledTableCell>
                <StyledTableCell align="right">Email</StyledTableCell>
                <StyledTableCell align="right">Preview</StyledTableCell>
                <StyledTableCell align="right">Approve</StyledTableCell>
                <StyledTableCell align="right">Reject</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? mentors?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : mentors
              ).map((row) => (
                <StyledTableRow key={row.date}>
                  <StyledTableCell component="th" scope="row">
                    {row.date}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.fullName}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.role}</StyledTableCell>
                  <StyledTableCell align="right">{row.phone}</StyledTableCell>
                  <StyledTableCell align="right">{row.email}</StyledTableCell>
                  <StyledTableCell align="right">
                    <Tooltip title="More Info" arrow>
                      <IconButton
                        color="default"
                        component="span"
                        size="small"
                        style={{
                          backgroundColor: "#3f3f3f",
                        }}
                      >
                        <InfoIcon
                          style={{ color: "#FFA500", fontSize: "20px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Tooltip title="Approve" arrow>
                      <IconButton
                        color="default"
                        component="span"
                        size="small"
                        style={{
                          backgroundColor: "#3f3f3f",
                          marginRight: "10px",
                        }}
                      >
                        <DoneAllIcon
                          style={{ color: "#FFA500", fontSize: "20px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <DeleteModal />
                  </StyledTableCell>
                </StyledTableRow>
              ))}

              {emptyRows > 0 && (
                <StyledTableRow style={{ height: 53 * emptyRows }}>
                  <StyledTableCell colSpan={6} />
                </StyledTableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={3}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
    </Fragment>
  );
};

export default MentorsList;
