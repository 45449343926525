import * as type from "../action/actionTypes/types";
const initialState = {
  payments: {
    loading: false,
    message: null,
    success: false,
    error: null,
  },
};

const allPaymentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.GET_PAYMENTS_START:
      return {
        ...state,
        payments: {
          loading: true,
          message: null,
          success: false,
          error: null,
        },
      };
    case type.GET_PAYMENTS_SUCCESS:
      return {
        ...state,
        payments: {
          loading: false,
          message: action.payload,
          success: true,
          error: null,
        },
      };
    case type.GET_PAYMENTS_FAILURE:
      return {
        ...state,
        payments: {
          loading: false,
          message: null,
          success: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default allPaymentsReducer;
