import React, { useState, Fragment, useEffect } from "react";
import MatchingCards from "./matchingCards";
import AupairMatchList from "./HostMatches";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import { getPayments } from "./../../../../../../../../Redux/action/payment";
import { Loader } from "./../../../../../../../../utils/loader";

const HostMacthes = ({ getPayments, payments, purchasedPlan }) => {
  const [hasSubscription, setHasSubscription] = useState(false);
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(true);
  const [paidUser, setPaidUser] = useState(null);

  const { user } = useSelector((state) => state.getUser);

  useEffect(() => {
    const fetchAllPayments = () => {
      getPayments();
    };

    if (purchasedPlan?.success) {
      fetchAllPayments();
    } else {
      fetchAllPayments();
    }
  }, [getPayments, purchasedPlan?.success]);

  useEffect(() => {
    const paymentChecker = () => {
      setPaidUser(
        payments?.message?.payments?.find(
          (payment) =>
            payment?.user?.uuid === user?.message?.data?.user?.uuid &&
            payment.subStatus === "active"
        )
      );
    };

    if (purchasedPlan?.success) {
      paymentChecker();
    } else {
      paymentChecker();
    }

    if (paidUser) {
      setHasSubscription(true);
      setTransaction(paidUser);
    } else {
      setHasSubscription(false);
      setTransaction(null);
    }
  }, [
    payments?.message?.payments,
    user?.message?.data?.user?.uuid,
    paidUser,
    purchasedPlan?.success,
  ]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <Fragment>
      <>
        {loading ? (
          <Loader />
        ) : (
          <Fragment>
            {!hasSubscription ? (
              <MatchingCards />
            ) : (
              <AupairMatchList transaction={transaction} />
            )}
          </Fragment>
        )}
      </>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    payments: state.payments.payments,
    purchasedPlan: state.purchasePlan.subscription,
  };
};

export default connect(mapStateToProps, { getPayments })(HostMacthes);
