import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CollectionsIcon from "@mui/icons-material/Collections";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import JoinInnerIcon from "@mui/icons-material/JoinInner";
import ListItemText from "@material-ui/core/ListItemText";
import { NavLink } from "react-router-dom";
import { styles } from "./styles";
import Tooltip from "@material-ui/core/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { getLayoutTabIndex } from "./../../../Redux/action/tabs";

// AUPAIR REDESIGNING

import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";
import SchoolIcon from "@material-ui/icons/School";
import InfoIcon from "@material-ui/icons/Info";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import HomeWorkIcon from "@material-ui/icons/HomeWork";

const useStyles = makeStyles(styles);

const MainListItems = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { index } = useSelector((state) => state.layoutTabs.tab);

  const handleTabs = (indexNumber) => {
    dispatch(getLayoutTabIndex(indexNumber));
  };

  return (
    <div className={classes.sideBar}>
      <Tooltip title="IDENTIFICATION" arrow>
        <NavLink
          to="/aupair/identification"
          className={classes.sideBarLink}
          onClick={() => handleTabs(1)}
        >
          <ListItem
            button
            className={
              index === 1
                ? `${classes.sideBarLink} ${classes.activeLink}`
                : `${classes.sideBarLink}`
            }
          >
            <ListItemIcon
              className={
                index === 1 ? `${classes.activeIcon}` : `${classes.sideBarIcon}`
              }
            >
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="IDENTIFICATION" />
          </ListItem>
        </NavLink>
      </Tooltip>
      <Tooltip title="CONTACT" arrow>
        <NavLink
          to="/aupair/contact"
          className={classes.sideBarLink}
          onClick={() => handleTabs(2)}
        >
          <ListItem
            button
            className={
              index === 2
                ? `${classes.sideBarLink} ${classes.activeLink}`
                : `${classes.sideBarLink}`
            }
          >
            <ListItemIcon
              className={
                index === 2 ? `${classes.activeIcon}` : `${classes.sideBarIcon}`
              }
            >
              <PhoneIcon />
            </ListItemIcon>
            <ListItemText primary="CONTACT" />
          </ListItem>
        </NavLink>
      </Tooltip>
      <Tooltip title="EDUCATION" arrow>
        <NavLink
          to="/aupair/education"
          className={classes.sideBarLink}
          onClick={() => handleTabs(3)}
        >
          <ListItem
            button
            className={
              index === 3
                ? `${classes.sideBarLink} ${classes.activeLink}`
                : `${classes.sideBarLink}`
            }
          >
            <ListItemIcon
              className={
                index === 3 ? `${classes.activeIcon}` : `${classes.sideBarIcon}`
              }
            >
              <SchoolIcon />
            </ListItemIcon>
            <ListItemText primary="EDUCATION" />
          </ListItem>
        </NavLink>
      </Tooltip>
      <Tooltip title="MORE INFO" arrow>
        <NavLink
          to="/aupair/moreinfo"
          className={classes.sideBarLink}
          onClick={() => handleTabs(4)}
        >
          <ListItem
            button
            className={
              index === 4
                ? `${classes.sideBarLink} ${classes.activeLink}`
                : `${classes.sideBarLink}`
            }
          >
            <ListItemIcon
              className={
                index === 4 ? `${classes.activeIcon}` : `${classes.sideBarIcon}`
              }
            >
              <InfoIcon />
            </ListItemIcon>
            <ListItemText primary="MORE ABOUT ME" />
          </ListItem>
        </NavLink>
      </Tooltip>
      <Tooltip title="PREFERENCES" arrow>
        <NavLink
          to="/aupair/preferences"
          className={classes.sideBarLink}
          onClick={() => handleTabs(5)}
        >
          <ListItem
            button
            className={
              index === 5
                ? `${classes.sideBarLink} ${classes.activeLink}`
                : `${classes.sideBarLink}`
            }
          >
            <ListItemIcon
              className={
                index === 5 ? `${classes.activeIcon}` : `${classes.sideBarIcon}`
              }
            >
              <SupervisedUserCircleIcon />
            </ListItemIcon>
            <ListItemText primary="PREFERENCES" />
          </ListItem>
        </NavLink>
      </Tooltip>
      <Tooltip title="EXPECTATIONS" arrow>
        <NavLink
          to="/aupair/expectations"
          className={classes.sideBarLink}
          onClick={() => handleTabs(6)}
        >
          <ListItem
            button
            className={
              index === 6
                ? `${classes.sideBarLink} ${classes.activeLink}`
                : `${classes.sideBarLink}`
            }
          >
            <ListItemIcon
              className={
                index === 6 ? `${classes.activeIcon}` : `${classes.sideBarIcon}`
              }
            >
              <CardGiftcardIcon />
            </ListItemIcon>
            <ListItemText primary="EXPECTATIONS" />
          </ListItem>
        </NavLink>
      </Tooltip>
      <Tooltip title="WISHES" arrow>
        <NavLink
          to="/aupair/wishes"
          className={classes.sideBarLink}
          onClick={() => handleTabs(7)}
        >
          <ListItem
            button
            className={
              index === 7
                ? `${classes.sideBarLink} ${classes.activeLink}`
                : `${classes.sideBarLink}`
            }
          >
            <ListItemIcon
              className={
                index === 7 ? `${classes.activeIcon}` : `${classes.sideBarIcon}`
              }
            >
              <HomeWorkIcon />
            </ListItemIcon>
            <ListItemText primary="WISHES" />
          </ListItem>
        </NavLink>
      </Tooltip>
      <Tooltip title="GALLERY" arrow>
        <NavLink
          to="/aupair/gallery"
          className={classes.sideBarLink}
          onClick={() => handleTabs(8)}
        >
          <ListItem
            button
            className={
              index === 8
                ? `${classes.sideBarLink} ${classes.activeLink}`
                : `${classes.sideBarLink}`
            }
          >
            <ListItemIcon
              className={
                index === 8 ? `${classes.activeIcon}` : `${classes.sideBarIcon}`
              }
            >
              <CollectionsIcon />
            </ListItemIcon>
            <ListItemText primary="GALLERY" />
          </ListItem>
        </NavLink>
      </Tooltip>
      <Tooltip title="DOCUMENTS" arrow>
        <NavLink
          to="/aupair/documents"
          className={classes.sideBarLink}
          onClick={() => handleTabs(9)}
        >
          <ListItem
            button
            className={
              index === 9
                ? `${classes.sideBarLink} ${classes.activeLink}`
                : `${classes.sideBarLink}`
            }
          >
            <ListItemIcon
              className={
                index === 9 ? `${classes.activeIcon}` : `${classes.sideBarIcon}`
              }
            >
              <FolderOpenIcon />
            </ListItemIcon>
            <ListItemText primary="DOCUMENTS" />
          </ListItem>
        </NavLink>
      </Tooltip>
      <Tooltip title="MATCHING" arrow>
        <NavLink
          to="/aupair/matching"
          className={classes.sideBarLink}
          onClick={() => handleTabs(10)}
        >
          <ListItem
            button
            className={
              index === 10
                ? `${classes.sideBarLink} ${classes.activeLink}`
                : `${classes.sideBarLink}`
            }
          >
            <ListItemIcon
              className={
                index === 10
                  ? `${classes.activeIcon}`
                  : `${classes.sideBarIcon}`
              }
            >
              <JoinInnerIcon />
            </ListItemIcon>
            <ListItemText primary="MATCHING" />
          </ListItem>
        </NavLink>
      </Tooltip>
    </div>
  );
};

export default MainListItems;
