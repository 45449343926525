import React, { Component } from "react";
import Aux from "../../hoc/Auxi/Auxilliary";
import Layout from "./LayoutCenter/Layout";
import AboutPage from "../../Components/Page/About/About";
import AboutFront from "../../Components/Page/About/AboutFront/AboutFront";
import Footer from "../../Components/Footer/NewFooter";
import Contact from "../../Components/ResponsiveNavBar/Contacts/Contacts";
import Navigation from "../../Components/ResponsiveNavBar/Nvabar";
import "./AboutCenter.css";

class Home extends Component {
  render() {
    return (
      <>
        <Contact />
        <Navigation />
        <Aux>
          <Layout>
            <AboutFront />
            <AboutPage />
            <Footer />
          </Layout>
        </Aux>
      </>
    );
  }
}
export default Home;
