import * as type from "../action/actionTypes/types";
const initialState = {
  documents: {
    loading: false,
    message: null,
    success: false,
    error: null,
  },
};

const getAllHostDocumentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.GET_HOST_DOCUMENTS_START:
      return {
        ...state,
        documents: {
          loading: true,
          message: null,
          success: false,
          error: null,
        },
      };
    case type.GET_HOST_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: {
          loading: false,
          message: action.payload,
          success: true,
          error: null,
        },
      };
    case type.GET_HOST_DOCUMENTS_FAILURE:
      return {
        ...state,
        documents: {
          loading: false,
          message: null,
          success: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default getAllHostDocumentsReducer;
