import React from "react";
import "./expections.css";

const Duties = () => {
  return (
    <div className="familyExpectations">
        <h1>EXPECTATIONS TO THE THE HOST FAMILY </h1>

      <div className="familyExpectations-container">
          <div className="familyExpectations-item--1">
            <div className="familyExpectations-item--1-panel">
              <h2>
                A host family could be anyone who have least one child under the
                age of 16. This also applies to single parents and the
                followings are expectations, duties, and responsibilities:
              </h2>
            </div>
          </div>
          <div
            whileHover={{ scale: 1, rotate: -5, backgroundColor: "#000" }}
            className="familyExpectations-item--2"
          >
            <ul>
              <li>
                Be ready to welcome an Aupair girl/ boy as a temporary new
                family member.
              </li>
              <li>
                Provide the Aupair an appropriate accommodation accessible with
                daylight.
              </li>
              <li>
                The accommodation facility may have windows and a lockable door.
              </li>
              <li>Being able to pay the Aupair pocket money / 280 Euro </li>
              <li>
                This pocket money can be paid either on a weekly/monthly basis
                as agreed.
              </li>
              <li>
                Paying the health insurance to Aupair that shall cover disease,
                hospital care, medical care, pharmaceutical expenses, pregnancy,
                and birth is a must.
              </li>
              <li>Provide the Aupair meals and drinks.</li>
              <li>Au Pair should not work for more than 20 hours per week.</li>
              <li>Au Pair should not work for more than 6 hours per day.</li>
              <li>The Au Pair is entitled to have 2 free days per week. </li>
              <li>
                Among the monthly free days is at least one full free weekend.
              </li>
              <li>Au Pair receives for every full month 2 days holiday.</li>
              <li>
                You would like to be supported by Aupair with childcare and
                light housework.
              </li>
              <li>Help the Aupair to improve their language skills at home.</li>
            </ul>
          </div>
      </div>
    </div>
  );
};
export default Duties;
