import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createHostPhoto } from "./../../../../../../../../Redux/action/Gallery";
import { toast } from "react-toastify";

const CloudinaryUploadWidget = ({ createHostPhoto, newPhoto }) => {
  const cloudName = "dsb0gu6km";
  const uploadPreset = "dasaupair";

  var myWidget = window.cloudinary.createUploadWidget(
    {
      cloudName: cloudName,
      uploadPreset: uploadPreset,
    },
    (error, result) => {
      if (!error && result && result.event === "success") {
        if (result?.info?.secure_url) {
          createHostPhoto({ photo: result?.info?.secure_url });
        }
      }
    }
  );

  const uplaodFile = () => {
    myWidget.open();
  };
  useEffect(() => {
    if (newPhoto?.success) {
      toast.success(newPhoto?.message?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [newPhoto?.message?.message, newPhoto?.success]);

  return (
    <button className="cloudinary-button" onClick={uplaodFile}>
      Add TO GALLERY
    </button>
  );
};

const mapStateToProps = (state) => {
  return {
    newPhoto: state.createHostPhoto.photo,
  };
};
export default connect(mapStateToProps, { createHostPhoto })(
  CloudinaryUploadWidget
);
