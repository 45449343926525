import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Layout from "./../../Layouts/Dashboard";
import CreateAnnouncement from "./Modal";
import Search from "./Search";
import Anouncements from "./Announcements";
import { Divider } from "antd";
import { NotificationOutlined } from "@ant-design/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    fontSize: "20px",
    textAlign: "center",
    fontWeight: 700,
    color: "#9e9e9e",
    textTransform: "uppercase",
  },
}));

const Announcements = () => {
  const classes = useStyles();
  const [searchableData, setSearchableData] = useState([]);

  return (
    <Layout>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} xlg={12}>
            <h1 className={classes.title}>
              Announcements &nbsp;
              <NotificationOutlined style={{ color: "#FFA500" }} />
            </h1>
          </Grid>
          <Divider />
          <Grid item xs={12} sm={12} md={4} lg={4} xlg={4}>
            <CreateAnnouncement />
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xlg={8}>
            <Search searchableData={searchableData} />
          </Grid>
          <Divider />
          <Grid item xs={12} sm={12} md={12} lg={12} xlg={12}>
            <Anouncements setSearchableData={setSearchableData} />
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
};

export default Announcements;
