/* eslint-disable jsx-a11y/alt-text */
import React, { useState, Fragment, useEffect } from "react";
import "./Aupair.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import UploadNewImage from "./documentUploaders/uploadImage";
import { Divider } from "antd";
import { fetchAupairPhotos } from "./../../../../../../../Redux/action/Gallery";
import { connect } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditPhoto from "./documentUploaders/editPhoto";
import DeletePhoto from "./documentUploaders/deletePhoto";
import { Empty } from "antd";

const MyGallery = ({
  fetchAupairPhotos,
  photos,
  photo,
  updatedPhoto,
  deletedPhoto,
}) => {
  const [slideNumber, setSlideNumber] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpenModal = (index) => {
    setSlideNumber(index);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const prevSlide = () => {
    slideNumber === 0
      ? setSlideNumber(photos?.message?.data?.photos.length - 1)
      : setSlideNumber(slideNumber - 1);
  };

  const nextSlide = () => {
    slideNumber + 1 === photos?.message?.data?.photos.length
      ? setSlideNumber(0)
      : setSlideNumber(slideNumber + 1);
  };

  useEffect(() => {
    const getAllAupairPhotos = () => {
      fetchAupairPhotos();
    };
    if (photo?.success || updatedPhoto?.success || deletedPhoto?.success) {
      getAllAupairPhotos();
    } else {
      getAllAupairPhotos();
    }
  }, [
    fetchAupairPhotos,
    photo.success,
    updatedPhoto?.success,
    deletedPhoto?.success,
  ]);

  useEffect(() => {
    if (!photos.success) {
      setOpen(true);
    } else if (photos.success) {
      setOpen(false);
    } else if (!photos.success && photos.error !== null) {
      setOpen(false);
    }
  }, [photos.success, photos.error]);

  return (
    <Fragment>
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      {openModal && (
        <div className="slideWrapper">
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="btnClose"
            onClick={handleCloseModal}
          />
          <FontAwesomeIcon
            icon={faCircleArrowLeft}
            className="btnPrev"
            onClick={prevSlide}
          />
          <FontAwesomeIcon
            icon={faCircleArrowRight}
            className="btnNext"
            onClick={nextSlide}
          />
          <div className="fullScreenImage">
            <img
              src={photos?.message?.data?.photos[slideNumber]?.photo}
              alt=""
            />
            <div className="imageOverlay">
              <div className="host_image_action_buttons">
                <EditPhoto doc={photos?.message?.data?.photos[slideNumber]} />
                <DeletePhoto doc={photos?.message?.data?.photos[slideNumber]} />
              </div>
            </div>
          </div>
        </div>
      )}
      <UploadNewImage />
      <Divider />
      {photos?.message?.data?.photos.length !== 0 ? (
        <div className="galleryWrapper">
          {photos?.success &&
            photos?.message?.data?.photos?.map((slide, index) => (
              <div
                className="single"
                key={slide.photo && slide.uuid}
                onClick={() => handleOpenModal(index)}
              >
                <img src={slide.photo && slide.photo} />
              </div>
            ))}
        </div>
      ) : (
        <Empty />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    photos: state.allAupairPhotos.photos,
    photo: state.addAupairPhoto.photo,
    updatedPhoto: state.updatedAupairPhoto.photo,

    deletedPhoto: state.deletedAupairPhoto.photo,
  };
};

export default connect(mapStateToProps, { fetchAupairPhotos })(MyGallery);
