import * as type from "../action/actionTypes/types";

const initialState = {
  tab: {
    index: 1,
  },
};

const getLayoutTabReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.GET_TAB_INDEX_START:
      return {
        ...state,
        tab: {
          index: 1,
        },
      };
    case type.GET_TAB_INDEX_SUCCESS:
      return {
        ...state,
        tab: {
          index: action.payload,
        },
      };
    case type.GET_TAB_INDEX_FAILURE:
      return {
        ...state,
        tab: {
          index: action.payload,
        },
      };
    default:
      return state;
  }
};

export default getLayoutTabReducer;
