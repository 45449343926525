import React, { Fragment } from "react";
import "./HostFront.css";
import { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import Actions from "./Actions";

const Front = () => {
  return (
    <Fragment>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        autoplay={true}
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="aupair__slide__container">
            <figure className="aupair__slide__img">
              <img
                className="d-block w-100"
                src="https://images.pexels.com/photos/7977929/pexels-photo-7977929.jpeg?auto=compress&cs=tinysrgb&w=1600"
                style={{ width: "100%", height: "100%" }}
                alt="aupair slides"
              />
              <figcaption className="aupair__slide__content">
                <div className="aupair__action__container">
                  <div className="aupair__slide__content__wrapper">
                    <h1 className="primary-header">
                      <span className="primary-header-main">
                        DAS AUPAIR MATCHING
                        <strong style={{ color: "orange" }}>®</strong>
                      </span>
                      <span className="primary-header-sub">
                        PLACEMENT SERVICES.
                      </span>
                    </h1>
                  </div>
                  <div>
                    <Actions />
                  </div>
                </div>
              </figcaption>
            </figure>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="aupair__slide__container">
            <figure className="aupair__slide__img">
              <img
                className="d-block w-100"
                src="https://images.pexels.com/photos/4833957/pexels-photo-4833957.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="aupair slides"
              />
              <figcaption className="aupair__slide__content">
                <div className="aupair__action__container">
                  <div className="aupair__slide__content__wrapper">
                    <h1 className="primary-header">
                      <span className="primary-header-main">
                        DAS AUPAIR MATCHING
                        <strong style={{ color: "orange" }}>®</strong>
                      </span>
                      <span className="primary-header-sub">
                        PLACEMENT SERVICES.
                      </span>
                    </h1>
                  </div>
                  <div>
                    <Actions />
                  </div>
                </div>
              </figcaption>
            </figure>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="aupair__slide__container">
            <figure className="aupair__slide__img">
              <img
                className="d-block w-100 slide__images"
                src="https://images.pexels.com/photos/6617770/pexels-photo-6617770.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="aupair slides"
              />
              <figcaption className="aupair__slide__content">
                <div className="aupair__action__container">
                  <div className="aupair__slide__content__wrapper">
                    <h1 className="primary-header">
                      <span className="primary-header-main">
                        DAS AUPAIR MATCHING
                        <strong style={{ color: "orange" }}>®</strong>
                      </span>
                      <span className="primary-header-sub">
                        PLACEMENT SERVICES.
                      </span>
                    </h1>
                  </div>
                  <div>
                    <Actions />
                  </div>
                </div>
              </figcaption>
            </figure>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="aupair__slide__container">
            <figure className="aupair__slide__img">
              <img
                className="d-block w-100 slide__images"
                src="https://images.pexels.com/photos/7937999/pexels-photo-7937999.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="aupair slides"
              />
              <figcaption className="aupair__slide__content">
                <div className="aupair__action__container">
                  <div className="aupair__slide__content__wrapper">
                    <h1 className="primary-header">
                      <span className="primary-header-main">
                        DAS AUPAIR MATCHING
                        <strong style={{ color: "orange" }}>®</strong>
                      </span>
                      <span className="primary-header-sub">
                        PLACEMENT SERVICES.
                      </span>
                    </h1>
                  </div>
                  <div>
                    <Actions />
                  </div>
                </div>
              </figcaption>
            </figure>
          </div>
        </SwiperSlide>
      </Swiper>
    </Fragment>
  );
};

export default Front;
