import {
  CREATE_AUPAIR_GALLERY_START,
  CREATE_AUPAIR_GALLERY_SUCCESS,
  CREATE_AUPAIR_GALLERY_FAILURE,
  GET_AUPAIR_GALLERIES_START,
  GET_AUPAIR_GALLERIES_SUCCESS,
  GET_AUPAIR_GALLERIES_FAILURE,
  GET_AUPAIR_GALLERY_START,
  GET_AUPAIR_GALLERY_SUCCESS,
  GET_AUPAIR_GALLERY_FAILURE,
  UPDATE_AUPAIR_GALLERY_START,
  UPDATE_AUPAIR_GALLERY_SUCCESS,
  UPDATE_AUPAIR_GALLERY_FAILURE,
  DELETE_AUPAIR_GALLERY_START,
  DELETE_AUPAIR_GALLERY_SUCCESS,
  DELETE_AUPAIR_GALLERY_FAILURE,
  CREATE_HOST_GALLERY_START,
  CREATE_HOST_GALLERY_SUCCESS,
  CREATE_HOST_GALLERY_FAILURE,
  GET_HOST_GALLERIES_START,
  GET_HOST_GALLERIES_SUCCESS,
  GET_HOST_GALLERIES_FAILURE,
  GET_HOST_GALLERY_START,
  GET_HOST_GALLERY_SUCCESS,
  GET_HOST_GALLERY_FAILURE,
  UPDATE_HOST_GALLERY_START,
  UPDATE_HOST_GALLERY_SUCCESS,
  UPDATE_HOST_GALLERY_FAILURE,
  DELETE_HOST_GALLERY_START,
  DELETE_HOST_GALLERY_SUCCESS,
  DELETE_HOST_GALLERY_FAILURE,
  REJECT_AUPAIR_GALLERY_START,
  REJECT_AUPAIR_GALLERY_SUCCESS,
  REJECT_AUPAIR_GALLERY_FAILURE,
  REJECT_HOST_GALLERY_START,
  REJECT_HOST_GALLERY_SUCCESS,
  REJECT_HOST_GALLERY_FAILURE,
  RESTORE_AUPAIR_GALLERY_START,
  RESTORE_AUPAIR_GALLERY_SUCCESS,
  RESTORE_AUPAIR_GALLERY_FAILURE,
  RESTORE_HOST_GALLERY_START,
  RESTORE_HOST_GALLERY_SUCCESS,
  RESTORE_HOST_GALLERY_FAILURE,
} from "../action/actionTypes/types";
import * as api from "../../api/index";

export const fetchAupairPhotos = () => async (dispatch) => {
  dispatch({
    type: GET_AUPAIR_GALLERIES_START,
  });
  try {
    const { data } = await api.getAupairPhotos();
    return dispatch({
      type: GET_AUPAIR_GALLERIES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: GET_AUPAIR_GALLERIES_FAILURE,
      payload: errorMessage,
    });
  }
};

export const deleteAupairPhoto = (photoId) => async (dispatch) => {
  dispatch({
    type: DELETE_AUPAIR_GALLERY_START,
  });
  try {
    const { data } = await api.deleteAupairPhoto(photoId);
    return dispatch({
      type: DELETE_AUPAIR_GALLERY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: DELETE_AUPAIR_GALLERY_FAILURE,
      payload: errorMessage,
    });
  }
};

export const createAupairPhoto = (photo) => async (dispatch) => {
  dispatch({
    type: CREATE_AUPAIR_GALLERY_START,
  });

  try {
    const { data } = await api.addGallery(photo);
    return dispatch({
      type: CREATE_AUPAIR_GALLERY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: CREATE_AUPAIR_GALLERY_FAILURE,
      payload: errorMessage,
    });
  }
};

export const getAupairPhoto = (photoId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_AUPAIR_GALLERY_START,
    });

    const { data } = await api.getAupairPhoto(photoId);

    return dispatch({ type: GET_AUPAIR_GALLERY_SUCCESS, payload: data });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: GET_AUPAIR_GALLERY_FAILURE,
      payload: errorMessage,
    });
  }
};

export const updateAupairPhoto = (photo, photoId) => async (dispatch) => {
  dispatch({
    type: UPDATE_AUPAIR_GALLERY_START,
  });
  try {
    const { data } = await api.updateAupairPhoto(photo, photoId);
    return dispatch({
      type: UPDATE_AUPAIR_GALLERY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: UPDATE_AUPAIR_GALLERY_FAILURE,
      payload: errorMessage,
    });
  }
};

export const fetchHostPhotos = () => async (dispatch) => {
  dispatch({
    type: GET_HOST_GALLERIES_START,
  });
  try {
    const { data } = await api.getHostPhotos();
    return dispatch({
      type: GET_HOST_GALLERIES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: GET_HOST_GALLERIES_FAILURE,
      payload: errorMessage,
    });
  }
};

export const deleteHostPhoto = (photoId) => async (dispatch) => {
  dispatch({
    type: DELETE_HOST_GALLERY_START,
  });
  try {
    const { data } = await api.deleteHostPhoto(photoId);
    return dispatch({
      type: DELETE_HOST_GALLERY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: DELETE_HOST_GALLERY_FAILURE,
      payload: errorMessage,
    });
  }
};

export const createHostPhoto = (photo) => async (dispatch) => {
  dispatch({
    type: CREATE_HOST_GALLERY_START,
  });

  try {
    const { data } = await api.addHostGallery(photo);
    return dispatch({
      type: CREATE_HOST_GALLERY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: CREATE_HOST_GALLERY_FAILURE,
      payload: errorMessage,
    });
  }
};

export const getHostPhoto = (photoId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_HOST_GALLERY_START,
    });

    const { data } = await api.getHostPhoto(photoId);

    return dispatch({ type: GET_HOST_GALLERY_SUCCESS, payload: data });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: GET_HOST_GALLERY_FAILURE,
      payload: errorMessage,
    });
  }
};

export const updateHostPhoto = (photo, photoId) => async (dispatch) => {
  dispatch({
    type: UPDATE_HOST_GALLERY_START,
  });

  try {
    const { data } = await api.updateHostPhoto(photo, photoId);

    return dispatch({
      type: UPDATE_HOST_GALLERY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: UPDATE_HOST_GALLERY_FAILURE,
      payload: errorMessage,
    });
  }
};

export const rejectAupairGallery = (docId) => async (dispatch) => {
  dispatch({
    type: REJECT_AUPAIR_GALLERY_START,
  });
  try {
    const { data } = await api.rejectAupairPhoto(docId);
    return dispatch({
      type: REJECT_AUPAIR_GALLERY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: REJECT_AUPAIR_GALLERY_FAILURE,
      payload: errorMessage,
    });
  }
};

export const restoreAupairGallery = (docId) => async (dispatch) => {
  dispatch({
    type: RESTORE_AUPAIR_GALLERY_START,
  });
  try {
    const { data } = await api.restoreAupairPhoto(docId);

    return dispatch({
      type: RESTORE_AUPAIR_GALLERY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: RESTORE_AUPAIR_GALLERY_FAILURE,
      payload: errorMessage,
    });
  }
};

export const rejecthostGallery = (docId) => async (dispatch) => {
  dispatch({
    type: REJECT_HOST_GALLERY_START,
  });
  try {
    const { data } = await api.rejectHostPhoto(docId);
    return dispatch({
      type: REJECT_HOST_GALLERY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: REJECT_HOST_GALLERY_FAILURE,
      payload: errorMessage,
    });
  }
};

export const restoreHostGallery = (docId) => async (dispatch) => {
  dispatch({
    type: RESTORE_HOST_GALLERY_START,
  });
  try {
    const { data } = await api.restoreHostPhoto(docId);
    return dispatch({
      type: RESTORE_HOST_GALLERY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: RESTORE_HOST_GALLERY_FAILURE,
      payload: errorMessage,
    });
  }
};
