import React, { useState, Fragment } from "react";
import "./styles.css";
import { MenueData } from "./MenuData";
import { NavLink } from "react-router-dom";
import LoginForm from "./../Account/LoginForm";
import RegistrationForm from "./../Account/RegistrationForm";
import { Modal } from "antd";

const MyNavBar = () => {
  const [clicked, setClicked] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isSignup, setIsSignUp] = useState(false);

  const toggleNavBar = () => {
    setClicked((prev) => !prev);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Fragment>
      <Modal
        title={isSignup ? "REGISTER" : "LOGIN"}
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        width="35%"
        className="authModal"
      >
        {isSignup ? (
          <RegistrationForm setIsSignUp={setIsSignUp} />
        ) : (
          <LoginForm setIsSignUp={setIsSignUp} />
        )}
      </Modal>
      <nav className="myNavItems">
        <NavLink to="/" className="myNavItems_logo">
          <img
            className="navlogo"
            src="https://res.cloudinary.com/dsb0gu6km/image/upload/v1671344806/aupair%20assets/icon_egokws.png"
            alt="aupair-logo"
          />
          <div className="logo_text">
            <span className="logo_das">Das</span>
            <span className="logo_das_aupair">Aupair</span>
            <span className="logo_das_matching">Matching</span>
          </div>
        </NavLink>
        <div className="menu-icons" onClick={toggleNavBar}>
          <i className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
        <ul className={clicked ? "nav-menu active-menu" : "nav-menu"}>
          {MenueData.map((item, index) => {
            return (
              <li key={index}>
                <NavLink
                  to={item.url}
                  className={({ isActive }) =>
                    isActive ? `${item.cName} activeTabLink` : item.cName
                  }
                  onClick={item.title === "LOGIN" && showModal}
                >
                  {item.title}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
    </Fragment>
  );
};

export default MyNavBar;
