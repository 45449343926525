import React, { useState, Fragment } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  form: {
    padding: "20px",
    background: "#464852",
  },
  paper: {
    display: "block",
    width: "100%",
    padding: "5px",
    marginBottom: "5px",
    borderRadius: "3px",
    background: "#9e9e9e",
    color: "#000",
  },
  button: {
    display: "block",
    width: "150px",
    padding: "5px",
    borderRadius: "0px",
    borderTopRightRadius: "3px",
    borderBottomRightRadius: "3px",
    background: "#3f3f3f",
    color: "#fff",
    marginTop: "10px",
  },
}));

const initialValues = {
  category: "",
};

const CreateBlog = () => {
  const classes = useStyles();

  const [formValues, setFormValues] = useState(initialValues);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formValues);
    clear();
  };

  const clear = () => {
    setFormValues(initialValues);
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };
  return (
    <Fragment>
      <Paper
        component="form"
        className={classes.form}
        elevation={0}
        onSubmit={handleSubmit}
      >
        <Paper
          className={classes.paper}
          component="input"
          type="text"
          name="category"
          placeholder="Category Name"
          value={formValues.category}
          onChange={handleChange}
        />
        <Paper className={classes.button} component="button">
          Create Category
        </Paper>
      </Paper>
    </Fragment>
  );
};

export default CreateBlog;
