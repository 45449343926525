import React, { Fragment } from "react";
import "./style.css";

const More = ({ Data }) => {
  return (
    <Fragment>
      <div className="personal__data">
        <div className="personal__data__header">
          <h1>More About Aupair</h1>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Aupair has a Driving Licence:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.drivingLicense}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Aupair can Ride a Bike:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.rideBike}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Aupair can Swim:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.swim}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Aupair has attended the first aid Training:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.firstaidtraining}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>He is willing to do the house work:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.dotheHousework}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Aupair will to care children with special needs:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.carechildren}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Aupair Willing to taking Care of Pets:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.Takingcareofpets}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Working Hours(per week):</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.working_hours}</label>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default More;
