export const AboutTextToSpeech = `Das Aupair Matching is a product of Akagera Rhein Center.
                    Das Aupair Matching was created in 2022 by the idea of
                    former aupairs and some host families wanting to create
                    something different and exceptional to make the aupair
                    epxerience more creative, accessible and easy. The core is
                    to put in place a dynamic and experienced intermediary to
                    facilitate contact between the two sides de- siring one or
                    the other. The accompaniment of the candidates consists of
                    the intercultural trainings, to provide the information and
                    the documentations and impeccable service. This platform is
                    a cost-effective service for aupairs and host families with
                    the aim of offering the quickest possible placement of
                    aupairs and host families, through direct communication with
                    registered users who want to get in touch one to each other.
                    Das Aupair Matching searches,selects, finds, and trains the
                    candidates.We provide the information about the aupair
                    epxerience and support the candidates before, during and
                    after the placement has taken place.`;

export const TeamTextToSpeech = `Our team of dedicated professionals, volunteers, mentors and
                coaches dispatched especially around in the countries of Akagera
                and Rhine rivers' regions, are aimed to provide through their
                service, a qualified support to our customers, to make the right
                decisions not just when applying for an aupair experience or
                seaching a sweetable candidate or a matching host family but
                also accompanying them during the placement's time and till it
                is completed and successed. We care that our customers can best
                use the matching to make the most of the unique opportunity,
                ensuring their adventure a sustainable experience and contribute
                to the personal development and leave a positive impact to the
                involved parties. We consider aupair experience as sustainable
                development instrument to live.`;

export const ProficiencyTextToSpeech = `With formers aupairs and advisors who are professional
                  qualified and expertise with a huge of experience know the
                  country's cultural background of both countries, either origin
                  or host country of Aupair and the potential host family. We do
                  our task impeccably. The combination of our innovative
                  experience oriented solutions and human expertise elevates us
                  to a higher level in terms of service delivery for our
                  customer.`;

export const AbilityTextToSpeech = `Competence, performance and efficiency: These three values
                  constitutes the indelible mark of Das Aupair Matching. Driven
                  by the satisfactory results of our customers, our values
                  enlighten us, towards the perfection and optimization of our
                  products. We are a new generation believing that the
                  simplification, the speed of recruitment procedures, and
                  inexpensive service delivery should be what is rightfully
                  yours.
`;

export const ClientTextToSpeech = `Choosing a right candidate can cause fear of failure and a
                  negative decision can lead to immeasurable disappointment and
                  frustration. Limited knowledge on both parties, poor
                  preparation, endless searching, and misunderstandings can
                  cause feelings of uncertainty, bitterness, and discouragement.
                  Our staffs accompany you systematically to prepare, to
                  identify, and find candidate who matches your expectations.`;

export const EligibilityTextToSpeech = `Das Aupair Matching assesses your elegibilty to the aupair
                    experience as a candidate or a host family for free of
                    charge using our digital automated assessment instrument.
                    Please click here to check your eligibility.`;

export const ProfileTextToSpeech = ` After the assessement has successed, proceed to the creation
                    of your profile so that you can be matched to the
                    potentional candidate. Activiate your profile and stay
                    intouch for further queries and requests.`;

export const MatchingTextToSpeech = `In case your matching successed the next step will be the
                    preparation to get the contract and other documents to
                    proccess the application of the visa and other procedure
                    regarding the travel and arrival.`;
