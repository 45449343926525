import React, { Fragment } from "react";
import "./style.css";

const Education = ({ Data }) => {
  return (
    <Fragment>
      <div className="personal__data">
        <div className="personal__data__header">
          <h1>Educational Background</h1>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Language skills:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.languageSkills[1]}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Language skills:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.languageSkills[2]}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Language skills:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.languageSkills[3]}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Highschool Certificate:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.highSchoolCertificate}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Professional Trainings:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.professionalTraining}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>University:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.university}</label>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Education;
