import React, { useState } from "react";
import "./Navigation.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Modal } from "antd";
import Auth from "./../../../Account/Authentication";
// import { useSelector } from "react-redux";

const Navigation = () => {
  const [visible, setVisible] = useState(false);

  // const { signup } = useSelector((state) => state.signup);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };
  return (
    <React.Fragment>
      <Modal
        title="SIGNUP/LOGIN"
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        width={700}
      >
        <Auth />
      </Modal>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="navbgcolor"
        bg="right"
        variant="right"
      >
        <Container>
          <Navbar.Brand href="/" className="landing__logo">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/akagera-rhein-aupair.appspot.com/o/images%2FAupairImages%2Flogo-print-hd-transparent.png?alt=media&token=013ea43c-3e54-41b9-b2f9-b3839bf42821"
              alt="aupair logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto nav__items">
              <Nav.Link href="/">HOME</Nav.Link>
              <Nav.Link href="/aupair">FOR AUPAIR CANDIDATES</Nav.Link>
              <Nav.Link href="/families">FOR HOST FAMILIES</Nav.Link>
              <Nav.Link href="/involved">GET INVOLVED</Nav.Link>
              <Nav.Link href="/faq">FAQ</Nav.Link>
              <Nav.Link href="/blog">BLOG</Nav.Link>
              <Nav.Link href="/announcement">ANNOUNCEMENTS</Nav.Link>
              <Nav.Link href="/contact">CONTACT</Nav.Link>
              <Nav.Link href="#">
                <select className="nav__items__lang">
                  <option>EN</option>
                  <option>FR</option>
                  <option>DE</option>
                </select>
              </Nav.Link>
              <Nav.Link href="#" className="signup__btn" onClick={showModal}>
                SIGNUP
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  );
};
export default Navigation;
