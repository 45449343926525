import {
  APPROVE_START,
  APPROVE_SUCCESS,
  APPROVE_FAILURE,
  DISAPPROVE_START,
  DISAPPROVE_SUCCESS,
  DISAPPROVE_FAILURE,
  HOLD_START,
  HOLD_SUCCESS,
  HOLD_FAILURE,
  UNHOLD_START,
  UNHOLD_SUCCESS,
  UNHOLD_FAILURE,
  MATCH_START,
  MATCH_SUCCESS,
  MATCH_FAILURE,
  REJECT_START,
  REJECT_SUCCESS,
  REJECT_FAILURE,
  UN_REJECT_START,
  UN_REJECT_SUCCESS,
  UN_REJECT_FAILURE,
} from "./actionTypes/types";

import * as api from "../../api/index";

export const ApproveUser = (userId) => async (dispatch) => {
  dispatch({ type: APPROVE_START });

  console.log("formData", userId);
  try {
    const { data } = await api.Approve(userId);
    console.log("data signup action", data);

    return dispatch({
      type: APPROVE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error?.response?.data?.error;
    return dispatch({
      type: APPROVE_FAILURE,
      payload: errorMessage,
    });
  }
};

export const DisapproveUser = (userId) => async (dispatch) => {
  // console.log("formData", userId);
  dispatch({ type: DISAPPROVE_START });
  try {
    const { data } = await api.Disapprove(userId);
    console.log("data", data);
    return dispatch({
      type: DISAPPROVE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: DISAPPROVE_FAILURE,
      payload: errorMessage,
    });
  }
};

export const HoldUser = (userId) => async (dispatch) => {
  dispatch({ type: HOLD_START });
  try {
    const { data } = await api.Hold(userId);
    return dispatch({
      type: HOLD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: HOLD_FAILURE,
      payload: errorMessage,
    });
  }
};

export const UnHoldUser = (userId) => async (dispatch) => {
  dispatch({ type: UNHOLD_START });
  try {
    const { data } = await api.UnHold(userId);
    return dispatch({
      type: UNHOLD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: UNHOLD_FAILURE,
      payload: errorMessage,
    });
  }
};

export const MatchUser = (userId) => async (dispatch) => {
  dispatch({ type: MATCH_START });
  try {
    const { data } = await api.Matched(userId);
    return dispatch({
      type: MATCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: MATCH_FAILURE,
      payload: errorMessage,
    });
  }
};

export const rejectUser = (userId) => async (dispatch) => {
  dispatch({ type: REJECT_START });
  try {
    const { data } = await api.rejectUser(userId);
    return dispatch({
      type: REJECT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: REJECT_FAILURE,
      payload: errorMessage,
    });
  }
};

export const unRejectUser = (userId) => async (dispatch) => {
  dispatch({ type: UN_REJECT_START });
  try {
    const { data } = await api.unrejectUser(userId);
    return dispatch({
      type: UN_REJECT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: UN_REJECT_FAILURE,
      payload: errorMessage,
    });
  }
};
