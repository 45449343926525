import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useStyles } from "./style";
import Grid from "@material-ui/core/Grid";
import "./moreaboutme.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  aupairOtherInfo,
  updateAupairMoreInfo,
} from "./../../../../Redux/action/aupairForms";
import Layout from "./../../../../Components/Layouts/Profile/Layout";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getUser } from "./../../../../Redux/action/staffMembers";

const initialValues = {
  drivingLicense: "",
  rideBike: "",
  swim: "",
  firstaidtraining: "",
  dotheHousework: "",
  carechildren: "",
  Takingcareofpets: "",
  working_hours: "",
};

const AupairOtherInfo = ({
  aupairMoreInfoData,
  aupairOtherInfo,
  updateAupairMoreInfo,
  AupOtherInfo,
  moreInfo,
  aupair,
  getUser,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [createMode, setCreateMode] = useState(true);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [disabled, setDisabled] = useState(false);
  const user = JSON.parse(localStorage.getItem("profile"))?.data?.user;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));

    if (Object.keys(formErrors).length === 0) {
      if (createMode) {
        setOpen(true);
        aupairOtherInfo(formValues);
        clear();
      } else {
        setOpen(true);
        updateAupairMoreInfo(
          formValues,
          aupair?.message?.data?.user?.aupairmoreinfo?.uuid
        );
      }
    }
  };

  const clear = () => {
    setFormValues(initialValues);
  };

  useEffect(() => {
    const fetchUser = () => {
      getUser(user?.uuid);
    };
    if (user?.uuid || AupOtherInfo.success || moreInfo?.success) {
      fetchUser();
    } else {
      return;
    }
  }, [user?.uuid, getUser, AupOtherInfo?.success, moreInfo?.success]);

  useEffect(() => {
    if (AupOtherInfo.success) {
      setOpen(false);
    } else if (!AupOtherInfo.success && AupOtherInfo.error !== null) {
      setOpen(false);
    }
  }, [
    AupOtherInfo.success,
    AupOtherInfo.error,
    AupOtherInfo?.message?.message,
  ]);

  useEffect(() => {
    if (moreInfo.success) {
      setOpen(false);
    } else if (!moreInfo.success && moreInfo.error !== null) {
      setOpen(false);
    }
  }, [moreInfo.success, moreInfo.error, moreInfo?.message?.message]);

  const validate = (values) => {
    const errors = {};

    if (!values.drivingLicense) {
      errors.drivingLicense = "This field is required!";
    }
    if (!values.rideBike) {
      errors.rideBike = "This field is required!";
    }
    if (!values.swim) {
      errors.swim = "This field is required!";
    }
    if (!values.firstaidtraining) {
      errors.firstaidtraining = "This field is required!";
    }
    if (!values.dotheHousework) {
      errors.dotheHousework = "This field is required!";
    }
    if (!values.carechildren) {
      errors.carechildren = "This field is required!";
    }

    if (!values.Takingcareofpets) {
      errors.Takingcareofpets = "This field is required!";
    }
    if (!values.working_hours) {
      errors.working_hours = "This field is required!";
    }

    return errors;
  };

  useEffect(() => {
    if (aupair?.message?.data?.user?.aupairmoreinfo) {
      setFormValues({
        drivingLicense:
          aupair?.message?.data?.user?.aupairmoreinfo?.drivingLicense,
        rideBike: aupair?.message?.data?.user?.aupairmoreinfo?.rideBike,
        swim: aupair?.message?.data?.user?.aupairmoreinfo?.swim,
        firstaidtraining:
          aupair?.message?.data?.user?.aupairmoreinfo?.firstaidtraining,
        dotheHousework:
          aupair?.message?.data?.user?.aupairmoreinfo?.dotheHousework,
        carechildren: aupair?.message?.data?.user?.aupairmoreinfo?.carechildren,
        Takingcareofpets:
          aupair?.message?.data?.user?.aupairmoreinfo?.Takingcareofpets,
        working_hours:
          aupair?.message?.data?.user?.aupairmoreinfo?.working_hours,
      });
      setCreateMode(false);
    } else if (!aupair?.message?.data?.user?.aupairmoreinfo) {
      setCreateMode(true);
      setFormValues(initialValues);
    }
  }, [aupair]);

  useEffect(() => {
    if (
      formValues.drivingLicense === "" ||
      formValues.rideBike === "" ||
      formValues.swim === "" ||
      formValues.firstaidtraining === "" ||
      formValues.dotheHousework === "" ||
      formValues.carechildren === "" ||
      formValues.Takingcareofpets === "" ||
      formValues.working_hours === ""
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formValues]);

  return (
    <Layout>
      <CssBaseline />
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Container maxWidth="md" className="form_container">
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="personalInformationGroup">
            <h3 className="InnerTitles">MORE ABOUT ME</h3>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    I have a driving license<span className="required">*</span>
                  </label>
                  <select
                    name="drivingLicense"
                    value={formValues.drivingLicense}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Working on it">Working on it</option>
                  </select>
                </div>
                <small className="formError">{formErrors.drivingLicense}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    I can ride a Bike<span className="required">*</span>
                  </label>
                  <select
                    name="rideBike"
                    value={formValues.rideBike}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Working on it">Working on it</option>
                  </select>
                </div>
                <small className="formError">{formErrors.rideBike}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    I can swim<span className="required">*</span>
                  </label>
                  <select
                    name="swim"
                    value={formValues.swim}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Working on it">Working on it</option>
                  </select>
                </div>
                <small className="formError">{formErrors.swim}</small>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    I attended the first aid training
                    <span className="required">*</span>
                  </label>
                  <select
                    name="firstaidtraining"
                    value={formValues.firstaidtraining}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Working on it">Working on it</option>
                  </select>
                </div>
                <small className="formError">
                  {formErrors.firstaidtraining}
                </small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    I am willing to do the Housework
                    <span className="required">*</span>
                  </label>
                  <select
                    name="dotheHousework"
                    value={formValues.dotheHousework}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
                <small className="formError">{formErrors.dotheHousework}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    care children with special needs.
                    <span className="required">*</span>
                  </label>
                  <select
                    name="carechildren"
                    value={formValues.carechildren}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="May be">May be</option>
                  </select>
                </div>
                <small className="formError">{formErrors.carechildren}</small>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Taking care of pets <span className="required">*</span>
                  </label>
                  <select
                    name="Takingcareofpets"
                    value={formValues.Takingcareofpets}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="May be">May be</option>
                  </select>
                </div>
                <small className="formError">
                  {formErrors.Takingcareofpets}
                </small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Working Hours (per week) <span className="required">*</span>
                  </label>
                  <input
                    name="working_hours"
                    type="number"
                    min="1"
                    required
                    placeholder="Ex:10"
                    value={formValues.working_hours}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.working_hours}</small>
              </Grid>
            </div>
            <div className="user_action_buttons">
              <button
                className={disabled ? "disabledButton" : "submit__btn"}
                type="submit"
                disabled={disabled}
              >
                {createMode ? "Save" : "Update"}
              </button>
              <button className="next__btn" type="button">
                <Link to="/aupair/education"> &larr; Prev</Link>
              </button>
              <button className="next__btn" type="button">
                <Link to="/aupair/preferences"> Next &rarr;</Link>
              </button>
            </div>
          </div>
        </form>
      </Container>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    aupair: state.getUser.user,
    AupOtherInfo: state.aupairMoreInfo.AupOtherInfo,
    moreInfo: state.updateAupairOtherInfo.moreInfo,
  };
};
export default connect(mapStateToProps, {
  updateAupairMoreInfo,
  getUser,
  aupairOtherInfo,
})(AupairOtherInfo);
