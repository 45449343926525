import React, { Fragment, useState, useEffect } from "react";
import "./Aupair.css";
import { Divider } from "antd";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { connect } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getAllAupairDocuments } from "./../../../../../../../Redux/action/Documents";
import PassPortWidget from "./documentUploaders/passport";
import GermanCertificateWidget from "./documentUploaders/germanCertificate";
import HighSchoolCertificateWidget from "./documentUploaders/highSchoolCertificate";
import DegreeWidget from "./documentUploaders/degree";
import InternShipCertificateWidget from "./documentUploaders/internshipCertificate";
import MotivationLetterWidget from "./documentUploaders/motivationLetter";
import CVWidget from "./documentUploaders/Cv";
import { Empty } from "antd";
import EditDocument from "./documentUploaders/editDocument";
import DeleteDocument from "./documentUploaders/deleteDocument";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const MyDocuments = ({
  getAllAupairDocuments,
  documents,
  document,
  updatedDocument,
  deletedDocument,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchData = () => {
      getAllAupairDocuments();
    };
    if (
      document?.success ||
      updatedDocument?.success ||
      deletedDocument?.success
    ) {
      fetchData();
    } else {
      fetchData();
    }
  }, [
    getAllAupairDocuments,
    document?.success,
    updatedDocument?.success,
    deletedDocument?.success,
  ]);

  useEffect(() => {
    if (!documents.success) {
      setOpen(true);
    } else if (documents.success) {
      setOpen(false);
    } else if (!documents.success && documents.error !== null) {
      setOpen(false);
    }
  }, [documents.success, documents.error]);

  return (
    <Fragment>
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <div className="uploader__container">
        <PassPortWidget />
        <GermanCertificateWidget />
        <HighSchoolCertificateWidget />
        <DegreeWidget />
        <InternShipCertificateWidget />
        <MotivationLetterWidget />
        <CVWidget />
      </div>
      <Divider />

      {documents?.message?.data?.allDocuments?.length !== 0 ? (
        <div className="uploader__container__documents">
          {documents?.success &&
            documents?.message?.data?.allDocuments?.map((document) => (
              <div key={document.uuid} className="aupair_document">
                <div className="documentName">
                  <h2>{document.documentName}</h2>
                </div>
                <div
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.3)",
                    height: "100%",
                    width: "500px",
                  }}
                >
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
                    <Viewer fileUrl={document.document} initialPage={2} />
                  </Worker>
                </div>

                <div className="aupair_document_overlay">
                  <div className="document_actions_buttons">
                    <EditDocument doc={document} />
                    <DeleteDocument doc={document} />
                    <Tooltip title="Download" arrow>
                      <IconButton aria-label="edit" size="small">
                        <a
                          href={document?.document}
                          download
                          target="_blank"
                          rel="noreferrer"
                        >
                          <DownloadForOfflineIcon style={{ color: "#000" }} />
                        </a>
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </div>
            ))}
        </div>
      ) : (
        <Empty />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    documents: state.allAupairDocuments.documents,
    document: state.uplaodAupairDocument.document,
    updatedDocument: state.updateAupairDocument.document,
    deletedDocument: state.deleteAupairDocument.document,
  };
};

export default connect(mapStateToProps, { getAllAupairDocuments })(MyDocuments);
