import React, { useState } from "react";
import { Form, Input } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const LoginForm = ({ setIsSignUp }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    setLoading(true);

    try {
      const { data } = await axios({
        // url: "http://localhost:8000/api/v1/users/login",
        url: "https://das-aupair-new-app-4481db6b895a.herokuapp.com/api/v1/users/login",
        method: "POST",
        data: values,
      });

      if (data) {
        localStorage.setItem("profile", JSON.stringify(data));
        setLoading(false);
      }
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => {
        if (
          data?.data?.user?.role === "aupair" &&
          data?.data?.user?.isVerified
        ) {
          navigate("/aupair/identification");
        } else if (
          data?.data?.user?.role === "hostfamily" &&
          data?.data?.user?.isVerified
        ) {
          navigate("/host/identification");
        } else if (
          data?.data?.user?.role === "hostfamily" &&
          !data?.data?.user?.isVerified
        ) {
          navigate("/confirm/email");
        } else if (
          data?.data?.user?.role === "aupair" &&
          !data?.data?.user?.isVerified
        ) {
          navigate("/confirm/email");
        }

        if (data?.data?.user?.role === "admin") {
          navigate("/dashboard");
        }
      }, 2000);
    } catch (error) {
      setLoading(false);
      toast.error("Wrong email or password", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <Form
      name="complex-form"
      onFinish={onFinish}
      wrapperCol={{
        span: 24,
      }}
    >
      <Form.Item
        style={{
          marginBottom: 0,
        }}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Your email please !",
            },
          ]}
          style={{
            display: "block",
            width: "100%",
          }}
        >
          <Input type="email" placeholder="Enter your email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Your password please !",
            },
          ]}
          style={{
            display: "block",
            width: "100%",
          }}
        >
          <Input.Password placeholder="Enter your password" />
        </Form.Item>
      </Form.Item>
      <Form.Item>
        <Link to="/forgot">Forgot password ?</Link>
      </Form.Item>
      <Form.Item>
        <div className="reister_actionBtns">
          <button type="submit" className="loginBtn" disabled={loading}>
            {loading ? <SyncOutlined spin /> : "Login"}
          </button>
          <span onClick={() => setIsSignUp(true)}>
            Don't have account?{" "}
            <small
              style={{ color: "orange", cursor: "pointer", fontWeight: "bold" }}
            >
              Register
            </small>
          </span>
        </div>
      </Form.Item>
    </Form>
  );
};
export default LoginForm;
