import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useStyles } from "./style";
import Grid from "@material-ui/core/Grid";
import "./expectations.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  aupairExpectation,
  updateAupairExpectations,
} from "./../../../../Redux/action/aupairForms";

import Layout from "./../../../../Components/Layouts/Profile/Layout";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getUser } from "./../../../../Redux/action/staffMembers";

const initialValues = {
  houseworK: "",
  timetable: "",
  flexibleFamily: "",
  integrateFamily: "",
};

const AupairExpectaions = ({
  aupairExpectation,
  updateAupairExpectations,
  Aupexpect,
  expectations,
  aupair,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [createMode, setCreateMode] = useState(true);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [disabled, setDisabled] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));
    if (Object.keys(formErrors).length === 0) {
      if (createMode) {
        setOpen(true);
        aupairExpectation(formValues);
        clear();
      } else {
        setOpen(true);
        updateAupairExpectations(
          formValues,
          aupair?.message?.data?.user?.aupairexpectations?.uuid
        );
      }
    }
  };

  const clear = () => {
    setFormValues(initialValues);
  };

  useEffect(() => {
    if (Aupexpect.success) {
      setOpen(false);
    } else if (!Aupexpect.success && Aupexpect.error !== null) {
      setOpen(false);
    }
  }, [Aupexpect.success, Aupexpect?.message?.message, Aupexpect.error]);

  useEffect(() => {
    if (expectations.success) {
      setOpen(false);
    } else if (!expectations.success && expectations.error !== null) {
      setOpen(false);
    }
  }, [expectations.success, expectations.error, expectations]);

  const validate = (values) => {
    const errors = {};

    if (!values.houseworK) {
      errors.houseworK = "This field is required!";
    }
    if (!values.timetable) {
      errors.timetable = "This field is required!";
    }
    if (!values.flexibleFamily) {
      errors.flexibleFamily = "This field is required!";
    }
    if (!values.integrateFamily) {
      errors.integrateFamily = "This field is required!";
    }

    return errors;
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (aupair?.message?.data?.user?.aupairexpectations) {
      setFormValues({
        houseworK: aupair?.message?.data?.user?.aupairexpectations?.houseworK,
        timetable: aupair?.message?.data?.user?.aupairexpectations?.timetable,
        flexibleFamily:
          aupair?.message?.data?.user?.aupairexpectations?.flexibleFamily,
        integrateFamily:
          aupair?.message?.data?.user?.aupairexpectations?.integrateFamily,
      });
      setCreateMode(false);
    } else if (!aupair?.message?.data?.user?.aupairexpectations) {
      setCreateMode(true);
      setFormValues(initialValues);
    }
  }, [aupair]);

  useEffect(() => {
    if (
      formValues.houseworK === "" ||
      formValues.timetable === "" ||
      formValues.flexibleFamily === "" ||
      formValues.integrateFamily === ""
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formValues]);

  return (
    <Layout>
      <CssBaseline />
      <div>
        <Backdrop
          style={{ zIndex: 1000, color: "orange" }}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Container maxWidth="md" className="form_container">
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="personalInformationGroup">
            <h3 className="InnerTitles">MY EXPECTATION FROM HOST FAMILY</h3>

            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    The work is done on the established plan
                    <span className="required">*</span>
                  </label>
                  <select
                    name="houseworK"
                    value={formValues.houseworK}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Not Necessary">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">{formErrors.houseworK}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    There are clearly defined timetable
                    <span className="required">*</span>
                  </label>
                  <select
                    name="timetable"
                    value={formValues.timetable}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Not Necessary">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">{formErrors.timetable}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Life is flexible and spontaneous
                    <span className="required">*</span>
                  </label>
                  <select
                    name="flexibleFamily"
                    value={formValues.flexibleFamily}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Not Necessary">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">{formErrors.flexibleFamily}</small>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    I wish to integrate the family life.
                    <span className="required">*</span>
                  </label>
                  <select
                    name="integrateFamily"
                    value={formValues.integrateFamily}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Not Necessary">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">
                  {formErrors.integrateFamily}
                </small>
              </Grid>
            </div>
            <div className="user_action_buttons">
              <button
                className={disabled ? "disabledButton" : "submit__btn"}
                type="submit"
                disabled={disabled}
              >
                {createMode ? "Save" : "Update"}
              </button>
              <button className="next__btn" type="button">
                <Link to="/aupair/preferences"> &larr; Prev</Link>
              </button>
              <button className="next__btn" type="button">
                <Link to="/aupair/wishes"> Next &rarr;</Link>
              </button>
            </div>
          </div>
        </form>
      </Container>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    aupair: state.getUser.user,
    Aupexpect: state.aupairExpectation.Aupexpect,
    expectations: state.updateAupairExpectations.expectations,
  };
};

export default connect(mapStateToProps, {
  aupairExpectation,
  updateAupairExpectations,
  getUser,
})(AupairExpectaions);
