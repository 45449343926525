import React from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useStyles } from "./style";
import { connect } from "react-redux";
import { hostExpectation } from "../../../Redux/action/hostFroms";
import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import "./HostFamilyHome.css";
import Navigation from "./../../Page/Landing/Navigation/Navigation";
import Contact from "./../../Page/Landing/Contacts/Contacts";
import HostHeader from "./HostFamilyHome";
import Footer from "../../Footer/NewFooter";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const experienced = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
  {
    value: "Not necessary",
    label: "Not necessary",
  },
];
const Livewith = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Couple",
    label: "Couple",
  },
  {
    value: "Only a single mother wife",
    label: "Only a single mother wife",
  },
  {
    value: "Single Father",
    label: "Single Father",
  },
  {
    value: "All family constellation",
    label: "All family constellation",
  },
];

const family = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Classic Family ",
    label: "Classic Family ",
  },
  {
    value: "Single Female",
    label: "Single Female",
  },
  {
    value: "Single Male",
    label: "Single Male",
  },
  {
    value: "Divers",
    label: "Divers",
  },
];
const country = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Germany",
    label: "Germany Switzerland",
  },
  {
    value: "Switzerland",
    label: "Switzerland ",
  },
  {
    value: "Austria",
    label: "Austria",
  },
  {
    value: "France",
    label: "France",
  },
  {
    value: "Luxamburg",
    label: "Luxamburg",
  },
  {
    value: "Netherland",
    label: "Netherland",
  },
  {
    value: "Dan-mark",
    label: "Dan-mark",
  },
  {
    value: "No preference",
    label: "No preference",
  },
];

const Languages = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Good knowledge of German",
    label: "Good knowledge of German ",
  },
  {
    value: "Basic knowledge of German",
    label: "Basic knowledge of German",
  },
  {
    value: "Good knowledge of English",
    label: "Good knowledge of English",
  },
  {
    value: "Basic knowledge of English",
    label: "Basic knowledge of English",
  },
  {
    value: "Good knowledge of French",
    label: "Good knowledge of French",
  },
  {
    value: "Basic knowledge of French",
    label: "Basic knowledge of French",
  },
  {
    value: "Other language",
    label: "Other language",
  },
];
const smoking = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
  {
    value: "Yes no at home",
    label: "Yes no at home",
  },
];

const HouseWork = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes ",
    label: "Yes ",
  },
  {
    value: "No",
    label: "No",
  },
];
const timeTable = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes ",
    label: "Yes ",
  },
  {
    value: "No",
    label: "No",
  },
];

const flexibleFams = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes ",
    label: "Yes ",
  },
  {
    value: "No",
    label: "No",
  },
];
const integrate = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes ",
    label: "Yes ",
  },
  {
    value: "No",
    label: "No",
  },
];

const Freedom = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes ",
    label: "Yes ",
  },
  {
    value: "No",
    label: "No",
  },
];

const HostExpectation = ({ formData, setFormData, navigation }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const {
    aupairExpAupair,
    aupairLiveWith,
    childrenNo,
    childrenAge,
    searchingFor,
    aupirAge,
    aupairCountry,
    aupairLanguage,
    smokingAupair,
    WorkingHrs,
    houseWorkPlanned,
    timetableDef,
    flexibleFam,
    integrateFamLife,
    eatOnWish,
  } = formData;

  const Next = (event) => {
    event.preventDefault();
    setOpen(!open);
    setTimeout(() => {
      setOpen(false);
      navigation.next();
    }, 1000);
  };

  const Previous = (event) => {
    event.preventDefault();
    setOpen(!open);
    setTimeout(() => {
      setOpen(false);
      navigation.previous();
    }, 1000);
  };

  return (
    <React.Fragment>
      <Contact />
      <Navigation />
      <HostHeader />
      <CssBaseline />
      <div>
        <Backdrop
          style={{ zIndex: 1000, color: "#fff" }}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Container maxWidth="md" className="form_container">
        <form className={classes.root} noValidate autoComplete="off">
          <div className="personalInformationGroup">
            <h1 className="InnerTitles">
              Your Family preference & Expectations
            </h1>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Family with aupair experience{" "}
                    <span className="required">*</span>
                  </label>
                  <select
                    name="aupairExpAupair"
                    value={aupairExpAupair}
                    onChange={setFormData}
                  >
                    {experienced.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Live with <span className="required">*</span>
                  </label>
                  <select
                    name="aupairLiveWith"
                    value={aupairLiveWith}
                    onChange={setFormData}
                  >
                    {Livewith.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Children Number<span className="required">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="childrenNo"
                    placeholder="Ex: 1-5 children"
                    value={childrenNo}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Children aged between <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Ex: 5-20 years"
                    name="childrenAge"
                    value={childrenAge}
                    onChange={setFormData}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Family <span className="required">*</span>
                  </label>
                  <select
                    name="searchingFor"
                    value={searchingFor}
                    onChange={setFormData}
                  >
                    {family.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Aupair's Age <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    name="aupirAge"
                    placeholder="Ex:20-75"
                    value={aupirAge}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
            </div>

            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Aupair's Country <span className="required">*</span>
                  </label>
                  <select
                    name="aupairCountry"
                    value={aupairCountry}
                    onChange={setFormData}
                  >
                    {country.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Aupair's Language <span className="required">*</span>
                  </label>
                  <select
                    name="aupairLanguage"
                    value={aupairLanguage}
                    onChange={setFormData}
                  >
                    {Languages.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Smoking ? <span className="required">*</span>
                  </label>
                  <select
                    name="smokingAupair"
                    value={smokingAupair}
                    onChange={setFormData}
                  >
                    {smoking.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Working hours <span className="required">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="WorkingHrs"
                    placeholder="Working hours"
                    value={WorkingHrs}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    House work planned ?<span className="required">*</span>
                  </label>
                  <select
                    type="text"
                    name="houseWorkPlanned"
                    value={houseWorkPlanned}
                    onChange={setFormData}
                  >
                    {HouseWork.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Defined TimeTable ?<span className="required">*</span>
                  </label>
                  <select
                    name="timetableDef"
                    value={timetableDef}
                    onChange={setFormData}
                  >
                    {timeTable.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Flexible Family <span className="required">*</span>
                  </label>
                  <select
                    name="flexibleFam"
                    value={flexibleFam}
                    onChange={setFormData}
                  >
                    {flexibleFams.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Integrate the family life ?{" "}
                    <span className="required">*</span>
                  </label>
                  <select
                    name="integrateFamLife"
                    value={integrateFamLife}
                    onChange={setFormData}
                  >
                    {integrate.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Eat on your wish<span className="required">*</span>
                  </label>
                  <select
                    name="eatOnWish"
                    value={eatOnWish}
                    onChange={setFormData}
                  >
                    {Freedom.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            </div>
          </div>
          <button className="Back" onClick={Previous}>
            <ArrowBackIcon />
            &nbsp; Back
          </button>
          <button className="Continue " onClick={Next}>
            Next&nbsp;
            <ArrowForwardIcon />
          </button>
        </form>
      </Container>
      <Footer />
    </React.Fragment>
  );
};
const mapStateToProps = ({ hostExpectation }) => {
  const { hostExpect } = hostExpectation;
  return { hostExpect };
};
export default connect(mapStateToProps, { hostExpectation })(HostExpectation);
