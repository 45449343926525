import React, { useEffect, useState, Fragment } from "react";
import Box from "@mui/material/Box";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CancelIcon from "@mui/icons-material/Cancel";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { connect } from "react-redux";
import { deleteAupairDocument } from "./../../../../../../../../Redux/action/Documents";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #ffa500;",
  boxShadow: 24,
  p: 4,
};

const DeleteDocument = ({ deleteAupairDocument, doc, deletedDocument }) => {
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDelete = () => {
    setProgress(true);
    deleteAupairDocument(doc?.uuid);
  };

  useEffect(() => {
    if (deletedDocument?.success) {
      setProgress(false);
      setOpen(false);
      toast.success(deletedDocument?.message?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (!deletedDocument?.success && deletedDocument?.error !== null) {
      setProgress(false);
      toast.success("There was an error", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [
    deletedDocument?.success,
    deletedDocument?.error,
    deletedDocument?.message?.message,
  ]);

  return (
    <Fragment>
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={progress}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Tooltip title="Remove Document" arrow>
        <IconButton aria-label="delete" onClick={handleOpen}>
          <RemoveCircleIcon style={{ color: "#000" }} />
        </IconButton>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to continue ?
          </Typography>
          <IconButton
            style={{ background: "#000" }}
            size="small"
            onClick={handleDelete}
          >
            <DoneAllIcon
              style={{
                color: "#ffa500",
              }}
            />
          </IconButton>
          <IconButton
            size="small"
            style={{
              background: "#000",
              position: "absolute",
              top: "5px",
              right: "5px",
            }}
          >
            <CancelIcon style={{ color: "#ffa500" }} onClick={handleClose} />
          </IconButton>
        </Box>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    deletedDocument: state.deleteAupairDocument.document,
  };
};

export default connect(mapStateToProps, { deleteAupairDocument })(
  DeleteDocument
);
