import React from "react";
import "./Task.css";

const OurUniqueness = () => {
  return (
    <div className="OurUniqueness-last">
      <div className="task-container">
          <blockquote>
            <p>
              Some requirements vary by host country to another. The host family
              can check whether they meet all the requirements. If your family
              meets all the requirements, you can look for an Aupair. We support
              you find one! Register and become a host family in Germany!
            </p>
          </blockquote>
      </div>
    </div>
  );
};
export default OurUniqueness;
