import React, { Fragment } from "react";
import "./Announcement.css";
import AnnouncementCard from "./../Announcement/AnnouncementCard";

const Announcement = ({ setSearchableData }) => {
  return (
    <Fragment>
      <div className="dashannouncement__wrapper">
        <AnnouncementCard setSearchableData={setSearchableData} />
      </div>
    </Fragment>
  );
};

export default Announcement;
