import React from "react";
import "./WhyAkagera.css";
import Grid from "@material-ui/core/Grid";
import Profession from "../../../../assets/businessman.png";
import Ability from "../../../../assets/multitasking.png";
import Clients from "../../../../assets/community.png";
import Zoom from "react-reveal/Zoom";
import VolumeDownIcon from "@material-ui/icons/VolumeDown";
import * as Speech from "./../Speeches/SpeechText";

const WhyAkagera = () => {
  const msg = new SpeechSynthesisUtterance();

  const ProficiencySpeechHandler = () => {
    msg.text = Speech.ProficiencyTextToSpeech;
    window.speechSynthesis.speak(msg);
  };

  const AbilitySpeechHandler = () => {
    msg.text = Speech.AbilityTextToSpeech;
    window.speechSynthesis.speak(msg);
  };

  const CientSpeechHandler = () => {
    msg.text = Speech.ProficiencyTextToSpeech;
    window.speechSynthesis.speak(msg);
  };

  return (
    <div className="whyakagera">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Zoom>
            <h1 className="whyakagera__primary__heading">
              Why Das Aupair Matching ?
            </h1>
          </Zoom>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Zoom>
            <div className="item__one">
              <div className="item__one__img">
                <img src={Profession} alt="proficiency" />
              </div>
              <div className="item__one__title">
                <strong>Proficiency</strong>
              </div>
              <div className="item__one__contents">
                Litsen
                <VolumeDownIcon
                  style={{ cursor: "pointer" }}
                  onClick={ProficiencySpeechHandler}
                />
                <p>
                  With formers aupairs and advisors who are professional
                  qualified and expertise with a huge of experience know the
                  country's cultural background of both countries, either origin
                  or host country of Aupair and the potential host family. We do
                  our task impeccably. The combination of our innovative
                  experience oriented solutions and human expertise elevates us
                  to a higher level in terms of service delivery for our
                  customer.
                </p>
              </div>
            </div>
          </Zoom>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Zoom>
            <div className="item__two">
              <div className="item__two__img">
                <img src={Ability} alt="ability" />
              </div>
              <div className="item__two__title">
                <strong>Ability</strong>
              </div>
              <div className="item__two__contents">
                Litsen
                <VolumeDownIcon
                  style={{ cursor: "pointer" }}
                  onClick={AbilitySpeechHandler}
                />
                <p>
                  Competence, performance and efficiency: These three values
                  constitutes the indelible mark of Das Aupair Matching. Driven
                  by the satisfactory results of our customers, our values
                  enlighten us, towards the perfection and optimization of our
                  products. We are a new generation believing that the
                  simplification, the speed of recruitment procedures, and
                  inexpensive service delivery should be what is rightfully
                  yours.
                </p>
              </div>
            </div>
          </Zoom>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Zoom>
            <div className="item__three">
              <div className="item__three__img">
                <img src={Clients} alt="clients" />
              </div>
              <div className="item__three__title">
                <strong>Clients first</strong>
              </div>
              <div className="item__three__contents">
                Litsen
                <VolumeDownIcon
                  style={{ cursor: "pointer" }}
                  onClick={CientSpeechHandler}
                />
                <p>
                  Choosing a right candidate can cause fear of failure and a
                  negative decision can lead to immeasurable disappointment and
                  frustration. Limited knowledge on both parties, poor
                  preparation, endless searching, and misunderstandings can
                  cause feelings of uncertainty, bitterness, and discouragement.
                  Our staffs accompany you systematically to prepare, to
                  identify, and find candidate who matches your expectations.
                </p>
              </div>
            </div>
          </Zoom>
        </Grid>
      </Grid>
    </div>
  );
};
export default WhyAkagera;
