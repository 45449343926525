import React, { Fragment } from "react";
import "./style.css";

const More = ({ Data }) => {
  return (
    <Fragment>
      <div className="personal__data">
        <div className="personal__data__header">
          <h1>More About Aupair</h1>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>I have a Driving Licence:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.drivingLicense}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>I can Ride a Bike:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.rideBike}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>I can Swim:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.swim}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>I attended the first aid Training:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.firstaidtraining}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>I am willing to care children with special needs:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.carechildren}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Taking Care of Pets:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.Takingcareofpets}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Working Hours(per week):</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.working_hours}</label>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default More;
