import { useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { Modal } from "antd";
import "./style.css";
import { SyncOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { updateProfilePicture } from "./../../../../Redux/action/Auth";
import { getUser, updateUser } from "./../../../../Redux/action/staffMembers";

const Profile = ({
  updateProfilePicture,
  profilePicture,
  getUser,
  aupair,
  updateUser,
  updatedProfile,
}) => {
  const user = JSON.parse(localStorage.getItem("profile"))?.data?.user;
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    updateUser(formValues, aupair?.message?.data?.user?.uuid);
  };

  const showModal = () => {
    setVisible((prev) => !prev);
  };

  const handleCancel = () => {
    setVisible(false);
  };
  const cloudName = "dsb0gu6km";
  const uploadPreset = "dasaupair";

  var myWidget = window.cloudinary.createUploadWidget(
    {
      cloudName: cloudName,
      uploadPreset: uploadPreset,
      folder: "profile_Pictures",
    },
    (error, result) => {
      if (!error && result && result.event === "success") {
        if (result?.info?.secure_url) {
          updateProfilePicture(
            { profilePicture: result.info.secure_url },
            user?.uuid
          );
        }
      }
    }
  );

  const uplaodFile = () => {
    myWidget.open();
  };

  useEffect(() => {
    const fetchUser = () => {
      getUser(user?.uuid);
    };
    if (user?.uuid || profilePicture?.success || updatedProfile?.success) {
      fetchUser();
    } else {
      fetchUser();
    }
  }, [user?.uuid, getUser, profilePicture?.success, updatedProfile?.success]);

  useEffect(() => {
    if (aupair?.success) {
      setFormValues({
        firstName: aupair?.message?.data?.user?.firstName,
        lastName: aupair?.message?.data?.user?.lastName,
        email: aupair?.message?.data?.user?.email,
        phone: aupair?.message?.data?.user?.phone,
      });
    } else {
      setFormValues({
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        phone: user?.phone,
      });
    }
  }, [aupair,user]);

  useEffect(() => {
    if (updatedProfile.success) {
      setLoading(false);
    } else if (!updatedProfile.success || updatedProfile.error !== null) {
      setLoading(false);
    }
  }, [updatedProfile.success, updatedProfile.error]);

  return (
    <>
      <button
        onClick={showModal}
        style={{
          width: "100%",
          color: "#222",
          display: "flex",
          justifyContent: "center",
          items: "center",
        }}
      >
        Profile
      </button>
      <Modal
        title="PROFILE"
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        width="35%"
        className="authModal"
        bodyStyle={{
          backgroundColor: "#464852",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div className="account_profilePicture">
          {aupair?.message?.data?.user?.profilePicture ? (
            <img
              className="accountProfilePicImg"
              src={aupair?.message?.data?.user?.profilePicture}
              alt="user profile"
            />
          ) : (
            <img
              className="accountProfilePicImg"
              src="https://images.pexels.com/photos/1264210/pexels-photo-1264210.jpeg?auto=compress&cs=tinysrgb&w=1600"
              alt="user profilepic"
            />
          )}
          <CameraAltIcon className="uploadIcon" onClick={uplaodFile} />
        </div>
        <div
          className="account__info"
          style={{ backgroundColor: "#464852", color: "#fff" }}
        >
          <form className="account__info__form" onSubmit={handleSubmit}>
            <div>
              <label>Full Name</label>
              <div className="user_fullname">
                <input
                  type="text"
                  value={formValues.firstName}
                  onChange={(event) =>
                    setFormValues({
                      ...formValues,
                      firstName: event.target.value,
                    })
                  }
                  placeholder="First Name"
                />
                <input
                  type="text"
                  value={formValues.lastName}
                  onChange={(event) =>
                    setFormValues({
                      ...formValues,
                      lastName: event.target.value,
                    })
                  }
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                disabled
                id="email"
                value={formValues.email}
                onChange={(event) =>
                  setFormValues({
                    ...formValues,
                    email: event.target.value,
                  })
                }
                placeholder="Email"
              />
            </div>
            <div>
              <label htmlFor="phone">Phone</label>
              <input
                type="text"
                id="phone"
                value={formValues.phone}
                onChange={(event) =>
                  setFormValues({
                    ...formValues,
                    phone: event.target.value,
                  })
                }
                placeholder="Phone"
              />
            </div>

            <button className="updateBtn">
              {loading ? <SyncOutlined spin /> : "update"}
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    profilePicture: state.profilePicture.picture,
    aupair: state.getUser.user,
    updatedProfile: state.updateProfile.updatedProfile,
  };
};

export default connect(mapStateToProps, {
  updateProfilePicture,
  updateUser,
  getUser,
})(Profile);
