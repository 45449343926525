import React, { Fragment, useEffect } from "react";
import DateRangeIcon from "@material-ui/icons/DateRange";
import "./Announcement.css";
import { connect } from "react-redux";
import { fetchAllAnnouncements } from "./../../../Redux/action/Announcements";

const AnnouncementCard = ({ fetchAllAnnouncements, announcements }) => {
  useEffect(() => {
    const getAnnouncements = () => {
      fetchAllAnnouncements();
    };

    getAnnouncements();
  }, [fetchAllAnnouncements]);

  return (
    <Fragment>
      <div className="announcements">
        {announcements?.message?.data?.announcements.map((announcement) => (
          <Fragment>
            <div key={announcement.uuid}>
              <div className="announcement__header">
                <span>
                  <DateRangeIcon />
                  {announcement.createdAt.split("T")[0]}
                </span>
              </div>
              <div className="announcement__heading">
                <h1>{announcement.title}</h1>
              </div>
              <div className="announcement__content">
                <p
                  dangerouslySetInnerHTML={{ __html: announcement.description }}
                ></p>
              </div>
            </div>
          </Fragment>
        ))}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    announcements: state.announcements.announcements,
  };
};

export default connect(mapStateToProps, { fetchAllAnnouncements })(
  AnnouncementCard
);
