import React, { Fragment } from "react";
import "./Blog.css";
import Footer from "./../../Footer/NewFooter";
import Tranding from "./Tranding";
import AllBlogs from "./newBlog";
import Contact from "../../../Components/ResponsiveNavBar/Contacts/Contacts";
import Navigation from "../../../Components/myNavbar/navbar";

const Blog = () => {
  return (
    <Fragment>
      <Contact />
      <Navigation />
      <div className="blog__container">
        <Tranding />
        <AllBlogs />
      </div>
      <Footer />
    </Fragment>
  );
};

export default Blog;
