import React from "react";
import "./SideDrawer.css";
import Backdrop from "../../UI/Backdrop/Backdrop";
import Aux from "../../../hoc/Auxi/Auxilliary";
import Logo from "./../../../assets/Logo/bigLogo-a.png";
import { NavLink } from "react-router-dom";
import { Modal } from "antd";
import Auth from "../../Auth/Auth";
import { Divider } from "antd";

const SideDrawer = (props) => {
  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [isLogin, setIsLogin] = React.useState(true);
  const [isSignUp, setIsSignUp] = React.useState(false);

  const showModalByLogin = () => {
    setVisible(true);
    setIsLogin(true);
    setIsSignUp(false);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };

  let attachedClasses = ["SideDrawer", "Close"];
  if (props.open) {
    attachedClasses = ["SideDrawer", "Open"];
  }

  return (
    <>
      <Modal
        title={isLogin ? "Login" : "Sign Up"}
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={null}
      >
        <Auth
          Login={isLogin}
          Signup={isSignUp}
          setIsLogin={setIsLogin}
          setIsSignUp={setIsSignUp}
          setVisible={setVisible}
        />
      </Modal>
      <Aux>
        <Backdrop show={props.open} clicked={props.closed} />
        <div className={attachedClasses.join(" ")}>
          <div style={{ marginLeft: "95%" }} onClick={props.closed}>
            X
          </div>
          <div className="toggle_logo">
            <img src={Logo} alt="logo" />
          </div>
          <div className="toggle_email">info@akagerarhein.com</div>
          <Divider />
          <nav className="toggle_navigation">
            <NavLink to="/">Aupair</NavLink>
            <NavLink to="/families">Host Family</NavLink>
            <NavLink to="/faq">FQ</NavLink>
            <NavLink to="contact">Contact</NavLink>
          </nav>
          <div className="toggle_buttons" onClick={showModalByLogin}>
            <button>Login</button>
          </div>
        </div>
      </Aux>
    </>
  );
};

export default SideDrawer;
