import React, { Fragment, useState, useEffect } from "react";
import CountUp from "react-countup";
import "./Served.css";
import axios from "axios";

const Served = () => {
  const [aupairs, setAupairs] = useState();
  const [hostFamilies, setHostFamilies] = useState();

  useEffect(() => {
    loadAupairs();
    loadHostFamilies();
  }, []);

  const loadAupairs = async () => {
    try {
      const { data } = await axios({
        url: "https://das-aupair-new-app-4481db6b895a.herokuapp.com/api/v1/users/count/aupairs",
        method: "GET",
      });

      data && setAupairs(data);
    } catch (err) {
      console.log("Error", err);
    }
  };

  const loadHostFamilies = async () => {
    try {
      const { data } = await axios({
        url: "https://das-aupair-new-app-4481db6b895a.herokuapp.com/api/v1/users/count/hostfamilies",
        method: "GET",
      });

      data && setHostFamilies(data);
    } catch (err) {
      console.log("Error", err);
    }
  };

  console.log("aupairs", "hostFamilies", aupairs, hostFamilies);

  return (
    <Fragment>
      <div className="served">
        <h1 className="served_primary_heading">STATISTICS</h1>
        <div className="completed__tasks__container">
          <div className="served_aupairs">
            <h1>
              <CountUp end={aupairs} duration={5} />
            </h1>
            <h3> AUPAIRS </h3>
          </div>
          <div className="served_aupairs">
            <h1>
              <CountUp end={hostFamilies} duration={5} />
            </h1>
            <h3>HOSTS FAMILIES</h3>
          </div>
          {/*<div className="served_aupairs">
            <h1>
              <CountUp end={1024} duration={5} />
            </h1>
            <h3>MACHES</h3>
  </div>*/}
        </div>
      </div>
    </Fragment>
  );
};

export default Served;
