export const styles = () => ({
  root: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    color: "#000133",
  },
  appBar: {
    background: "#464852",
    color: "#FFA500",
    boxShadow: "rgba(17, 17, 26, 0.1) 0px 1px 0px;",
  },
  toolbar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
  },
  icon: {
    padding: "10px",
  },
  userName: {
    color: "#FFA500",
  },
  profilePic: {
    cursor: "pointer",
  },
  container: {
    display: "flex",
    flex: 1,
    marginTop: "50px",
  },
  drawer: {
    background: "#272727",
    padding: "30px 0px 0px 0px",
    transition: "width .5s",
    zIndex: 1000,
    position: "fixed",
    marginTop: "40px",
    overFollowY: "scroll",
  },
  ClientDrawer: {
    background: "#012241",
    position: "static",
    transition: "width .5s",
  },
  closed: {
    width: "0px",
  },
  clientOpened: {
    width: "350px",
  },
  opened: {
    width: "240px",
  },
  main: {
    flex: 1,
    background: "#696969",
    color: "black",
    padding: "50px",
  },
  footer: {
    background: "#FFF",
    height: "50px",
    color: "#012241",
  },
  sideBarIcon: {
    color: "#FFA500",
  },
  activeIcon: {
    color: "#000",
  },
  appBarIcon: {
    color: "#FFA500",
    cursor: "pointer",
  },
  sideBarItem: {
    color: "#FFA500",
    "&:hover": {
      color: "#FFA500",
    },
  },
  sideBarLink: {
    textDecoration: "none",
    color: "#fff",
    fontSize: "16px",
  },
  activeLink: {
    background: "#FFA500 !important",
    color: "black",
    fontWeight: "bold",
  },
  clientSideBar: {
    padding: "0px 0px 0px 60px",
  },
  listItem: {
    color: "#fff",
  },
  toggleButton: {
    color: "#FFF",
    padding: "10px",
  },
  formFields: {
    display: "flex",
    padding: "0 0 0 20px",
  },
  title: {
    width: "100%",
    color: "#FFA500",
  },
  routeInput: {
    border: "none",
    padding: "5px",
    marginTop: "20px",
    height: "30px",
    borderTopLeftRadius: "3px",
    borderBottomLeftRadius: "3px",
    boxShadow: "0px 7px 29px 0px #000",
  },
  startButton: {
    border: "none",
    display: "block",
    width: "80px",
    background: "#fff",
    color: "#000",
    cursor: "pointer",
    height: "30px",
    borderTopRightRadius: "3px",
    borderBottomRightRadius: "3px",
    boxShadow: "0px 7px 29px 0px #000",
  },
  realTimeResults: {
    background: "#fff",
    width: "260px",
    height: "30vh",
    marginTop: "30px",
    marginLeft: "20px",
    borderRadius: "5px",
    boxShadow: "0px 7px 29px 0px #000",
  },
  logout: {
    width: "150px",
    borderRadius: "3px",
    outline: "none",
    padding: "5px",
    background: "#272727",
    color: "#FFA500",
    cursor: "pointer",
  },
  current: {
    background: "#272727",
    textDecoration: "none",
    color: "#fff",
    fontSize: "16px",
  },
  notcurrent: {
    background: "none",
    textDecoration: "none",
    color: "#fff",
    fontSize: "16px",
  },
  settings: {
    display: "flex",
    position: "relative",
    columnGap: "0px",
  },
  dropDown: {
    position: "absolute",
    top: "50px",
    right: "0px",
    height: "1-0px",
    width: "200px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "#464852",
    padding: "5px",
  },
  logoutBtn: {
    color: "#000",
    width: "100%",
  },
  sideBar: {
    color: "#FFF",
  },
});
