import {
  GET_STAFF_MEMBERS_START,
  GET_STAFF_MEMBERS_SUCCESS,
  GET_STAFF_MEMBERS_FAILURE,
  ADD_STAFF_MEMBERS_START,
  ADD_STAFF_MEMBERS_SUCCESS,
  ADD_STAFF_MEMBERS_FAILURE,
  UPDATE_STAFF_MEMBERS_START,
  UPDATE_STAFF_MEMBERS_SUCCESS,
  UPDATE_STAFF_MEMBERS_FAILURE,
  GET_USER_START,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  DELETE_USER_START,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
} from "../action/actionTypes/types";
import * as api from "../../api/index";
import { toast } from "react-toastify";

export const fetchStaffMembers = () => async (dispatch) => {
  dispatch({
    type: GET_STAFF_MEMBERS_START,
  });
  try {
    const { data } = await api.loadStaffMembers();
    return dispatch({
      type: GET_STAFF_MEMBERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: GET_STAFF_MEMBERS_FAILURE,
      payload: errorMessage,
    });
  }
};

export const registerStaffMembers = (formDta) => async (dispatch) => {
  dispatch({
    type: ADD_STAFF_MEMBERS_START,
  });
  try {
    const { data } = await api.addStaffMembers(formDta);
    return dispatch({
      type: ADD_STAFF_MEMBERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: ADD_STAFF_MEMBERS_FAILURE,
      payload: errorMessage,
    });
  }
};

export const updateStaffMembers = (formDta, userId) => async (dispatch) => {
  dispatch({
    type: UPDATE_STAFF_MEMBERS_START,
  });
  try {
    const { data } = await api.updateStaffMembers(formDta, userId);
    return dispatch({
      type: UPDATE_STAFF_MEMBERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: UPDATE_STAFF_MEMBERS_FAILURE,
      payload: errorMessage,
    });
  }
};

export const getUser = (userId) => async (dispatch) => {
  dispatch({
    type: GET_USER_START,
  });
  try {
    const { data } = await api.getUser(userId);
    return dispatch({
      type: GET_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: GET_USER_FAILURE,
      payload: errorMessage,
    });
  }
};

export const deleteUser = (userId) => async (dispatch) => {
  dispatch({
    type: DELETE_USER_START,
  });
  try {
    const { data } = await api.deleteUser(userId);
    return dispatch({
      type: DELETE_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: DELETE_USER_FAILURE,
      payload: errorMessage,
    });
  }
};

export const updateUser = (formData, userId) => async (dispatch) => {
  dispatch({
    type: UPDATE_USER_START,
  });
  try {
    const { data } = await api.updateProfile(formData, userId);
    if (data) {
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    return dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    toast.error("There was an Error", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return dispatch({
      type: UPDATE_USER_FAILURE,
      payload: errorMessage,
    });
  }
};
