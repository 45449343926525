/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import "./payment.css";
import { getClientToken, subscribe } from "./brainTreeAPIs";
import DropIn from "braintree-web-drop-in-react";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import { purchasePlan } from "./../Redux/action/payment";
import { connect } from "react-redux";

const Payment = ({
  plan,
  setHasSubscribed,
  onDone,
  purchasePlan,
  purchasedPlan,
}) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    clientToken: null,
    success: "",
    error: "",
    instance: "",
    payment: null,
  });

  const { clientToken, instance } = values;

  const getToken = () => {
    getClientToken().then((response) => {
      if (response.error) {
        setValues({ ...values, error: response.error });
      } else {
        setValues({ ...values, clientToken: response.clientToken });
      }
    });
  };

  const onSubScription = () => {
    instance.requestPaymentMethod().then((data) => {
      let nonce = data.nonce;

      let subscriptionData = {
        payment_method_nonce: nonce,
        plan: plan.id,
      };
      setLoading(true);
      subscribe(subscriptionData)
        .then((response) => {
          console.log("RESPONSE", response);

          if (response.error) {
            setValues({ ...values, error: response.err });
          } else {
            setValues({ ...values, success: response.success });
            purchasePlan(response);
            setLoading(false);
            setHasSubscribed(true);
            onDone(true);
            toast.success("Thank you for subscribing", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });

            // setTimeout(() => {
            //   window.location.reload(false);
            // }, 2000);
          }
        })
        .catch((err) => {
          setValues({ ...values, error: err, success: "" });
          setLoading(false);
        });
    });
  };

  useEffect(() => {
    getToken();
  }, []);

  return (
    <div className="payment_methods">
      <div className="subscription_payment">
        {clientToken && (
          <div>
            <DropIn
              options={{ authorization: clientToken }}
              onInstance={(instance) => setValues({ ...values, instance })}
            />
            {loading ? (
              <CircularProgress
                size={25}
                style={{
                  color: "orange",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            ) : (
              <button className="payment_button" onClick={onSubScription}>
                Pay
              </button>
            )}
          </div>
        )}
        {!clientToken && <h1>Loading...</h1>}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    purchasedPlan: state.purchasePlan.subscription,
  };
};

export default connect(mapStateToProps, { purchasePlan })(Payment);
