import React from "react";
import { Link } from "react-router-dom";
import logoAupair from "./../../assets/Logo/bigLogo-a.png";
import "./Logo.css";

const logo = (props) => (
  <Link to="/home">
    <div className="Logo">
      <img
        src={logoAupair}
        alt="aupair logo"
        style={{ height: "70px", width: "200px" }}
      />
    </div>
  </Link>
);
export default logo;
