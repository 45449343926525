import React, { Fragment } from "react";
import Layout from "./../../Layouts/Dashboard";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import AupairsChart from "./aupairs";
import HostFamiliesChart from "./hostFamilies";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(5),
    background: "#3f3f3f",
    color: theme.palette.text.secondary,
  },
  chartPaper: {
    display: "flex",
    height: "200px",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(5),
    background: "#3f3f3f",
    color: theme.palette.text.secondary,
  },
  title: {
    fontSize: "50px",
    textAlign: "center",
    fontWeight: 700,
    color: "#9e9e9e",
    fontFamily: "Roboto",
  },
  label: {
    color: "#ffe9a2",
  },
}));

const AllHost = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <Layout>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} xlg={12}>
              <Paper className={classes.paper}>
                <AupairsChart />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xlg={12}>
              <Paper className={classes.paper}>
                <HostFamiliesChart />
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Layout>
    </Fragment>
  );
};
export default AllHost;
