import React from "react";
import "./Host.css";

const OurHost = () => {
  return (
    <div className="our-Host">
      <div className="host-item-banel">
        <div className="up-host">
            <h1>What is a Host Family ? </h1>
       
            <blockquote>
              <p>
                A Host Family is a family willing to host an aupair. As host
                family you must meet certain requirements, such as, having a
                child under 16 years old. The ability to host an aupair in your
                home and respect the host family responsibilities during the
                whole stay. You must offers an aupair the pocket money, free
                board and lodging. In return, the aupair helps with childcare
                and light household chores. Both parties benefit from an aupair
                experience. As a host family you gain a new family member and
                get support in childcare and household management. According to
                the requirements, the aupair will have to learn or improve
                his/her knowledge of the host country’s language. To make it
                possible, you must speak this language at home.
              </p>
            </blockquote>
        </div>
      </div>
    </div>
  );
};
export default OurHost;
