import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useStyles } from "./style";
import Grid from "@material-ui/core/Grid";
import "./Host.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import {
  hostOtherInfo,
  UpdatehostOtherInfo,
} from "./../../../../../../../Redux/action/hostFroms";
import { toast } from "react-toastify";

const initialValues = {
  drivingLicense: "",
  rideBike: "",
  swim: "",
  firstaidtraining: "",
  dotheHousework: "",
  carechildren: "",
  Takingcareofpets: "",
  working_hours: "",
};

const AupairOtherInfo = ({ hostotherInfoData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [createMode, setCreateMode] = useState(true);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [message, setMessage] = useState("");

  const { otherInfo } = useSelector((state) => state.hostMoreInfo);
  const { hostOtherInfo: updatedOtherInfo } = useSelector(
    (state) => state.updateHostOtherInfo
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setFormErrors(validate(formValues));
    if (Object.keys(formErrors).length === 0) {
      if (createMode) {
        setOpen(true);
        dispatch(hostOtherInfo(formValues));
        clear();
      } else {
        setOpen(true);
        dispatch(UpdatehostOtherInfo(formValues, hostotherInfoData.uuid));
      }
    }
  };

  const clear = () => {
    setFormValues(initialValues);
  };

  useEffect(() => {
    if (otherInfo?.success) {
      setOpen(false);
      setMessage(otherInfo?.message?.message);
    } else if (!otherInfo?.success && otherInfo?.error !== null) {
      setOpen(false);
      setMessage("There was an error,try again");
    }
  }, [otherInfo?.success, otherInfo?.error, otherInfo?.message?.message]);

  useEffect(() => {
    if (updatedOtherInfo.success) {
      setOpen(false);
      setMessage(updatedOtherInfo?.message?.message);
    } else if (!updatedOtherInfo.success && updatedOtherInfo.error !== null) {
      setOpen(false);
      setMessage("There was an error,try again");
    }
  }, [
    updatedOtherInfo.success,
    updatedOtherInfo.error,
    updatedOtherInfo?.message?.message,
  ]);

  useEffect(() => {
    const notify = () => {
      if (otherInfo?.error && message) {
        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (otherInfo?.success && message) {
        toast.success(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    if (otherInfo?.success && message) {
      notify();
    } else if (!otherInfo?.success && otherInfo?.error !== null && message) {
      notify();
    } else {
      return;
    }
  }, [otherInfo?.success, otherInfo?.error, message]);

  useEffect(() => {
    const notify = () => {
      if (updatedOtherInfo?.error && message) {
        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (updatedOtherInfo?.success && message) {
        toast.success(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    if (updatedOtherInfo?.success && message) {
      notify();
    } else if (
      !updatedOtherInfo?.success &&
      updatedOtherInfo?.error !== null &&
      message
    ) {
      notify();
    } else {
      return;
    }
  }, [updatedOtherInfo?.success, updatedOtherInfo?.error, message]);

  const validate = (values) => {
    const errors = {};

    if (!values.drivingLicense) {
      errors.drivingLicense = "This field is required!";
    }
    if (!values.rideBike) {
      errors.rideBike = "This field is required!";
    }
    if (!values.swim) {
      errors.swim = "This field is required!";
    }
    if (!values.firstaidtraining) {
      errors.firstaidtraining = "This field is required!";
    }
    if (!values.dotheHousework) {
      errors.dotheHousework = "This field is required!";
    }
    if (!values.carechildren) {
      errors.carechildren = "This field is required!";
    }

    if (!values.Takingcareofpets) {
      errors.Takingcareofpets = "This field is required!";
    }
    if (!values.working_hours) {
      errors.working_hours = "This field is required!";
    }

    return errors;
  };

  useEffect(() => {
    if (hostotherInfoData) {
      setFormValues({
        drivingLicense: hostotherInfoData?.drivingLicense,
        rideBike: hostotherInfoData?.rideBike,
        swim: hostotherInfoData?.swim,
        firstaidtraining: hostotherInfoData?.firstaidtraining,
        dotheHousework: hostotherInfoData?.dotheHousework,
        carechildren: hostotherInfoData?.carechildren,
        Takingcareofpets: hostotherInfoData?.Takingcareofpets,
        working_hours: hostotherInfoData?.working_hours,
      });
      setCreateMode(false);
    } else if (!hostotherInfoData) {
      setCreateMode(true);
      setFormValues(initialValues);
    }
  }, [hostotherInfoData]);

  useEffect(() => {
    if (
      formValues.drivingLicense === "" ||
      formValues.rideBike === "" ||
      formValues.swim === "" ||
      formValues.firstaidtraining === "" ||
      formValues.dotheHousework === "" ||
      formValues.carechildren === "" ||
      formValues.Takingcareofpets === "" ||
      formValues.working_hours === ""
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formValues]);

  return (
    <React.Fragment>
      <CssBaseline />
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Container maxWidth="md" className="form_container">
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="personalInformationGroup">
            <h3 className="InnerTitles">MORE ABOUT AUPAIR WE NEED</h3>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Aupair has a driving license
                    <span className="required">*</span>
                  </label>
                  <select
                    name="drivingLicense"
                    value={formValues.drivingLicense}
                    onChange={handleChange}
                  >
                    <option value={null}>--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Working on it">Working on it</option>
                  </select>
                </div>
                <small className="formError">{formErrors.drivingLicense}</small>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Aupair can ride a Bike<span className="required">*</span>
                  </label>
                  <select
                    name="rideBike"
                    value={formValues.rideBike}
                    onChange={handleChange}
                  >
                    <option value={null}>--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Working on it">Working on it</option>
                  </select>
                </div>
                <small className="formError">{formErrors.rideBike}</small>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Apair can swim <span className="required">*</span>
                  </label>
                  <select
                    name="swim"
                    value={formValues.swim}
                    onChange={handleChange}
                  >
                    <option value={null}>--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Working on it">Working on it</option>
                  </select>
                </div>
                <small className="formError">{formErrors.swim}</small>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Aupair has attended the first aid training
                    <span className="required">*</span>
                  </label>
                  <select
                    name="firstaidtraining"
                    value={formValues.firstaidtraining}
                    onChange={handleChange}
                  >
                    <option value={null}>--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Working on it">Working on it</option>
                  </select>
                </div>
                <small className="formError">
                  {formErrors.firstaidtraining}
                </small>
              </Grid>

              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Aupair is willing to do the Housework.
                    <span className="required">*</span>
                  </label>
                  <select
                    name="dotheHousework"
                    value={formValues.dotheHousework}
                    onChange={handleChange}
                  >
                    <option value={null}>--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Not Necessary">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">{formErrors.dotheHousework}</small>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Aupair can care children with special needs
                    <span className="required">*</span>
                  </label>
                  <select
                    name="carechildren"
                    value={formValues.carechildren}
                    onChange={handleChange}
                  >
                    <option value={null}>--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Not Necessary">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">{formErrors.carechildren}</small>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Aupair willing to take care of pets
                    <span className="required">*</span>
                  </label>
                  <select
                    name="Takingcareofpets"
                    value={formValues.Takingcareofpets}
                    onChange={handleChange}
                  >
                    <option value={null}>--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Not Necessary">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">
                  {formErrors.Takingcareofpets}
                </small>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Working Hours (per week) <span className="required">*</span>
                  </label>
                  <input
                    name="working_hours"
                    type="number"
                    min="1"
                    required
                    placeholder="Ex:10"
                    value={formValues.working_hours}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.working_hours}</small>
              </Grid>
            </div>
            <button
              className={disabled ? "disabledButton" : "submit__btn"}
              type="submit"
              disabled={disabled}
            >
              {createMode ? "Create" : "Update"}
            </button>
          </div>
        </form>
      </Container>
    </React.Fragment>
  );
};
export default AupairOtherInfo;
