import Task from "./../TaskApart/Task";
import "./NotExpectations.css";

const AupairApart = () => {
  return (
    <div className="NotExpectations-last">
        <h1>WHAT MAY NOT BE EXPECTED FROM THE HOST FAMILY</h1>

      <div className="NotExpectations-container">
          <div
            whileHover={{ scale: 0.8, rotate: 5 }}
            className="NotExpectations-last-item--1"
          >
            <div className="NotExpectations-last-item--1-panel">&nbsp;</div>
          </div>
          <div
            whileHover={{ scale: 1, rotate: -5, backgroundColor: "#000" }}
            className="NotExpectations-last-item--2"
          >
            <ul>
              <li>
                Host Family does not pay the Au Pair's travel costs to and from
                the Host Family's residence. But they could pay it if they
                decided to do that.
              </li>
              <li>
                Providing fees for the host country language learning while
                Aupair still in the country of origin
              </li>
              <li>Paying the visa or other related fees</li>
              <li>
                Paying other fees related to travel preparation from the country
                of origin.
              </li>
            </ul>
          </div>
      </div>
      <Task />
    </div>
  );
};
export default AupairApart;
