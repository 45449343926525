import React from "react";
import "./App.css";
import Aupairs from "./container/HomeAupair/Home";
import About from "./container/AboutAupair/AboutCenter";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Host from "./container/HostAupair/HostAupair";
import Contact from "./container/ContactAupair/ContactCenter";
import Faq from "./container/FaqAupair/FaqCenter";
import GetInvolved from "./container/GetAupair/GetInvolved";
import RegisterAsAupair from "./Components/Register/Aupair/index";
import RegisterAsHostFamily from "./Components/Register/HostFamily/index";
import VerifyEmail from "./Components/Auth/VerifyEmail";
import MatchList from "./Components/Register/Aupair/match";
import MacthListFOrHost from "./Components/Register/HostFamily/match";
import { AupairMultiForm } from "./Components/Register/Aupair/AupairMultiForm";
import { HostMultForms } from "./Components/Register/HostFamily/HostMultForms";
import Registration from "./Components/Account/Authentication";
import Landing from "./Components/Page/Landing/Landing";
import AupairProfile from "./Components/Page/Profile/Profile";
import HostProfile from "./Components/Page/Profile/HostProfile";
import AupairList from "./Components/AdminPanel/AupairList";
import HostList from "./Components/AdminPanel/HostList";
import DashboardOverview from "./Components/AdminPanel/Dashboard";
import MatchesList from "./Components/AdminPanel/MatchList";
import RolesList from "./Components/AdminPanel/Roles";
import UsersList from "./Components/AdminPanel/Users";
import MentorList from "./Components/AdminPanel/Mentors";
import Messages from "./Components/AdminPanel/Messages";
import Contract from "./Components/AdminPanel/AupairHostContract/Contract";
import Eligibility from "./Components/Page/Eligibility/Eligibility";
import Blog from "./Components/Page/Blog/Blog";
import SingleBlog from "./Components/Page/Blog/SingleBlog";
import DashSingleBlog from "./Components/AdminPanel/Blog/SingleBlog";
import Announcement from "./Components/Page/Announcement/Announcement";
import DashAnnouncement from "./Components/AdminPanel/Announcement";
import DashBlogs from "./Components/AdminPanel/Blog";
import BlogCategories from "./Components/AdminPanel/BlogCategory";
import BlogTestmony from "./Components/AdminPanel/Testmony";
import ForgotPasswowrd from "./Components/Page/forgotPasswod/forgotpassword";
import RestPasswowrd from "./Components/Page/ResetPassword/ResetPassword";
import Subscribers from "./Components/AdminPanel/subscribers";
import { DasiNotifier } from "./utils/Notifier";
import Payment from "./Components/AdminPanel/payments";
import AupairMatches from "./Components/Profile/Aupair/matches";
import AupairIdentification from "./Components/Profile/Aupair/profile";
import AupairContact from "./Components/Profile/Aupair/contact";
import AupairEducation from "./Components/Profile/Aupair/education";
import AupairOtherInfo from "./Components/Profile/Aupair/moreAboutme";
import AupairPreferences from "./Components/Profile/Aupair/preferences";
import AupairExpectations from "./Components/Profile/Aupair/expectations";
import AupairWishes from "./Components/Profile/Aupair/wishes";
import AupairGallery from "./Components/Profile/Aupair/gallery";
import AupairDocuments from "./Components/Profile/Aupair/documents";
import HostMatching from "./Components/Profile/HostFamiliy/Matching";
import HostIdentificaton from "./Components/Profile/HostFamiliy/profile";
import HostEducation from "./Components/Profile/HostFamiliy/eduction";
import HostContact from "./Components/Profile/HostFamiliy/contact";
import HostLivingArea from "./Components/Profile/HostFamiliy/livingarea";
import HostPreferences from "./Components/Profile/HostFamiliy/preferences";
import HostExpectations from "./Components/Profile/HostFamiliy/expectation";
import HostAboutAupair from "./Components/Profile/HostFamiliy/aboutAupair";
import HostOffer from "./Components/Profile/HostFamiliy/offer";
import HostGallery from "./Components/Profile/HostFamiliy/gallery";
import HostDocument from "./Components/Profile/HostFamiliy/Document";

const App = (props) => {
  return (
    <div>
      <DasiNotifier />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/aupair" exact element={<Aupairs />} />
          <Route path="/about" exact element={<About />} />
          <Route path="/families" exact element={<Host />} />
          <Route path="/contact" exact element={<Contact />} />
          <Route path="/involved" exact element={<GetInvolved />} />
          <Route path="/faq" exact element={<Faq />} />
          <Route path="/register/aupair" exact element={<RegisterAsAupair />} />
          <Route
            path="/register/host/family"
            exact
            element={<RegisterAsHostFamily />}
          />
          <Route path="/confirm/email" exact element={<VerifyEmail />} />
          <Route path="/aupair/matches" exact element={<MatchList />} />
          <Route path="/host/matches" exact element={<MacthListFOrHost />} />
          <Route
            path="/aupair/registration"
            exact
            element={<AupairMultiForm />}
          />
          <Route
            path="/hostfamily/registration"
            exact
            element={<HostMultForms />}
          />
          <Route path="/contract" element={<Contract />} />
          <Route path="/eligibility" element={<Eligibility />} />
          <Route path="/blog" element={<Blog />} />
          <Route path={`/singleblog/:${props.id}`} element={<SingleBlog />} />
          <Route path="/announcement" element={<Announcement />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/aupair/profile" element={<AupairProfile />} />
          <Route path="/host/profile" element={<HostProfile />} />
          <Route path="/dashboard" element={<DashboardOverview />} />
          <Route path="/dashboard/aupairs" element={<AupairList />} />
          <Route path="/dashboard/hosts" element={<HostList />} />
          <Route path="/dashboard/match" element={<MatchesList />} />
          <Route path="/dashboard/roles" element={<RolesList />} />
          <Route path="/dashboard/users" element={<UsersList />} />
          <Route path="/dashboard/mentors" element={<MentorList />} />
          <Route path="/dashboard/messages" element={<Messages />} />
          <Route path="/dashboard/testmony" element={<BlogTestmony />} />
          <Route
            path="/dashboard/announcements"
            element={<DashAnnouncement />}
          />
          <Route path="/dashboard/blog/category" element={<BlogCategories />} />
          <Route path="/dashboard/blogs" element={<DashBlogs />} />
          <Route
            path={`/dashboard/singleblog/:${props.id}`}
            element={<DashSingleBlog />}
          />
          <Route path="/dashboard/payments" element={<Payment />} />
          <Route path="/dashboard/subscribers" element={<Subscribers />} />
          <Route path="/forgot" element={<ForgotPasswowrd />} />
          <Route path="/resertpassword/:token" element={<RestPasswowrd />} />
          <Route path="/aupair/matching" element={<AupairMatches />} />
          <Route
            path="/aupair/identification"
            element={<AupairIdentification />}
          />
          <Route path="/aupair/contact" element={<AupairContact />} />
          <Route path="/aupair/education" element={<AupairEducation />} />
          <Route path="/aupair/moreinfo" element={<AupairOtherInfo />} />
          <Route path="/aupair/preferences" element={<AupairPreferences />} />
          <Route path="/aupair/expectations" element={<AupairExpectations />} />
          <Route path="/aupair/wishes" element={<AupairWishes />} />
          <Route path="/aupair/gallery" element={<AupairGallery />} />
          <Route path="/aupair/documents" element={<AupairDocuments />} />
          <Route path="/host/matching" element={<HostMatching />} />
          <Route path="/host/identification" element={<HostIdentificaton />} />
          <Route path="/host/contact" element={<HostContact />} />
          <Route path="/host/education" element={<HostEducation />} />
          <Route path="/host/livingarea" element={<HostLivingArea />} />
          <Route path="/host/preferences" element={<HostPreferences />} />
          <Route path="/host/expectations" element={<HostExpectations />} />
          <Route path="/host/aboutaupair" element={<HostAboutAupair />} />
          <Route path="/host/offer" element={<HostOffer />} />
          <Route path="/host/gallery" element={<HostGallery />} />
          <Route path="/host/documents" element={<HostDocument />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
