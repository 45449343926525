import React, { useEffect } from "react";
import { connect } from "react-redux";
import { updateHostDocument } from "./../../../../../Redux/action/Documents";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { toast } from "react-toastify";

const EditDocument = ({ updateHostDocument, doc, updatedDocument }) => {
  const cloudName = "dsb0gu6km";
  const uploadPreset = "dasaupair";

  var myWidget = window.cloudinary.createUploadWidget(
    {
      cloudName: cloudName,
      uploadPreset: uploadPreset,
      folder: "aupair_images",
    },
    (error, result) => {
      if (!error && result && result.event === "success") {
        if (result?.info?.secure_url) {
          updateHostDocument(
            {
              documentName: doc.documentName,
              document: result?.info?.secure_url,
            },
            doc.uuid
          );
        }
      }
    }
  );

  const uplaodFile = () => {
    myWidget.open();
  };

  useEffect(() => {
    if (updatedDocument?.success) {
      toast.success(updatedDocument?.message?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [updatedDocument?.message?.message, updatedDocument?.success]);

  return (
    <Tooltip title="Edit Document" arrow>
      <IconButton aria-label="edit" onClick={uplaodFile}>
        <EditIcon style={{ color: "#000" }} />
      </IconButton>
    </Tooltip>
  );
};

const mapStateToProps = (state) => {
  return {
    updatedDocument: state.updateHostDocument.document,
  };
};

export default connect(mapStateToProps, { updateHostDocument })(EditDocument);
