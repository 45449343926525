import React, { useState, Fragment, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@material-ui/core/IconButton";
import { Search } from "./../../../Redux/action/Search";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  form: {
    background: "#4f4f4f",
    position: "relative",
  },
  searchIcon: {
    position: "absolute",
    top: "5px",
    left: "5px",
    cursor: "pointer",
  },
  paper: {
    width: "100%",
    padding: "5px",
    borderRadius: "3px",
    background: "#9e9e9e",
    color: "#000",
    textIndent: "30px",
    caretColor: "#FFA500",
  },
}));

const SearchBlog = ({ searchableData, searchResults, Search }) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState({
    term: "",
  });

  useEffect(() => {
    const fetchUsers = async () => {
      await Search({ searchkeyword: searchTerm.term, data: searchableData });
    };
    fetchUsers();
  }, [Search, searchTerm.term, searchableData]);
  return (
    <Fragment>
      <Paper component="form" className={classes.form} elevation={0}>
        <Paper
          className={classes.paper}
          component="input"
          type="search"
          placeholder="Search blog here ✍"
          value={searchTerm.term}
          onChange={(event) =>
            setSearchTerm({
              ...searchTerm,
              term: event.target.value,
            })
          }
        />
        <span className={classes.searchIcon}>
          <IconButton size="small">
            <SearchIcon />
          </IconButton>
        </span>
      </Paper>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    searchResults: state.search.searchResults,
  };
};
export default connect(mapStateToProps, { Search })(SearchBlog);
