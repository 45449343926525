import React from "react";
import "./Contract.css";

const Page_three = () => {
  return (
    <div className="page">
      <main className="page__three__main__body">
        <small className="page__three__main_body_pagraph">
          II. 3. In addition, the host shall pay the au pair pocket money
          amounting to ................................................ weekly.
        </small>
        <small className="page__three__main_body_pagraph">
          II. 4. The au pair's hours of duty shall be organised in such a way as
          ,to allow her/him to fallow courses, and to improve her/his general
          cultural development and knowledge of the language.
        </small>

        <article className="page__three__main__body_article">
          <small className="page__three__main__body_article_content">
            <strong>1.</strong> According to Article 3 of the European Agreement
            on “au pair” Placement, the initial period shall net exceed one year
            but may be extended to permit of a maximum stay of two years. Where
            the agreement has been concluded for an unspecified period, either
            party shall be entitled to terminate it by giving two weeks' notice.
          </small>
          <small className="page__three__main__body_article_content">
            <strong>2.</strong> According to Article 6 of the European Agreement
            on “au pair” Placement, the present agreement should be concluded
            preferably before the person au pair leaves the country in which
            she/he was resident and at latest during the first week of the
            placement.
          </small>
          <small className="page__three__main__body_article_content">
            <strong>3.</strong> According to Article 8, paragraph 1, of the
            European Agreement on “au pair”Placement, the au pair shall, where
            possible, occupy a separate room.
          </small>
          <small className="page__three__main__body_article_content">
            II. 5. The au pair shall have ....................... free day(s)
            per week and shall have full opportunity to take part in religious
            worship 1.
          </small>
          <small className="page__three__main__body_article_content">
            II. 6.
            <strong>
              (Each country should adopt a text according best with national
              circumstances resulting from the adaptation of one of the
              alternatives proposed below or a combination of both.)
            </strong>
          </small>
          <small className="page__three__main__body_article_content">
            The host undertakes to affiliate the au pair -to the social security
            scheme in respect of the following benefits
            ..................................................................................…………………………………...
            2
          </small>
          <small className="page__three__main__body_article_content">
            <strong style={{ display: "block", textAlign: "center" }}>
              or
            </strong>
          </small>
          <small className="page__three__main__body_article_content">
            The host shall take out a private insurance guaranteeing the au pair
            the following
            benefits.....................................................................................................……………………………
            2
          </small>
          <small className="page__three__main__body_article_content">
            The premium amounts to
            ....................................................... per month.
            The whole/half of the premium. (delete whichever does not apply)
            3shall be paid by the host.
          </small>
        </article>
      </main>
    </div>
  );
};

export default Page_three;
