import React from "react";
import "./Contacts.css";
import CallIcon from "@material-ui/icons/Call";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import Link from "@material-ui/core/Link";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Contacts = () => {
  const navigate = useNavigate();
  const currentUser = useSelector(
    (state) => state?.SignInReducer?.signin?.message?.fullName
  );

  const handleLogout = () => {
    navigate.push("/registration");
  };

  return (
    <React.Fragment>
      <div className="Contacts">
        <div className="Tel1">
          <CallIcon style={{ fontSize: "15px" }} />
        </div>
        <div className="Tel">
          <Link
            href="https://www.akagera-rhein-aupair.org/contact"
            style={{
              textDecoration: "none",
              margin: "0px 2px",
              color: "white",
              zIndex: "",
            }}
          >
            Call Us
          </Link>
        </div>
        <div className="Email1">
          <MailOutlineIcon
            style={{ fontSize: "15px", marginTop: "4px", marginRight: "5px" }}
          />
        </div>
        <div className="Email">
          <a
            href="https://www.akagera-rhein-aupair.org/contact"
            style={{
              textDecoration: "none",
              margin: "0px 2px",
              color: "white",
            }}
          >
            Email Us
          </a>
        </div>
        {currentUser ? (
          <>
            <div className="username">
              {<strong>{currentUser}</strong>}
              <button className="Logout" onClick={handleLogout}>
                Logout
              </button>
            </div>
          </>
        ) : (
          <>
            <Link href="/registration" variant="body2">
              <div className="create__account">
                <button>Signup</button>
              </div>
            </Link>
          </>
        )}
      </div>
    </React.Fragment>
  );
};
export default Contacts;
