import React, { useState, useEffect, useRef } from "react";
import { useStyles } from "./style";
import Footer from "../Footer/NewFooter";
import axios from "axios";
import { Modal } from "antd";
import Contact from "../../Components/ResponsiveNavBar/Contacts/Contacts";
import Navigation from "../../Components/myNavbar/navbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import OtpInput from "react-otp-input";
import "./verify.css";

const VerifyEmail = () => {
  const classes = useStyles();
  const otpRef = useRef();
  // const [result, setResult] = useState("");
  const [visible, setVisible] = useState(false);
  // const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [otp, setOtp] = useState({
    otp: "",
  });

  const hangeChange = (otp) => {
    setOtp({ otp });
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };

  useEffect(() => {
    if (otpRef?.current?.props?.value?.split("")?.length === 4) {
      const handleVerify = async () => {
        setOpen(true);
        await axios({
          url: `https://das-aupair-new-app-4481db6b895a.herokuapp.com/api/v1/users/verify/${otp.otp}`,
          // url: `http://localhost:8000/api/v1/users/verify/${otp.otp}`,
          method: "PUT",
        })
          .then((info) => {
            if (info) {
              // console.log("info", info);
              setIsVerified(true);
              setOpen(false);
            }
          })
          .catch((error) => {
            if (error?.message) {
              setIsVerified(false);
              setOpen(false);
            }
          });
      };
      handleVerify();
    } else {
      return;
    }
  }, [otp]);

  useEffect(()=>{
    if(typeof window !== "undefined"){
      window.scrollTo(0,0)
    }
  },[])
  
  return (
    <React.Fragment>
      <Contact />
      <Navigation />
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <div className={`${classes.verifyBtnContainer} verify__container`}>
        <Modal
          title="Email Verification"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <h1 className="resultMessage">result here</h1>
          <p className="messageBody">
            <strong style={{ color: "orange", fontSize: "25px" }}>
              &nbsp;
              message here
              &nbsp;
            </strong>
            Thank you
          </p>
        </Modal>

        <div className="verifyEmailFormContainer">
          {!isVerified ? (
            <p>Enter the code sent to your email to verify your account.</p>
          ) : (
            <p>Congratulations !!!!</p>
          )}
          <form>
            {!isVerified ? (
              <>
                <div className="code-inputs">
                  <OtpInput
                    ref={otpRef}
                    value={otp.otp}
                    onChange={hangeChange}
                    numInputs={4}
                    separator={<span>&nbsp;&nbsp;</span>}
                  />
                </div>
                <div>
                  <small>Keep this window open while check your inbox.</small>
                </div>
              </>
            ) : (
              <>
                <p>
                  Your email is now verified, you may login to your acccount.
                </p>
              </>
            )}
          </form>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default VerifyEmail;
