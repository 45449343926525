import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useStyles } from "./style";
import Grid from "@material-ui/core/Grid";
import "./expectation.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  hostExpectation,
  UpdatehostExpectations,
} from "./../../../../Redux/action/hostFroms";
import { Link } from "react-router-dom";
import Layout from "./../../../Layouts/hostFamily/Layout";
import { connect } from "react-redux";
import { getUser } from "./../../../../Redux/action/staffMembers";

const initialValues = {
  houseworK: "",
  timetable: "",
  flexibleFamily: "",
  integrateFamily: "",
  initiative: "",
};

const HostExpectaions = ({
  hostExpectation,
  UpdatehostExpectations,
  getUser,
  host,
  expectations,
  updatedHostExpectations,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [createMode, setCreateMode] = useState(true);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [disabled, setDisabled] = useState(false);

  const user = JSON.parse(localStorage.getItem("profile"))?.data?.user;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
    console.log(formValues);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));

    if (Object.keys(formErrors).length === 0) {
      if (createMode) {
        setOpen(true);
        hostExpectation(formValues);
        clear();
      } else {
        setOpen(true);
        UpdatehostExpectations(
          formValues,
          host?.message?.data?.user?.hostExpectations?.uuid
        );
      }
    }
  };

  const clear = () => {
    setFormValues(initialValues);
  };
  useEffect(() => {
    const fetchUser = () => {
      getUser(user?.uuid);
    };
    if (
      user?.uuid ||
      expectations?.success ||
      updatedHostExpectations?.success
    ) {
      fetchUser();
    } else {
      return;
    }
  }, [
    user?.uuid,
    getUser,
    expectations?.success,
    updatedHostExpectations?.success,
  ]);

  useEffect(() => {
    if (expectations?.success) {
      setOpen(false);
    } else if (!expectations?.success || expectations?.error !== null) {
      setOpen(false);
    }
  }, [expectations?.success, expectations?.error]);

  useEffect(() => {
    if (updatedHostExpectations?.success) {
      setOpen(false);
    } else if (
      !updatedHostExpectations?.success ||
      updatedHostExpectations?.error !== null
    ) {
      setOpen(false);
    }
  }, [updatedHostExpectations?.success, updatedHostExpectations?.error]);

  const validate = (values) => {
    const errors = {};

    if (!values.houseworK) {
      errors.houseworK = "This field is required!";
    }
    if (!values.timetable) {
      errors.timetable = "This field is required!";
    }
    if (!values.flexibleFamily) {
      errors.flexibleFamily = "This field is required!";
    }
    if (!values.integrateFamily) {
      errors.integrateFamily = "This field is required!";
    }
    if (!values.initiative) {
      errors.initiative = "This field is required!";
    }
    return errors;
  };

  useEffect(() => {
    if (host?.message?.data?.user?.hostExpectations) {
      setFormValues({
        houseworK: host?.message?.data?.user?.hostExpectations?.houseworK,
        timetable: host?.message?.data?.user?.hostExpectations?.timetable,
        flexibleFamily:
          host?.message?.data?.user?.hostExpectations?.flexibleFamily,
        integrateFamily:
          host?.message?.data?.user?.hostExpectations?.integrateFamily,
        initiative: host?.message?.data?.user?.hostExpectations?.initiative,
      });
      setCreateMode(false);
    } else if (!host?.message?.data?.user?.hostExpectations) {
      setCreateMode(true);
      setFormValues(initialValues);
    }
  }, [host]);

  useEffect(() => {
    if (
      formValues.houseworK === "" ||
      formValues.timetable === "" ||
      formValues.flexibleFamily === "" ||
      formValues.integrateFamily === "" ||
      formValues.initiative === ""
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formValues]);

  return (
    <Layout>
      <CssBaseline />
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <Container maxWidth="md" className="form_container">
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="personalInformationGroup">
            <h3 className="InnerTitles">OUR EXPECTATION TO THE AUPAIR</h3>

            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Work is done on the pre-established plan
                    <span className="required">*</span>
                  </label>
                  <select
                    name="houseworK"
                    value={formValues.houseworK}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Not Necessary">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">{formErrors.houseworK}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    There are clearly defined timetable
                    <span className="required">*</span>
                  </label>
                  <select
                    name="timetable"
                    value={formValues.timetable}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Not Necessary">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">{formErrors.timetable}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Life is flexible and spontaneous
                    <span className="required">*</span>
                  </label>
                  <select
                    name="flexibleFamily"
                    value={formValues.flexibleFamily}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Not Necessary">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">{formErrors.flexibleFamily}</small>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    I wish to integrate the family life.
                    <span className="required">*</span>
                  </label>
                  <select
                    name="integrateFamily"
                    value={formValues.integrateFamily}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Not Necessary">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">
                  {formErrors.integrateFamily}
                </small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Wish Aupair to work on her/his initiative.
                    <span className="required">*</span>
                  </label>
                  <select
                    name="initiative"
                    value={formValues.initiative}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Not Necessary">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">{formErrors.initiative}</small>
              </Grid>
            </div>
            <div className="user_action_buttons">
              <button
                className={disabled ? "disabledButton" : "submit__btn"}
                type="submit"
                disabled={disabled}
              >
                {createMode ? "Save" : "Update"}
              </button>
              <button className="next__btn" type="button">
                <Link to="/host/aboutaupair"> &larr; Prev</Link>
              </button>
              <button className="next__btn" type="button">
                <Link to="/host/offer"> Next &rarr;</Link>
              </button>
            </div>
          </div>
        </form>
      </Container>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    host: state.getUser.user,
    expectations: state.hostExpectations.expectations,
    updatedHostExpectations: state.updateHostExpectations.hostExpectations,
  };
};

export default connect(mapStateToProps, {
  hostExpectation,
  UpdatehostExpectations,
  getUser,
})(HostExpectaions);
