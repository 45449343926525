import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Layout from "./../../Layouts/Dashboard";
import CreateCategory from "./categoryModal";
import CreateBlog from "./Modal";
import Search from "./Search";
import AllBlogs from "./newBlog";
import { Divider } from "antd";
import SubjectIcon from "@material-ui/icons/Subject";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    fontSize: "20px",
    textAlign: "center",
    fontWeight: 700,
    color: "#9e9e9e",
    textTransform: "uppercase",
  },
}));

const Blogs = () => {
  const classes = useStyles();
  const [searchableData, setSearchableData] = useState([]);

  return (
    <Fragment>
      <Layout>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} xlg={12}>
              <h1 className={classes.title}>
                BLOG POSTS &nbsp;
                <SubjectIcon style={{ color: "#FFA500" }} />
              </h1>
            </Grid>
            <Divider />
            <Grid item xs={12} sm={12} md={4} lg={4} xlg={4}>
              <CreateCategory />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xlg={4}>
              <CreateBlog />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xlg={4}>
              <Search searchableData={searchableData} />
            </Grid>
            <Divider />
            <Grid item xs={12} sm={12} md={12} lg={12} xlg={12}>
              <AllBlogs setSearchableData={setSearchableData} />
            </Grid>
          </Grid>
        </div>
      </Layout>
    </Fragment>
  );
};

export default Blogs;
