import React, { useEffect } from "react";
import { connect } from "react-redux";
import { uploadAupairDocument } from "./../../../../../../../../Redux/action/Documents";
import { toast } from "react-toastify";

const CloudinaryUploadWidget = ({ uploadAupairDocument, document }) => {
  const cloudName = "dsb0gu6km";
  const uploadPreset = "dasaupair";

  var myWidget = window.cloudinary.createUploadWidget(
    {
      cloudName: cloudName,
      uploadPreset: uploadPreset,
      folder: "aupair_images",
    },
    (error, result) => {
      if (!error && result && result.event === "success") {
        if (result?.info?.secure_url) {
          uploadAupairDocument({
            documentName: "GERMAN CERTIFICATE",
            document: result?.info?.secure_url,
          });
        }
      }
    }
  );

  const uplaodFile = () => {
    myWidget.open();
  };

  useEffect(() => {
    if (document?.success) {
      toast.success(document?.message?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [document?.message?.message, document?.success]);

  return (
    <button className="cloudinary-button" onClick={uplaodFile}>
      Add German Certificate
    </button>
  );
};

const mapStateToProps = (state) => {
  return {
    document: state.uplaodAupairDocument.document,
  };
};

export default connect(mapStateToProps, { uploadAupairDocument })(
  CloudinaryUploadWidget
);
