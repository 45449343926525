import React from "react";
import { useForm, useStep } from "react-hooks-helper";
import Profile from "./AupairProfile";
import Expectations from "./Expectation";
import OtherInfo from "./AupairOtherInfo";
import AupairWishes from "./AupairWishes";
import Review from "./Review";

const defaultData = {
  //Profile

  fullName: "",
  phone: "",
  email: "",
  birthDate: "",
  familyStatus: "",
  country: "",
  religion: "",
  religionImpt: "",
  hobbies: "",
  routine: "",
  uniqueness: "",
  quality: "",
  weakness: "",
  specialNeed: "",
  street: "",
  postCode: "",
  houseNo: "",
  city: "",
  workPreference: "",

  //Expectations

  famExpAupair: "",
  famLiveWith: "",
  childrenNo: "",
  childrenAge: "",
  exFamilyStatus: "",
  famAge: "",
  famCountry: "",
  famLanguage: "",
  smokingHostFam: "",
  WorkingHrs: "",
  houseWorkPlanned: "",
  timetableDef: "",
  flexibleFam: "",
  integrateFamLife: "",
  eatOnWish: "",

  //Other info

  drivingLicense: "",
  canRideBike: "",
  canSwim: "",
  firstAidTraining: "",
  houseWork: "",
  childrenCareness: "",
  careAboutPets: "",
  educatLevel: "",
  specialRegime: "",
  foodPreference: "",

  //wishes

  travelExpenceFees: "",
  medicalInsuranceFees: "",
  pocketMoney: "",
  languageCourseFees: "",
  localTranspTicket: "",
  provideCar: "",
  provideBike: "",
  privateRoom: "",
  privateBathroom: "",
  basicOfferIsEnough: "",
  letter: "",
  imageOne: "",
  imageTwo: "",
  imageThree: "",
  imageFour: "",
  imageFive: "",
};

const steps = [
  { id: "profiles" },
  { id: "expectations" },
  { id: "otherinfos" },
  { id: "wishes" },
  { id: "review" },
];

export const AupairMultiForm = () => {
  const [formData, setFormData] = useForm(defaultData);
  const [aupairUploads, setAupairUploads] = React.useState([]);
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });

  const props = {
    formData,
    setFormData,
    navigation,
    aupairUploads,
    setAupairUploads,
  };

  switch (step.id) {
    case "profiles":
      return <Profile {...props} />;
    case "expectations":
      return <Expectations {...props} />;
    case "otherinfos":
      return <OtherInfo {...props} />;
    case "wishes":
      return <AupairWishes {...props} />;
    case "review":
      return <Review {...props} />;
    default:
      break;
  }
};
