import React from "react";
import "./Contacts.css";
import CallIcon from "@material-ui/icons/Call";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import Link from "@material-ui/core/Link";
const Contacts = () => {
  return (
    <React.Fragment>
      <div className="Contacts">
        <div className="Tel">
          <Link
            href="/contact"
            style={{
              textDecoration: "none",
              color: "#000",
            }}
          >
            <strong>
              <CallIcon className="tel_icon" />
              Call Us
            </strong>
          </Link>
        </div>
        <div className="Email">
          <Link
            href="/contact"
            style={{
              textDecoration: "none",
              margin: "0px 2px",
              color: "#000",
            }}
          >
            <strong>
              <MailOutlineIcon className="email_icon" />
              Email Us
            </strong>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Contacts;
