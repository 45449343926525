import {
  GET_MESSAGES_START,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAILURE,
  DELETE_MESSAGE_START,
  DELETE_MESSAGES_SUCCESS,
  DELETE_MESSAGES_FAILURE,
} from "../action/actionTypes/types";
import * as api from "../../api/index";

export const fetchMessages = () => async (dispatch) => {
  dispatch({
    type: GET_MESSAGES_START,
  });
  try {
    const { data } = await api.getMessages();
    return dispatch({
      type: GET_MESSAGES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: GET_MESSAGES_FAILURE,
      payload: errorMessage,
    });
  }
};

export const deleteMessages = (msgId) => async (dispatch) => {
  dispatch({
    type: DELETE_MESSAGE_START,
  });
  try {
    const { data } = await api.deleteMessage(msgId);
    return dispatch({
      type: DELETE_MESSAGES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: DELETE_MESSAGES_FAILURE,
      payload: errorMessage,
    });
  }
};
