import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useStyles } from "./style";
import Grid from "@material-ui/core/Grid";
import "./contact.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  aupairContacts,
  updateAupairContacts,
} from "./../../../../Redux/action/aupairForms";

import Layout from "./../../../../Components/Layouts/Profile/Layout";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getUser } from "./../../../../Redux/action/staffMembers";

const initialValues = {
  street: "",
  postcode: "",
  country: "",
  houseNo: "",
  city: "",
  email: "",
  phone: "",
  instagram: "",
  twitter: "",
  facebook: "",
  tiktok: "",
  skype: "",
};

const ContactInfo = ({
  contacts,
  aupairContacts,
  aupair,
  updateAupairContacts,
  updatedContacts,
  getUser,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [createMode, setCreateMode] = useState(true);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [disabled, setDisabled] = useState(false);

  const user = JSON.parse(localStorage.getItem("profile"))?.data?.user;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));
    if (Object.keys(formErrors).length === 0) {
      if (createMode) {
        setOpen(true);
        aupairContacts(formValues);
        clear();
      } else {
        setOpen(true);
        updateAupairContacts(
          formValues,
          aupair?.message?.data?.user?.aupaircontacts?.uuid
        );
      }
    }
  };

  const clear = () => {
    setFormValues(initialValues);
  };

  useEffect(() => {
    const fetchUser = () => {
      getUser(user?.uuid);
    };
    if (user?.uuid || contacts.success || updatedContacts?.success) {
      fetchUser();
    } else {
      return;
    }
  }, [user?.uuid, getUser, contacts?.success, updatedContacts?.success]);

  useEffect(() => {
    if (contacts?.success) {
      setOpen(false);
    } else if (contacts?.error !== null && !contacts?.success) {
      setOpen(false);
    }
  }, [contacts?.success, contacts?.message?.message, contacts?.error]);

  useEffect(() => {
    if (updatedContacts?.success) {
      setOpen(false);
    } else if (updatedContacts?.error !== null && !updatedContacts?.success) {
      setOpen(false);
    }
  }, [
    updatedContacts?.success,
    updatedContacts?.error,
    updatedContacts?.message?.message,
  ]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.street) {
      errors.street = "Street code Please!";
    }
    if (!values.postcode) {
      errors.postcode = "Post Code Please !";
    }
    if (!values.country) {
      errors.country = "Country Please !";
    }
    if (!values.houseNo) {
      errors.houseNo = "House Number Please!";
    }
    if (!values.city) {
      errors.city = "The City Please!";
    }
    if (!values.email) {
      errors.email = "Your Email Please!";
    } else if (!regex.test(values.email)) {
      errors.email = "this is not a valid email address";
    }
    if (!values.phone) {
      errors.phone = "Your Phone Number Please!";
    }

    return errors;
  };

  useEffect(() => {
    if (aupair?.message?.data?.user?.aupaircontacts) {
      setFormValues({
        street: aupair?.message?.data?.user?.aupaircontacts?.street,
        postcode: aupair?.message?.data?.user?.aupaircontacts?.postcode,
        country: aupair?.message?.data?.user?.aupaircontacts?.country,
        houseNo: aupair?.message?.data?.user?.aupaircontacts?.houseNo,
        city: aupair?.message?.data?.user?.aupaircontacts?.city,
        email: aupair?.message?.data?.user?.aupaircontacts?.email,
        phone: aupair?.message?.data?.user?.aupaircontacts?.phone,
        instagram: aupair?.message?.data?.user?.aupaircontacts?.instagram,
        twitter: aupair?.message?.data?.user?.aupaircontacts?.twitter,
        facebook: aupair?.message?.data?.user?.aupaircontacts?.facebook,
        tiktok: aupair?.message?.data?.user?.aupaircontacts?.tiktok,
        skype: aupair?.message?.data?.user?.aupaircontacts?.skype,
      });
      setCreateMode(false);
    } else if (!aupair?.message?.data?.user?.aupaircontacts) {
      setCreateMode(true);
      setFormValues(initialValues);
    }
  }, [aupair]);

  useEffect(() => {
    if (
      formValues.street === "" ||
      formValues.postcode === "" ||
      formValues.country === "" ||
      formValues.houseNo === "" ||
      formValues.city === "" ||
      formValues.email === "" ||
      formValues.phone === ""
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formValues]);

  return (
    <Layout>
      <CssBaseline />
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Container maxWidth="md" className="form_container">
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="personalInformationGroup">
            <h3 className="InnerTitles">Contact information</h3>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Street <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    name="street"
                    placeholder="Ex:KG 42 st"
                    value={formValues.street}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.street}</small>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Post Code <span className="required">*</span>
                  </label>
                  <input
                    style={{ width: "95%" }}
                    type="text"
                    name="postcode"
                    placeholder="Ex:0000"
                    value={formValues.postcode}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.postcode}</small>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Country <span className="required">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="country"
                    placeholder="Ex:Rwanda"
                    value={formValues.country}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.country}</small>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    House Number <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    name="houseNo"
                    placeholder="Ex:123"
                    value={formValues.houseNo}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.houseNo}</small>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    City <span className="required">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="city"
                    placeholder="Ex:Kigali"
                    value={formValues.city}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.city}</small>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Email <span className="required">*</span>
                  </label>
                  <input
                    required
                    type="email"
                    name="email"
                    placeholder="Ex:example@gmail.com"
                    value={formValues.email}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.email}</small>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Telephone <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    name="phone"
                    placeholder="Ex:+25078xxxxxxx"
                    value={formValues.phone}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.phone}</small>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Instagram<span className="required"></span>
                  </label>
                  <input
                    type="text"
                    placeholder="Your instagram "
                    name="instagram"
                    value={formValues.instagram}
                    onChange={handleChange}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Twitter<span className="required"></span>
                  </label>
                  <input
                    type="text"
                    placeholder="Your twitter "
                    name="twitter"
                    value={formValues.twitter}
                    onChange={handleChange}
                  />
                </div>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Facebook<span className="required"></span>
                  </label>
                  <input
                    type="text"
                    placeholder="Your facebook"
                    name="facebook"
                    value={formValues.facebook}
                    onChange={handleChange}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    TikToK<span className="required"></span>
                  </label>
                  <input
                    type="text"
                    placeholder="Your tiktok"
                    name="tiktok"
                    value={formValues.tiktok}
                    onChange={handleChange}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Skype<span className="required"></span>
                  </label>
                  <input
                    type="text"
                    placeholder="Your skype"
                    name="skype"
                    value={formValues.skype}
                    onChange={handleChange}
                  />
                </div>
              </Grid>
            </div>
            <div className="user_action_buttons">
              <button
                className={disabled ? "disabledButton" : "submit__btn"}
                type="submit"
                disabled={disabled}
              >
                {createMode ? "Save" : "Update"}
              </button>
              <button className="next__btn" type="button">
                <Link to="/aupair/identification"> &larr; Prev</Link>
              </button>
              <button className="next__btn" type="button">
                <Link to="/aupair/education"> Next &rarr;</Link>
              </button>
            </div>
          </div>
        </form>
      </Container>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    contacts: state.aupairContacts.contacts,
    aupair: state.getUser.user,
    updatedContacts: state.updateAupairContacts.contacts,
  };
};

export default connect(mapStateToProps, {
  getUser,
  aupairContacts,
  updateAupairContacts,
})(ContactInfo);
