import React, { useEffect } from "react";
import { connect } from "react-redux";
import { updateAupairPhoto } from "./../../../../../../../../Redux/action/Gallery";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { toast } from "react-toastify";

const EditPhoto = ({ updateAupairPhoto, doc, updatedPhoto }) => {
  const cloudName = "dsb0gu6km";
  const uploadPreset = "dasaupair";

  var myWidget = window.cloudinary.createUploadWidget(
    {
      cloudName: cloudName,
      uploadPreset: uploadPreset,
      folder: "host_images",
    },
    (error, result) => {
      if (!error && result && result.event === "success") {
        if (result?.info?.secure_url) {
          updateAupairPhoto(
            {
              photo: result?.info?.secure_url,
            },
            doc.uuid
          );
        }
      }
    }
  );

  const uplaodFile = () => {
    myWidget.open();
  };

  useEffect(() => {
    if (updatedPhoto?.success) {
      toast.success(updatedPhoto?.message?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [updatedPhoto?.message?.message, updatedPhoto?.success]);

  return (
    <Tooltip title="Edit Document" arrow>
      <IconButton aria-label="edit" onClick={uplaodFile}>
        <EditIcon style={{ color: "#000" }} />
      </IconButton>
    </Tooltip>
  );
};

const mapStateToProps = (state) => {
  return {
    updatedPhoto: state.updatedAupairPhoto.photo,
  };
};

export default connect(mapStateToProps, { updateAupairPhoto })(EditPhoto);
