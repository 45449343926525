import React from "react";
import "./Cores.css";

const AupairCores = () => {
  return (
    <div className="cores">
      <div className="cores-container">
          <div className="cores-item--1">
            <div className="cores-item--1-panel">
              <h2>
                In addition to childcare, you are also expected to help with the
                household. Here are some examples of what household chores can
                be expected of you:
              </h2>
            </div>
          </div>
          <div className="cores-item--2">
            <ul>
              <li> Loading and unloading the dishwasher</li>
              <li>Keeping the kitchen clean</li>
              <li>To make the laundry</li>
              <li>Ironing children's laundry</li>
              <li>Vacuuming Dust off If available</li>
              <li>keep children's bathrooms clean</li>
              <li>Keeping your own room clean and tidy</li>
              <li>Empty trash can</li>
              <li> Small purchases </li>
              <li>Taking care of pets</li>
            </ul>
          </div>
      </div>
    </div>
  );
};
export default AupairCores;
