import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableHead from "@material-ui/core/TableHead";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import UpdateUser from "./EditModal";
import DeleteModal from "./DeleteModal";
import { Empty } from "antd";
import { connect, useSelector } from "react-redux";
import { fetchStaffMembers } from "./../../../Redux/action/staffMembers.js";
import { toast } from "react-toastify";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#3f3f3f",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#9e9e9e",
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    background: "#464852a9",
    boxShadow: "rgba(0, 0, 0, 0.5) 0px 4px 12px",
  },
});

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const Users = ({
  staffMembers,
  fetchStaffMembers,
  setSearchableData,
  register,
  updateStaff,
  deteted,
}) => {
  const tableStyles = useStyles();
  const { message: searchResult } = useSelector(
    (state) => state.search.searchResults
  );
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const getStaffMembers = () => {
      fetchStaffMembers();
    };
    if (register?.success || updateStaff?.success || deteted?.success) {
      getStaffMembers();
    } else {
      getStaffMembers();
    }
  }, [
    fetchStaffMembers,
    register?.success,
    updateStaff?.success,
    deteted?.success,
  ]);

  useEffect(() => {
    if (!staffMembers.success) {
      setOpen(true);
    } else if (staffMembers.success) {
      setOpen(false);
      setUsers(staffMembers?.message?.data?.staffMembers);
      setSearchableData(staffMembers?.message?.data?.staffMembers);
    }
  }, [
    staffMembers.success,
    setSearchableData,
    staffMembers?.message?.data?.staffMembers,
  ]);

  useEffect(() => {
    if (searchResult) {
      setUsers(searchResult);
    } else if (staffMembers.success) {
      setUsers(staffMembers?.message?.data?.staffMembers);
    }
  }, [
    searchResult,
    staffMembers.success,
    staffMembers?.message?.data?.staffMembers,
  ]);

  useEffect(() => {
    const toastify = () => {
      if (register?.success) {
        toast.success("Added Successfully!!👍", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    toastify();
  }, [register?.success]);

  useEffect(() => {
    const toastify = () => {
      if (updateStaff?.success) {
        toast.success(updateStaff?.message?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    toastify();
  }, [updateStaff?.success, updateStaff?.message?.message]);

  useEffect(() => {
    const toastify = () => {
      if (deteted?.success) {
        toast.success(deteted?.message?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    toastify();
  }, [deteted?.success, deteted?.message?.message]);

  return (
    <Fragment>
      <div>
        <Backdrop style={{ zIndex: 1000, color: "#fff" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      {users?.length === 0 ? (
        <Empty />
      ) : (
        <TableContainer component={Paper}>
          <Table
            className={tableStyles.table}
            aria-label="custom pagination table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell align="right">Full Name</StyledTableCell>
                <StyledTableCell align="right">Role</StyledTableCell>
                <StyledTableCell align="right">Phone</StyledTableCell>
                <StyledTableCell align="right">Email</StyledTableCell>
                <StyledTableCell align="right">Edit</StyledTableCell>
                <StyledTableCell align="right">Delete</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsPerPage > 0
                ? users
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((staff) => (
                      <StyledTableRow key={staff.uuid}>
                        <StyledTableCell component="th" scope="row">
                          {staff.createdAt.split("T")[0]}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {staff.fullName}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {staff.role}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {staff.phone}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {staff.email}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <UpdateUser user={staff} />
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <DeleteModal userId={staff.uuid} />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                : users?.map((staff) => (
                    <StyledTableRow key={staff.uuid}>
                      <StyledTableCell component="th" scope="row">
                        {staff.createdAt.split("T")[0]}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {staff.fullName}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {staff.role}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {staff.phone}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {staff.email}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <UpdateUser user={staff} />
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <DeleteModal userId={staff.uuid} />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}

              {emptyRows > 0 && (
                <StyledTableRow style={{ height: 53 * emptyRows }}>
                  <StyledTableCell colSpan={6} />
                </StyledTableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={3}
                  count={users?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    staffMembers: state.staff.members,
    register: state.register.register,
    updateStaff: state.updateStaff.updateUser,
    deteted: state.deletedUser.deleted,
  };
};

export default connect(mapStateToProps, { fetchStaffMembers })(Users);
