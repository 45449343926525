import React, { Fragment, useState } from "react";
import Layout from "./../../Layouts/Dashboard";
import AllAupairsList from "./Aupairs";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Divider } from "antd";
import Search from "./Search";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  title: {
    fontSize: "20px",
    textAlign: "center",
    fontWeight: 700,
    color: "#9e9e9e",
  },
}));

const AllAupairs = () => {
  const classes = useStyles();
  const [searchableData, setSearchableData] = useState([]);

  return (
    <Fragment>
      <Layout>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} xlg={12}>
              <h1 className={classes.title}>AUPAIR MANAGEMENT</h1>
            </Grid>
            <Divider />
            <Grid item xs={12} sm={12} md={4} lg={4} xlg={4}>
              &nbsp;
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xlg={8}>
              <Search searchableData={searchableData} />
            </Grid>
            <Divider />
            <Grid item xs={12} sm={12} md={12} lg={12} xlg={12}>
              <AllAupairsList setSearchableData={setSearchableData} />
            </Grid>
          </Grid>
        </div>
      </Layout>
    </Fragment>
  );
};
export default AllAupairs;
