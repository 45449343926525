import React from "react";
import "./NewFooter.css";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import { useNavigate, Link } from "react-router-dom";
import Subscribe from "./../../Components/Page/Blog/Subscribe";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "#464852",
    height: "500px",
    padding: "20px 0 0 0 ",
    marginTop: "50px",
  },
  paper: {
    color: "#fff",
    background: "#464852",
  },
}));

const Footer = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <>
      <div className={`${classes.root} myfooter`}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            style={{ textAlign: "center" }}
          >
            <Paper className={classes.paper} elevation={0}>
              <div className="footer-logo">
                <img
                  src="https://res.cloudinary.com/dsb0gu6km/image/upload/v1671344806/aupair%20assets/icon_egokws.png"
                  alt="logo"
                />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Paper className={classes.paper} elevation={0}>
              <div className="links_container">
                <div className="footer-text">
                  <span>TERMS OF USE </span>
                </div>
                <div className="footer-text footer-text-items">
                  Terms & Conditions
                </div>
                <div className="footer-text footer-text-items">
                  Privacy Policy
                </div>
                <div className="footer-text footer-text-items">
                  Data Declaration for Customers
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Paper className={classes.paper} elevation={0}>
              <div className="links_container">
                <div className="footer-text">
                  <span>QUICK LINKS</span>
                </div>
                <div
                  className="footer-text footer-text-items"
                  onClick={() => navigate("/aupair")}
                >
                  For Aupair Candidates
                </div>
                <div
                  className="footer-text footer-text-items"
                  onClick={() => navigate("/families")}
                >
                  For Host Family
                </div>
                <div
                  className="footer-text footer-text-items"
                  onClick={() => navigate("/involved")}
                >
                  Ge Involved
                </div>
                <div
                  className="footer-text footer-text-items"
                  onClick={() => navigate("/faq")}
                >
                  FAQ
                </div>
                <div
                  className="footer-text footer-text-items"
                  onClick={() => navigate("/contact")}
                >
                  Contact Us
                </div>
                <div
                  className="footer-text footer-text-items"
                  onClick={() => navigate("/announcement")}
                >
                  Announcements
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Paper className={classes.paper} elevation={0}>
              <Subscribe />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Paper className={classes.paper} elevation={0}>
              <div className="footer_social_medias">
                <a
                  href="https://www.instagram.com/ukwjeandedieu1gm/"
                  className="social__media"
                  target="blank"
                >
                  <InstagramIcon className="foot-bottom-social-icon" />
                </a>
                <a
                  href="https://twitter.com/@Jean_de_Dieu_UK"
                  className="social__media"
                  target="blank"
                >
                  <TwitterIcon className="foot-bottom-social-icon" />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=100076722369602"
                  className="social__media"
                  target="blank"
                >
                  <FacebookIcon className="foot-bottom-social-icon" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCoQT0PapNz1C4N_j-bFpOtg/featured"
                  className="social__media"
                  target="blank"
                >
                  <YouTubeIcon className="foot-bottom-social-icon" />
                </a>
                <a
                  href="https://www.linkedin.com/in/ukwitegetse/"
                  className="social__media"
                  target="blank"
                >
                  <LinkedInIcon className="foot-bottom-social-icon" />
                </a>
                <Link to="/inprint">
                  <small>Imprint</small>
                </Link>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Paper className={classes.paper} elevation={0}>
              <p className="copyright">
                Copyright © 2022 Das Aupair Matching is a trademark and service
                of Akagera Rhein Center.
              </p>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default Footer;
