import React from "react";
import "./expectationsPartTwo.css";

const AupairCores = () => {
  return (
    <div className="expectationsPartTwo">
      <div className="expectationsPartTwo-container">
          <div className="expectationsPartTwo-item--1">
            <div className="expectationsPartTwo-item--1-panel">&nbsp;</div>
          </div>
          <div className="expectationsPartTwo-item--2">
            <ul>
              <li>
                Ready to give Aupair the opportunity to attend a language
                course.
              </li>
              <li>
                Support the Aupair financially to participate in language course
                / 50 Euro monthly or more.
              </li>
              <li>Being interested in cultural exchange with the Aupair.</li>
              <li>
                At home, the family speaks the language of the host country.
              </li>
              <li>Show the openness to the culture of the au pair</li>
              <li>Let’s Aupair practice their religion freely.</li>
              <li>
                If possible, provide a bedroom a shower, and toilet for
                individual use.
              </li>
              <li>The bedroom must be at least 8 square meters</li>
              <li>Accept the Aupair need to do holiday separately</li>
              <li>The weekend day may be the resting day for day</li>
              <li>
                The boarding and lodging for the Aupair are free of charge
              </li>
            </ul>
          </div>
      </div>
    </div>
  );
};
export default AupairCores;
