import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useStyles } from "./style";
import Grid from "@material-ui/core/Grid";
import "./livingarea.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  hostLivingArea,
  updateHostLivingArea,
} from "./../../../../Redux/action/hostFroms";
import { Link } from "react-router-dom";
import Layout from "./../../../Layouts/hostFamily/Layout";
import { connect } from "react-redux";
import { getUser } from "./../../../../Redux/action/staffMembers";

const initialValues = {
  welivein: "",
  state: "",
  city: "",
};

const LivingArea = ({
  hostLivingArea,
  updateHostLivingArea,
  getUser,
  host,
  livingArea,
  hostLiving,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [createMode, setCreateMode] = useState(true);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [disabled, setDisabled] = useState(false);

  const user = JSON.parse(localStorage.getItem("profile"))?.data?.user;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));

    if (Object.keys(formErrors).length === 0) {
      if (createMode) {
        setOpen(true);
        hostLivingArea(formValues);
        clear();
      } else {
        setOpen(true);
        updateHostLivingArea(
          formValues,
          host?.message?.data?.user?.livingArea?.uuid
        );
      }
    }
  };

  const clear = () => {
    setFormValues(initialValues);
  };

  useEffect(() => {
    const fetchUser = () => {
      getUser(user?.uuid);
    };
    if (user?.uuid || livingArea?.success || hostLiving?.success) {
      fetchUser();
    } else {
      return;
    }
  }, [user?.uuid, getUser, livingArea?.success, hostLiving?.success]);

  useEffect(() => {
    if (livingArea.success) {
      setOpen(false);
    } else if (!livingArea.success || livingArea.error !== null) {
      setOpen(false);
    }
  }, [livingArea?.success, livingArea?.error]);

  useEffect(() => {
    if (hostLiving.success) {
      setOpen(false);
    } else if (!hostLiving?.success || hostLiving?.error !== null) {
      setOpen(false);
    }
  }, [hostLiving?.success, hostLiving?.error]);

  const validate = (values) => {
    const errors = {};

    if (!values.welivein) {
      errors.welivein = "This field is required!";
    }
    if (!values.state) {
      errors.state = "This field is required!";
    }
    if (!values.city) {
      errors.city = "This field is required!";
    }

    return errors;
  };

  useEffect(() => {
    if (host?.message?.data?.user?.livingArea) {
      setFormValues({
        welivein: host?.message?.data?.user?.livingArea?.welivein,
        state: host?.message?.data?.user?.livingArea?.state,
        city: host?.message?.data?.user?.livingArea?.city,
      });
      setCreateMode(false);
    } else if (!host?.message?.data?.user?.livingArea) {
      setCreateMode(true);
      setFormValues(initialValues);
    }
  }, [host]);

  useEffect(() => {
    if (
      formValues.welivein === 0 ||
      formValues.state === "" ||
      formValues.city === ""
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formValues]);

  return (
    <Layout>
      <CssBaseline />
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <Container maxWidth="md" className="form_container">
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="personalInformationGroup">
            <h3 className="InnerTitles">LIVING AREA</h3>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    We Live In<span className="required">*</span>
                  </label>
                  <select
                    name="welivein"
                    value={formValues.welivein}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Country Side">Country Side</option>
                    <option value="Village">Village</option>
                    <option value="Town">Town</option>
                    <option value="Big City">Big City</option>
                    <option value="Metropole Region">Metropole Region</option>
                    <option value="All Posibilities">All Posibilities</option>
                  </select>
                </div>
                <small className="formError">{formErrors.welivein}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    In State <span className="required">*</span>
                  </label>
                  <select
                    name="state"
                    value={formValues.state}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Baden-Württemberg">Baden-Württemberg</option>
                    <option value="Bayern">Bayern</option>
                    <option value="Berlin">Berlin</option>
                    <option value="Brandenburg">Brandenburg</option>
                    <option value="Bremen">Bremen</option>
                    <option value="Hamburg">Hamburg</option>
                    <option value="Hessen">Hessen</option>
                    <option value="Mecklenburg-Vorpommern">
                      Mecklenburg-Vorpommern
                    </option>
                    <option value="Niedersachsen">Niedersachsen</option>
                    <option value="Nordrhein-Westfalen">
                      Nordrhein-Westfalen
                    </option>
                    <option value="Rheinland-Pfalz">Rheinland-Pfalz</option>
                    <option value="Saarland">Saarland</option>
                    <option value="Sachsen">Sachsen</option>
                    <option value="Sachsen-Anhalt">Sachsen-Anhalt</option>
                    <option value="Schleswig-Holstein">
                      Schleswig-Holstein
                    </option>
                    <option value="Thüringen">Thüringen</option>
                    <option value="No preference">No preference</option>
                  </select>
                </div>
                <small className="formError">{formErrors.state}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    City of <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Ex:Berlin"
                    required
                    name="city"
                    value={formValues.city}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.city}</small>
              </Grid>
            </div>
            <div className="user_action_buttons">
              <button
                className={disabled ? "disabledButton" : "submit__btn"}
                type="submit"
                disabled={disabled}
              >
                {createMode ? "Save" : "Update"}
              </button>
              <button className="next__btn" type="button">
                <Link to="/host/education"> &larr; Prev</Link>
              </button>
              <button className="next__btn" type="button">
                <Link to="/host/preferences"> Next &rarr;</Link>
              </button>
            </div>
          </div>
        </form>
      </Container>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    host: state.getUser.user,
    livingArea: state.hostLivingArea.livingArea,
    hostLiving: state.updateHostLivingArea.hostLiving,
  };
};

export default connect(mapStateToProps, {
  hostLivingArea,
  updateHostLivingArea,
  getUser,
})(LivingArea);
