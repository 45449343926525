import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ListItemText from "@material-ui/core/ListItemText";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Navigation from "./../../Page/Landing/Navigation/Navigation";
import Contact from "./../../Page/Landing/Contacts/Contacts";
import AupairHeader from "./AupairHeader";
import Footer from "../../Footer/NewFooter";
import "./AupairHome.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";

import {
  aupairProfile as aupairProfileAction,
  aupairExpectation as aupairExpectaionAction,
  aupairOtherInfo as aupairOtherInfoAction,
  aupairWishes as aupairWishesAction,
} from "../../../Redux/action/aupairForms";

const Review = ({
  formData,
  aupairUploads,
  navigation,
  profile,
  Aupexpect,
  AupOtherInfo,
  wish,
  aupairProfileAction,
  aupairExpectaionAction,
  aupairOtherInfoAction,
  aupairWishesAction,
}) => {
  const { go } = navigation;
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const {
    fullName,
    phone,
    email,
    birthDate,
    familyStatus,
    country,
    religion,
    religionImpt,
    hobbies,
    routine,
    uniqueness,
    quality,
    weakness,
    specialNeed,
    street,
    postCode,
    houseNo,
    city,
    workPreference,

    //Expectations

    famExpAupair,
    famLiveWith,
    childrenNo,
    childrenAge,
    exFamilyStatus,
    famAge,
    famCountry,
    famLanguage,
    smokingHostFam,
    WorkingHrs,
    houseWorkPlanned,
    timetableDef,
    flexibleFam,
    integrateFamLife,
    eatOnWish,
    //Other info
    drivingLicense,
    canRideBike,
    canSwim,
    firstAidTraining,
    houseWork,
    childrenCareness,
    careAboutPets,
    educatLevel,
    specialRegime,
    foodPreference,
    //wishes
    travelExpenceFees,
    medicalInsuranceFees,
    pocketMoney,
    languageCourseFees,
    localTranspTicket,
    provideCar,
    provideBike,
    privateRoom,
    privateBathroom,
    basicOfferIsEnough,
    letter,
  } = formData;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setOpen(!open);
    //*****AUPAIR PROFILE*****//
    await aupairProfileAction({
      fullName,
      phone,
      email,
      birthDate,
      familyStatus,
      country,
      religion,
      religionImpt,
      hobbies,
      routine,
      uniqueness,
      quality,
      weakness,
      specialNeed,
      street,
      postCode,
      houseNo,
      city,
      workPreference,
    });

    //*****EXPECTATION*****//
    await aupairExpectaionAction({
      famExpAupair,
      famLiveWith,
      childrenNo,
      childrenAge,
      exFamilyStatus,
      famAge,
      famCountry,
      famLanguage,
      smokingHostFam,
      WorkingHrs,
      houseWorkPlanned,
      timetableDef,
      flexibleFam,
      integrateFamLife,
      eatOnWish,
    });

    //*****OTHER INFO*****//
    await aupairOtherInfoAction({
      drivingLicense,
      canRideBike,
      canSwim,
      firstAidTraining,
      houseWork,
      childrenCareness,
      careAboutPets,
      educatLevel,
      specialRegime,
      foodPreference,
    });

    //*****WISHES*****//
    await aupairWishesAction({
      travelExpenceFees,
      medicalInsuranceFees,
      pocketMoney,
      languageCourseFees,
      localTranspTicket,
      provideCar,
      provideBike,
      privateRoom,
      privateBathroom,
      basicOfferIsEnough,
      letter,
      imageOne: aupairUploads[0]?.secure_url,
      imageTwo: aupairUploads[1]?.secure_url,
      imageThree: aupairUploads[2]?.secure_url,
      imageFour: aupairUploads[3]?.secure_url,
      imageFive: aupairUploads[4]?.secure_url,
    });
  };

  const Previous = (event) => {
    event.preventDefault();
    setOpen(!open);
    setTimeout(() => {
      setOpen(false);
      navigation.previous();
    }, 1000);
  };

  useEffect(() => {
    if (
      profile.success &&
      Aupexpect.success &&
      AupOtherInfo.success &&
      wish.succcess
    ) {
      setOpen(false);
    }
  }, [profile.success, Aupexpect.success, AupOtherInfo.success, wish.succcess]);
  return (
    <React.Fragment>
      <Contact />
      <Navigation />
      <AupairHeader />
      <div>
        <Backdrop
          style={{ zIndex: 1000, color: "#fff" }}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Container maxWidth="md" className="reviews">
        <h3 className="reviewsTitle">Review Your Application</h3>

        <RenderAccordion
          go={go}
          summary="PROFILES"
          details={[
            { FullName: fullName },
            { Phone: phone },
            { Email: email },
            { BirthDate: birthDate },
            { FamilyStatus: familyStatus },
            { Country: country },
            { Religion: religion },
            { IsReligionImportant: religionImpt },
            { Hobbies: hobbies },
            { Routine: routine },
            { Uniqueness: uniqueness },
            { Quality: quality },
            { Weakness: weakness },
            { SpecialNeed: specialNeed },
            { Street: street },
            { PostCode: postCode },
            { HouseNumber: houseNo },
            { City: city },
            { WorkPreference: workPreference },
          ]}
        />
        <RenderAccordion
          go={go}
          summary="EXPECTATIONS"
          details={[
            { FamilyWithExperience: famExpAupair },
            { FamilyLiveWith: famLiveWith },
            { ChildrenNumber: childrenNo },
            { ChildrenAge: childrenAge },
            { FamilyStatus: exFamilyStatus },
            { FamilyAge: famAge },
            { FamilyCountry: famCountry },
            { FamilyLanguage: famLanguage },
            { SmokingHostFamily: smokingHostFam },
            { WorkingHours: WorkingHrs },
            { HouseWorkPlanned: houseWorkPlanned },
            { TimeTablePlanned: timetableDef },
            { FlexibleFamily: flexibleFam },
            { IntegrateFamilyLife: integrateFamLife },
            { EatOnYourOwnWish: eatOnWish },
          ]}
        />
        <RenderAccordion
          go={go}
          summary="OTHERINFOS"
          details={[
            { IhaveDrivingLicense: drivingLicense },
            { ICanRideBike: canRideBike },
            { IcanSwim: canSwim },
            { FirstAidTraining: firstAidTraining },
            { IcanDoHouseWork: houseWork },
            { ChildrenCareNess: childrenCareness },
            { CareAboutPets: careAboutPets },
            { EducationLevel: educatLevel },
            { SpecialRegime: specialRegime },
            { FoodPrefrence: foodPreference },
          ]}
        />
        <RenderAccordion
          go={go}
          summary="WISHES"
          details={[
            { ProvideTravelExpenseFees: travelExpenceFees },
            { ProvideMedicalInsuranceFees: medicalInsuranceFees },
            { ProvidePocketMoney: pocketMoney },
            { ProvideLanguageCourseFees: languageCourseFees },
            { ProvideLocalTransportTicket: localTranspTicket },
            { ProvideCar: provideCar },
            { ProvideBike: provideBike },
            { ProvidePrivateRoom: privateRoom },
            { ProvidePrivateBirthRoom: privateBathroom },
            { BasicOfferIsEnough: basicOfferIsEnough },
            { Letter: letter },
          ]}
        />

        <div className="hostImages">
          <div className="hostimg">
            <img src={aupairUploads[0]?.secure_url} alt="first" />
          </div>
          <div className="hostimg">
            <img src={aupairUploads[1]?.secure_url} alt="second" />
          </div>
          <div className="hostimg">
            <img src={aupairUploads[2]?.secure_url} alt="third" />
          </div>
          <div className="hostimg">
            <img src={aupairUploads[3]?.secure_url} alt="fourth" />
          </div>
          <div className="hostimg">
            <img src={aupairUploads[4]?.secure_url} alt="fourth" />
          </div>
        </div>

        <button
          className="Back"
          onClick={Previous}
          style={{ marginTop: "1rem" }}
        >
          Back
        </button>
        <button
          className="Continue"
          type="submit"
          onClick={handleSubmit}
          style={{ marginTop: "1rem" }}
        >
          Submit
        </button>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

const mapStateToProps = ({
  AupairProfileReducer,
  aupairExpectationReducer,
  aupairOtherInfoReducer,
  aupairWishes,
}) => {
  const { profile } = AupairProfileReducer;
  const { Aupexpect } = aupairExpectationReducer;
  const { AupOtherInfo } = aupairOtherInfoReducer;
  const { wish } = aupairWishes;

  return {
    profile,
    Aupexpect,
    AupOtherInfo,
    wish,
  };
};
export default connect(mapStateToProps, {
  aupairProfileAction,
  aupairExpectaionAction,
  aupairOtherInfoAction,
  aupairWishesAction,
})(Review);

export const RenderAccordion = ({ summary, details, go }) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      {summary}
    </AccordionSummary>
    <AccordionDetails>
      <div>
        {details.map((data, index) => {
          const objKey = Object.keys(data)[0];
          const objValue = data[Object.keys(data)[0]];
          return (
            <ListItemText key={index}>
              <strong style={{ marginRight: "50px" }}>{`${objKey}`}:</strong>
              {`${objValue}`}
            </ListItemText>
          );
        })}
        <IconButton
          style={{ color: "#077" }}
          component="span"
          onClick={() => go(summary.toLowerCase())}
        >
          <EditIcon />
        </IconButton>
      </div>
    </AccordionDetails>
  </Accordion>
);
