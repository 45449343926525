import React, { useState, useEffect, Fragment } from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useStyles } from "./style";
import Grid from "@material-ui/core/Grid";
import "./Host.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import {
  hostContacts as HostContactInfo,
  updateHostContact,
} from "./../../../../../../../Redux/action/hostFroms";
import { toast } from "react-toastify";

const initialValues = {
  street: "",
  postCode: "",
  country: "",
  houseNo: "",
  city: "",
  email: "",
  phone: "",
  instagram: "",
  twitter: "",
  facebook: "",
  tiktok: "",
  skype: "",
};

const ContactInfo = ({ hostContactData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [createMode, setCreateMode] = useState(true);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [message, setMessage] = useState("");

  const { contacts } = useSelector((state) => state.hostContact);
  const { hostContacts: updatedContacts } = useSelector(
    (state) => state.updateHostContact
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));
    if (Object.keys(formErrors).length === 0) {
      if (createMode) {
        setOpen(true);
        dispatch(HostContactInfo(formValues));
        clear();
      } else {
        setOpen(true);
        dispatch(updateHostContact(formValues, hostContactData.uuid));
      }
    }
  };

  const clear = () => {
    setFormValues(initialValues);
  };

  useEffect(() => {
    if (contacts?.success) {
      setOpen(false);
      setMessage(contacts?.message?.message);
    } else if (contacts?.error !== null && !contacts?.success) {
      setOpen(false);
      setMessage("There was an error,try again");
    }
  }, [contacts?.success, contacts?.message?.message, contacts?.error]);

  useEffect(() => {
    if (updatedContacts?.success) {
      setOpen(false);
      setMessage(updatedContacts?.message?.message);
    } else if (updatedContacts?.error !== null && !updatedContacts?.success) {
      setOpen(false);
      setMessage("There was an error,try again");
    }
  }, [
    updatedContacts?.success,
    updatedContacts?.error,
    updatedContacts?.message?.message,
  ]);

  useEffect(() => {
    const notify = () => {
      if (updatedContacts?.error && message) {
        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (updatedContacts?.success && message) {
        toast.success(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    if (updatedContacts?.success && message) {
      notify();
    } else if (
      !updatedContacts?.success &&
      updatedContacts?.error !== null &&
      message
    ) {
      notify();
    } else {
      return;
    }
  }, [updatedContacts?.success, updatedContacts?.error, message]);

  useEffect(() => {
    const notify = () => {
      if (contacts?.error && message) {
        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (contacts?.success && message) {
        toast.success(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    if (contacts?.success && message) {
      notify();
    } else if (!contacts?.success && contacts?.error !== null && message) {
      notify();
    } else {
      return;
    }
  }, [contacts?.success, contacts?.error, message]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.street) {
      errors.street = "Street code Please!";
    }
    if (!values.postCode) {
      errors.postCode = "Post Code Please !";
    }
    if (!values.country) {
      errors.country = "Country Please !";
    }
    if (!values.houseNo) {
      errors.houseNo = "House Number Please!";
    }
    if (!values.city) {
      errors.city = "The City Please!";
    }
    if (!values.email) {
      errors.email = "Your Email Please!";
    } else if (!regex.test(values.email)) {
      errors.email = "this is not a valid email address";
    }
    if (!values.phone) {
      errors.phone = "Your Phone Number Please!";
    }
    return errors;
  };

  useEffect(() => {
    if (hostContactData) {
      setFormValues({
        street: hostContactData?.street,
        postCode: hostContactData?.postCode,
        country: hostContactData?.country,
        houseNo: hostContactData?.houseNo,
        city: hostContactData?.city,
        email: hostContactData?.email,
        phone: hostContactData?.phone,
        instagram: hostContactData?.instagram,
        twitter: hostContactData?.twitter,
        facebook: hostContactData?.facebook,
        tiktok: hostContactData?.tiktok,
        skype: hostContactData?.skype,
      });
      setCreateMode(false);
    } else if (!hostContactData) {
      setCreateMode(true);
      setFormValues(initialValues);
    }
  }, [hostContactData]);

  useEffect(() => {
    if (
      formValues.street === "" ||
      formValues.postcode === "" ||
      formValues.country === "" ||
      formValues.houseNo === "" ||
      formValues.city === "" ||
      formValues.email === "" ||
      formValues.phone === ""
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formValues]);

  return (
    <Fragment>
      <CssBaseline />
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <Container maxWidth="md" className="form_container">
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="personalInformationGroup">
            <h3 className="InnerTitles">Contact information</h3>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Street <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    name="street"
                    placeholder="Ex:KG 42 st"
                    value={formValues.street}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.street}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Post Code <span className="required">*</span>
                  </label>
                  <input
                    style={{ width: "95%" }}
                    type="text"
                    name="postCode"
                    placeholder="Ex:0000"
                    value={formValues.postCode}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.postCode}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Country <span className="required">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="country"
                    placeholder="Ex:German"
                    value={formValues.country}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.country}</small>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    House Number <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    name="houseNo"
                    placeholder="Ex:123"
                    value={formValues.houseNo}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.houseNo}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    City <span className="required">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="city"
                    placeholder="Ex:Berlin"
                    value={formValues.city}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.city}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Email <span className="required">*</span>
                  </label>
                  <input
                    required
                    type="email"
                    name="email"
                    placeholder="Ex:example@gmail.com"
                    value={formValues.email}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.email}</small>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Telephone <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    name="phone"
                    placeholder="Ex:+49xxxxxxx"
                    value={formValues.phone}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.phone}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Instagram<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Your instagram"
                    name="instagram"
                    value={formValues.instagram}
                    onChange={handleChange}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Twitter<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Your twitter"
                    name="twitter"
                    value={formValues.twitter}
                    onChange={handleChange}
                  />
                </div>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Facebook<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Your facebook"
                    name="facebook"
                    value={formValues.facebook}
                    onChange={handleChange}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    TikToK<span className="required"></span>
                  </label>
                  <input
                    type="text"
                    placeholder="Your Tiktok"
                    name="tiktok"
                    value={formValues.tiktok}
                    onChange={handleChange}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Skype<span className="required"></span>
                  </label>
                  <input
                    type="text"
                    placeholder="Your skype"
                    name="skype"
                    value={formValues.skype}
                    onChange={handleChange}
                  />
                </div>
              </Grid>
            </div>
            <button
              className={disabled ? "disabledButton" : "submit__btn"}
              type="submit"
              disabled={disabled}
            >
              {createMode ? "Create" : "Update"}
            </button>
          </div>
        </form>
      </Container>
    </Fragment>
  );
};

export default ContactInfo;
