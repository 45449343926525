import React, { useState, useEffect, Fragment } from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useStyles } from "./style";
import Grid from "@material-ui/core/Grid";
import "./Aupair.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  aupairExpectation,
  updateAupairExpectations,
} from "./../../../../../../../Redux/action/aupairForms";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const initialValues = {
  houseworK: "",
  timetable: "",
  flexibleFamily: "",
  integrateFamily: "",
};

const AupairExpectaions = ({ expectationData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [createMode, setCreateMode] = useState(true);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [message, setMessage] = useState("");

  const { Aupexpect } = useSelector((state) => state.aupairExpectation);
  const { expectations } = useSelector(
    (state) => state.updateAupairExpectations
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));
    if (Object.keys(formErrors).length === 0) {
      if (createMode) {
        setOpen(true);
        dispatch(aupairExpectation(formValues));
        clear();
      } else {
        setOpen(true);
        dispatch(updateAupairExpectations(formValues, expectationData.uuid));
      }
    }
  };

  const clear = () => {
    setFormValues(initialValues);
  };

  useEffect(() => {
    if (Aupexpect.success) {
      setOpen(false);
      setMessage(Aupexpect?.message?.message);
    } else if (!Aupexpect.success && Aupexpect.error !== null) {
      setOpen(false);
      setMessage("There was an error,try again");
    }
  }, [Aupexpect.success, Aupexpect?.message?.message, Aupexpect.error]);

  useEffect(() => {
    if (expectations.success) {
      setOpen(false);
      setMessage(expectations?.message?.message);
    } else if (!expectations.success && expectations.error !== null) {
      setOpen(false);
      setMessage("There was an error,try again");
    }
  }, [expectations.success, expectations.error, expectations]);

  useEffect(() => {
    const notify = () => {
      if (Aupexpect?.error && message) {
        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (Aupexpect?.success && message) {
        toast.success(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    if (Aupexpect?.success && message) {
      notify();
    } else if (!Aupexpect?.success && Aupexpect?.error !== null && message) {
      notify();
    } else {
      return;
    }
  }, [Aupexpect?.success, Aupexpect?.error, message]);

  useEffect(() => {
    const notify = () => {
      if (expectations?.error && message) {
        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (expectations?.success && message) {
        toast.success(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    if (expectations?.success && message) {
      notify();
    } else if (
      !expectations?.success &&
      expectations?.error !== null &&
      message
    ) {
      notify();
    } else {
      return;
    }
  }, [expectations?.success, expectations?.error, message]);

  const validate = (values) => {
    const errors = {};

    if (!values.houseworK) {
      errors.houseworK = "This field is required!";
    }
    if (!values.timetable) {
      errors.timetable = "This field is required!";
    }
    if (!values.flexibleFamily) {
      errors.flexibleFamily = "This field is required!";
    }
    if (!values.integrateFamily) {
      errors.integrateFamily = "This field is required!";
    }

    return errors;
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (expectationData) {
      setFormValues({
        houseworK: expectationData?.houseworK,
        timetable: expectationData?.timetable,
        flexibleFamily: expectationData?.flexibleFamily,
        integrateFamily: expectationData?.integrateFamily,
      });
      setCreateMode(false);
    } else if (!expectationData) {
      setCreateMode(true);
      setFormValues(initialValues);
    }
  }, [expectationData]);

  useEffect(() => {
    if (
      formValues.houseworK === "" ||
      formValues.timetable === "" ||
      formValues.flexibleFamily === "" ||
      formValues.integrateFamily === ""
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formValues]);

  return (
    <Fragment>
      <CssBaseline />
      <div>
        <Backdrop
          style={{ zIndex: 1000, color: "orange" }}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Container maxWidth="md" className="form_container">
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="personalInformationGroup">
            <h3 className="InnerTitles">MY EXPECTATION FROM HOST FAMILY</h3>

            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    The work is done on the established plan
                    <span className="required">*</span>
                  </label>
                  <select
                    name="houseworK"
                    value={formValues.houseworK}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Not Necessary">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">{formErrors.houseworK}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    There are clearly defined timetable
                    <span className="required">*</span>
                  </label>
                  <select
                    name="timetable"
                    value={formValues.timetable}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Not Necessary">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">{formErrors.timetable}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Life is flexible and spontaneous
                    <span className="required">*</span>
                  </label>
                  <select
                    name="flexibleFamily"
                    value={formValues.flexibleFamily}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Not Necessary">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">{formErrors.flexibleFamily}</small>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    I wish to integrate the family life.
                    <span className="required">*</span>
                  </label>
                  <select
                    name="integrateFamily"
                    value={formValues.integrateFamily}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Not Necessary">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">
                  {formErrors.integrateFamily}
                </small>
              </Grid>
            </div>
            <button
              className={disabled ? "disabledButton" : "submit__btn"}
              type="submit"
              disabled={disabled}
            >
              {createMode ? "Create" : "Update"}
            </button>
          </div>
        </form>
      </Container>
    </Fragment>
  );
};

export default AupairExpectaions;
