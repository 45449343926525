import React, { Fragment, useEffect } from "react";
import Layout from "./../../Layouts/Dashboard";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Divider } from "antd";
import Card from "./connectionCard";
import { getAllMatchingConnections } from "./../../../Redux/action/matches";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  title: {
    fontSize: "20px",
    textAlign: "center",
    fontWeight: 700,
    color: "#FFA500",
  },
}));

const AllMatches = ({ getAllMatchingConnections, connections }) => {
  const classes = useStyles();
  useEffect(() => {
    const fetchAllConnections = () => {
      getAllMatchingConnections();
    };
    fetchAllConnections();
  }, [getAllMatchingConnections]);

  return (
    <Fragment>
      <Layout>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} xlg={12}>
              <h1 className={classes.title}>MATCH MANAGEMENT</h1>
            </Grid>
            <Divider />
            {connections?.message?.data?.matches?.map((connection) => (
              <Grid item xs={12} sm={12} md={4} lg={4} xlg={4}>
                <Card connection={connection} />
              </Grid>
            ))}
          </Grid>
        </div>
      </Layout>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    connections: state.allConnections.connections,
  };
};

export default connect(mapStateToProps, {
  getAllMatchingConnections,
})(AllMatches);
