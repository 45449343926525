import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useStyles } from "./style";
import Grid from "@material-ui/core/Grid";
import "./Host.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import {
  hostPreferences,
  updateHostPreferences,
} from "./../../../../../../../Redux/action/hostFroms";
import { toast } from "react-toastify";

const initialValues = {
  aupairFrom: "",
  experiencedHost: "",
  experiencedaupair: "",
  familyLivingModel: "",
  liveWithPets: "",
  smokinghostfamily: "",
  specialNeeds: "",
  ChildrenFrom: "",
  toChildrenNumber: "",
  agedfrom: "",
  agedto: "",
};

const FamilyPreference = ({ hostPreferencesData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [createMode, setCreateMode] = useState(true);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [message, setMessage] = useState("");

  const { preferences } = useSelector((state) => state.hostPreferences);
  const { hostPreferences: updatedPreferences } = useSelector(
    (state) => state.updateHostPreferences
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
    console.log(formValues);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));
    if (Object.keys(formErrors).length === 0) {
      if (createMode) {
        setOpen(true);
        dispatch(hostPreferences(formValues));
        clear();
      } else {
        setOpen(true);
        dispatch(updateHostPreferences(formValues, hostPreferencesData.uuid));
      }
    }
  };

  const clear = () => {
    setFormValues(initialValues);
  };

  useEffect(() => {
    if (preferences?.success) {
      setOpen(false);
      setMessage(preferences?.message?.message);
    } else if (!preferences?.success && preferences?.error !== null) {
      setOpen(false);
      setMessage("There was an error,try again");
    }
  }, [preferences?.success, preferences?.message?.message, preferences?.error]);

  useEffect(() => {
    if (updatedPreferences?.success) {
      setOpen(false);
      setMessage(updatedPreferences?.message?.message);
    } else if (
      !updatedPreferences?.success &&
      updatedPreferences?.error !== null
    ) {
      setOpen(false);
      setMessage("There was an error,try again");
    }
  }, [
    updatedPreferences?.success,
    updatedPreferences?.error,
    updatedPreferences?.message?.message,
  ]);

  useEffect(() => {
    const notify = () => {
      if (preferences?.error && message) {
        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (preferences?.success && message) {
        toast.success(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    if (preferences?.success && message) {
      notify();
    } else if (
      !preferences?.success &&
      preferences?.error !== null &&
      message
    ) {
      notify();
    } else {
      return;
    }
  }, [preferences?.success, preferences?.error, message]);

  useEffect(() => {
    const notify = () => {
      if (updatedPreferences?.error && message) {
        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (updatedPreferences?.success && message) {
        toast.success(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    if (updatedPreferences?.success && message) {
      notify();
    } else if (
      !updatedPreferences?.success &&
      updatedPreferences?.error !== null &&
      message
    ) {
      notify();
    } else {
      return;
    }
  }, [updatedPreferences?.success, updatedPreferences?.error, message]);

  const validate = (values) => {
    const errors = {};

    if (!values.aupairFrom) {
      errors.aupairFrom = "This field is required!";
    }
    if (!values.experiencedHost) {
      errors.experiencedHost = "This field is required!";
    }
    if (!values.experiencedaupair) {
      errors.experiencedaupair = "This field is required!";
    }
    if (!values.familyLivingModel) {
      errors.familyLivingModel = "This field is required!";
    }
    if (!values.liveWithPets) {
      errors.liveWithPets = "This field is required!";
    }
    if (!values.smokinghostfamily) {
      errors.smokinghostfamily = "This field is required!";
    }

    if (!values.specialNeeds) {
      errors.specialNeeds = "This field is required!";
    }
    if (!values.ChildrenFrom) {
      errors.ChildrenFrom = "This field is required!";
    }
    if (!values.toChildrenNumber) {
      errors.toChildrenNumber = "This field is required!";
    }
    if (!values.agedfrom) {
      errors.agedfrom = "This field is required!";
    }
    if (!values.agedto) {
      errors.agedto = "This field is required!";
    }

    return errors;
  };

  useEffect(() => {
    if (hostPreferencesData) {
      setFormValues({
        aupairFrom: hostPreferencesData?.aupairFrom,
        experiencedHost: hostPreferencesData?.experiencedHost,
        experiencedaupair: hostPreferencesData?.experiencedaupair,
        familyLivingModel: hostPreferencesData?.familyLivingModel,
        liveWithPets: hostPreferencesData?.liveWithPets,
        smokinghostfamily: hostPreferencesData?.smokinghostfamily,
        specialNeeds: hostPreferencesData?.specialNeeds,
        ChildrenFrom: hostPreferencesData?.ChildrenFrom,
        toChildrenNumber: hostPreferencesData?.toChildrenNumber,
        agedfrom: hostPreferencesData?.agedfrom,
        agedto: hostPreferencesData?.agedto,
      });
      setCreateMode(false);
    } else if (!hostPreferencesData) {
      setCreateMode(true);
      setFormValues(initialValues);
    }
  }, [hostPreferencesData]);

  useEffect(() => {
    if (
      formValues.aupairFrom === 0 ||
      formValues.experiencedHost === "" ||
      formValues.experiencedaupair === "" ||
      formValues.familyLivingModel === "" ||
      formValues.liveWithPets === "" ||
      formValues.smokinghostfamily === "" ||
      formValues.specialNeeds === "" ||
      formValues.ChildrenFrom === "" ||
      formValues.toChildrenNumber === "" ||
      formValues.agedfrom === "" ||
      formValues.agedto === ""
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formValues]);
  return (
    <React.Fragment>
      <CssBaseline />
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Container maxWidth="md" className="form_container">
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="personalInformationGroup">
            <h3 className="InnerTitles">OUR AUPAIR PREFERENCE</h3>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    I'm searching an Aupair From
                    <span className="required">*</span>
                  </label>
                  <select
                    name="aupairFrom"
                    value={formValues.aupairFrom}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Uganda">Uganda</option>
                    <option value="DRCongo">DRCongo</option>
                    <option value="Tanzania">Tanzania</option>
                    <option value="No preference">No preference</option>
                  </select>
                </div>
                <small className="formError">{formErrors.aupairFrom}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Our family is experienced with Aupair.
                    <span className="required">*</span>
                  </label>
                  <select
                    name="experiencedHost"
                    value={formValues.experiencedHost}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Not Necessary">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">
                  {formErrors.experiencedHost}
                </small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Aupair with children experienced.
                    <span className="required">*</span>
                  </label>
                  <select
                    name="experiencedaupair"
                    value={formValues.experiencedaupair}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Not Necessary">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">
                  {formErrors.experiencedaupair}
                </small>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Our Family Living Model.
                    <span className="required">*</span>
                  </label>
                  <select
                    name="familyLivingModel"
                    value={formValues.familyLivingModel}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Classic Couple">Classic Couple</option>
                    <option value="A Single Mother">A Single Mother</option>
                    <option value="Single Father">Single Father</option>
                    <option value="Divers Family">Divers Family</option>
                    <option value="Other Family constellation">
                      Other Family constellation
                    </option>
                  </select>
                </div>
                <small className="formError">
                  {formErrors.familyLivingModel}
                </small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    We live with pets animals
                    <span className="required">*</span>
                  </label>

                  <select
                    name="liveWithPets"
                    value={formValues.liveWithPets}
                    onChange={handleChange}
                  >
                    <option value="">--select---</option>
                    <option value="Yes">Yes</option>
                    <option value="Never">Never</option>
                    <option value="Not Given">Not Given</option>
                  </select>
                </div>
                <small className="formError">{formErrors.liveWithPets}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Smoking Host family <span className="required">*</span>
                  </label>
                  <select
                    name="smokinghostfamily"
                    value={formValues.smokinghostfamily}
                    onChange={handleChange}
                  >
                    <option value="">---select---</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Yes Not At Home">Yes Not At Home</option>
                  </select>
                </div>
                <small className="formError">
                  {formErrors.smokinghostfamily}
                </small>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Children with special needs
                    <span className="required">*</span>
                  </label>
                  <select
                    name="specialNeeds"
                    value={formValues.specialNeeds}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="Never">Never</option>
                    <option value="Maybe">Maybe</option>
                  </select>
                </div>
                <small className="formError">{formErrors.specialNeeds}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Our Family Has Children From
                    <span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    min="1"
                    name="ChildrenFrom"
                    placeholder="Ex:1"
                    value={formValues.ChildrenFrom}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.ChildrenFrom}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    To<span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    min="1"
                    name="toChildrenNumber"
                    placeholder="Ex:5"
                    value={formValues.toChildrenNumber}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">
                  {formErrors.toChildrenNumber}
                </small>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Children with age From <span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    min="1"
                    name="agedfrom"
                    placeholder="Ex:1"
                    value={formValues.agedfrom}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.agedfrom}</small>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    To<span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    min="1"
                    name="agedto"
                    placeholder="Ex:10"
                    value={formValues.agedto}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.agedto}</small>
              </Grid>
            </div>
            <button
              className={disabled ? "disabledButton" : "submit__btn"}
              type="submit"
              disabled={disabled}
            >
              {createMode ? "Create" : "Update"}
            </button>
          </div>
        </form>
      </Container>
    </React.Fragment>
  );
};

export default FamilyPreference;
