import React from "react";
import "./AupairHome.css";

const AupairHeader = () => {
  return (
    <div className="Aupair-header">
      <div>
        <div className="Aupair-header__title">
          <h1>REGISTER AS AN AUPAIR</h1>
        </div>
      </div>
    </div>
  );
};

export default AupairHeader;
