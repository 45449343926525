import "./dashboard.css";
import React from "react";
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";

const data = [
  {
    month: "Jan",
    Aupairs: 100,
  },
  {
    month: "Feb",
    Aupairs: 1000,
  },
  {
    month: "Marc",
    Aupairs: 200,
  },
  {
    month: "Apir",
    Aupairs: 1500,
  },
  {
    month: "May",
    Aupairs: 3000,
  },
  {
    month: "June",
    Aupairs: 2000,
  },
  {
    month: "Jul",
    Aupairs: 400,
  },
  {
    month: "Aug",
    Aupairs: 1800,
  },
  {
    month: "Sept",
    Aupairs: 5000,
  },
  {
    month: "Oct",
    Aupairs: 2500,
  },
  {
    month: "Nov",
    Aupairs: 8000,
  },
];
export default function AupairsChart() {
  return (
    <div style={{ width: "100%", height: 300 }}>
      <ResponsiveContainer>
        <ComposedChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <XAxis dataKey="month" scale="band" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Area
            type="monotone"
            dataKey="Aupairs"
            fill="#8884d8"
            stroke="#8884d8"
          />
          <Bar dataKey="Aupairs" barSize={20} fill="#ffff" />
          <Line type="monotone" dataKey="Aupairs" stroke="#ff7300" />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}
