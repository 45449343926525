import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Zoom from "react-reveal/Zoom";
import { fetchBlogs } from "./../../../Redux/action/blog";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
  },
}));

const BlogCategories = ({ blog, fetchBlogs, allBlogs }) => {
  const classes = useStyles();

  const handleNavigation = (blogId) => {
    window.open(`/singleblog/${blogId}`);
  };

  useEffect(() => {
    const fetchAllBlogs = () => {
      fetchBlogs();
    };
    fetchAllBlogs();
  }, [fetchBlogs]);

  return (
    <div className={`${classes.root} blog__categories`}>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={12} xlg={12}>
          <Paper className={classes.paper} elevation={0}>
            <h4 className="relatredPost">Related</h4>
          </Paper>
        </Grid>
        {allBlogs?.message?.data?.blogs
          ?.filter(
            (item) =>
              item?.category === blog?.category && item.uuid !== blog?.uuid
          )
          .map((blog) => (
            <Grid item xs={12} sm={12} md={6} lg={6} xlg={6}>
              <Paper className={classes.paper} elevation={0}>
                <div className="blog__category">
                  <div className="blog__category__card">
                    <Zoom>
                      <div className="blog__category__content__wrapper">
                        <div className="blog__category__card-img d-block w-50">
                          <img
                            onClick={() => handleNavigation(blog.uuid)}
                            className="d-block w-100 h-100"
                            src={blog.picture}
                            alt="rhein"
                          />
                        </div>
                        <div className="blog__category__card__content">
                          <h2
                            className="blog__category__title"
                            onClick={() => handleNavigation(blog.uuid)}
                          >
                            {blog.title}
                          </h2>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: blog.blogText.substr(0, 100) + " ....",
                            }}
                          />
                        </div>
                      </div>
                    </Zoom>
                  </div>
                </div>
              </Paper>
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allBlogs: state.allBlogs.blogs,
  };
};

export default connect(mapStateToProps, { fetchBlogs })(BlogCategories);
