import React from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useStyles } from "./style";
import { connect } from "react-redux";
import { aupairOtherInfo } from "../../../Redux/action/aupairForms";
import Grid from "@material-ui/core/Grid";
import "./AupairHome.css";
import Navigation from "./../../Page/Landing/Navigation/Navigation";
import Contact from "./../../Page/Landing/Contacts/Contacts";
import AupairHeader from "./AupairHeader";
import Footer from "../../Footer/NewFooter";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const license = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes ",
    label: "Yes ",
  },
  {
    value: "No",
    label: "No",
  },
];

const IcanRideBike = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes ",
    label: "Yes ",
  },
  {
    value: "No",
    label: "No",
  },
];
const IcanSwim = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];

const firstAidTrainings = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];
const houseWorking = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];

const childrenCaring = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];

const caringAboutPets = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];

const educationLevel = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "High School",
    label: "High School",
  },
  {
    value: "University",
    label: "University",
  },
];
const SpecialRegime = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];

const AupairOtherInfo = ({ formData, setFormData, navigation }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const {
    drivingLicense,
    canRideBike,
    canSwim,
    firstAidTraining,
    houseWork,
    childrenCareness,
    careAboutPets,
    educatLevel,
    specialRegime,
    foodPreference,
  } = formData;

  const Next = (event) => {
    event.preventDefault();
    setOpen(!open);
    setTimeout(() => {
      setOpen(false);
      navigation.next();
    }, 1000);
  };

  const Previous = (event) => {
    event.preventDefault();
    setOpen(!open);
    setTimeout(() => {
      setOpen(false);
      navigation.previous();
    }, 1000);
  };

  return (
    <React.Fragment>
      <Contact />
      <Navigation />
      <AupairHeader />
      <CssBaseline />
      <div>
        <Backdrop
          style={{ zIndex: 1000, color: "#fff" }}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Container
        maxWidth="md"
        className="form_container"
        // style={{ marginTop: "150px" }}
      >
        <form className={classes.root} noValidate autoComplete="off">
          <div className="personalInformationGroup">
            <h3 className="InnerTitles">Other information (About aupair)</h3>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    I have a driving license<span className="required">*</span>
                  </label>
                  <select
                    name="drivingLicense"
                    value={drivingLicense}
                    onChange={setFormData}
                  >
                    {license.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    I can ride a Bike<span className="required">*</span>
                  </label>
                  <select
                    name="canRideBike"
                    value={canRideBike}
                    onChange={setFormData}
                  >
                    {IcanRideBike.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    I can swim<span className="required">*</span>
                  </label>
                  <select name="canSwim" value={canSwim} onChange={setFormData}>
                    {IcanSwim.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    First aid training <span className="required">*</span>
                  </label>
                  <select
                    name="firstAidTraining"
                    value={firstAidTraining}
                    onChange={setFormData}
                  >
                    {firstAidTrainings.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Willing to do the Housework{" "}
                    <span className="required">*</span>
                  </label>
                  <select
                    name="houseWork"
                    value={houseWork}
                    onChange={setFormData}
                  >
                    {houseWorking.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Care about children <span className="required">*</span>
                  </label>
                  <select
                    name="childrenCareness"
                    value={childrenCareness}
                    onChange={setFormData}
                  >
                    {childrenCaring.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Taking care of pets <span className="required">*</span>
                  </label>
                  <select
                    name="careAboutPets"
                    value={careAboutPets}
                    onChange={setFormData}
                  >
                    {caringAboutPets.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    My education Level <span className="required">*</span>
                  </label>
                  <select
                    name="educatLevel"
                    value={educatLevel}
                    onChange={setFormData}
                  >
                    {educationLevel.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Special Regime <span className="required">*</span>
                  </label>
                  <select
                    name="specialRegime"
                    value={specialRegime}
                    onChange={setFormData}
                  >
                    {SpecialRegime.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Food Preference <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    name="foodPreference"
                    placeholder="Food preference"
                    value={foodPreference}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
            </div>
          </div>
          <button className="Back" onClick={Previous}>
            <ArrowBackIcon /> &nbsp; Back
          </button>
          <button className="Continue" onClick={Next}>
            Next&nbsp;
            <ArrowForwardIcon />
          </button>
        </form>
      </Container>
      <Footer />
    </React.Fragment>
  );
};
const mapStateToProps = ({ aupairOtherInfoReducer }) => {
  const { AupOtherInfo } = aupairOtherInfoReducer;
  return { AupOtherInfo };
};
export default connect(mapStateToProps, { aupairOtherInfo })(AupairOtherInfo);
