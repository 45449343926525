import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useStyles } from "./style";
import Grid from "@material-ui/core/Grid";
import "./wishes.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  aupairWishes,
  updateAupairWishes,
} from "./../../../../Redux/action/aupairForms";
import Layout from "./../../../../Components/Layouts/Profile/Layout";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getUser } from "./../../../../Redux/action/staffMembers";

const initialValues = {
  travelExpenses: "",
  medicalInsurance: "",
  pocketMoney: "",
  languageCourse: "",
  transportationTicket: "",
  provideCar: "",
  provideBike: "",
  privateRoom: "",
  bathroom: "",
  basicOffer: "",
};

const AupairWishes = ({
  aupairWishes,
  updateAupairWishes,
  aupair,
  wish,
  updatedWishes,
  getUser,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [createMode, setCreateMode] = useState(true);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [disabled, setDisabled] = useState(false);
  const user = JSON.parse(localStorage.getItem("profile"))?.data?.user;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));
    if (Object.keys(formErrors).length === 0) {
      if (createMode) {
        setOpen(true);
        aupairWishes(formValues);
        clear();
      } else {
        setOpen(true);
        updateAupairWishes(
          formValues,
          aupair?.message?.data?.user?.aupairWishes?.uuid
        );
      }
    }
  };

  const clear = () => {
    setFormValues(initialValues);
  };
  useEffect(() => {
    const fetchUser = () => {
      getUser(user?.uuid);
    };
    if (user?.uuid || wish.success || updatedWishes?.success) {
      fetchUser();
    } else {
      return;
    }
  }, [user?.uuid, getUser, wish?.success, updatedWishes?.success]);

  useEffect(() => {
    if (wish?.success) {
      setOpen(false);
    } else if (!wish?.success && wish?.error !== null) {
      setOpen(false);
    }
  }, [wish?.success, wish?.error]);

  useEffect(() => {
    if (updatedWishes?.success) {
      setOpen(false);
    } else if (!updatedWishes?.success || updatedWishes?.error !== null) {
      setOpen(false);
    }
  }, [updatedWishes?.success, updatedWishes?.error]);

  const validate = (values) => {
    const errors = {};

    if (!values.travelExpenses) {
      errors.travelExpenses = "This field is required!";
    }
    if (!values.medicalInsurance) {
      errors.medicalInsurance = "This field is required!";
    }
    if (!values.pocketMoney) {
      errors.pocketMoney = "This field is required!";
    }
    if (!values.languageCourse) {
      errors.languageCourse = "This field is required!";
    }
    if (!values.transportationTicket) {
      errors.transportationTicket = "This field is required!";
    }
    if (!values.provideCar) {
      errors.provideCar = "This field is required!";
    }

    if (!values.provideBike) {
      errors.provideBike = "This field is required!";
    }
    if (!values.privateRoom) {
      errors.privateRoom = "This field is required!";
    }
    if (!values.bathroom) {
      errors.bathroom = "This field is required!";
    }
    if (!values.privateRoom) {
      errors.privateRoom = "This field is required!";
    }
    if (!values.basicOffer) {
      errors.basicOffer = "This field is required!";
    }

    return errors;
  };

  useEffect(() => {
    if (aupair?.message?.data?.user?.aupairWishes) {
      setFormValues({
        travelExpenses:
          aupair?.message?.data?.user?.aupairWishes?.travelExpenses,
        medicalInsurance:
          aupair?.message?.data?.user?.aupairWishes?.medicalInsurance,
        pocketMoney: aupair?.message?.data?.user?.aupairWishes?.pocketMoney,
        languageCourse:
          aupair?.message?.data?.user?.aupairWishes?.languageCourse,
        transportationTicket:
          aupair?.message?.data?.user?.aupairWishes?.transportationTicket,
        provideCar: aupair?.message?.data?.user?.aupairWishes?.provideCar,
        provideBike: aupair?.message?.data?.user?.aupairWishes?.provideBike,
        privateRoom: aupair?.message?.data?.user?.aupairWishes?.privateRoom,
        bathroom: aupair?.message?.data?.user?.aupairWishes?.bathroom,
        basicOffer: aupair?.message?.data?.user?.aupairWishes?.basicOffer,
      });
      setCreateMode(false);
    } else if (!aupair?.message?.data?.user?.aupairWishes) {
      setCreateMode(true);
      setFormValues(initialValues);
    }
  }, [aupair]);

  useEffect(() => {
    if (
      formValues.travelExpenses === "" ||
      formValues.medicalInsurance === "" ||
      formValues.pocketMoney === "" ||
      formValues.languageCourse === "" ||
      formValues.transportationTicket === "" ||
      formValues.provideCar === "" ||
      formValues.provideBike === "" ||
      formValues.privateRoom === "" ||
      formValues.bathroom === "" ||
      formValues.basicOffer === ""
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formValues]);

  return (
    <Layout>
      <CssBaseline />
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Container maxWidth="md" className="form_container">
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="personalInformationGroup">
            <h3 className="InnerTitles">MY WISHED OFFER FROM HOST FAMILY</h3>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Family is willing to pay travel expenses
                    <span className="required">*</span>
                  </label>
                  <select
                    name="travelExpenses"
                    value={formValues.travelExpenses}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Working on it">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">{formErrors.travelExpenses}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Family will pay the medical insurance
                    <span className="required">*</span>
                  </label>
                  <select
                    name="medicalInsurance"
                    value={formValues.medicalInsurance}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Working on it">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">
                  {formErrors.medicalInsurance}
                </small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Family will pay the pocket money
                    <span className="required">*</span>
                  </label>
                  <select
                    name="pocketMoney"
                    value={formValues.pocketMoney}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Working on it">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">{formErrors.pocketMoney}</small>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Family will pay the language course
                    <span className="required">*</span>
                  </label>
                  <select
                    name="languageCourse"
                    value={formValues.languageCourse}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Working on it">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">{formErrors.languageCourse}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Family will pay local transportation ticket
                    <span className="required">*</span>
                  </label>
                  <select
                    name="transportationTicket"
                    value={formValues.transportationTicket}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Working on it">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">
                  {formErrors.transportationTicket}
                </small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Family will provide the car.
                    <span className="required">*</span>
                  </label>
                  <select
                    name="provideCar"
                    value={formValues.provideCar}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Working on it">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">{formErrors.provideCar}</small>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Family will provide a bike
                    <span className="required">*</span>
                  </label>
                  <select
                    name="provideBike"
                    value={formValues.provideBike}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Working on it">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">{formErrors.provideBike}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Family will provide a private room
                    <span className="required">*</span>
                  </label>
                  <select
                    name="privateRoom"
                    value={formValues.privateRoom}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Working on it">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">{formErrors.privateRoom}</small>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Family provide a toilet and bathroom.
                    <span className="required">*</span>
                  </label>
                  <select
                    name="bathroom"
                    value={formValues.bathroom}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Working on it">Not Necessary</option>
                  </select>
                </div>
                <small className="formError">{formErrors.bathroom}</small>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    The basic offer as provided by law is enough
                    <span className="required">*</span>
                  </label>
                  <select
                    name="basicOffer"
                    value={formValues.basicOffer}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
                <small className="formError">{formErrors.basicOffer}</small>
              </Grid>
            </div>
            <div className="user_action_buttons">
              <button
                className={disabled ? "disabledButton" : "submit__btn"}
                type="submit"
                disabled={disabled}
              >
                {createMode ? "Save" : "Update"}
              </button>
              <button className="next__btn" type="button">
                <Link to="/aupair/expectations"> &larr; Prev</Link>
              </button>
            </div>
          </div>
        </form>
      </Container>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    aupair: state.getUser.user,
    wish: state.aupairWishes.wish,
    updatedWishes: state.updateAupairWishes.wishes,
  };
};

export default connect(mapStateToProps, {
  getUser,
  aupairWishes,
  updateAupairWishes,
})(AupairWishes);
