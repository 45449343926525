import React, { Fragment, useEffect, useState } from "react";
import { fetchCtegories } from "./../../../Redux/action/blog";
import { connect } from "react-redux";

const Search = ({ fetchCtegories, categories, setSearchTerm }) => {
  const [tabIndex, setTabIndex] = useState(0);

  const getTabIndex = (index, term) => {
    setTabIndex(index);
    setSearchTerm(term);
  };

  useEffect(() => {
    const getCategories = () => {
      fetchCtegories();
    };
    getCategories();
  }, [fetchCtegories]);

  return (
    <Fragment>
      <div className="filter">
        <ul className="filter_list">
          <li
            className={tabIndex === 0 ? "activeTabCategory" : ""}
            onClick={() => getTabIndex(0, "all")}
          >
            All
          </li>
          {categories?.message?.data?.categories?.map((category, index) => (
            <li
              key={category.uuid}
              className={tabIndex === index + 1 ? "activeTabCategory" : ""}
              onClick={() => getTabIndex(index + 1, category?.categoryName)}
            >
              {category?.categoryName}
            </li>
          ))}
        </ul>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    categories: state.allCategories.categories,
  };
};

export default connect(mapStateToProps, { fetchCtegories })(Search);
