import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

const TotalCard = () => {
  return (
    <Card sx={{ width: "350px", background: "#464852", color: "#fff" }}>
      <CardHeader
        avatar={
          <Avatar style={{ background: "orange" }}>
            <AccountBalanceWalletIcon style={{ color: "#000" }} />
          </Avatar>
        }
        action={
          <IconButton
            aria-label="settings"
            style={{ backgroundColor: "#fff" }}
            size="small"
          >
            <MoreVertIcon sx={{ color: "#000" }} />
          </IconButton>
        }
        title="SUMMARY"
      />
      <CardContent
        style={{ textAlign: "Center", color: "orange", fontSize: "25px" }}
      >
        <Typography variant="body2">Today: &euro; 5000</Typography>
        <Typography variant="body2">Total: &euro; 55000</Typography>
      </CardContent>
    </Card>
  );
};

export default TotalCard;
