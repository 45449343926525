import { Drawer } from "antd";
import React, { useState } from "react";
import AupairAcountInfo from "../Forms/HostForms/AccountInfo";
import "./Drawer.css";

const AcountDrawer = ({ user, setUser }) => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <button className="edit__btn" onClick={showDrawer}>
        Edit My Acount
      </button>
      <Drawer
        title="EDIT MY ACCOUNT"
        placement="right"
        onClose={onClose}
        visible={visible}
        width="80%"
        bodyStyle={{ backgroundColor: "#696969" }}
      >
        <AupairAcountInfo user={user} setUser={setUser} />
      </Drawer>
    </>
  );
};

export default AcountDrawer;
