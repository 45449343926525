import React,{useEffect} from "react";
import FaqPage from "../../Components/Page/FAQ/Faq";
import FrontFaq from "../../Components/Page/FAQ/FrontFaq/FaqFront";
import Footer from "../../Components/Footer/NewFooter";
import Contact from "../../Components/ResponsiveNavBar/Contacts/Contacts";
import Navigation from "../../Components/myNavbar/navbar";

const Faq = () => {

  useEffect(()=>{
    if(typeof window !== "undefined"){
      window.scrollTo(0,0)
    }
  },[])

  return (
    <React.Fragment>
      <Contact />
      <Navigation />
      <FrontFaq />
      <FaqPage />
      <Footer />
    </React.Fragment>
  );
};

export default Faq;
