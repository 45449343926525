import React, { Fragment, useState, useEffect } from "react";
import "./Blog.css";
import { connect } from "react-redux";
import { subscribe } from "./../../../Redux/action/subscribers";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";

const Subscribe = ({ subscribe, subscriber }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    subscriber: "",
  });

  const handleSubscribe = (event) => {
    event.preventDefault();
    setOpen(true);
    subscribe(formData);
    clear();
  };

  const clear = () => {
    setFormData({
      subscriber: "",
    });
  };

  useEffect(() => {
    if (subscriber.success) {
      setOpen(false);
      setMessage(subscriber?.message?.data?.message);
    } else if (!subscriber?.success && subscriber?.error !== null) {
      setOpen(false);
      setMessage("Some thing went wrong");
    }
  }, [
    subscriber.success,
    subscriber.error,
    subscriber?.message?.data?.message,
  ]);

  useEffect(() => {
    const notify = () => {
      if (subscriber?.error && message) {
        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (subscriber?.success && message) {
        toast.success(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    if (subscriber?.success && message) {
      notify();
    } else if (!subscriber?.success && subscriber?.error !== null && message) {
      notify();
    } else {
      return;
    }
  }, [subscriber?.success, subscriber?.error, message]);

  return (
    <Fragment>
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <div className="subscribe__container">
        <form onSubmit={handleSubscribe} className="subscribe__container__form">
          <input
            className="form__input"
            type="email"
            name="email"
            required
            placeholder="Don't missout news letter:Your email here please !!"
            value={formData.subscriber}
            onChange={(event) =>
              setFormData({
                ...formData,
                subscriber: event.target.value,
              })
            }
          />
          <button className="subscribe-button">
            <strong>Subscribe</strong>
          </button>
        </form>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    subscriber: state.subscribe.subscriber,
  };
};

export default connect(mapStateToProps, { subscribe })(Subscribe);
