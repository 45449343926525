import {
  CREATE_CATEGORIES_START,
  CREATE_CATEGORIES_SUCCESS,
  CREATE_CATEGORIES_FAILURE,
  FETCH_CATEGORIES_START,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
  GET_CATEGORY_START,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAILURE,
  UPDATE_CATEGORY_START,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILURE,
  DELETE_CATEGORY_START,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAILURE,
  CREATE_BLOG_START,
  CREATE_BLOG_SUCCESS,
  CREATE_BLOG_FAILURE,
  FETCH_BLOGS_START,
  FETCH_BLOGS_SUCCESS,
  FETCH_BLOGS_FAILURE,
  FETCH_AMIN_BLOGS_START,
  FETCH_AMIN_BLOGS_SUCCESS,
  FETCH_AMIN_BLOGS_FAILURE,
  GET_BLOG_START,
  GET_BLOG_SUCCESS,
  GET_BLOG_FAILURE,
  UPDATE_BLOG_START,
  UPDATE_BLOG_SUCCESS,
  UPDATE_BLOG_FAILURE,
  DELETE_BLOG_START,
  DELETE_BLOG_SUCCESS,
  DELETE_BLOG_FAILURE,
  PUBLISH_BLOG_START,
  PUBLISH_BLOG_SUCCESS,
  PUBLISH_BLOG_FAILURE,
} from "../action/actionTypes/types";

import * as api from "../../api/index";

export const createCtegory = (formData) => async (dispatch) => {
  dispatch({
    type: CREATE_CATEGORIES_START,
  });
  try {
    const { data } = await api.createCategory(formData);
    return dispatch({
      type: CREATE_CATEGORIES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: CREATE_CATEGORIES_FAILURE,
      payload: errorMessage,
    });
  }
};

export const fetchCtegories = () => async (dispatch) => {
  dispatch({
    type: FETCH_CATEGORIES_START,
  });
  try {
    const { data } = await api.fetchAllCategory();
    return dispatch({
      type: FETCH_CATEGORIES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: FETCH_CATEGORIES_FAILURE,
      payload: errorMessage,
    });
  }
};

export const getCtegory = () => async (dispatch) => {
  dispatch({
    type: GET_CATEGORY_START,
  });
  try {
    const { data } = await api.getCategory();
    return dispatch({
      type: GET_CATEGORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: GET_CATEGORY_FAILURE,
      payload: errorMessage,
    });
  }
};

export const updateCategory = (formData, catId) => async (dispatch) => {
  dispatch({
    type: UPDATE_CATEGORY_START,
  });
  try {
    const { data } = await api.updateCategory(formData, catId);
    return dispatch({
      type: UPDATE_CATEGORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: UPDATE_CATEGORY_FAILURE,
      payload: errorMessage,
    });
  }
};

export const deleteCategory = (catId) => async (dispatch) => {
  dispatch({
    type: DELETE_CATEGORY_START,
  });
  try {
    const { data } = await api.deleteCategory(catId);
    return dispatch({
      type: DELETE_CATEGORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: DELETE_CATEGORY_FAILURE,
      payload: errorMessage,
    });
  }
};

export const createBlog = (formData) => async (dispatch) => {
  console.log(formData);

  dispatch({
    type: CREATE_BLOG_START,
  });
  try {
    const { data } = await api.createBlog(formData);
    return dispatch({
      type: CREATE_BLOG_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: CREATE_BLOG_FAILURE,
      payload: errorMessage,
    });
  }
};

export const fetchBlogs = () => async (dispatch) => {
  dispatch({
    type: FETCH_BLOGS_START,
  });
  try {
    const { data } = await api.fetchAllBlog();
    return dispatch({
      type: FETCH_BLOGS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: FETCH_BLOGS_FAILURE,
      payload: errorMessage,
    });
  }
};

export const fetchBlogsForAdmin = () => async (dispatch) => {
  dispatch({
    type: FETCH_AMIN_BLOGS_START,
  });
  try {
    const { data } = await api.fetchAllBlogForAdmin();
    return dispatch({
      type: FETCH_AMIN_BLOGS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: FETCH_AMIN_BLOGS_FAILURE,
      payload: errorMessage,
    });
  }
};

export const getBlog = (blogId) => async (dispatch) => {
  dispatch({
    type: GET_BLOG_START,
  });
  try {
    const { data } = await api.getBlog(blogId);
    return dispatch({
      type: GET_BLOG_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: GET_BLOG_FAILURE,
      payload: errorMessage,
    });
  }
};

export const updateBlog = (formData, blogId) => async (dispatch) => {
  dispatch({
    type: UPDATE_BLOG_START,
  });

  try {
    const { data } = await api.updateBlog(formData, blogId);

    return dispatch({
      type: UPDATE_BLOG_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: UPDATE_BLOG_FAILURE,
      payload: errorMessage,
    });
  }
};

export const publishBlog = (blogId) => async (dispatch) => {
  dispatch({
    type: PUBLISH_BLOG_START,
  });

  try {
    const { data } = await api.publishBlog(blogId);

    return dispatch({
      type: PUBLISH_BLOG_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: PUBLISH_BLOG_FAILURE,
      payload: errorMessage,
    });
  }
};

export const deleteBlog = (blogId) => async (dispatch) => {
  dispatch({
    type: DELETE_BLOG_START,
  });
  try {
    const { data } = await api.deleteBlog(blogId);
    return dispatch({
      type: DELETE_BLOG_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: DELETE_BLOG_FAILURE,
      payload: errorMessage,
    });
  }
};
