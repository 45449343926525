import React, { useState } from "react";
import { Modal } from "antd";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import AddTestmony from "./AddTestmony";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  form: {
    background: "#4f4f4f",
  },
  paper: {
    display: "block",
    width: "400px",
    padding: "5px",
    borderRadius: "0px",
    borderTopLeftRadius: "3px",
    borderBottomLeftRadius: "3px",
    background: "#9e9e9e",
    color: "#000",
  },
  button: {
    width: "50%",
    padding: "5px",
    borderRadius: "0px",
    borderTopRightRadius: "3px",
    borderBottomRightRadius: "3px",
    background: "#464852",
    color: "#fff",
  },
}));

const CreateModal = () => {
  const classes = useStyles();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Paper className={classes.button} component="button" onClick={showModal}>
        Add Testmony
      </Paper>
      <Modal
        title="ADD TESTMONY"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        bodyStyle={{
          backgroundColor: "#464852",
        }}
        width="80%"
      >
        <AddTestmony />
      </Modal>
    </>
  );
};

export default CreateModal;
