import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import DashboardLayout from "./../../Layouts/Dashboard";
import CardList from "./PaymentList";
import TotalCard from "./Total";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const Payments = () => {
  const classes = useStyles();
  return (
    <DashboardLayout>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              style={{ color: "orange", fontWeight: "bold", fontSize: "20px" }}
              variant="body2"
            >
              PAYMENTS
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <div
              style={{
                height: "100vh",
                overflowY: "scroll",
              }}
            >
              <CardList />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <div style={{ position: "fixed" }}>
              <TotalCard />
            </div>
          </Grid>
        </Grid>
      </div>
    </DashboardLayout>
  );
};

export default Payments;
