import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { styles } from "./styles";
import Divider from "@material-ui/core/Divider";
import MainListItems from "./SideBar";
import List from "@material-ui/core/List";
import Avatar from "@material-ui/core/Avatar";
import { useNavigate } from "react-router-dom";
import Notification from "./../AdminPanel/Notifications/Notifications";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

const useStyles = makeStyles(styles);

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const naviagte = useNavigate();
  const [isOpened, setIsOpened] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("profile"))?.data?.user
  );

  React.useEffect(() => {
    if (
      user === null ||
      user === undefined ||
      user?.role === "aupair" ||
      user?.role === "hostfamily"
    ) {
      naviagte("/");
    }
  }, [user, naviagte]);

  const handleLogout = () => {
    setUser(null);
    localStorage.setItem("profile", null);
    naviagte("/");
  };

  const handleToggle = () => {
    setOpenSettings((prev) => !prev);
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={() => setIsOpened(!isOpened)}
            className={classes.icon}
          >
            {isOpened ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            AUPAIR SERVICES
          </Typography>
          <Notification />
          <Typography variant="h6" className={classes.userName}>
            {user?.firstName.split(" ")[0]}
          </Typography>
          {user.profilePicture ? (
            <>
              <div className={classes.settings} onClick={handleToggle}>
                <Avatar
                  className={classes.profilePic}
                  alt="profile"
                  src={user?.profilePicture}
                  sx={{ width: 40, height: 40, bgcolor: "#012241" }}
                />
                <IconButton size="small">
                  {!openSettings ? (
                    <ArrowDropDownIcon style={{ color: "#FFA500" }} />
                  ) : (
                    <ArrowDropUpIcon style={{ color: "#FFA500" }} />
                  )}
                </IconButton>
                {openSettings && (
                  <div className={classes.dropDown}>
                    <button
                      onClick={handleLogout}
                      className={classes.logoutBtn}
                    >
                      Logout
                    </button>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className={classes.settings} onClick={handleToggle}>
                <Avatar
                  className={classes.profilePic}
                  alt="profile"
                  sx={{
                    width: 50,
                    height: 50,
                    bgcolor: "#012241",
                    fontSize: "20px",
                  }}
                >
                  {user?.firstName.charAt(0).toUpperCase()}
                </Avatar>
                <IconButton size="small">
                  {!openSettings ? (
                    <ArrowDropDownIcon style={{ color: "#FFA500" }} />
                  ) : (
                    <ArrowDropUpIcon style={{ color: "#FFA500" }} />
                  )}
                </IconButton>
                {openSettings && (
                  <div className={classes.dropDown}>
                    <button
                      onClick={handleLogout}
                      className={classes.logoutBtn}
                    >
                      Logout
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
        </Toolbar>
      </AppBar>
      <div className={classes.container}>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawer, {
              [classes.closed]: !isOpened,
              [classes.opened]: isOpened,
            }),
          }}
        >
          <Divider />
          <List>
            <MainListItems />
          </List>
        </Drawer>
        <main className={classes.main}>{children}</main>
      </div>
    </div>
  );
};

export default DashboardLayout;
