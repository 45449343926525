import React from "react";
import "./styles.css";
import PlanIcon from "./../../../../../../../../assets/contract.png";
import BrainTreePaymentModal from "./PaymentModal";

const packageData = [
  {
    id: "basic_plan",
    name: "Basic",
    price: "9.99",
    listItems: [
      {
        id: "basic_item1",
        label: "1 Possible Match",
      },
      {
        id: "basic_item2",
        label: "Only One Month",
      },
      {
        id: "basic_item3",
        label: "Chart Support",
      },
    ],
  },

  {
    id: "standard_plan",
    name: "Standard",
    price: "99.99",
    listItems: [
      {
        id: "standard_plan_item1",
        label: "3 Possible Matches",
      },
      {
        id: "standard_plan_item2",
        label: "Within 3 Months",
      },
      {
        id: "standard_plan_item3",
        label: "Chart Support",
      },
      {
        id: "standard_plan_item4",
        label: "Email Support",
      },
      {
        id: "standard_plan_item5",
        label: "Live Call",
      },
    ],
  },
  {
    id: "gold_plan",
    name: "Gold",
    price: "199.99",
    listItems: [
      {
        id: "gold_plan_item1",
        label: "10 Possible Matches",
      },
      {
        id: "gold_plan_item2",
        label: "Within 6 Months",
      },
      {
        id: "gold_plan_item3",
        label: "Live Chart Support 24h",
      },
      {
        id: "gold_plan_item4",
        label: "Email Support",
      },
      {
        id: "gold_plan_item5",
        label: "Live Call",
      },
    ],
  },
  {
    id: "premium_plan",
    name: "Premium",
    price: "299.99",
    listItems: [
      {
        id: "premium_plan_item1",
        label: "Unlimited Searching",
      },
      {
        id: "premium_plan_item2",
        label: "Within 6 Months",
      },
      {
        id: "premium_plan_item3",
        label: "Live Chart Support 24h",
      },
      {
        id: "premium_plan_item4",
        label: "Email Support",
      },
      {
        id: "premium_plan_item5",
        label: "Live Call",
      },
      {
        id: "premium_plan_item6",
        label: "Postage Correspondance",
      },
    ],
  },
];

const MatchingCards = () => {
  return (
    <section>
      <div className="container-fluid">
        <div className="container">
          <div className=" row payment_card_container">
            {packageData.map((item) => (
              <div className="col-sm-3" key={item.id}>
                <div className="card text-center">
                  <div className="title">
                    <img
                      className="fa"
                      src={PlanIcon}
                      alt="subscription"
                      style={{
                        width: "50px",
                        height: "50px",
                        objectFit: "contain",
                      }}
                    />
                    <h2>{item.name}</h2>
                  </div>
                  <div className="price">
                    <h4>
                      <sup>&euro;</sup>
                      {item.price}
                    </h4>
                  </div>
                  <div className="option">
                    <ul>
                      {item.listItems.map((listItem) => (
                        <li key={listItem.id}>
                          <i className="fa fa-check" aria-hidden="true"></i>
                          {listItem.label}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <BrainTreePaymentModal plan={item} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MatchingCards;
