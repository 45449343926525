import React, { useState } from "react";
import { Modal } from "antd";
import EditCategory from "./EditCategory";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

const EditUserModal = ({ category }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formData, setFormData] = useState({
    categoryName: "",
  });

  const showModal = () => {
    setIsModalVisible(true);

    if (category) {
      setFormData({
        categoryName: category.categoryName,
      });
    } else {
      setFormData({
        categoryName: "",
      });
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <IconButton
        color="default"
        component="span"
        size="small"
        onClick={showModal}
        style={{
          backgroundColor: "#3f3f3f",
        }}
      >
        <EditIcon style={{ color: "#FFA500", fontSize: "20px" }} />
      </IconButton>
      <Modal
        title="EDIT CATEGORY"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        bodyStyle={{
          backgroundColor: "#464852",
        }}
      >
        <EditCategory
          category={category}
          formData={formData}
          setFormData={setFormData}
        />
      </Modal>
    </>
  );
};

export default EditUserModal;
