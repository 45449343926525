import React, { Fragment, useEffect, useState } from "react";
import "./matchStyles.css";
import MatchingList from "./HostList";
import Search from "./Search";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { getAllMatchingConnections } from "./../../../../../../../../Redux/action/matches";
import { connect, useSelector } from "react-redux";
import { Empty } from "antd";
import { cancelSubscription } from "./../../../../../../../../utils/brainTreeAPIs";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";

const useMstyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    background: "#9e9e9e",
  },
  previewBtn: {
    border: "none",
    outline: "none",
    width: "200px",
    marginTop: "10px",
    background: "#fff",
    color: "#000",
    fontWeight: "bold",
    borderRadius: "1px",
  },
}));

const MatchList = ({
  transaction,
  getAllMatchingConnections,
  connections,
  setHasCanceled,
  hasCanceled,
}) => {
  const styles = useMstyles();
  const [connectedMatch, setConnectedMmatch] = useState(null);
  const [open, setOpen] = useState(false);

  const { user, transaction: userTransactions } = transaction;
  const [values, setValues] = useState({
    clientToken: null,
    success: "",
    error: "",
    instance: "",
    payment: null,
  });

  const matching = useSelector((state) => state.matching.matches);

  const handleCancelSubScription = (plan) => {
    let cancelsubscriptionData = {
      planId: plan,
    };
    setOpen(true);
    cancelSubscription(cancelsubscriptionData)
      .then((response) => {
        console.log("RESPONSE", response);

        if (response.error) {
          setValues({ ...values, error: response.err });
          setOpen(false);
        } else {
          setValues({ ...values, success: response.success });
          toast.success(
            "You have canceled your subscription,you need to subscribe again",
            {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );

          setTimeout(() => {
            window.location.reload(false);
          }, 2000);

          setOpen(false);
          setHasCanceled(true);
        }
      })
      .catch((err) => {
        setValues({ ...values, error: err, success: "" });
        setOpen(false);
      });
  };

  useEffect(() => {
    const fetchAllConnections = () => {
      getAllMatchingConnections();
    };
    fetchAllConnections();
  }, [getAllMatchingConnections]);

  useEffect(() => {
    const connection = connections?.message?.data?.matches?.find(
      (connection) => connection.inviterId === user?.uuid
    );
    if (connection) {
      setConnectedMmatch(connection);
    } else {
      setConnectedMmatch(null);
    }
  }, [connections?.message?.data?.matches, user?.uuid]);

  return (
    <Fragment>
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <div className={styles.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={3} lg={3} xlg={3}>
            <div className="match_container_left_side">
              <div className="user_profile_pictures">
                <img src={user.profilePicture} alt="user profile" />
              </div>
              <div className="mySubscription">
                <div>
                  {user.firstName} &nbsp; {user.lastName}
                </div>
                My subscription
                <div>
                  {JSON.parse(userTransactions)
                    .subscription.planId.split("_")[0]
                    .toUpperCase()}
                </div>
                <div>
                  Price:&nbsp; &euro;&nbsp;
                  {JSON.parse(userTransactions).subscription.price}
                </div>
                <div>
                  Status:&nbsp;
                  {JSON.parse(userTransactions).subscription.status}
                </div>
                <div>
                  Started:&nbsp;
                  {
                    JSON.parse(userTransactions).subscription
                      .billingPeriodStartDate
                  }
                </div>
                <div>
                  Billing Day:&nbsp;
                  {JSON.parse(userTransactions).subscription.billingDayOfMonth}
                </div>
                <div>
                  Renew: &nbsp;
                  {
                    JSON.parse(userTransactions).subscription
                      .billingPeriodEndDate
                  }
                </div>
                <div>
                  <button
                    className={styles.previewBtn}
                    onClick={() =>
                      handleCancelSubScription(
                        JSON.parse(userTransactions).subscription.planId
                      )
                    }
                  >
                    Cancel Subscription
                  </button>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xlg={6}>
            <div className="match_container_right_side">
              {matching?.message?.data?.matchingUsers[0] !==undefined? (
                <Fragment>
                  <Search />
                  <MatchingList userTransactions={userTransactions} />
                </Fragment>
              ) : (
                <Empty
                  description="No Matching Available"
                  style={{ color: "#fff" }}
                />
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xlg={3}>
            <div className="match_container_left_side">
              {connectedMatch !== null ? (
                <Fragment>
                  <div className="user_profile_pictures">
                    <img
                      src={connectedMatch?.inviteePicture}
                      alt="user profile"
                    />
                  </div>
                  <div className="mySubscription">
                    <strong>My Matching</strong>
                    <div>
                      {connectedMatch?.inviteeFirstName} &nbsp;
                      {connectedMatch?.inviteeLastName}
                    </div>
                    <div>
                      <button className={styles.previewBtn}>Preiew</button>
                    </div>
                  </div>
                </Fragment>
              ) : (
                <Empty
                  description="No connected Matching"
                  style={{ color: "#fff" }}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    connections: state.allConnections.connections,
  };
};

export default connect(mapStateToProps, {
  getAllMatchingConnections,
})(MatchList);
