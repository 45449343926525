import React, { Fragment } from "react";

const Expectation = ({ Data }) => {
  return (
    <Fragment>
      <div className="personal__data">
        <div className="personal__data__header">
          <h1>Expectations</h1>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>The House work is done on the pre-established plan:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.houseworK}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>There are clearly defined time table:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.timetable}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Life in the family is flexible and Spontaneous:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.flexibleFamily}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong> Wish Aupair to integrate the family life:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.integrateFamily}</label>
          </div>
        </div>

        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Wish Aupair to work on his/her initiative:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.initiative}</label>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Expectation;
