import {
  SEARCH_START,
  SEARCH_SUCCESS,
  SEARCH_FAILURE,
} from "./actionTypes/types";

export const Search =
  ({ searchkeyword, data }) =>
  async (dispatch) => {
    dispatch({ type: SEARCH_START });

    let searchResults;

    if (searchkeyword !== "") {
      searchResults = data.filter((item) => {
        return Object.values(item)
          .join(" ")
          .toLowerCase()
          .includes(searchkeyword.toLowerCase());
      });

      return dispatch({
        type: SEARCH_SUCCESS,
        payload: searchResults,
      });
    } else {
      return dispatch({
        type: SEARCH_FAILURE,
        payload: data,
      });
    }
  };
