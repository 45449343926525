import React from "react";
import Footer from "../../Footer/NewFooter";
import RegisterAupair from "./Aupair";

const AupairData = () => {
  return (
    <React.Fragment>
      <RegisterAupair />
      <Footer />
    </React.Fragment>
  );
};

export default AupairData;
