import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "./card";
import { connect } from "react-redux";
import { getPayments } from "./../../../Redux/action/payment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const Payments = ({ getPayments, payments }) => {
  const classes = useStyles();

  useEffect(() => {
    const fetchAllPayments = () => {
      getPayments();
    };
    fetchAllPayments();
  }, [getPayments]);

  console.log("transaction", payments?.message?.payments);

  if (payments?.message?.payments[0]?.transaction) {
    console.log(
      "transaction json",
      JSON.parse(payments?.message?.payments[0]?.transaction)
    );
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {payments?.message?.payments?.map((payment) => {
          return (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={payment.uuid}>
              <Card payment={payment} />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    payments: state.payments.payments,
  };
};

export default connect(mapStateToProps, { getPayments })(Payments);
