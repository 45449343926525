import React,{useEffect} from "react";
import Header from "./Header/Header";
import About from "./About/About";
import Team from "./Team/Team";
import WhyAkagera from "./WhyAkagera/WhyAkagera";
import HowToProceed from "./HowToproceed/HowToProceed";
import Footer from "./../../Footer/NewFooter";
// import Testmonial from "./Testmonials/Testmonial";
import ServedCustomers from "./ServedCustomers/Served";
// import TrandingBlog from "./../Blog/Tranding";
import Contact from "./../../ResponsiveNavBar/Contacts/Contacts";
import Navigation from "./../../myNavbar/navbar";

const Landing = () => {

  useEffect(()=>{
    if(typeof window !== "undefined"){
      window.scrollTo(0,0)
    }
  },[])

  return (
    <React.Fragment>
      <Contact />
      <Navigation />
      <main
        style={{
          display: "flex",
          flexDirection: "column",
          background: " #f3f3f3",
        }}
      >
        <Header />
        <About />
        <Team />
        <WhyAkagera />
        <HowToProceed />
        <ServedCustomers />
        {/* <Testmonial />*}
        {/* <TrandingBlog />*/}
        <Footer />
      </main>
    </React.Fragment>
  );
};
export default Landing;
