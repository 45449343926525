import React, { useState } from "react";
import { Modal } from "antd";
import UpdateUser from "./EditUser";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

const EditUserModal = ({ user }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    phone: "",
    email: "",
    role: "",
    password: "",
    confirmPassword: "",
  });

  const showModal = () => {
    setIsModalVisible(true);

    if (user) {
      setFormData({
        fullName: user?.fullName,
        phone: user?.phone,
        email: user?.email,
        role: user?.role,
        password: "",
        confirmPassword: "",
      });
    } else {
      setFormData({
        fullName: "",
        phone: "",
        email: "",
        role: "",
        password: "",
        confirmPassword: "",
      });
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <IconButton
        color="default"
        component="span"
        size="small"
        onClick={showModal}
        style={{ backgroundColor: "#3f3f3f" }}
      >
        <EditIcon style={{ color: "#FFA500", fontSize: "20px" }} />
      </IconButton>
      <Modal
        title="EDIT USER"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        bodyStyle={{
          backgroundColor: "#464852",
        }}
      >
        <UpdateUser user={user} formData={formData} setFormData={setFormData} />
      </Modal>
    </>
  );
};

export default EditUserModal;
