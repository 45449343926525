import React, { useState, Fragment, useRef, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Editor } from "@tinymce/tinymce-react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { updateBlog, fetchCtegories } from "./../../../Redux/action/blog";
import Grid from "@material-ui/core/Grid";
import UploadBlogPicture from "./uploadBlog";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  form: {
    padding: "20px",
    background: "#464852",
  },
  paper: {
    display: "block",
    width: "100%",
    padding: "5px",
    marginBottom: "5px",
    borderRadius: "3px",
    background: "#9e9e9e",
    color: "#000",
  },
  button: {
    display: "block",
    width: "150px",
    padding: "5px",
    borderRadius: "0px",
    borderTopRightRadius: "3px",
    borderBottomRightRadius: "3px",
    background: "#3f3f3f",
    color: "#fff",
    marginTop: "10px",
  },
}));

const EditBlog = ({
  formValues,
  setFormValues,
  blogId,
  updateBlog,
  updatedBlog,
  fetchCtegories,
  categories,
}) => {
  const classes = useStyles();
  const editorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true);

    if (formValues) {
      updateBlog(
        {
          category: formValues.category,
          title: formValues.title,
          blogText: editorRef.current.getContent(),
          popular: formValues.popular,
          trending: formValues.trending,
          picture: formValues.picture,
          author:
            "https://firebasestorage.googleapis.com/v0/b/akagera-rhein-aupair.appspot.com/o/images%2FAupairImages%2Ficon.png?alt=media&token=34849ef0-6edd-400f-bfc3-d132a4de10ae",
        },
        blogId
      );
    } else {
      return;
    }
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  useEffect(() => {
    const loadAllCategories = () => {
      fetchCtegories();
    };
    loadAllCategories();
  }, [fetchCtegories]);

  useEffect(() => {
    if (updatedBlog?.success) {
      setOpen(false);
    } else if (!updatedBlog?.success && updatedBlog?.error !== null) {
      setOpen(false);
    }
  }, [updatedBlog?.success, updatedBlog?.error]);

  console.log("formValues", formValues);

  return (
    <Fragment>
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Paper
        component="form"
        className={classes.form}
        elevation={0}
        onSubmit={handleSubmit}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper} component="div">
              <UploadBlogPicture
                formValues={formValues}
                setFormValues={setFormValues}
              />
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Paper
              className={classes.paper}
              component="select"
              name="category"
              value={formValues.category}
              onChange={handleChange}
            >
              <option value={null}>--CHOOSE CATEGORY--</option>
              {categories?.message?.data?.categories?.map((category) => (
                <option value={category.categoryName} key={category.uuid}>
                  {category.categoryName}
                </option>
              ))}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xlg={6}>
            <Paper
              className={classes.paper}
              component="input"
              type="text"
              name="title"
              placeholder="Title"
              value={formValues.title}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xlg={6}>
            <Paper
              className={classes.paper}
              component="select"
              name="trending"
              value={formValues.trending}
              onChange={handleChange}
            >
              <option value={null}>--MARK AS TRENDING--</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Editor
              apiKey="efkjfq1mftenm8s7fwqa9nd4vnw94i0zr8246x1v5qcd143v"
              onInit={(evt, editor) =>
                editorRef && (editorRef.current = editor)
              }
              initialValue={formValues.blogText}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount  ",
                  "image",
                ],
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic backcolor | alignleft aligncenter " +
                  " image " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                a11y_advanced_options: false,
              }}
            />
          </Grid>
        </Grid>

        <Paper className={classes.button} component="button">
          Update Blog
        </Paper>
      </Paper>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    categories: state.allCategories.categories,
    updatedBlog: state.updateBlog.blog,
  };
};

export default connect(mapStateToProps, { updateBlog, fetchCtegories })(
  EditBlog
);
