import React from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "./style";
import { hostOtherInfo as HostFamilyOtherInfo } from "../../../Redux/action/hostFroms";
import { connect } from "react-redux";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import "./HostFamilyHome.css";
import Navigation from "./../../Page/Landing/Navigation/Navigation";
import Contact from "./../../Page/Landing/Contacts/Contacts";
import HostHeader from "./HostFamilyHome";
import Footer from "../../Footer/NewFooter";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const driving = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes ",
    label: "Yes ",
  },
  {
    value: "No",
    label: "No",
  },
];

const Bike = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes ",
    label: "Yes ",
  },
  {
    value: "No",
    label: "No",
  },
];
const swim = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes ",
    label: "Yes ",
  },
  {
    value: "No",
    label: "No",
  },
];

const firstAid = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes ",
    label: "Yes ",
  },
  {
    value: "No",
    label: "No",
  },
];
const Housework = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes ",
    label: "Yes ",
  },
  {
    value: "No",
    label: "No",
  },
];
const specialNeeds = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes ",
    label: "Yes ",
  },
  {
    value: "No",
    label: "No",
  },
];
const pets = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes ",
    label: "Yes ",
  },
  {
    value: "No",
    label: "No",
  },
];
const Education = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "High School ",
    label: "High School ",
  },
  {
    value: "University",
    label: "University",
  },
];
const regime = [
  {
    value: "--select--",
    label: "--select--",
  },
  {
    value: "Yes ",
    label: "Yes ",
  },
  {
    value: "No",
    label: "No",
  },
];

const HostOtherInfo = ({ formData, setFormData, navigation }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const {
    drivingLicense,
    canRideBike,
    canSwim,
    firstAidTraining,
    houseWork,
    childrenCareness,
    careAboutPets,
    educatLevel,
    specialRegime,
    foodPreference,
  } = formData;

  const Next = (event) => {
    event.preventDefault();
    setOpen(!open);
    setTimeout(() => {
      setOpen(false);
      navigation.next();
    }, 1000);
  };

  const Previous = (event) => {
    event.preventDefault();
    setOpen(!open);
    setTimeout(() => {
      setOpen(false);
      navigation.previous();
    }, 1000);
  };

  return (
    <React.Fragment>
      <Contact />
      <Navigation />
      <HostHeader />
      <CssBaseline />
      <div>
        <Backdrop
          style={{ zIndex: 1000, color: "#fff" }}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Container maxWidth="md" className="form_container">
        <form className={classes.root} noValidate autoComplete="off">
          <div className="personalInformationGroup">
            <h1 className="InnerTitles">
              Other information (About Host Family)
            </h1>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    I have a driving license <span className="required">*</span>
                  </label>
                  <select
                    name="drivingLicense"
                    value={drivingLicense}
                    onChange={setFormData}
                  >
                    {driving.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>

              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    I can ride a Bike <span className="required">*</span>
                  </label>
                  <select
                    name="canRideBike"
                    value={canRideBike}
                    onChange={setFormData}
                  >
                    {Bike.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    I can swim<span className="required">*</span>
                  </label>
                  <select name="canSwim" value={canSwim} onChange={setFormData}>
                    {swim.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            </div>

            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    First aid training<span className="required">*</span>
                  </label>
                  <select
                    name="firstAidTraining"
                    value={firstAidTraining}
                    onChange={setFormData}
                  >
                    {firstAid.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Willing to do Housework<span className="required">*</span>
                  </label>
                  <select
                    name="houseWork"
                    value={houseWork}
                    onChange={setFormData}
                  >
                    {Housework.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>

              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Care about Children<span className="required">*</span>
                  </label>
                  <select
                    name="childrenCareness"
                    value={childrenCareness}
                    onChange={setFormData}
                  >
                    {specialNeeds.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            </div>

            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Taking care of pets<span className="required">*</span>
                  </label>
                  <select
                    name="careAboutPets"
                    value={careAboutPets}
                    onChange={setFormData}
                  >
                    {pets.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    My education Level<span className="required">*</span>
                  </label>
                  <select
                    name="educatLevel"
                    value={educatLevel}
                    onChange={setFormData}
                  >
                    {Education.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    Special regime <span className="required">*</span>
                  </label>
                  <select
                    name="specialRegime"
                    value={specialRegime}
                    onChange={setFormData}
                  >
                    {regime.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            </div>
            <div className="FieldContainer">
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    food preference <span className="required">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    name="foodPreference"
                    placeholder="food preference"
                    value={foodPreference}
                    onChange={setFormData}
                  />
                </div>
              </Grid>
            </div>
          </div>
          <button className="Back" onClick={Previous}>
            <ArrowBackIcon /> &nbsp; Back
          </button>
          <button className="Continue " onClick={Next}>
            Next &nbsp;
            <ArrowForwardIcon />
          </button>
        </form>
      </Container>
      <Footer />
    </React.Fragment>
  );
};
const mapStateToProps = ({ hostOtherInfoReducer }) => {
  const { otherInfo } = hostOtherInfoReducer;
  return { otherInfo };
};
export default connect(mapStateToProps, { HostFamilyOtherInfo })(HostOtherInfo);
