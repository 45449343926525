import React, { Fragment } from "react";
import Layout from "./../../Layouts/Dashboard";
import AllCategoryList from "./Category";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CreateCategory from "./CreateCategory";
import { Divider } from "antd";
import SubjectIcon from "@material-ui/icons/Subject";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    fontSize: "20px",
    textAlign: "center",
    fontWeight: 700,
    color: "#9e9e9e",
    textTransform: "uppercase",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const AllHost = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <Layout>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} xlg={12}>
              <h1 className={classes.title}>
                MANAGE BLOG CATEGORIES &nbsp;
                <SubjectIcon style={{ color: "#FFA500" }} />
              </h1>
            </Grid>
            <Divider />
            <Grid item xs={12} sm={12} md={12} lg={12} xlg={12}>
              <CreateCategory />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xlg={12}>
              <AllCategoryList />
            </Grid>
          </Grid>
        </div>
      </Layout>
    </Fragment>
  );
};
export default AllHost;
