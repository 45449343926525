import React, { Fragment } from "react";
import "./style.css";

const ContactInfo = ({ Data }) => {
  return (
    <Fragment>
      <div className="personal__data">
        <div className="personal__data__header">
          <h1>Contact Information</h1>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Street:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.street}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Post Code:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.postCode}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Country:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.country}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>House Number:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.houseNo}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>City:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.city}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Email:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.email}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Telephone:</strong>
          </div>
          <div className="personal__list__item__value">
            <label>{Data?.phone}</label>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Instagram:</strong>
          </div>
          <div className="personal__list__item__value">
            <a href="https://www.instagram.com">
              <label>{Data?.instagram}</label>
            </a>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Twitter:</strong>
          </div>
          <div className="personal__list__item__value">
            <a href="hhtps://twitter.com/">
              <label>{Data?.twitter}</label>
            </a>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Facebook:</strong>
          </div>
          <div className="personal__list__item__value">
            <a href="hhtps://facebook.com/">
              <label>{Data?.facebook}</label>
            </a>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Facebook:</strong>
          </div>
          <div className="personal__list__item__value">
            <a href="hhtps://facebook.com/">
              <label>{Data?.tiktok}</label>
            </a>
          </div>
        </div>
        <div className="personal__list__item">
          <div className="personal__list__item__text">
            <strong>Facebook:</strong>
          </div>
          <div className="personal__list__item__value">
            <a href="hhtps://facebook.com/">
              <label>{Data?.skype}</label>
            </a>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ContactInfo;
