import React, { useState } from "react";
import { Form, Input, Select, Typography } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const { Option } = Select;

const RegistrationForm = ({ setIsSignUp }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    setLoading(true);

    try {
      const { data } = await axios({
        // url: "http://localhost:8000/api/v1/users/register",
        url: "https://das-aupair-new-app-4481db6b895a.herokuapp.com/api/v1/users/register",
        method: "POST",
        data: values,
      });

      if (data) {
        setLoading(false);
      }
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => {
        if (data) {
          navigate("/confirm/email");
        }
      }, 2000);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Form
      name="complex-form"
      onFinish={onFinish}
      wrapperCol={{
        span: 24,
      }}
    >
      <Form.Item
        style={{
          marginBottom: 0,
        }}
      >
        <Form.Item
          name="firstName"
          rules={[
            {
              required: true,
              message: "Your first name !",
            },
          ]}
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
          }}
        >
          <Input placeholder="Enter First Name" />
        </Form.Item>
        <Form.Item
          name="lastName"
          rules={[
            {
              required: true,
              message: "Your last name !",
            },
          ]}
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "0 8px",
          }}
        >
          <Input placeholder="Enter Last Name" />
        </Form.Item>
      </Form.Item>
      <Form.Item
        style={{
          marginBottom: 0,
        }}
      >
        <Form.Item
          name="phone"
          rules={[
            {
              required: true,
              message: "Your phone number !",
            },
          ]}
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
          }}
        >
          <Input type="number" min="0" placeholder="Enter your phone" />
        </Form.Item>
        <Form.Item
          name="role"
          rules={[
            {
              required: true,
              message: "Select Role !",
            },
          ]}
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "0 8px",
          }}
        >
          <Select placeholder="Select role" allowClear>
            <Option value="aupair">Aupair</Option>
            <Option value="hostfamily">Host Family</Option>
          </Select>
        </Form.Item>
      </Form.Item>
      <Form.Item
        style={{
          marginBottom: 0,
        }}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Your Email Address !",
            },
          ]}
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
          }}
        >
          <Input type="email" placeholder="Enter your email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Your password !",
            },
          ]}
          style={{
            display: "inline-block",
            width: "calc(50% - 8px)",
            margin: "0 8px",
          }}
        >
          <Input.Password placeholder="Enter your password" />
        </Form.Item>
      </Form.Item>

      <Form.Item>
        <div className="reister_actionBtns">
          <button type="submit" className="loginBtn">
            {loading ? <SyncOutlined spin /> : "Register"}
          </button>
          <span onClick={() => setIsSignUp(false)}>
            Already have account? &nbsp;
            <small
              style={{ color: "orange", cursor: "pointer", fontWeight: "bold" }}
            >
              Login
            </small>
          </span>
        </div>
      </Form.Item>
      <Form.Item>
        <Typography>
          <div>
            <p>
              By registering, you agree to the &nbsp;
              <a href="/terms">Terms & conditions</a> of services and{" "}
              <a href="/privacy">Privacy Policy</a>, including
              <a href="/cookies">Cookie Use</a>.
            </p>
          </div>
        </Typography>
      </Form.Item>
    </Form>
  );
};
export default RegistrationForm;
