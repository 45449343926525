import React, { Fragment, useEffect, useState } from "react";
import "./style.css";
import Zoom from "react-reveal/Zoom";
import Filter from "./search";
import { useNavigate } from "react-router-dom";
import { fetchBlogs } from "./../../../Redux/action/blog";
import { connect } from "react-redux";

const Blogs = ({ fetchBlogs, blogs }) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("all");
  const [filteredBlogs, setFilteredBlogs] = useState(
    blogs?.message?.data?.blogs
  );

  const handleNavigation = (blogId) => {
    navigate(`/singleblog/${blogId}`);
  };
  useEffect(() => {
    const fetchData = () => {
      fetchBlogs();
    };
    fetchData();
  }, [fetchBlogs]);

  useEffect(() => {
    if (searchTerm === "all") {
      setFilteredBlogs(blogs?.message?.data?.blogs);
    } else {
      setFilteredBlogs(
        blogs?.message?.data?.blogs?.filter(
          (blog) => blog.category === searchTerm
        )
      );
    }
  }, [blogs?.message?.data?.blogs, searchTerm]);

  return (
    <Fragment>
      <div className="blogs">
        <div className="searchfilter">
          {" "}
          {filteredBlogs && <Filter setSearchTerm={setSearchTerm} />}{" "}
        </div>
        <div className="blogs_container">
          {filteredBlogs?.map((blog) => (
            <Zoom>
              <div className="blogs_card">
                <img
                  src={blog.picture}
                  alt="blogImage"
                  onClick={() => handleNavigation(blog.uuid)}
                />
                <div className="blogs_blog_title">
                  <span>{blog.title}</span>
                </div>
                <div className="blogs_blog_text">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: blog.blogText.substr(0, 150) + " ....",
                    }}
                  />
                </div>
                <button
                  className="readmoreBtn"
                  onClick={() => handleNavigation(blog.uuid)}
                >
                  Read more &rarr;
                </button>
              </div>
            </Zoom>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    blogs: state.allBlogs.blogs,
  };
};

export default connect(mapStateToProps, {
  fetchBlogs,
})(Blogs);
