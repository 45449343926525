import React from "react";
import "./GetInvolved.css";
import Grid from "@material-ui/core/Grid";
import Welcome from "./../../../assets/WELCOME.png";
import Wallet from "./../../../assets/Wallet.png";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import { Link } from "react-router-dom";

const InvolvedAkagera = () => {
  return (
    <div className="involved-aupair">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={4} lg={4} xlg={4}>
          <Fade left>
            <div className="involved-aupair__welcome">
              <img src={Welcome} alt="get involved" />
            </div>
          </Fade>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8} xlg={8}>
          <Fade right>
            <div className="involved-aupair__descrption">
              <p>
                Akagera & Rhein® Aupair Service is very young organisation. To
                Support the cultural Exchange through the Aupair Program. Please
                be aware that your support for the continued a successful
                implementation of our networking, will be greatly appreciated.
              </p>
              <h5>
                Donate for Akagera & Rhein® Aupair Service or work with us
              </h5>
              <ul>
                <li>
                  As a fromer Aupair for for our Mentoringor Coaching our new
                  Aupairr Candidate of Host Family.
                </li>
                <li>
                  As a former Host Family for Mentoring or Coaching new Host
                  Family or new Aupair Candi- date.
                </li>
                <li>
                  As a private company ( such as Aupair insurance company,
                  Airline).
                </li>
                <li>
                  As an organisation (Association, cooperative, NGO, church,
                  syndicate, others who work- ing to support all parties
                  involved in Aupair Program)
                </li>
              </ul>
            </div>
          </Fade>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xlg={12}>
          <div className="involved-aupair__donate">
            <Zoom>
              <h4>donate now</h4>
            </Zoom>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xlg={12}>
          <Fade right>
            <Link to="/contact">
              <div className="donate__svg">
                <img src={Wallet} alt="donate" />
              </div>
            </Link>
          </Fade>
        </Grid>
      </Grid>
    </div>
  );
};

export default InvolvedAkagera;
