import React from "react";
import "./Apart.css";
import Task from "./TaskApart/Task";

const AupairApart = () => {
  return (
    <div className="apart-last">
        <h1>
          The following tasks may not be part of the aupair's area of
          responsibility.
        </h1>

      <div className="apart-container">
          <div
            whileHover={{ scale: 0.8, rotate: 5 }}
            className="apart-last-item--1"
          >
            <div className="apart-last-item--1-panel">&nbsp;</div>
          </div>
          <div
            whileHover={{ scale: 1, rotate: -5, backgroundColor: "#000" }}
            className="apart-last-item--2"
          >
            <ul>
              <li>Clean windows</li>
              <li>Spring cleaning </li>
              <li>Clean carpets </li>
              <li>Car wash </li>
              <li>Weekly shopping for the whole family </li>
              <li>Education of the pet </li>
              <li>Cooking for the whole family </li>
              <li>Ironing for the parents </li>
              <li>Make parents' beds </li>
              <li>Keep parents' bathroom clean , Gardening</li>
            </ul>
          </div>
      </div>
      <Task />
    </div>
  );
};
export default AupairApart;
