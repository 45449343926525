import {
  CREATE_AUPAIR_DOCUMENT_START,
  CREATE_AUPAIR_DOCUMENT_SUCCESS,
  CREATE_AUPAIR_DOCUMENT_FAILURE,
  GET_AUPAIR_DOCUMENTS_START,
  GET_AUPAIR_DOCUMENTS_SUCCESS,
  GET_AUPAIR_DOCUMENTS_FAILURE,
  GET_AUPAIR_DOCUMENT_START,
  GET_AUPAIR_DOCUMENT_SUCCESS,
  GET_AUPAIR_DOCUMENT_FAILURE,
  UPDATE_AUPAIR_DOCUMENT_START,
  UPDATE_AUPAIR_DOCUMENT_SUCCESS,
  UPDATE_AUPAIR_DOCUMENT_FAILURE,
  DELETE_AUPAIR_DOCUMENT_START,
  DELETE_AUPAIR_DOCUMENT_SUCCESS,
  DELETE_AUPAIR_DOCUMENT_FAILURE,
  CREATE_HOST_DOCUMENT_START,
  CREATE_HOST_DOCUMENT_SUCCESS,
  CREATE_HOST_DOCUMENT_FAILURE,
  GET_HOST_DOCUMENTS_START,
  GET_HOST_DOCUMENTS_SUCCESS,
  GET_HOST_DOCUMENTS_FAILURE,
  GET_HOST_DOCUMENT_START,
  GET_HOST_DOCUMENT_SUCCESS,
  GET_HOST_DOCUMENT_FAILURE,
  UPDATE_HOST_DOCUMENT_START,
  UPDATE_HOST_DOCUMENT_SUCCESS,
  UPDATE_HOST_DOCUMENT_FAILURE,
  DELETE_HOST_DOCUMENT_START,
  DELETE_HOST_DOCUMENT_SUCCESS,
  DELETE_HOST_DOCUMENT_FAILURE,
  REJECT_AUPAIR_DOCUMENT_START,
  REJECT_AUPAIR_DOCUMENT_SUCCESS,
  REJECT_AUPAIR_DOCUMENT_FAILURE,
  REJECT_HOST_DOCUMENT_START,
  REJECT_HOST_DOCUMENT_SUCCESS,
  REJECT_HOST_DOCUMENT_FAILURE,
  RESTORE_AUPAIR_DOCUMENT_START,
  RESTORE_AUPAIR_DOCUMENT_SUCCESS,
  RESTORE_AUPAIR_DOCUMENT_FAILURE,
} from "../action/actionTypes/types";
import * as api from "../../api/index";
import axios from "axios";

export const uploadAupairDocument = (formData) => async (dispatch) => {
  dispatch({
    type: CREATE_AUPAIR_DOCUMENT_START,
  });
  try {
    const { data } = await api.addAupairDocument(formData);
    return dispatch({
      type: CREATE_AUPAIR_DOCUMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: CREATE_AUPAIR_DOCUMENT_FAILURE,
      payload: errorMessage,
    });
  }
};

export const getAllAupairDocuments = () => async (dispatch) => {
  dispatch({
    type: GET_AUPAIR_DOCUMENTS_START,
  });
  try {
    const { data } = await axios({
      // url: "http://localhost:8000/api/v1/aupair/documents",
      url: "https://das-aupair-new-app-4481db6b895a.herokuapp.com/api/v1/aupair/documents",
      method: "GET",
      mode: "no-cors",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("profile"))?.token
        }`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    });

    return dispatch({
      type: GET_AUPAIR_DOCUMENTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: GET_AUPAIR_DOCUMENTS_FAILURE,
      payload: errorMessage,
    });
  }
};

export const getAupairDocument = (docId) => async (dispatch) => {
  dispatch({
    type: GET_AUPAIR_DOCUMENT_START,
  });
  try {
    const { data } = await api.getAupairDocument(docId);
    return dispatch({
      type: GET_AUPAIR_DOCUMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: GET_AUPAIR_DOCUMENT_FAILURE,
      payload: errorMessage,
    });
  }
};

export const updateAupairDocument = (formData, docId) => async (dispatch) => {
  dispatch({
    type: UPDATE_AUPAIR_DOCUMENT_START,
  });
  try {
    const { data } = await api.updateAupairDocument(formData, docId);
    return dispatch({
      type: UPDATE_AUPAIR_DOCUMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: UPDATE_AUPAIR_DOCUMENT_FAILURE,
      payload: errorMessage,
    });
  }
};

export const deleteAupairDocument = (docId) => async (dispatch) => {
  dispatch({
    type: DELETE_AUPAIR_DOCUMENT_START,
  });
  try {
    const { data } = await api.deleteAupairDocument(docId);
    return dispatch({
      type: DELETE_AUPAIR_DOCUMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: DELETE_AUPAIR_DOCUMENT_FAILURE,
      payload: errorMessage,
    });
  }
};

export const uploadHostDocument = (formData) => async (dispatch) => {
  dispatch({
    type: CREATE_HOST_DOCUMENT_START,
  });
  try {
    const { data } = await api.addHostDocument(formData);
    return dispatch({
      type: CREATE_HOST_DOCUMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: CREATE_HOST_DOCUMENT_FAILURE,
      payload: errorMessage,
    });
  }
};

export const getAllHostDocuments = () => async (dispatch) => {
  dispatch({
    type: GET_HOST_DOCUMENTS_START,
  });
  try {
    const { data } = await axios({
      // url: "http://localhost:8000/api/v1/host/documents",
      url: "https://das-aupair-new-app-4481db6b895a.herokuapp.com/api/v1/host/documents",
      method: "GET",
      mode: "no-cors",
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("profile"))?.token
        }`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    });

    return dispatch({
      type: GET_HOST_DOCUMENTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: GET_HOST_DOCUMENTS_FAILURE,
      payload: errorMessage,
    });
  }
};

export const getHostDocument = (docId) => async (dispatch) => {
  dispatch({
    type: GET_HOST_DOCUMENT_START,
  });
  try {
    const { data } = await api.getHostDocument(docId);
    return dispatch({
      type: GET_HOST_DOCUMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: GET_HOST_DOCUMENT_FAILURE,
      payload: errorMessage,
    });
  }
};

export const updateHostDocument = (formData, docId) => async (dispatch) => {
  dispatch({
    type: UPDATE_HOST_DOCUMENT_START,
  });
  try {
    const { data } = await api.updateHostDocument(formData, docId);
    return dispatch({
      type: UPDATE_HOST_DOCUMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: UPDATE_HOST_DOCUMENT_FAILURE,
      payload: errorMessage,
    });
  }
};

export const deleteHostDocument = (docId) => async (dispatch) => {
  dispatch({
    type: DELETE_HOST_DOCUMENT_START,
  });
  try {
    const { data } = await api.deleteHostDocument(docId);
    return dispatch({
      type: DELETE_HOST_DOCUMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: DELETE_HOST_DOCUMENT_FAILURE,
      payload: errorMessage,
    });
  }
};

export const rejectAupairDocument = (docId) => async (dispatch) => {
  dispatch({
    type: REJECT_AUPAIR_DOCUMENT_START,
  });
  try {
    const { data } = await api.rejectAupairDocument(docId);
    return dispatch({
      type: REJECT_AUPAIR_DOCUMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: REJECT_AUPAIR_DOCUMENT_FAILURE,
      payload: errorMessage,
    });
  }
};

export const unRejectAupairDocument = (docId) => async (dispatch) => {
  dispatch({
    type: RESTORE_AUPAIR_DOCUMENT_START,
  });

  try {
    const { data } = await api.unRejectAupairDocument(docId);

    return dispatch({
      type: RESTORE_AUPAIR_DOCUMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: RESTORE_AUPAIR_DOCUMENT_FAILURE,
      payload: errorMessage,
    });
  }
};
export const rejecthostDocument = (docId) => async (dispatch) => {
  dispatch({
    type: REJECT_HOST_DOCUMENT_START,
  });
  try {
    const { data } = await api.rejectHostDocument(docId);
    return dispatch({
      type: REJECT_HOST_DOCUMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    return dispatch({
      type: REJECT_HOST_DOCUMENT_FAILURE,
      payload: errorMessage,
    });
  }
};
