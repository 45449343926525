import React, { useState } from "react";
import "./Navbar.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTab } from "./../../Redux/action/tabs";
import { Modal } from "antd";
import Auth from "./../Account/Authentication";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isSignup, setIsSignUp] = useState(true);
  // const [tabs, setTabs] = useState(1);

  const { tab } = useSelector((state) => state.tab);
  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const ReturnHome = () => {
    navigate("/");
  };

  const toggleTabs = (tabIndex, path) => {
    // setTabs(tabIndex);
    navigate(`${path}`);
    dispatch(getTab(tabIndex));
  };

  return (
    <React.Fragment>
      <Modal
        title={`${isSignup}` ? "SIGNUP" : "LOGIN"}
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        width={700}
      >
        <Auth isSignup={isSignup} setIsSignUp={setIsSignUp} />
      </Modal>
      <div className="Navbar">
        <span className="nav-logo landing__logo" onClick={ReturnHome}>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/akagera-rhein-aupair.appspot.com/o/images%2FAupairImages%2Flogo-print-hd-transparent.png?alt=media&token=013ea43c-3e54-41b9-b2f9-b3839bf42821"
            alt="aupair logo"
          />
        </span>

        <div className={`nav-items ${isOpen && "open"}`}>
          <span
            onClick={() => toggleTabs(1, "/")}
            className={tab.index === 1 ? " tab activeTab" : "tab"}
          >
            HOME
          </span>
          <span
            onClick={() => toggleTabs(2, "/aupair")}
            className={tab.index === 2 ? " tab activeTab" : "tab"}
          >
            FOR AUPAIR CANDIDATES
          </span>

          <span
            onClick={() => toggleTabs(3, "/families")}
            className={tab.index === 3 ? " tab activeTab" : "tab"}
          >
            FOR HOST FAMILIES
          </span>
          <span
            onClick={() => toggleTabs(4, "/involved")}
            className={tab.index === 4 ? " tab activeTab" : "tab"}
          >
            GET INVOLVED
          </span>
          <span
            onClick={() => toggleTabs(5, "/blog")}
            className={tab.index === 5 ? " tab activeTab" : "tab"}
          >
            BLOG
          </span>
          <span
            onClick={() => toggleTabs(6, "/faq")}
            className={tab.index === 6 ? " tab activeTab" : "tab"}
          >
            FAQ
          </span>

          <span
            onClick={() => toggleTabs(7, "/contact")}
            className={tab.index === 7 ? " tab activeTab" : "tab"}
          >
            CONTACT
          </span>

          <select className="nav__items__lang">
            <option>EN</option>
            <option>FR</option>
            <option>DE</option>
          </select>

          <button className="signup__btn" onClick={showModal}>
            SIGNUP
          </button>
        </div>
        <div
          className={`nav-toggle ${isOpen && "open"}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="bar"></div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Navbar;
