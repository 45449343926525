import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useStyles } from "./style";
import Grid from "@material-ui/core/Grid";
import "./Host.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import {
  hostLivingArea,
  updateHostLivingArea,
} from "./../../../../../../../Redux/action/hostFroms";
import { toast } from "react-toastify";

const initialValues = {
  welivein: "",
  state: "",
  city: "",
};

const LivingArea = ({ hostLivingData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [createMode, setCreateMode] = useState(true);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [message, setMessage] = useState("");

  const { livingArea } = useSelector((state) => state.hostLivingArea);
  const { hostLiving } = useSelector((state) => state.updateHostLivingArea);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));

    if (Object.keys(formErrors).length === 0) {
      if (createMode) {
        setOpen(true);
        dispatch(hostLivingArea(formValues));
        clear();
      } else {
        setOpen(true);
        dispatch(updateHostLivingArea(formValues, hostLivingData.uuid));
      }
    }
  };

  const clear = () => {
    setFormValues(initialValues);
  };

  useEffect(() => {
    if (livingArea.success) {
      setOpen(false);
      setMessage(livingArea?.message?.message);
    } else if (!livingArea.success && livingArea.error !== null) {
      setOpen(false);
      setMessage("There was an error,try again");
    }
  }, [livingArea.success, livingArea?.message?.message, livingArea.error]);

  useEffect(() => {
    if (hostLiving.success) {
      setOpen(false);
      setMessage(hostLiving?.message?.message);
    } else if (!hostLiving.success && hostLiving.error !== null) {
      setOpen(false);
      setMessage("There was an error,try again");
    }
  }, [hostLiving.success, hostLiving.error, hostLiving?.message?.message]);

  useEffect(() => {
    const notify = () => {
      if (livingArea?.error && message) {
        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (livingArea?.success && message) {
        toast.success(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    if (livingArea?.success && message) {
      notify();
    } else if (!livingArea?.success && livingArea?.error !== null && message) {
      notify();
    } else {
      return;
    }
  }, [livingArea?.success, livingArea?.error, message]);

  useEffect(() => {
    const notify = () => {
      if (hostLiving?.error && message) {
        toast.error(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (hostLiving?.success && message) {
        toast.success(message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    if (hostLiving?.success && message) {
      notify();
    } else if (!hostLiving?.success && hostLiving?.error !== null && message) {
      notify();
    } else {
      return;
    }
  }, [hostLiving?.success, hostLiving?.error, message]);

  const validate = (values) => {
    const errors = {};

    if (!values.welivein) {
      errors.welivein = "This field is required!";
    }
    if (!values.state) {
      errors.state = "This field is required!";
    }
    if (!values.city) {
      errors.city = "This field is required!";
    }

    return errors;
  };

  useEffect(() => {
    if (hostLivingData) {
      setFormValues({
        welivein: hostLivingData?.welivein,
        state: hostLivingData?.state,
        city: hostLivingData?.city,
      });
      setCreateMode(false);
    } else if (!hostLivingData) {
      setCreateMode(true);
      setFormValues(initialValues);
    }
  }, [hostLivingData]);

  useEffect(() => {
    if (
      formValues.welivein === 0 ||
      formValues.state === "" ||
      formValues.city === ""
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formValues]);

  return (
    <React.Fragment>
      <CssBaseline />
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <Container maxWidth="md" className="form_container">
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="personalInformationGroup">
            <h3 className="InnerTitles">LIVING AREA</h3>
            <div className="FieldContainer">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    We Live In<span className="required">*</span>
                  </label>
                  <select
                    name="welivein"
                    value={formValues.welivein}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Country Side">Country Side</option>
                    <option value="Village">Village</option>
                    <option value="Town">Town</option>
                    <option value="Big City">Big City</option>
                    <option value="Metropole Region">Metropole Region</option>
                    <option value="All Posibilities">All Posibilities</option>
                  </select>
                </div>
                <small className="formError">{formErrors.welivein}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    In State <span className="required">*</span>
                  </label>
                  <select
                    name="state"
                    value={formValues.state}
                    onChange={handleChange}
                  >
                    <option value="">--select--</option>
                    <option value="Baden-Württemberg">Baden-Württemberg</option>
                    <option value="Bayern">Bayern</option>
                    <option value="Berlin">Berlin</option>
                    <option value="Brandenburg">Brandenburg</option>
                    <option value="Bremen">Bremen</option>
                    <option value="Hamburg">Hamburg</option>
                    <option value="Hessen">Hessen</option>
                    <option value="Mecklenburg-Vorpommern">
                      Mecklenburg-Vorpommern
                    </option>
                    <option value="Niedersachsen">Niedersachsen</option>
                    <option value="Nordrhein-Westfalen">
                      Nordrhein-Westfalen
                    </option>
                    <option value="Rheinland-Pfalz">Rheinland-Pfalz</option>
                    <option value="Saarland">Saarland</option>
                    <option value="Sachsen">Sachsen</option>
                    <option value="Sachsen-Anhalt">Sachsen-Anhalt</option>
                    <option value="Schleswig-Holstein">
                      Schleswig-Holstein
                    </option>
                    <option value="Thüringen">Thüringen</option>
                    <option value="No preference">No preference</option>
                  </select>
                </div>
                <small className="formError">{formErrors.state}</small>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <label>
                    City of <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Ex:Berlin"
                    required
                    name="city"
                    value={formValues.city}
                    onChange={handleChange}
                  />
                </div>
                <small className="formError">{formErrors.city}</small>
              </Grid>
            </div>
            <button
              className={disabled ? "disabledButton" : "submit__btn"}
              type="submit"
              disabled={disabled}
            >
              {createMode ? "Create" : "Update"}
            </button>
          </div>
        </form>
      </Container>
    </React.Fragment>
  );
};
export default LivingArea;
