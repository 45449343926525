import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export const Loader = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        marginTop: "10%",
      }}
    >
      <CircularProgress style={{ color: "orange" }} />
    </div>
  );
};
