import {
  AUPAIR_PROFILE_START,
  AUPAIR_PROFILE_SUCCESS,
  AUPAIR_PROFILE_FAILURE,
  AUPAIR_EXPECTATION_START,
  AUPAIR_EXPECTAION_SUCCESS,
  AUPAIR_EXPECTATION_FAILURE,
  AUPAIRE_OTHER_INFO_START,
  AUPAIRE_OTHER_INFO_SUCCESS,
  AUPAIRE_OTHER_INFO_FAILURE,
  AUPAIRE_WISHES_START,
  AUPAIRE_WISHES_SUCCESS,
  AUPAIRE_WISHES_FAILURE,
  AUPAIR_CONTACTS_START,
  AUPAIR_CONTACTS_SUCCESS,
  AUPAIR_CONTACTS_FAILURE,
  AUPAIR_EDUCATION_START,
  AUPAIR_EDUCATION_SUCCESS,
  AUPAIR_EDUCATION_FAILURE,
  AUPAIR_PREFERENCES_START,
  AUPAIR_PREFERENCES_SUCCESS,
  AUPAIR_PREFERENCES_FAILURE,
  GET_AUPAIRS_START,
  GET_AUPAIRS_SUCCESS,
  GET_AUPAIRS_FAILURE,
  UPDATE_AUPAIR_PROFILE_START,
  UPDATE_AUPAIR_PROFILE_SUCCESS,
  UPDATE_AUPAIR_PROFILE_FAILURE,
  UPDATE_AUPAIR_CONTACTS_START,
  UPDATE_AUPAIR_CONTACTS_SUCCESS,
  UPDATE_AUPAIR_CONTACTS_FAILURE,
  UPDATE_AUPAIR_EDUCATION_START,
  UPDATE_AUPAIR_EDUCATION_SUCCESS,
  UPDATE_AUPAIR_EDUCATION_FAILURE,
  UPDATE_AUPAIRE_OTHER_INFO_START,
  UPDATE_AUPAIRE_OTHER_INFO_SUCCESS,
  UPDATE_AUPAIRE_OTHER_INFO_FAILURE,
  UPDATE_AUPAIR_PREFERENCES_START,
  UPDATE_AUPAIR_PREFERENCES_SUCCESS,
  UPDATE_AUPAIR_PREFERENCES_FAILURE,
  UPDATE_AUPAIR_EXPECTATION_START,
  UPDATE_AUPAIR_EXPECTAION_SUCCESS,
  UPDATE_AUPAIR_EXPECTATION_FAILURE,
  UPDATE_AUPAIRE_WISHES_START,
  UPDATE_AUPAIRE_WISHES_SUCCESS,
  UPDATE_AUPAIRE_WISHES_FAILURE,
  GET_AUPAIR_PROFILE_START,
  GET_AUPAIR_PROFILE_SUCCESS,
  GET_AUPAIR_PROFILE_FAILURE,
} from "./actionTypes/types";

import * as api from "../../api/index";
import { toast } from "react-toastify";

export const aupairProfile = (formData) => async (dispatch) => {
  dispatch({ type: AUPAIR_PROFILE_START });
  try {
    const { data } = await api.aupairProfileData(formData);

    if (data) {
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    return dispatch({
      type: AUPAIR_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    toast.error("There was an Error", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return dispatch({
      type: AUPAIR_PROFILE_FAILURE,
      payload: errorMessage,
    });
  }
};

export const getAupairProfile = (userId) => async (dispatch) => {
  dispatch({ type: GET_AUPAIR_PROFILE_START });
  try {
    const { data } = await api.getAupairProfile(userId);

    return dispatch({
      type: GET_AUPAIR_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;

    return dispatch({
      type: GET_AUPAIR_PROFILE_FAILURE,
      payload: errorMessage,
    });
  }
};

export const aupairContacts = (formData) => async (dispatch) => {
  dispatch({
    type: AUPAIR_CONTACTS_START,
  });

  try {
    const { data } = await api.aupairContactsData(formData);
    if (data) {
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    return dispatch({
      type: AUPAIR_CONTACTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    toast.error("There was an Error", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return dispatch({
      type: AUPAIR_CONTACTS_FAILURE,
      payload: errorMessage,
    });
  }
};

export const aupairEducations = (formData) => async (dispatch) => {
  dispatch({
    type: AUPAIR_EDUCATION_START,
  });
  try {
    const { data } = await api.aupairEducationData(formData);
    if (data) {
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    return dispatch({
      type: AUPAIR_EDUCATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    toast.error("There was an Error", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return dispatch({
      type: AUPAIR_EDUCATION_FAILURE,
      payload: errorMessage,
    });
  }
};

export const aupairExpectation = (formData) => async (dispatch) => {
  dispatch({ type: AUPAIR_EXPECTATION_START });
  try {
    const { data } = await api.aupairExpectation(formData);
    if (data) {
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    return dispatch({
      type: AUPAIR_EXPECTAION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    toast.error("There was an Error", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return dispatch({
      type: AUPAIR_EXPECTATION_FAILURE,
      payload: errorMessage,
    });
  }
};

export const aupairOtherInfo = (formData) => async (dispatch) => {
  dispatch({ type: AUPAIRE_OTHER_INFO_START });
  try {
    const { data } = await api.aupairOtherInfo(formData);
    if (data) {
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    return dispatch({
      type: AUPAIRE_OTHER_INFO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    toast.error("There was an Error", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return dispatch({
      type: AUPAIRE_OTHER_INFO_FAILURE,
      payload: errorMessage,
    });
  }
};
export const aupairPreferences = (formData) => async (dispatch) => {
  dispatch({ type: AUPAIR_PREFERENCES_START });
  try {
    const { data } = await api.aupairPreferences(formData);
    if (data) {
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    return dispatch({
      type: AUPAIR_PREFERENCES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error.message;
    toast.error("There was an Error", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return dispatch({
      type: AUPAIR_PREFERENCES_FAILURE,
      payload: errorMessage,
    });
  }
};

export const aupairWishes = (formData) => async (dispatch) => {
  dispatch({ type: AUPAIRE_WISHES_START });
  try {
    const { data } = await api.aupairWishesData(formData);
    if (data) {
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    return dispatch({
      type: AUPAIRE_WISHES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error?.message;
    toast.error("There was an Error", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return dispatch({
      type: AUPAIRE_WISHES_FAILURE,
      payload: errorMessage,
    });
  }
};

export const getAllAupairs = () => async (dispatch) => {
  dispatch({ type: GET_AUPAIRS_START });
  try {
    const { data } = await api.allAupairsData();

    return dispatch({
      type: GET_AUPAIRS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error?.message;

    return dispatch({
      type: GET_AUPAIRS_FAILURE,
      payload: errorMessage,
    });
  }
};

export const updateAupairProfile =
  (formData, profileId) => async (dispatch) => {
    dispatch({ type: UPDATE_AUPAIR_PROFILE_START });
    try {
      const { data } = await api.updateAupairProfile(formData, profileId);

      if (data) {
        toast.success(data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return dispatch({
        type: UPDATE_AUPAIR_PROFILE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const errorMessage = error?.message;
      toast.error("There was an Error", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return dispatch({
        type: UPDATE_AUPAIR_PROFILE_FAILURE,
        payload: errorMessage,
      });
    }
  };

export const updateAupairContacts =
  (formData, contactId) => async (dispatch) => {
    dispatch({ type: UPDATE_AUPAIR_CONTACTS_START });
    try {
      const { data } = await api.updateAupairContacts(formData, contactId);
      if (data) {
        toast.success(data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return dispatch({
        type: UPDATE_AUPAIR_CONTACTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const errorMessage = error?.message;
      toast.error("There was an Error", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return dispatch({
        type: UPDATE_AUPAIR_CONTACTS_FAILURE,
        payload: errorMessage,
      });
    }
  };

export const updateAupairEducation =
  (formData, educationId) => async (dispatch) => {
    dispatch({ type: UPDATE_AUPAIR_EDUCATION_START });
    try {
      const { data } = await api.updateAupairEducation(formData, educationId);
      if (data) {
        toast.success(data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return dispatch({
        type: UPDATE_AUPAIR_EDUCATION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const errorMessage = error?.message;
      toast.error("There was an Error", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      return dispatch({
        type: UPDATE_AUPAIR_EDUCATION_FAILURE,
        payload: errorMessage,
      });
    }
  };

export const updateAupairMoreInfo = (formData, infoId) => async (dispatch) => {
  dispatch({ type: UPDATE_AUPAIRE_OTHER_INFO_START });
  try {
    const { data } = await api.updateAupairMoreInfo(formData, infoId);
    if (data) {
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    return dispatch({
      type: UPDATE_AUPAIRE_OTHER_INFO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error?.message;
    toast.error("There was an Error", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return dispatch({
      type: UPDATE_AUPAIRE_OTHER_INFO_FAILURE,
      payload: errorMessage,
    });
  }
};

export const updateAupairPreferences =
  (formData, prefId) => async (dispatch) => {
    dispatch({ type: UPDATE_AUPAIR_PREFERENCES_START });
    try {
      const { data } = await api.updateAupairPreferences(formData, prefId);
      if (data) {
        toast.success(data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return dispatch({
        type: UPDATE_AUPAIR_PREFERENCES_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const errorMessage = error?.message;
      toast.error("There was an Error", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return dispatch({
        type: UPDATE_AUPAIR_PREFERENCES_FAILURE,
        payload: errorMessage,
      });
    }
  };

export const updateAupairExpectations =
  (formData, expId) => async (dispatch) => {
    dispatch({ type: UPDATE_AUPAIR_EXPECTATION_START });
    try {
      const { data } = await api.updateAupairExpectations(formData, expId);
      if (data) {
        toast.success(data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return dispatch({
        type: UPDATE_AUPAIR_EXPECTAION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const errorMessage = error?.message;
      toast.error("There was an Error", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return dispatch({
        type: UPDATE_AUPAIR_EXPECTATION_FAILURE,
        payload: errorMessage,
      });
    }
  };

export const updateAupairWishes = (formData, wishId) => async (dispatch) => {
  dispatch({ type: UPDATE_AUPAIRE_WISHES_START });
  try {
    const { data } = await api.updateAupairWishes(formData, wishId);
    if (data) {
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    return dispatch({
      type: UPDATE_AUPAIRE_WISHES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage = error?.message;
    toast.error("There was an Error", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return dispatch({
      type: UPDATE_AUPAIRE_WISHES_FAILURE,
      payload: errorMessage,
    });
  }
};
