import React, { useState, useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Layout from "./../../Layouts/Dashboard";
import { Divider } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import { connect } from "react-redux";
import { fetchSubscribers } from "./../../../Redux/action/subscribers";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    fontSize: "20px",
    textAlign: "center",
    fontWeight: 700,
    color: "#9e9e9e",
    textTransform: "uppercase",
  },
}));

const Subscribers = ({ fetchSubscribers, subscribers }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const getAnnouncements = () => {
      fetchSubscribers();
    };

    getAnnouncements();
  }, [fetchSubscribers]);

  useEffect(() => {
    if (!subscribers.success) {
      setOpen(true);
    }
  }, [subscribers.success]);

  useEffect(() => {
    if (subscribers.success) {
      setOpen(false);
    }
  }, [subscribers.success]);

  useEffect(() => {
    if (!subscribers.success && subscribers.error !== null) {
      setOpen(false);
    }
  }, [subscribers.error, subscribers.success]);

  return (
    <Layout>
      <div>
        <Backdrop style={{ zIndex: 1000, color: "orange" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <h1 className={classes.title}>
        SUBSCRIBERS &nbsp;
        <Diversity1Icon style={{ color: "#ffe9a2" }} />
      </h1>
      <Divider />
      <List
        sx={{
          maxWidth: 360,
          width: "100%",
          bgcolor: "background.paper",
          margin: "0 auto",
        }}
      >
        {subscribers?.message?.data?.subscribers?.map((subscriber) => (
          <ListItem key={subscriber.uuid}>
            <ListItemAvatar>
              <Avatar src="https://joeschmoe.io/api/v1/random" />
            </ListItemAvatar>
            <ListItemText
              primary={subscriber.subscriber}
              secondary={moment(subscriber.createdAt).fromNow()}
            />
          </ListItem>
        ))}
      </List>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    subscribers: state.subscribers.subscribers,
  };
};
export default connect(mapStateToProps, { fetchSubscribers })(Subscribers);
