import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import FirstModal from "./firstMatchModal";
import MatchModal from "./MatchModal";
import { approveMatching } from "./../../../Redux/action/matches";
import { connect } from "react-redux";

const ConnectionCard = ({ connection, approveMatching, status }) => {
  const handleApproval = () => {
    approveMatching(connection.uuid);
  };

  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        background: "#464852",
        color: "#fff",
      }}
    >
      <CardHeader
        avatar={<Avatar src={connection?.inviterPicture} aria-label="recipe" />}
        action={<FirstModal connection={connection} />}
      />

      <button
        style={{
          background: "none",
          border: "1px solid orange",
          padding: "0px 2px",
          marginTop: "30px",
          borderRadius: "2px",
          fontSize: "14px",
        }}
        onClick={handleApproval}
      >
        {connection?.isApproved ? "Approved" : "Approve"}
      </button>

      <CardHeader
        avatar={<Avatar src={connection?.inviteePicture} aria-label="recipe" />}
        action={<MatchModal connection={connection} />}
      />
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    status: state.approveMatching.status,
  };
};

export default connect(mapStateToProps, { approveMatching })(ConnectionCard);
