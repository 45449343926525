import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CancelIcon from "@material-ui/icons/Cancel";
import { connect } from "react-redux";
import { deleteUser } from "./../../../Redux/action/staffMembers";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const DeleteModal = ({ deleteUser, userId, deteted }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDelete = () => {
    setOpen(true);
    deleteUser(userId);
  };

  useEffect(() => {
    if (deteted.success) {
      setOpen(false);
    }
  }, [deteted.success]);

  return (
    <>
      <div>
        <Backdrop style={{ zIndex: 1000, color: "#fff" }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <Tooltip title="Delete" arrow>
        <IconButton
          color="default"
          component="span"
          size="small"
          onClick={showModal}
          style={{ backgroundColor: "#3f3f3f" }}
        >
          <CancelIcon style={{ color: "#FFA500", fontSize: "20px" }} />
        </IconButton>
      </Tooltip>
      <Modal
        title="DELETE USER"
        visible={isModalVisible}
        onOk={handleOk}
        okButtonProps={{ backgroundColor: "red" }}
        onCancel={handleCancel}
        okText="Delete"
        bodyStyle={{
          backgroundColor: "#464852",
        }}
        width="20%"
        footer={null}
      >
        <p style={{ color: "#fff", fontWeight: "bold" }}>
          Are sure you want to delete this row?
        </p>
        <button
          style={{
            background: "red",
            border: "1px solid red",
            marginTop: "20px",
            fontWeight: "bold",
            broderRadius: "3px",
          }}
          onClick={handleDelete}
        >
          YES DELETE
        </button>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    deteted: state.deletedUser.deleted,
  };
};

export default connect(mapStateToProps, { deleteUser })(DeleteModal);
